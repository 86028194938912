import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import _ from 'lodash'

import { PersonCircle, People } from 'react-bootstrap-icons'

import { ContentWrapper, LoadingModal } from '../../components'

import './ToggleAgencyPage.css'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS, GENERAL } from '../../config'
import { useHistory } from 'react-router-dom'

const ToggleAgencyPage = (props) => {
    const [agencies, setAgencies] = useState()
    const [loading, setLoading] = useState(true)
    const GetAllAgency = () => {
        const { agencyId, userType } = props.user;
        
        if (userType === 'APAD') {

            if (global.store.getState().user) {
                ApiRequest.fetch({
                    method: 'get',
                    url: `${API_END_POINTS.AGENCY_APAD_ACCESS}`
                }).then((data) => {
                    // console.log("data",data);
                    setAgencies(data)
                   
                    
                }).catch(e => { }).finally(()=>{
                    setLoading(false)
                })
            }
        }
    }
        useEffect(() => {
            const delay = setTimeout(() => {
             
                GetAllAgency()
                
              }, 1500)
    
    }, [])
    const handleAgencyUpdate = async(id) =>{
        
        
        const editedData = {}
        editedData.newId = id
        if (Object.keys(editedData).length > 0) {
          await  props.requestUpdateUserProfile(editedData)
        }
        setTimeout(() => {
           
          if( String(window.location.href).split('/')[3] != 'profile'){
            window.location.href = 'apad-home'
        }
        }, 2500);
    
    
    }

// 
const navigation = useHistory()
useEffect(() => {
//  if (agencies == 'ok') {
    navigation.push('/agency-select')
//  }
}, [navigation])

const handleLogout = () => {
    navigation.push('/logout')
}

    return (

        // <ContentWrapper >
            !loading ?
            <div className='overlay d-flex justify-content-center align-items-center'>
                <Card style={{ width: '18rem',borderColor:'#362FA9' }}>
                    <Card.Body>
                        <Card.Title className='text-center'><PersonCircle color='#362FA9' size={50}></PersonCircle></Card.Title>
                        <Card.Title className='text-center'>{`${props.user.firstName}  ${props.user.lastName}`} </Card.Title>
                        <p className='text-center'>{`${props.user.email}`} </p>
                        <div class="line">
                            <span class="logo"></span>
                        </div>
                        <Card.Text>
                            <h6 className='text-center'>  Select from the below agencies to proceed:</h6>
                            {agencies != 'ok' && agencies?.length > 0 && agencies?.map((data) =>
                                <div className='agency-item' onClick={()=>handleAgencyUpdate(data.id)}>  <h6 className='my-2 px-2 py-2'><People size={20}></People> {data.name}</h6></div>
                                // <NavDropdown.Item key={data.id} className={agencyId == data.id ? 'bg-primary text-light': ''} onClick={()=>handleAgencyUpdate(data.id)}>{data.name} </NavDropdown.Item>
                            )}
                            {agencies == 'ok' && 
                                <div className=' text-center'>  <p className='my-2 px-2 py-2'>Nothing to select, try again later!</p></div>
                                // <NavDropdown.Item key={data.id} className={agencyId == data.id ? 'bg-primary text-light': ''} onClick={()=>handleAgencyUpdate(data.id)}>{data.name} </NavDropdown.Item>
                            }
                        </Card.Text>
                        <Card.Text className='text-center text-white'>
                            <a class="btn btn-danger" onClick={handleLogout}>Sign out</a>
                            </Card.Text>
                    </Card.Body>
                </Card>
            </div>
           : <LoadingModal loading={loading} />
        // </ContentWrapper>

    )
}

export default ToggleAgencyPage