import { connect } from "react-redux";
import { requestUserLogin } from "../../redux/actions";
import SignIn from "./SignIn";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    requestUserLogin
})(SignIn);