import VerifyEmailPage from "./VerifyEmailPage";
import { connect } from "react-redux";
import { requestUserLogout } from "../../redux/actions";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    requestUserLogout
})(VerifyEmailPage)