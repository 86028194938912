import { connect } from "react-redux";
import { requestUserLogout ,requestUpdateUserProfile} from "../../redux/actions";
import Sidebar from "./Sidebar";

const mapStateToProp = (state) => (
    {
        user: state.user
    }
)
export default
    connect(mapStateToProp, {
        requestUserLogout,requestUpdateUserProfile
    }
    )(Sidebar)

// export default Sidebar