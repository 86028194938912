import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ErrorModal = ({ showError, handleClose }) => {
  return (
    <Modal size="sm" centered show={showError} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p>Something went wrong.</p>
          <p>Please try again later.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
