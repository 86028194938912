import React, { Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { Card, Button, Form } from 'react-bootstrap'
import {  Map as LeafletMap,MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip } from 'react-leaflet'
import L from 'leaflet'
import moment from 'moment'
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3'
import "../../../node_modules/leaflet/dist/leaflet.css";


// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import geoPinGreen from '../../assets/svgIcon/geoPinGreen.svg'
import vehiclePin from '../../assets/svgIcon/busPin.svg';

import 'leaflet/dist/leaflet.css'
import { Download, Geo, Play, PlayBtn, Shuffle, Stop } from 'react-bootstrap-icons'

// let DefaultIcon = L.icon({
//     iconUrl: icon,
//     shadowUrl: iconShadow,
//     iconSize: [20, 30],
//     iconAnchor: [10, 30],
//     shadowSize: [20, 30],
//     shadowAnchor: [10, 30],
//     popupAnchor: [0, -31]
// });

let GeoPinGreen = L.icon({
    iconUrl: geoPinGreen,
    iconSize: [30, 30],
    iconAnchor: [15, 27],
    popupAnchor: [0, -31]
});

let VehiclePin = L.icon({
    iconUrl: vehiclePin,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40]
});

const getBounds = (markers) => {
    let leftestLat = markers[0][0]
    let highestLng = markers[0][1]
    let rightestLat = markers[0][0]
    let lowestLng = markers[0][1]

    markers.forEach(([lat, lng]) => {
        leftestLat = Math.min(lat, leftestLat)
        highestLng = Math.max(lng, highestLng)
        rightestLat = Math.max(lat, rightestLat)
        lowestLng = Math.min(lng, lowestLng)
    })

    return [[leftestLat, highestLng], [rightestLat, lowestLng]]
}

/**
 * @description Provide static/preview map with marker and polyline values
 * @param {Array} markers [[lat, lng, text]] show one or more markers depending on the lenth of position array  
 * @param {Array} polylines [[[lat, lng],[lat, lng],[lat, lng]]] 2D array of positions array to show one or more polyline
 */

const HeatMap = ({
    title,
    markers = [],
    vehicleMarker = [],
    polylines = [],
    center = [3.1390, 101.6869],
    height = '40vh',
    zoom = 10,
    polylinesPathOpt = [
        { color: 'red' }
    ],
    polylineNames = [],
    icon = GeoPinGreen,
    tripLog = [],
    tripInfo = '',
    addressPoints = [],
    istripCenterVehicle = false
}) => {
// 
    const tripLogMarkerRef = useRef()
    const tripLogPlayButtonRef = useRef()
    const tripLogSeekRef = useRef()
    const tripLogStopButtonRef = useRef()
    const map = useRef()

    let playLoop = null
    let newLoc = 0

    useEffect(() => {
        return () => {
            clearInterval(playLoop)
            playLoop = null
            newLoc = 0
            // 
        }
    })

    const handlePlayLog = (state) => {
        const markerLog = tripLogMarkerRef.current
        const playBtn = tripLogPlayButtonRef.current
        const stopBtn = tripLogStopButtonRef.current
        const seekRange = tripLogSeekRef.current

        if (state === 'play') {
            playBtn.disabled = true
            stopBtn.disabled = false

            playLoop = setInterval(() => {
                newLoc += 1
                seekRange.value = newLoc
                if (newLoc === tripLog.length - 1) {
                    playBtn.disabled = false
                    stopBtn.disabled = true

                    clearInterval(playLoop)
                    playLoop = null
                    newLoc = 0
                } else {
                    if (tripLog[newLoc]) {
                        const newLatLng = new L.LatLng(tripLog[newLoc].latitude, tripLog[newLoc].longitude)
                        markerLog.setLatLng(newLatLng)
                        markerLog.bindPopup(`Time: ${new Date(+tripLog[newLoc].timestamp).toLocaleTimeString()}`).openPopup()
                        if (istripCenterVehicle) {
                            if (map.current) {
                                map.current.flyTo(newLatLng)
                            }
                        }
                    } else {
                        playBtn.disabled = false
                        stopBtn.disabled = true
                        seekRange.value = newLoc

                        clearInterval(playLoop)
                        playLoop = null
                    }
                }
            }, [300])

        }

        if (state === 'stop') {
            playBtn.disabled = false
            stopBtn.disabled = true
            seekRange.value = newLoc

            clearInterval(playLoop)
            playLoop = null
            // 
        }
    }

    const handleTripLogSeek = (e) => {
        if (e.target.value) {
            const markerLog = tripLogMarkerRef.current
            newLoc = +e.target.value
            if ((newLoc !== tripLog.length - 1)) {
                const newLatLng = new L.LatLng(tripLog[newLoc].latitude, tripLog[newLoc].longitude)
                markerLog.setLatLng(newLatLng)
                markerLog.bindPopup(`Time: ${new Date(+tripLog[newLoc].timestamp).toLocaleTimeString()}`).openPopup()
                if (istripCenterVehicle) {
                    if (map.current) {
                        map.current.flyTo(newLatLng)
                    }
                }
            }
        }
    }
    function preferredOrder(obj, order) {
        var newObject = {};
        for (var i = 0; i < order.length; i++) {
            if (obj.hasOwnProperty(order[i])) {
                newObject[order[i]] = obj[order[i]];
            }
        }
        return newObject;
    }

    const handleExportTripLog = (log) => {
        let bodyData = String(Object.keys(preferredOrder(log[0], [
            "tripId", "timestamp", "timestampLocation", "latitude", "longitude", "speed", "bearing", "directionId","reconnect","sequence","stopId","stopName"
        ]))) + '\r\n'
        log.forEach((o) => {
            const obj = { ...o }
            const formatedDate = moment(+obj.timestamp, 'x').format('DD-MM-YYYY HH:mm:ss')
            obj.timestamp = formatedDate
         let orderedObj =   preferredOrder(obj, [
                "tripId", "timestamp","timestampLocation", "latitude", "longitude", "speed", "bearing", "directionId","reconnect","sequence","stopId","stopName"
            ]);

            bodyData += String(Object.values(orderedObj)) + '\r\n'
        })
        // 

        const filename = `history log ${tripInfo}.csv`

        let pom = document.createElement('a');
        pom.setAttribute('href', 'data:text/plain;charset=urf-8,' + encodeURIComponent(bodyData));
        pom.setAttribute('download', filename);
        pom.click();
    }

    const bounds = useMemo(() => {
        if (markers && markers.length > 0) {
            const b = getBounds(markers)
            if (map.current) {
                map.current.fitBounds(b)
            }
            return b
        }
        return null
    }, [markers, map])

    const handleWhenCreated = useCallback((m) => {
        map.current = m
    }, [])

    return (
        <Card className='text-dark' >
            <Card.Body>
                {
                    title &&
                    <Card.Title>{title}</Card.Title>
                }
                <MapContainer whenCreated={handleWhenCreated} bounds={bounds} style={{ height }} center={center} zoom={zoom} scrollWheelZoom={false}>
                <HeatmapLayer
            max = {1}
            min = {0.1}
            points={addressPoints}
            longitudeExtractor={m => m[1]}
            latitudeExtractor={m => m[0]}
            intensityExtractor={m => parseFloat(m[2])} 
            
            />
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        // url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
                        // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                        url="http://a.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
                    />
                    {
                        markers && markers.length > 0 &&
                        markers.map(([lat, lng, text], key) => (
                            <Fragment key={key}>
                            <Marker riseOnHover  icon={icon} position={[lat, lng]}>
                            {/* {key+1} */}
                                <Popup ><Geo size={15} className='mr-2 mb-1' />{key+1}-{text}</Popup>
                                <Tooltip>{key+1}-{text}</Tooltip>
                            </Marker>
                            </Fragment>
                        ))
                    }
                    {
                        polylines && polylines.length > 0 &&
                        polylines.map((position, key) => (
                            <Polyline pathOptions={polylinesPathOpt[key]} key={key} positions={position} >
                                <Tooltip>{polylineNames[key] || 'Route'}</Tooltip>
                                {
                                    polylineNames.length > 0 && polylineNames[key] &&
                                    <Popup><Shuffle size={15} className='mr-2 mb-1' color={polylinesPathOpt[key].color} />{polylineNames[key]}</Popup>
                                }
                            </Polyline>
                        ))
                    }
                   
                </MapContainer>
            </Card.Body>
            
        </Card>
    )
}

export default HeatMap