import React, { useMemo } from 'react'
import { Button, Row } from 'react-bootstrap'
import moment from 'moment'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { ContentWrapper, StatCard, Table } from '../../components'
import { Utils } from '../../config'

import './DailyTransactionReportPage.css'

const DailyTransactionReportPage = () => {
    const state = useLocation().state
    const goTo = useHistory()

    const transactionHeader = useMemo(() => [
        {
            Header: 'Route',
            accessor: 'routeId_',
            // disableFilters: true
        },
        {
            Header: 'Driver',
            accessor: 'driverId_',
            // disableFilters: true
        },
        {
            Header: 'Date & Time',
            accessor: 'datetime_',
            // disableFilters: true
        },
        {
            Header: 'Pick Up',
            accessor: 'startStopName',
            // disableFilters: true
        },
        {
            Header: 'Drop Off',
            accessor: 'endStopName',
            // disableFilters: true
        },
        {
            Header: 'Trip ID',
            accessor: 'tripId',
            // disableFilters: true
        },
        {
            Header: 'Vehicle No.',
            accessor: 'vehicleNo_',
            // disableFilters: true
        },
        {
            Header: 'Ridership',
            accessor: 'noOfPax',
            // disableFilters: true
        },
        {
            Header: 'Amount',
            accessor: 'amount_',
            disableFilters: true
        },
    ], [])

    if (!state) {
        return <Redirect to='/settlement' />
    }

    const { data: transaction, date, summary } = state
    
    const appendAction = (data) => {
        if (!data.length > 0) return []
        data.map((row, i) => {
            row['datetime_'] = moment(row.createdAt).format('HH:mm DD MMM YYYY')
            row['driverId_'] = `${row.driver.firstName} ${row.driver.lastName}`
            row['routeId_'] = row.route.shortName
            row['vehicleNo_'] = row.vehicle.registrationNumber
            row['amount_'] = <b className='text-dark' style={{ fontSize: '1.5rem' }} >{Utils.currencyFormatter(row.amount)}</b>
        })
        return data
    }
        
    return (
        <ContentWrapper >
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <h2 className='mb-3' >Transaction on {date}</h2>
            {
                summary && transaction &&
                <Row>
                    <StatCard lg={3} md={6} sm={12} data={summary.ridership} text='Total Ridership' />
                    <StatCard lg={3} md={6} sm={12} data={transaction.length} text='Total Transaction' />
                    <StatCard lg={3} md={6} sm={12} data={summary.netPayable} text='Net Payable' />
                    <StatCard lg={3} md={6} sm={12} data={summary.grossFare} text='Gross Revenue' />
                </Row>
            }
            {
                transaction?.length > 0 &&
                <Table numbering columns={transactionHeader} data={appendAction(transaction)} />
            }
        </ContentWrapper>
    )
}

export default DailyTransactionReportPage