export const exportData = (data, filename, type = "text/plain") => {
    var blob = new Blob([data]);
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    }
    else {
        var a = window.document.createElement("a");

        a.href = window.URL.createObjectURL(blob, {
            type
        });
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}