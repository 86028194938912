import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'
import { async } from 'regenerator-runtime'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(moment().startOf('month').format("YYYY-MM-DD 00:00:00"))
    const [selectToDate, setToDate] = useState(moment().endOf('month').format("YYYY-MM-DD 23:59:59"))
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }
        
        

        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        // document.getElementById('date-to').value = ''
        setFromDate(moment().startOf('month').format("YYYY-MM-DD 00:00:00"))
        setToDate(moment().endOf('month').format("YYYY-MM-DD 23:59:59"))
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        onClearQuery()
    }
    const setperiod = async(e) =>{
        
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)
        // e.currentTarget.value && 
        // await setFromDate(e.currentTarget.value ? `moment(${e.currentTarget.value}).startOf('month').format("YYYY-DD-MM 00:00:00")` : e.currentTarget.value)
        // await setToDate(e.currentTarget.value ? `moment(${mon}).endOf('month').format("YYYY-DD-MM 00:00:00")` : e.currentTarget.value)
        // setTimeout(() => {
            
        //     
        // }, 3000);
    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='Month:'>
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM')} onChange={setperiod
                                        } type='month' />
                                    </SimpleInputGroup>
                                </Col>
                                {/* <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control id='date-to' min={moment(selectFromDate).add(1, 'day').format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col> */}
                            </Row>
                            {/* <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="Weekend / Weekday" >
                                <Form.Control value={weekendWeekday} onChange={(e) => setWeekendWeekday(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Weekend</option>
                                    <option>Weekday</option>
                                </Form.Control>
                            </SimpleInputGroup> */}
                            {/*  */}
                            {/* <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Paid By' >
                                <Form.Control value={paidBy} onChange={(e) => setPaidBy(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Cash</option>
                                    <option>Cashless</option>
                                </Form.Control>
                            </SimpleInputGroup> */}
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const SalesReportPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)
    const [ultraFilteredTripCollection, setUltraFilteredTripCollection] = useState(null)
    // const [toDate,setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    // const [fromDate,setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    const [routesData, setRoutesData] = useState({})

    const handleGetTransactionHistory = async () => {
        setLoading(true)
        try {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.CLAIM_COLLECTION}`,
                params: {
                    timestamp: new Date()
                }
            })
            // 
            setTransaction(data)
            const sortedData = _.orderBy(data, [({ startedAt }) => new Date(startedAt)], ['desc'])
            // 

            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            const groupedData = _.groupBy(addedLocalTime, 'localDate')
             
            // setTripCollection(groupedData)
            const groupedDataReport =  _.groupBy(addedLocalTime, item => `${item.localDate}+${item.routeName}`)
             
            // setUltraFilteredTripCollection(groupedDataReport)
        } catch (error) { }
        finally {
            setLoading(false)
        }
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then(async (data) => {
            await setRoutesArr(data)
            // handleRouteData()
        }).catch(e => { })
    }


    useEffect(() => {
          const routeWithStops = {}
        // 
        routesArr && routesArr.forEach(({id})=>{
            if(!routeWithStops[id]){
                ApiRequest.fetch({
                            method: 'get',
                            url: `${API_END_POINTS.STOP_GET_ALL}`,
                            params: {
                                routeId : id
                            }
                        }).then((data) => {
                            // 
                            // return data
                            routeWithStops[id] = data
                        })
            }
        })
        setRoutesData(routesData => ({...routesData,...routeWithStops}))
        // 
        // 

    }, [routesArr]);
    

    // const handleRouteData = () => {
    //     let routeWithStops = {}
    //     
    //     routesArr && routesArr.forEach(({id})=>{
    //         if(!routeWithStops[id]){
    //             routeWithStops[id] = () =>{ ApiRequest.fetch({
    //                         method: 'get',
    //                         url: `${API_END_POINTS.STOP_GET_ALL}`,
    //                         params: {
    //                             routeId : id
    //                         }
    //                     }).then((data) => {
    //                         
    //                         return data
    //                     })}
    //         }
    //     })
    //     setRoutesData(routeWithStops)
    //     
    
    // };
    

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    useEffect(() => {
        handleGetTransactionHistory()
     handleGetAllRoute()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Date',
            accessor: 'localTimeGroup_',
            // disableFilters: true
        },
        {
            Header: 'PAX',
            accessor: 'totalRidership_',
            // disableFilters: true
        },
        {
            Header: 'Total Collection',
            accessor: 'totalAmount_',
            // disableFilters: true
        },
        
         

    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = filteredTripCollection || tripCollection
        if (!mainData) return []
        // 
        Object.entries(mainData).forEach(([localTimeGroup, trxs]) => {
            // 
            const accumulativeTrip = {
                'datetime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                'checkoutTime_': moment(trxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss'),
                'uniqueTrip_': new Set(),
                'uniqueScheduledTrip_': new Set(),
                'totalTripCount_': 0,
                'uniqueIbTrip_': new Set(),
                'onTimeTripCount_': 0,
                'uniqueOnTimeTrip_': new Set(),
                'ibTripCount_': 0,
                'uniqueObTrip_': new Set(),
                'obTripCount_': 0,
                'uniqueObIbTrip_': new Set(),
                'obIbTripCount_': 0,
                'tripCompilanceCount_': 0,
                'uniqueDriver_': new Set(),
                'totalUniqueDriverCount_': 0,
                'uniqueVehicle_': new Set(),
                'totalUniqueVehicleCount_': 0,
                'uniqueJourney_': new Set(),
                'totalTransaction_': 0,
                'totalAmount_': 0,
                'noOfAdult': 0,
                'noOfChild': 0,
                'noOfSenior': 0,
                'totalChild': 0,
                'totalSenior': 0,
                'totalAdult': 0,
                'noOfOku': 0,
                'noOfForeignAdult': 0,
                'noOfForeignChild': 0,
                'totalRidership_': 0,
                'cashTotalAmount_': 0,
                'cashTotalRidership_': 0,
                'cashlessTotalAmount_': 0,
                'cashlessTotalRidership_': 0,
            }
            trxs.map((row) => {
                // 
                const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                accumulativeTrip['uniqueDriver_'].add(row.driverName)
                accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                accumulativeTrip['uniqueTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 2 && accumulativeTrip['uniqueIbTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 1 && accumulativeTrip['uniqueObTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 0 && accumulativeTrip['uniqueObIbTrip_'].add(row.tripId)
                accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                accumulativeTrip['totalAmount_'] += +row.amount
                accumulativeTrip['noOfAdult'] += +row.noOfAdult
                accumulativeTrip['noOfChild'] += +row.noOfChild
                accumulativeTrip['noOfSenior'] += +row.noOfSenior
                accumulativeTrip['noOfOku'] += +row.noOfOku
                accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                accumulativeTrip['totalRidership_'] += totalPax
                
                accumulativeTrip['cashTotalAmount_'] += row.userId ? 0 : +row.amount
                accumulativeTrip['cashTotalRidership_'] += row.userId ? 0 : totalPax
                
                accumulativeTrip['cashlessTotalAmount_'] += row.userId ? +row.amount : 0
                accumulativeTrip['cashlessTotalRidership_'] += row.userId ? totalPax : 0
                if (row.scheduledAt && row.startedAt && (Math.abs(Number(moment(row.scheduledAt).format('X'))) - Math.abs(Number(moment(row.startedAt).format('X')))) <= 500) {
                    accumulativeTrip['uniqueOnTimeTrip_'].add(row.tripId)
                } else {
                    
                }
                // row.scheduledAt && row.startedAt ? Math.abs(Number(moment(row.scheduledAt).format('X'))) - Math.abs(Number(moment(row.startedAt).format('X'))) <= 500  ? accumulativeTrip['uniqueOnTimeTrip_'].add(row.tripId) : '' : ''
            })

            accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
            accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
            accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
            accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
            accumulativeTrip['totalAdult'] = accumulativeTrip.noOfAdult
            accumulativeTrip['ibTripCount_'] = accumulativeTrip.uniqueIbTrip_.size
            accumulativeTrip['obTripCount_'] = accumulativeTrip.uniqueObTrip_.size
            accumulativeTrip['obIbTripCount_'] = accumulativeTrip.uniqueObIbTrip_.size
            accumulativeTrip['completeTripCount_'] = accumulativeTrip.uniqueIbTrip_.size + accumulativeTrip.uniqueObTrip_.size + accumulativeTrip.uniqueObIbTrip_.size
            accumulativeTrip['tripCompilanceCount_'] = (accumulativeTrip['completeTripCount_'] / accumulativeTrip['totalTripCount_'] * 100).toFixed(2)
            accumulativeTrip['localTimeGroup_'] = String(localTimeGroup).split("+")[0] 
            accumulativeTrip['trxs'] = trxs
            accumulativeTrip['punctuality'] = accumulativeTrip.uniqueOnTimeTrip_.size
            accumulativeTrip['punctualityP'] = (accumulativeTrip.uniqueOnTimeTrip_.size / accumulativeTrip['totalTripCount_'] * 100).toFixed(2)
            

            //format amount
            accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
            accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
            accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

            returnData.push(accumulativeTrip)
        })
        // 
        return returnData
    }, [tripCollection, filteredTripCollection])

    // claim report data start
    const claimData = useMemo(() => {
    const returnData = []
    
    // 
    tabulated.forEach(({
        trxs,
        localTimeGroup_,
        totalAmount_,
        totalRidership_,
        totalTripCount_,
        cashTotalAmount_,
        cashTotalRidership_,
        cashlessTotalAmount_,
        cashlessTotalRidership_,
    }) => {
        
        const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))
        // 
        //
        // 
        uniqueTrips.forEach((sameTripTrxs) => {
            // 
            const totalByTrip = {
                totalPax: 0,
                totalAmount:0,
                cash: 0,
                cashPax: 0,
                cashless: 0,
                cashlessPax: 0,
                cashAdult: 0,
                cashChild: 0,
                cashSenior: 0,
                cashOku: 0,
                cashFAdult: 0,
                cashFChild: 0,
                cashlessAdult: 0,
                cashlessChild: 0,
                cashlessSenior: 0,
                cashlessOku: 0,
                cashlessFAdult: 0,
                cashlessFChild: 0,
                trxsTime:[]
            }
            sameTripTrxs.forEach(({
                userId,
                amount,
                noOfAdult,
                noOfChild,
                noOfSenior,
                noOfOku,
                noOfForeignAdult,
                noOfForeignChild,
                journeyCreated,
                journeyEnded
            }) => {
                const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild
                totalByTrip.routeId = sameTripTrxs[0].routeId
                totalByTrip.routeName = sameTripTrxs[0].routeName
                totalByTrip.tripId = sameTripTrxs[0].tripId
                totalByTrip.tripStart = moment(sameTripTrxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)')
                totalByTrip.tripEnd = moment(sameTripTrxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss (ddd)')
                totalByTrip.status = sameTripTrxs[0].endedAt != null ? 'COMPLETE' : 'NOT COMPLETE'
                totalByTrip.busPlate = sameTripTrxs[0].vehicleRegistrationNumber
                totalByTrip.driverIdentification = sameTripTrxs[0].driverIdentificationNumber
                totalByTrip.direction = sameTripTrxs[0].obIb == 1 ? 'OB' : sameTripTrxs[0].obIb == 2 ? 'IB' : 'OBIB'
                // totalByTrip.totalAmount += Number(sameTripTrxs[0].amount)
                totalByTrip.noOfAdult = Number(noOfAdult) + Number(noOfForeignAdult)
                totalByTrip.noOfChild = Number(noOfChild) + Number(noOfForeignChild)
                totalByTrip.noOfSenior = Number(noOfSenior)
                totalByTrip.cash += userId ? 0 : amount
                totalByTrip.cashPax += userId ? 0 : totalPax
                totalByTrip.cashless += userId ? amount : 0
                totalByTrip.totalAmount +=  amount 
                totalByTrip.cashlessPax += userId ? totalPax : 0
                totalByTrip.cashAdult += userId ? 0 : noOfAdult
                totalByTrip.cashChild += userId ? 0 : noOfChild
                totalByTrip.cashSenior += userId ? 0 : noOfSenior
                totalByTrip.cashOku += userId ? 0 : noOfOku
                totalByTrip.cashFAdult += userId ? 0 : noOfForeignAdult
                totalByTrip.cashFChild += userId ? 0 : noOfForeignChild
                totalByTrip.cashlessAdult += userId ? noOfAdult : 0
                totalByTrip.cashlessChild += userId ? noOfChild : 0
                totalByTrip.cashlessSenior += userId ? noOfSenior : 0
                totalByTrip.cashlessOku += userId ? noOfOku : 0
                totalByTrip.cashlessFAdult += userId ? noOfForeignAdult : 0
                totalByTrip.cashlessFChild += userId ? noOfForeignChild : 0
                totalByTrip.punctuality = sameTripTrxs[0].scheduledAt && sameTripTrxs[0].startedAt ?  Math.abs(Number(moment(sameTripTrxs[0].scheduledAt).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].startedAt).format('X'))) <= 500  ? 'ON TIME' : '' : ' '
                totalByTrip.trxsTime.push(userId ? moment(journeyCreated).format('X') : moment(journeyEnded).format('X'))
                totalByTrip.localTimeGroup_ = localTimeGroup_
                
                
            })
            // 
            // const handleGetStops = (routeId) => {
                //     ApiRequest.fetch({
                    //         method: 'get',
                    //         url: `${API_END_POINTS.STOP_GET_ALL}`,
                    //         params: {
                        //             routeId : routeId
                        //         }
                        //     }).then((data) => {
                            //         // 
                            //         return data
                            //     }).catch(e => { })
                            // }
                            // const busStops = handleGetStops(sameTripTrxs[0].routeId)
                            // 
                            // totalByTrip.busStops = Number(busStops) 
                            totalByTrip.busStops = routesData[sameTripTrxs[0].routeId]
                            totalByTrip.salesStart = moment.unix(Math.min(totalByTrip.trxsTime)).format('DD-MM-YYYY HH:mm:ss (ddd)');
            totalByTrip.salesEnd = moment.unix(Math.max(totalByTrip.trxsTime)).format('DD-MM-YYYY HH:mm:ss (ddd)');
            returnData.push(totalByTrip);
        })
        // data += `\r\n, , TOTAL, , , , , , ${cashTotalAmount_}, ${cashTotalRidership_}, , , , , , , ${cashlessTotalAmount_}, ${cashlessTotalRidership_}, , , , , , , ${totalRidership_}\r\n`
    }) 
    return returnData
  }, [tabulated]);
//   
    // claim report data end

    const handleFilterTransactionData = useCallback(({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        if (!transaction) return []
        const filtered = transaction.filter(({ createdAt,startedAt, routeShortName, driverName, vehicleRegistrationNumber, userId }) => {
            let returnVal = true
            // if (amPm !== 'All') {
            //     returnVal = String(moment(createdAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
            //     if (!returnVal) return false
            // }

            // if (weekendWeekday !== 'All') {
            //     const isWeekendWeekday = WEEKEND_DAY_NUM.includes(new Date(createdAt).getDay()) ? 'Weekend' : 'Weekday'
            //     returnVal = isWeekendWeekday === weekendWeekday
            //     if (!returnVal) return false
            // }

            if (selectFromDate) {
                // setFromDate(moment(selectFromDate).format('DD-MM-YYYY'))
                // 
                returnVal = new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                // setToDate(moment(selectToDate).format('DD-MM-YYYY'))
                returnVal = new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false
            }

            // if (vehicle) {
            //     returnVal = vehicleRegistrationNumber === vehicle
            //     if (!returnVal) return false
            // }

            // if (driver) {
            //     returnVal = driverName === driver
            //     if (!returnVal) return false
            // }

            // if (paidBy !== 'All') {
            //     returnVal = userId ? 'cashless' : 'cash' === String(paidBy).toLowerCase()
            //     if (!returnVal) return false
            // }

            return true
        })

        const sortedData = _.orderBy(filtered, [({ startedAt }) => new Date(startedAt)], ['desc'])
        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        const groupedData = _.groupBy(addedLocalTime, 'localDate')
        setFilteredTripCollection(groupedData)
        
        const groupedDataReportt =  _.groupBy(addedLocalTime, item => `${item.routeName}`)
             
            setUltraFilteredTripCollection(groupedDataReportt)
        
    }, [transaction])

    
    
    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
        handleGetTransactionHistory()
    }, [])

    const generateExportCsvData = useCallback(() => {
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')
        const dateGenerated = `Generated At:, ${new Date().toString()}\r\n`

        let data = dateGenerated + '\r\n\n';

        // latest start
        
        // const returnData = []
        const mainData = ultraFilteredTripCollection ||filteredTripCollection || tripCollection
        if (!mainData) return []
        
        Object.entries(mainData).forEach(([routeName, trxs]) => {
            const sortedData = _.orderBy(trxs, [({ startedAt }) => new Date(startedAt)], ['desc'])
            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            const groupedData = _.groupBy(addedLocalTime, 'localDate')
            const currMonth = Object.keys(groupedData)[0].split("-")[1]
            const currMonthName = moment().month(currMonth - 1).format('MMMM')
            const dateForm = Object.keys(groupedData)[0].split(" ")[0]
            const noOfDays = moment(dateForm, "DD-MM-YYYY").daysInMonth()
            const dateArr = Array(Number(noOfDays) + 1).fill(0)
            const datesArr = Array.from(Array(noOfDays), (_, index) => index + 1)
            // dateArr[0] = 'null'
            const ibArrPax = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obArrPax = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obibArrPax = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const ibArrAmount = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obArrAmount = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obibArrAmount = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const totalTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const ibTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const obibTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const completedTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const tripComplianceArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const ontimeTripArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const punctualPercentArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const vehicleArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const fareboxArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            const ridershipArr = Array(Number(noOfDays) + 1).fill(0, 1, Number(noOfDays) + 1)
            
            // 
            Object.entries(groupedData).forEach(([createdAt,startedAt, trxs]) => {
                
                const accumulativeTrip = {
                    'datetime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                    'checkoutTime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss'),
                    'uniqueTrip_': new Set(),
                    'uniqueScheduledTrip_': new Set(),
                    'totalTripCount_': 0,
                    'uniqueIbTrip_': new Set(),
                    'onTimeTripCount_': 0,
                    'uniqueOnTimeTrip_': new Set(),
                    'ibTripCount_': 0,
                    'uniqueObTrip_': new Set(),
                    'obTripCount_': 0,
                    'uniqueObIbTrip_': new Set(),
                    'obIbTripCount_': 0,
                    'tripCompilanceCount_': 0,
                    'uniqueDriver_': new Set(),
                    'totalUniqueDriverCount_': 0,
                    'uniqueVehicle_': new Set(),
                    'totalUniqueVehicleCount_': 0,
                    'uniqueJourney_': new Set(),
                    'totalTransaction_': 0,
                    'totalAmount_': 0,
                    'ibPax': 0,
                    'obPax': 0,
                    'obibPax': 0,
                    'ibAmt': 0,
                    'obAmt': 0,
                    'obibAmt': 0,
                    'noOfAdult': 0,
                    'noOfChild': 0,
                    'noOfSenior': 0,
                    'totalChild': 0,
                    'totalSenior': 0,
                    'totalAdult': 0,
                    'noOfOku': 0,
                    'noOfForeignAdult': 0,
                    'noOfForeignChild': 0,
                    'totalRidership_': 0,
                    'cashTotalAmount_': 0,
                    'cashTotalRidership_': 0,
                    'cashlessTotalAmount_': 0,
                    'cashlessTotalRidership_': 0,
                }
                trxs.forEach((row) => {
                

                const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                accumulativeTrip['uniqueDriver_'].add(row.driverName)
                accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                accumulativeTrip['uniqueTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 2 && accumulativeTrip['uniqueIbTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 1 && accumulativeTrip['uniqueObTrip_'].add(row.tripId)
                row.endedAt && row.obIb == 0 && accumulativeTrip['uniqueObIbTrip_'].add(row.tripId)
                if(row.endedAt && row.obIb == 2){
                    accumulativeTrip['ibPax'] += totalPax 
                    accumulativeTrip['ibAmt'] += +row.amount
                }

                if(row.endedAt && row.obIb == 1){
                    accumulativeTrip['obPax'] += totalPax 
                    accumulativeTrip['obAmt'] += +row.amount
                }
                if(row.endedAt && row.obIb == 0){
                    accumulativeTrip['obibPax'] += totalPax 
                    accumulativeTrip['obibAmt'] += +row.amount
                }
                accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                accumulativeTrip['totalAmount_'] += +row.amount
                accumulativeTrip['noOfAdult'] += +row.noOfAdult
                accumulativeTrip['noOfChild'] += +row.noOfChild
                accumulativeTrip['noOfSenior'] += +row.noOfSenior
                accumulativeTrip['noOfOku'] += +row.noOfOku
                accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                accumulativeTrip['totalRidership_'] += totalPax
                
                accumulativeTrip['cashTotalAmount_'] += row.userId ? 0 : +row.amount
                accumulativeTrip['cashTotalRidership_'] += row.userId ? 0 : totalPax
                
                accumulativeTrip['cashlessTotalAmount_'] += row.userId ? +row.amount : 0
                accumulativeTrip['cashlessTotalRidership_'] += row.userId ? totalPax : 0
                accumulativeTrip['localTimeGroup_'] = row.startedAt ? moment(row.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined' 
                if (row.scheduledAt && row.startedAt && (Math.abs(Number(moment(row.scheduledAt).format('X'))) - Math.abs(Number(moment(row.startedAt).format('X')))) <= 500) {
                    accumulativeTrip['uniqueOnTimeTrip_'].add(row.tripId)
                } else {
                    
                }
            })
                // row.scheduledAt && row.startedAt ? Math.abs(Number(moment(row.scheduledAt).format('X'))) - Math.abs(Number(moment(row.startedAt).format('X'))) <= 500  ? accumulativeTrip['uniqueOnTimeTrip_'].add(row.tripId) : '' : ''
                
                accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
                accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
                accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
                accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
            accumulativeTrip['totalAdult'] = accumulativeTrip.noOfAdult
            accumulativeTrip['ibTripCount_'] = accumulativeTrip.uniqueIbTrip_.size
            accumulativeTrip['obTripCount_'] = accumulativeTrip.uniqueObTrip_.size
            accumulativeTrip['obIbTripCount_'] = accumulativeTrip.uniqueObIbTrip_.size
            accumulativeTrip['completeTripCount_'] = accumulativeTrip.uniqueIbTrip_.size + accumulativeTrip.uniqueObTrip_.size + accumulativeTrip.uniqueObIbTrip_.size
            accumulativeTrip['tripCompilanceCount_'] = (accumulativeTrip['completeTripCount_'] / accumulativeTrip['totalTripCount_'] * 100).toFixed(2)
            accumulativeTrip['trxs'] = trxs
            accumulativeTrip['date'] = String(accumulativeTrip['localTimeGroup_']).slice(0,2)
            accumulativeTrip['punctuality'] = accumulativeTrip.uniqueOnTimeTrip_.size
            accumulativeTrip['punctualityP'] = (accumulativeTrip.uniqueOnTimeTrip_.size / accumulativeTrip['totalTripCount_'] * 100).toFixed(2)
            

            //format amount
            accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
            accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
            accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

            // returnData.push(accumulativeTrip)
            // 
            ibArrPax[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['ibPax'])
            ibArrAmount[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['ibAmt'])
            obArrPax[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['obPax'])
            obArrAmount[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['obAmt'])
            obibArrPax[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['obibPax'])
            obibArrAmount[Number(accumulativeTrip['date'])] = Number(accumulativeTrip['obibAmt'])
            // dateArr[Number(accumulativeTrip['date'])] = Number(String(accumulativeTrip['localTimeGroup_']).slice(0, 2))
            // totalTripArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['totalTripCount_']))
            // ibTripArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['ibTripCount_']))
            // obTripArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['obTripCount_']))
            // obibTripArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['obIbTripCount_']))
            // completedTripArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['completeTripCount_']))
            // tripComplianceArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['tripCompilanceCount_']))
            // ontimeTripArr[Number(accumulativeTrip['date'])] = (accumulativeTrip['punctuality'])
            // punctualPercentArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['punctualityP']))
            // vehicleArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['totalUniqueVehicleCount_']))
            // fareboxArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['totalAmount_']))
            // ridershipArr[Number(accumulativeTrip['date'])] = Number((accumulativeTrip['totalRidership_']))
         
        })
        
        
        
        function mergeArrays(first, second) {
            var min = Math.min(first.length, second.length),
                i = 1,
                result = [];
        
            while (i < min) {
                result.push(first[i], second[i]);
                ++i;
            }
            return result.concat(first.slice(min), second.slice(min));
        }
        const finalIb = mergeArrays(ibArrPax,ibArrAmount)
        const finalOb = mergeArrays(obArrPax,obArrAmount)
        const finalObib = mergeArrays(obibArrPax,obibArrAmount)
        data += `,,,,,,,,,,,,${currMonthName},\r\n`
        data += `Route ,RouteId,IB/OB,${datesArr.join(`,${currMonthName},`)}\r\n`
        data += `${routeName},${trxs[0].routeId},,${new Array(noOfDays).fill(['PAX','Total Collection']).flat()}\r\n`
        data += `,,"IB",${finalIb.join(",")}\r\n`
        data += `,,"OB",${finalOb.join(",")}\r\n`
        data += `,,"Loop",${finalObib.join(",")}\r\n`
     
        
        })
        

    var BOM = "\uFEFF"; 
        var csvContent = BOM + data;
        exportData(csvContent, `Sales Collection ${moment().format('YYYYMMDD_HHmmss')}.csv`, 'text/csv;charset=utf-8;')
    }, [tabulated])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Sales Report</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        transaction && transaction.length > 0 ?
                            <div>
                                {
                                    routesArr && driverList && vehicleList ?
                                        <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' > <InfoCircle /> Sales Report only shows data for the past 1 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={tabulated} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default SalesReportPage