import { Button, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap"
import ModalHeader from "react-bootstrap/esm/ModalHeader"

const ConfirmModal = ({ description = 'This is sample description', title = 'This is sample title', onConfirm = () => { }, onCancel = () => { }, open = false }) => {
    return (
        <Modal style={{ zIndex: 9999, backgroundColor: 'rgba(0,0,0,.3)' }} onHide={onCancel} size='md' centered show={open} >
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
                {description}
            </ModalBody>
            <ModalFooter>
                <Button variant="warning" className='mr-2' onClick={onCancel} >Cancel</Button>
                <Button onClick={onConfirm} >Confirm</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmModal