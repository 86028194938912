import React, { useEffect, useState } from "react";
import { CardImage } from "react-bootstrap-icons";
import { Card, Image, Row, Col, Form, DropdownButton, Dropdown, InputGroup, Button } from "react-bootstrap";

import {
    Modal,
    SimpleInputGroup,
} from "../../components";
import { ApiRequest } from "../../helpers";
import { API_END_POINTS } from "../../config";

import "./TripExperiencePage.css";

const ReadOnlyInput = ({ label, value, sm, md, lg, rows, preappendTextClassName, readOnly = true }) => (
    <Col sm={sm} md={md} lg={lg}>
        <SimpleInputGroup preappendTextClassName={preappendTextClassName} labelWidth={null} preappendText={label}>
            <Form.Control as={rows && 'textarea'} rows={rows && rows} className='bg-white text-dark' id={label} readOnly={readOnly} value={value} />
        </SimpleInputGroup>
    </Col>
);

const FeedbackDetailsModal = ({
    open = false,
    onClose = () => { },
    data = null,
    submitUpdate = () => { }
}) => {
    const [attURL, setAttURL] = useState(null)
    const [newNote, setNewNote] = useState(null)

    const handleGetAttachmentUrl = (att) => {
        ApiRequest.fetch({
            method: "get",
            url: `${API_END_POINTS.GET_ATTACHMENT_URL}/${att}`,
        })
            .then(({ url }) => {
                setAttURL(url);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        if (data?.attachment) {
            handleGetAttachmentUrl(data.attachment);
        }
    }, [data]);

    if (!data) {
        return null;
    }

    const handleSubmitChanges = (modifiedCaseStatus) => {
        const mdata = {}
        if (modifiedCaseStatus) {
            if (data.caseStatus !== modifiedCaseStatus) {
                mdata.status = modifiedCaseStatus
            }
        }

        if (newNote) {
            mdata.notes = newNote
        }

        if (Object.keys(mdata).length > 0) {
            mdata.caseId = data.caseId
            submitUpdate(mdata)
            setNewNote(null)
        }
    }

    return (
        <Modal closeButton show={open} onHide={onClose}>
            <Card>
                <Card.Body className='mb-5'>
                    <Card.Title >Case Report</Card.Title>
                    <Row>
                        <Col sm={6} >
                            <InputGroup size='sm' className='my-2' title='Update Case Status' >
                                <InputGroup.Prepend >
                                    <InputGroup.Text>Case Status</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DropdownButton
                                    variant={data.caseStatus === 'Open' ? 'danger' : 'success'}
                                    title={data.caseStatus}
                                    id="case-status-dropdown"
                                    size='sm'
                                    onSelect={handleSubmitChanges}
                                >
                                    <Dropdown.Item eventKey='Open' active={data.caseStatus === 'Open'} >Open</Dropdown.Item>
                                    <Dropdown.Item eventKey='Done' active={data.caseStatus !== 'Open'} >Done</Dropdown.Item>
                                </DropdownButton>
                            </InputGroup>
                        </Col>
                        <ReadOnlyInput sm={6} label="Case ID" value={`CASE_${data.caseId}`} />
                    </Row>
                    <div className="mt-3">
                        {data.tripId && <><h5 className='mt-3' >Route Details  : </h5>
                        <Row>
                            <ReadOnlyInput sm={6} lg={8} label="Route" value={data.routeName} />
                            <ReadOnlyInput sm={6} lg={4} label="Route ID" value={data.routeShortName} />
                        </Row>
                        <Row>
                            <ReadOnlyInput sm={12} lg={4} label="Trip ID" value={data.tripId || 'N/A'} />
                            <ReadOnlyInput sm={12} lg={4} label="Vehicle No." value={data.vehicleRegistrationNumber || 'N/A'} />
                            <ReadOnlyInput sm={12} lg={4} label="Driver Name" value={data.driverName || 'N/A'} />
                        </Row></>}
                        <h5 className='mt-3' >User Info  : </h5>
                        <Row>
                            <ReadOnlyInput sm={12} lg={6} label="User Name" value={data.userName} />
                            <ReadOnlyInput sm={12} lg={6} label="User Email" value={data.userEmail} />
                        </Row>
                        <h5 className='mt-3' >Attachment : </h5>
                        {attURL ? (
                            <a
                                href={attURL}
                                target="_blank"
                                style={{
                                    height: "40vh",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Image
                                    title='Click to open in full sized image'
                                    src={attURL}
                                    style={{ height: '100%', maxWidth: '100%' }}
                                    alt={`case-${data.tripId}-attachment`}
                                />
                            </a>
                        ) : (
                            <div className="d-flex align-items-center flex-column">
                                <CardImage className="text-secondary" size={50} />
                                <small>No Attacment </small>
                            </div>
                        )}
                        <h5 className='mt-3' >Feedback Details : </h5>
                        <Row>
                            <ReadOnlyInput sm={12} lg={6} label="Case Category" value={data.title} />
                            <ReadOnlyInput sm={12} lg={6} label="Date Reported" value={data.dateCreated_} />
                        </Row>
                        <Row>
                            <ReadOnlyInput rows={3} label="Feedback" value={data.feedback} />
                        </Row>
                        <h5 className='mt-3' >Resolution : </h5>
                        <Row>
                            <Form.Control
                                onChange={e => {
                                    setNewNote(e.target.value || undefined)
                                }}
                                className='mx-3'
                                placeholder='Write any note / resolution regarding this case...'
                                defaultValue={data.caseNote}
                                rows={3}
                                as='textarea'
                            />
                        </Row>
                        {
                            newNote &&
                            <Button className='mt-3 w-100' onClick={() => handleSubmitChanges()} >Update Resolution</Button>
                        }
                    </div>
                </Card.Body>
            </Card>
        </Modal>
    );
};

export default FeedbackDetailsModal