import React, { useState, useEffect } from 'react'
import { Card, Container, Spinner } from 'react-bootstrap'
import EventBus from 'eventing-bus'

import ellipseBG from '../../assets/image/Ellipse-signup-bg.svg'
import logoPrimary from '../../assets/logo/logo-primary.png'

// import './VerifyEmailPage.scss'
import { Check2Circle, XCircle } from 'react-bootstrap-icons'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { userVerifyEmail } from '../../redux/api'
import { ACTION_TYPES } from '../../config'

const VerifyEmailPage = ({ requestUserLogout, user }) => {
    const [verified, setVerified] = useState(null)
    const { emailCode } = useParams()

    const handleVerifyEmailCall = async () => {
        try {
            const data = await userVerifyEmail(emailCode)
            // 
            if (data.error) {
                setVerified(false)
                return toast.error(data.message)
            }
            setVerified(true)
            toast.success('Email successfully verified')
        } catch (error) {
            setVerified(false)
        }
    }

    useEffect(() => {
        handleVerifyEmailCall()
        const logoutSuccess = EventBus.on(ACTION_TYPES.USER_LOGOUT_SUCCEEDED, () => window.location = '/')

        return () => {
            logoutSuccess()
        }
    }, [])

    useEffect(() => {
        if (verified) {
            if (user.jwtToken) {
                requestUserLogout()
            }else{
                window.location = '/'
            }
        }
    }, [verified])

    return (
        <div className='signup-main' >
            <img className="signup-bg" src={ellipseBG} alt='bg' />
            <Container>
                <Link to='/' className='logo-primary' >
                    <img width={200} src={logoPrimary} alt='logo-primary' />
                </Link>
                <div className='d-flex justify-content-center align-items-center h-50'  >
                    {
                        verified === null ?
                            <Card >
                                <Card.Body className='text-center' >
                                    <Card.Title>Verifying your email</Card.Title>
                                    <Spinner animation='border' />
                                </Card.Body>
                            </Card>
                            :
                            <div>
                                {verified === true ?
                                    <Card >
                                        <Card.Body className='text-center' >
                                            <Card.Title>Verified</Card.Title>
                                            <Check2Circle color='green' size={50} />
                                        </Card.Body>
                                    </Card>
                                    :
                                    <Card >
                                        <Card.Body className='text-center' >
                                            <Card.Title>Not Verified</Card.Title>
                                            <XCircle color='red' size={50} />
                                        </Card.Body>
                                    </Card>}
                            </div>
                    }
                </div>
            </Container>
        </div>
    )
}

export default VerifyEmailPage