import React from 'react'
import { Carousel } from 'react-bootstrap'

import slide1 from '../../assets/image/screen1.png'
import slide2 from '../../assets/image/screen2.png'
import slide3 from '../../assets/image/screen3.png'
import slide4 from '../../assets/image/screen4.png'

const JustnaikAppCarousel = () => {
    return (
        <Carousel >
            <Carousel.Item className='text-center' >
                <img width={200} src={slide1} alt='justnaik-1' />
            </Carousel.Item>
            <Carousel.Item className='text-center' >
                <img width={200} src={slide2} alt='justnaik-2' />
            </Carousel.Item>
            <Carousel.Item className='text-center' >
                <img width={200} src={slide3} alt='justnaik-3' />
            </Carousel.Item>
            <Carousel.Item className='text-center' >
                <img width={200} src={slide4} alt='justnaik-4' />
            </Carousel.Item>
        </Carousel>
    )
}

export default JustnaikAppCarousel