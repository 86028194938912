import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import Modal from '../Modal'
import { ExclamationTriangle } from 'react-bootstrap-icons'
import './ContentWrapper.css'
import { Button } from 'react-bootstrap'

/**
 * 
 * @param {Boolean} fluid default:true -- if fluid is true, the container will fill the whole page
 * @param {Object} style JavaScript styling
 */

const ContentWrapper = ({ children, fluid = true, requestUserLogout, ...rest }) => {
    const logout = () => {
        requestUserLogout()
    }
    return (
        <Container fluid={fluid} className='container-wrapper mb-5' {...rest} >
            {/* {
                global.store.getState().user.isEmailVerified === false &&
                <Alert variant='warning' className='py-1 text-center position-relative'>
                    <ExclamationTriangle className='mr-1' /> Your email is not yet verified - Please check your registered email to verify
                </Alert>
            } */}
            {children}
            <Modal show={!global.store.getState().user.isEmailVerified}>
                <div className='text-center' >
                    <ExclamationTriangle size={50} className='text-danger mb-3' />
                    <h4>Your email is not yet verified</h4>
                    <h4>Please check your registered email to verify</h4>
                    <Button onClick={logout} className='mt-3' >Logout</Button>
                </div>
            </Modal>
        </Container>
    )
}

export default ContentWrapper