import { connect } from "react-redux";
import { requestUserLogout ,requestUpdateUserProfile} from "../../redux/actions";
import Appbar from "./Appbar";

const mapStateToProp = (state) => (
    {
        user: state.user
    }
)

export default
    connect(mapStateToProp, {
        requestUserLogout,requestUpdateUserProfile
    }
    )(Appbar)