import React, { useEffect, useState } from "react";
import { Card, Row, Col, DropdownButton, Dropdown, Button, Form } from "react-bootstrap";
import { DateTimePicker } from '@material-ui/pickers'
import moment from "moment";
import { toast } from "react-toastify";
import { PencilSquare, Trash } from "react-bootstrap-icons";

import {
    Modal,
    SimpleInputGroup,
} from "../../components";
import { ApiRequest } from "../../helpers";
import { API_END_POINTS } from "../../config";

const NOT_SELECTED = 'Not Selected'

const Placeholder = ({ sm, md, lg }) => {
    return (
        <Col sm={sm} md={md} lg={lg}>
            <div className='simple-placeholder' />
        </Col>
    )
}

const SimpleDropdownWrapper = ({ label, sm, md, lg, preappendTextClassName, items = [], onSelect = () => { }, selected, readOnly }) => {
    return (
        <Col sm={sm} md={md} lg={lg}>
            <SimpleInputGroup preappendTextClassName={preappendTextClassName} labelWidth={100} preappendText={label}>
                {
                    readOnly ?
                        <Form.Control className='bg-white text-dark' id={label} readOnly value={selected} />
                        :
                        <DropdownButton
                            title={selected}
                            id="case-status-dropdown"
                            size='sm'
                            onSelect={(evtK) => onSelect(evtK)}
                            className='flex-fill'
                            variant='default'
                        >
                            <Dropdown.Item eventKey={NOT_SELECTED} >{NOT_SELECTED}</Dropdown.Item>
                            {
                                items.map(({ id, name }) =>
                                    <Dropdown.Item eventKey={id} key={id} >{name}</Dropdown.Item>
                                )
                            }
                        </DropdownButton>
                }
            </SimpleInputGroup>
        </Col>
    )
}

const SimpleDateTimePicker = ({ minDate, value, onChange, label, sm, md, lg, preappendTextClassName, readOnly }) => {
    return (
        <Col sm={sm} md={md} lg={lg}>
            <SimpleInputGroup preappendTextClassName={preappendTextClassName} labelWidth={100} preappendText={label}>
                <DateTimePicker readOnly={readOnly} minDate={readOnly ? null : minDate} id="case-status-picker" ampm={false} DialogProps={{ style: { zIndex: 9999 } }} className='flex-fill' value={value} onChange={onChange} />
            </SimpleInputGroup>
        </Col>
    )
}

const EventForm = ({
    open = false,
    onClose = () => { },
    data = null,
    onSubmit = () => { },
    onUpdate = () => { }
}) => {

    const [routeId, setRouteId] = useState(NOT_SELECTED)
    const [driverId, setDriverId] = useState(NOT_SELECTED)
    const [vehicleId, setVehicleId] = useState(NOT_SELECTED)
    const [scheduledAt, setScheduledAt] = useState(moment())
    const [scheduledEndTime, setScheduledEndTime] = useState(moment())
    const [isEdit, setIsEdit] = useState(false)
const [directionId, setDirectionId] = useState(null);
    const [vehicles, setVehicles] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [drivers, setDrivers] = useState(null)


    //TODO: redux this
    const handleGetVehicles = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`
        }).then((data) => {
            setVehicles(data.map(({ registrationNumber, id }) => ({ name: registrationNumber, id })))
        }).catch(e => { })
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`
        }).then((data) => {
            setRoutes(data.map(({ shortName, id }) => ({ name: shortName, id })))
        }).catch(e => { })
    }

    const handleGetDrivers = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DRIVER_GET_ALL}`
        }).then((data) => {
            setDrivers(data.map(({ firstName, lastName, id }) => ({ name: `${firstName} ${lastName}`, id })))
        }).catch(e => { })
    }

    const handleToggleEdit = () => {
        setIsEdit(!isEdit)
    }

    useEffect(() => {
        setRouteId(data ? data.routeId : NOT_SELECTED)
        setDriverId(data ? data.driverId : NOT_SELECTED)
        setVehicleId(data ? data.vehicleId : NOT_SELECTED)
        setScheduledAt(data ? data.scheduledAt : moment())
        setIsEdit(!data)
    }, [open])

    useEffect(() => {
        handleGetAllRoute()
        handleGetDrivers()
        handleGetVehicles()
    }, [])

    const handleResetForm = () => {
        setRouteId(NOT_SELECTED)
        setDriverId(NOT_SELECTED)
        setVehicleId(NOT_SELECTED)
        setScheduledAt(moment())
        setScheduledEndTime(moment())
        setDirectionId(null)
        setIsEdit(false)
        onClose()
    }

    const handleSubmit = () => {
        if (routeId === NOT_SELECTED || driverId === NOT_SELECTED || vehicleId === NOT_SELECTED || scheduledEndTime === null || directionId === null) {
            toast.error('Pease select all the info to create a Schedule')
        } else if (moment(scheduledAt).isBefore(moment())) {
            toast.error('Cannot create Schedule after current time')
        } 
        else if (!moment(scheduledAt).isBefore(moment(scheduledEndTime))) {
            toast.error('scheduled end should be after schedules start time')
        }
        else {
            const d = {
                routeId,
                driverId,
                vehicleId,
                scheduledAt,//: moment(scheduledAt).toISOString(true)
                scheduledEndTime,
                directionId
            }
            onSubmit(d)
            handleResetForm()
        }
    }

    const handleUpdate = () => {
        const uData = { id: data.id }
        if (routeId !== data.routeId) {
            uData.routeId = routeId
        }
        if (driverId !== data.driverId) {
            uData.driverId = driverId
        }
        if (vehicleId !== data.vehicleId) {
            uData.vehicleId = vehicleId
        }
        if (!moment(scheduledAt).isSame(moment(data.scheduledAt))) {
            uData.scheduledAt = scheduledAt//moment(scheduledAt).toISOString(true)
        }

        if (Object.keys(uData).length === 0) {
            return toast.error('Nothing to update')
        } else {
            onUpdate(uData)
            handleResetForm()
        }
    }

    const handleDeleteScheduledTrip = () => {
        const c = window.confirm('Are you sure to delete this trip?')
        if (c) {
            onUpdate({ id: data.id, isActive: false })
            handleResetForm()
        }
    }

    const getNameFromId = (itemList = [], id) => {
        if (+id !== NaN) {
            const c = itemList.find(({ id: a }) => a === +id)
            return c?.name || id
        }
        return id
    }

    return (
        <Modal closeButton show={open} onHide={handleResetForm}>
            <Card>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center' >
                        <Card.Title >{!isEdit ? 'View' : 'Create'} Scheduled Trip</Card.Title>
                        {
                            data &&
                            <div>
                                <Button className='mr-2' title={moment().isAfter(scheduledAt) ? 'Cannot update passed trip' : `Turn ${isEdit ? 'off' : 'on'} edit`} active={isEdit} disabled={moment().isAfter(scheduledAt)} variant='outline-primary' onClick={handleToggleEdit} >
                                    <PencilSquare size={20} />
                                </Button>
                                <Button title="Delete this Scheduled Trip" variant='danger' onClick={handleDeleteScheduledTrip} >
                                    <Trash size={20} />
                                </Button>
                            </div>
                        }
                    </div>
                    <div className='d-flex justify-content-between align-items-center' >
                        <h5 className='mt-3' >Trip Details </h5>
                        {
                            data &&
                            <div title={data.status} className={`status-circle-lg bg-${data.status === 'completed' ? 'success' : data.status === 'active' ? 'warning' : 'danger'}`} />
                        }
                    </div>
                    <Row>
                     <SimpleDateTimePicker readOnly={!isEdit} sm={12} lg={6} label="Start Date Time" value={scheduledAt} onChange={setScheduledAt} minDate={moment()} />
                   { isEdit && !data &&  <SimpleDateTimePicker readOnly={!isEdit} sm={12} lg={6} label="End Date Time" value={scheduledEndTime} onChange={setScheduledEndTime} minDate={moment()} />}
                       
                    </Row>
                    <Row>
                        {
                            routes ?
                                <SimpleDropdownWrapper readOnly={!isEdit} selected={getNameFromId(routes, routeId)} onSelect={setRouteId} sm={12} lg={6} label="Route" items={routes} />
                                :
                                <Placeholder sm={12} lg={6} />
                        }
                        {
                            drivers ?
                                <SimpleDropdownWrapper readOnly={!isEdit} selected={getNameFromId(drivers, driverId)} onSelect={setDriverId} sm={12} lg={6} label="Driver" items={drivers} />
                                :
                                <Placeholder sm={12} lg={6} />
                        }
                    </Row>
                    <Row>
                        {
                            vehicles ?
                                <SimpleDropdownWrapper readOnly={!isEdit} selected={getNameFromId(vehicles, vehicleId)} onSelect={setVehicleId} sm={12} lg={6} label="Vehicle" items={vehicles} />
                                :
                                <Placeholder sm={12} lg={6} />
                        }
                        </Row>
                        <Row>
                            <Col md={4}>
                            { isEdit && !data &&  <Form.Group>
                                        <Form.Label >Direction:</Form.Label>
                                        <Form.Control custom as='select' value={directionId || -1} onChange={(e) => setDirectionId(e.currentTarget.value)}  >
                                        <option disabled value={-1} key={-1} > </option>
                                            <option value='0' >LOOP</option>
                                            <option value='1' >OUTBOUND</option>
                                            <option value='2' >INBOUND</option>
                                        </Form.Control>
                                    </Form.Group>}
                                    </Col>
                    </Row>
                   
                </Card.Body>
                <Card.Footer className='d-flex justify-content-between' >
                    <Button onClick={handleResetForm} variant='warning' >Cancel</Button>
                    {
                        data ?
                            <Button onClick={handleUpdate} disabled={!isEdit} >Update</Button>
                            :
                            <Button onClick={handleSubmit} >Create</Button>
                    }
                </Card.Footer>
            </Card>
        </Modal>
    );
};

export default EventForm