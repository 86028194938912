import React from 'react'
import { Card } from 'react-bootstrap'
import { MapContainer, Marker, Popup, TileLayer, Polyline } from 'react-leaflet'
import L from 'leaflet'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import 'leaflet/dist/leaflet.css'

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
    shadowSize: [20, 30],
    shadowAnchor: [10, 30],
    popupAnchor: [0, -31]
});

const RealTimeMap = ({
    title,
    vehicleMarker = [],
    polylines = [],
    polylinesPathOpt = [
        { color: 'red' }
    ]
}) => {

    const position = [3.1390, 101.6869]

    return (
        <Card className='text-dark' >
            <Card.Body>
                {
                    title &&
                    <Card.Title>{title}</Card.Title>
                }
                <MapContainer style={{ height: '40vh' }} center={position} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                        url="http://a.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
                    />
                    {
                        vehicleMarker.length > 0 &&
                        vehicleMarker.map(({ vehicleRegistrationNumber, location, driverName, routeName, createdAt }, key) => (
                            <Marker key={key} icon={DefaultIcon} position={[location.latitude, location.longitude]}>
                                <Popup >{routeName}<br />{vehicleRegistrationNumber}<br />{driverName}<br />Start Time: {new Date(createdAt).toLocaleTimeString()}</Popup>
                            </Marker>
                        ))
                    }
                    {
                        polylines.length > 0 &&
                        polylines.map((position, key) => (
                            <Polyline pathOptions={polylinesPathOpt[key]} key={key} positions={position} />
                        ))
                    }
                </MapContainer>
            </Card.Body>
        </Card>
    )
}

export default RealTimeMap