import { call, put, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES } from '../../config'
import { getPJCityBusUserExp, getUserExp, updateUserExp } from '../api'
import { userExpSuccess, userExpFailure, userExpUpdateFailure, userExpUpdateSuccess, pjCityBusUserExpSuccess, pjCityBusUserExpFailure } from '../actions'
import { toast } from 'react-toastify'

export function* watchUserExpRequests() {
  yield takeLatest(ACTION_TYPES.USER_EXP_REQUESTED, requestUserExpG)
  yield takeLatest(ACTION_TYPES.USER_EXP_UPDATE_REQUESTED, updateUserExpG)
  yield takeLatest(ACTION_TYPES.PJCITYBUS_USER_EXP_REQUESTED, requestPJCityBUsUserExpG)
}

function* requestUserExpG() {
  try {
    const userExps = yield call(getUserExp)
    yield put(userExpSuccess(userExps))
  } catch {
    yield put(userExpFailure())
  }
}

function* requestPJCityBUsUserExpG() {
  try {
    const userExps = yield call(getPJCityBusUserExp)
    yield put(pjCityBusUserExpSuccess(userExps))
  } catch {
    yield put(pjCityBusUserExpFailure())
  }
}

function* updateUserExpG(action) {
  const { caseId, ...params } = action.payload
  try {
    const userExp = yield call(updateUserExp, caseId, params)
    yield put(userExpUpdateSuccess(userExp))
    toast.success(`CASE_${caseId} is updated`)
  } catch {
    yield put(userExpUpdateFailure())
  }
}