import React, { useState, useEffect } from 'react'
import { Card, Container, Spinner, Modal, Form, Alert, Button } from 'react-bootstrap'

import ellipseBG from '../../assets/image/Ellipse-signup-bg.svg'
import logoPrimary from '../../assets/logo/logo-primary.png'

import { Check, Check2Circle, XCircle } from 'react-bootstrap-icons'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { userVerifyNewBasic } from '../../redux/api'
import { FailedAlert, SuccessAlert } from '../../components'
import validator from 'validator'

const VerifyNewBasic = () => {
    const [verified, setVerified] = useState(null)
    const [loading, setLoading] = useState(false)
    const { emailCode } = useParams()
    const [password, setPassword] = useState('')

    const [passwordConditions, setPasswordConditions] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)

    useEffect(() => {
        const doc = document.querySelector('#password')
        if (passwordConditions !== null && doc) {
            if (passwordConditions[0].checked === true && passwordConditions[1].checked === true && passwordConditions[2].checked === true && confirmPass === true) {
                const password = doc.value
                setPassword(password)
            } else {
                setPassword('')
            }
        }
    }, [confirmPass])

    const passwordChecker = (evt) => {
        const pass = evt.currentTarget.value
        const specialChar = new RegExp(/[!@#$%^&*(),.?":{}|<>]/)
        const number = new RegExp(/[0-9]/)

        const prev = [
            {
                text: 'Minimum 8 characters',
                checked: false
            },
            {
                text: 'One special character',
                checked: false
            },
            {
                text: 'One number',
                checked: false
            }
        ]

        if (pass.length < 8) {
            prev[0].checked = false
        } else {
            prev[0].checked = true
        }

        if (!specialChar.test(pass)) {
            prev[1].checked = false
        } else {
            prev[1].checked = true
        }

        if (!number.test(pass)) {
            prev[2].checked = false
        } else {
            prev[2].checked = true
        }

        setPasswordConditions([...prev])

        const cpass = document.querySelector('#confirm-password').value
        if (pass === cpass) {
            setConfirmPass(true)
        } else {
            setConfirmPass(false)
        }

    }

    const confirmPassword = (evt) => {
        const cpass = evt.currentTarget.value
        const password = document.querySelector('#password').value
        if (!cpass.length > 0) return setConfirmPass(null)
        if (password === cpass) {
            setConfirmPass(true)
        } else {
            setConfirmPass(false)
        }
    }

    const handleVerifyEmail = async (e) => {
        e.preventDefault()
        
        if (!password || validator.isEmpty(password)) return toast.warn('password not valid')
        
        try {
            setLoading(true)
            const data = await userVerifyNewBasic(emailCode, { newPassword: password })
            // 
            if (data.error) {
                setVerified(false)
                return toast.error(data.message)
            }
            setVerified(true)
            toast.success('Email successfully verified')
        } catch (error) {
            setVerified(false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='signup-main' >
            <img className="signup-bg" src={ellipseBG} alt='bg' />
            <Container>
                <Link to='/' className='logo-primary' >
                    <img width={200} src={logoPrimary} alt='logo-primary' />
                </Link>
                <div className='d-flex justify-content-center align-items-center h-75'  >
                    {
                        verified === null ?
                            <Card >
                                <Card.Body >
                                    <Alert variant='info' >
                                        Please provide your new password to complete your registration
                                    </Alert>
                                    <Form onSubmit={handleVerifyEmail} >
                                        <Form.Group >
                                            <Form.Label>New Password</Form.Label>
                                            {
                                                passwordConditions !== null &&
                                                passwordConditions.map(({ text, checked }, i) => (
                                                    <div key={i} className='p-0 m-0'>
                                                        {
                                                            checked ?
                                                                <SuccessAlert text={text} /> :
                                                                <FailedAlert text={text} />
                                                        }
                                                    </div>
                                                ))
                                            }
                                            <Form.Control required id='password' onChange={passwordChecker} type='password' />
                                        </Form.Group>
                                        <Form.Group >
                                            <Form.Label>Confirm New Password</Form.Label>
                                            {
                                                confirmPass !== null &&
                                                <Alert className='py-0 my-1' variant={confirmPass ? 'success' : 'danger'} >
                                                    <Check /> <small>{confirmPass ? 'Password matched!' : 'Password not match'}</small>
                                                </Alert>
                                            }
                                            <Form.Control id='confirm-password' required onChange={confirmPassword} type='password' />
                                        </Form.Group>
                                        <Button type='submit' className='w-100' >Sign Up</Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                            :
                            <div>
                                {verified === true ?
                                    <Card >
                                        <Card.Body className='text-center' >
                                            <Card.Title>Verified</Card.Title>
                                            <Check2Circle className='mb-3' color='green' size={50} /><br/>
                                            <Link to='/signin' >Login</Link>
                                        </Card.Body>
                                    </Card>
                                    :
                                    <Card >
                                        <Card.Body className='text-center' >
                                            <Card.Title>Not Verified</Card.Title>
                                            <XCircle color='red' className='mb-3' size={50} />
                                            <Alert variant='warning' className='py-0 m-0' >Please contact your Administrator</Alert>
                                        </Card.Body>
                                    </Card>}
                            </div>
                    }
                </div>
            </Container>
            <Modal centered size='sm' className show={loading} >
                <Modal.Body className='text-center' >
                    <Modal.Title>Verifying your email</Modal.Title>
                    <Spinner className='mt-2' variant='primary' animation='border' />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default VerifyNewBasic