const GENERAL = {
    MAX_PHOTO_UPLOAD_SIZE            : 500000, //500kb - 0.5mb
    AGENCY_PHOTO_LOCAL_BASE_URL      : 'http://localhost:5002/photo',
    AGENCY_ROLES                     : ['BASIC', 'ADMIN'],
    IS_PRODUCTION                    : true,
    REALTIME_INTERVAL_MS             : 1000 * 15, //15 secs
    COMISSION_CUT                    : 0.07, //7%
    HQ_ADDRESS                       : "72A, JALAN UNIVERSITI, SEKSYEN 13, 46200 PETALING JAYA, SELANGOR",
    PAYMENT_TYPE_ONLINE              : 'ONLINE',
    PAYMENT_TYPE_OFFLINE             : 'OFFLINE',
    SUPER_ADMIN                      : 'SUPER_ADMIN'
  };
  
  export default GENERAL