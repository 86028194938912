import React, { useEffect, useState, useMemo } from 'react'
import { Card, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import { PencilSquare } from 'react-bootstrap-icons'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ContentWrapper, Table, Modal } from '../../components'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'

const ScheduleForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { day: '', first: '', last: '', active: true } }) => {
    const [day, setDay] = useState(null)
    const [first, setFirst] = useState(null)
    const [last, setLast] = useState(null)
    const [active, setActive] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()
        const data = {}

        if (day !== null) {
            data.day = day
        } else {
            data.day = defaultValues.day
        }

        if (first !== null) {
            data.first = first
        } else {
            data.first = defaultValues.first
        }

        if (last !== null) {
            data.last = last
        } else {
            data.last = defaultValues.last
        }

        if (active !== null) {
            data.active = active
        } else {
            data.active = defaultValues.active
        }

        onSubmitForm(data)

        if (onClickCancel) {
            onClickCancel()
        } else {
            setDay(null)
            setFirst(null)
            setLast(null)
        }
    }

    return (
        <Row>
            <Col className={` ${!edit ? 'pt-3' : ''} `} lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Create New Schedule</Card.Title>
                            }
                            {
                                edit &&
                                < Form.Group >
                                    <Form.Label >Active</Form.Label>
                                    <Form.Check id={defaultValues.id + '-is-active'} type='switch' onChange={(e) => setActive(e.target.checked)} defaultChecked={defaultValues.active} />
                                </Form.Group>
                            }
                            <Row>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Day</Form.Label>
                                        <Form.Control value={day ? day : defaultValues.day} onChange={(e) => setDay(e.currentTarget.value)} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >First Schedule</Form.Label>
                                        <Form.Control type='time' value={first ? first : defaultValues.first} onChange={(e) => setFirst(e.currentTarget.value)} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Last Schedule</Form.Label>
                                        <Form.Control type='time' onChange={(e) => setLast(e.currentTarget.value)} value={last ? last : defaultValues.last} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            {
                                edit &&
                                <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            }
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Create' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row >
    )
}

const FrequencyForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { start_time: '', end_time: '', headway_secs: '' } }) => {
    const [startTime, setStartTime] = useState(null)
    const [endtime, setEndtime] = useState(null)
    const [headway, setHeadway] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()
        const data = {}

        if (startTime !== null) {
            data['start_time'] = startTime
        } else {
            data['start_time'] = defaultValues.start_time
        }

        if (endtime !== null) {
            data['end_time'] = endtime
        } else {
            data['end_time'] = defaultValues.end_time
        }

        if (headway !== null) {
            data['headway_secs'] = headway
        } else {
            data['headway_secs'] = defaultValues.headway_secs
        }

        data['time'] = `${data.start_time} - ${data.end_time}`
        const freqInMin = Math.floor(+data.headway_secs / 60)
        data['frequency'] = `${freqInMin} ${freqInMin > 1 ? "mins" : "min"}`

        // { freq
        //     "time": "8:00:00 - 9:59:59",
        //     "end_time": "9:59:59",
        //     "frequency": "10 mins",
        //     "start_time": "8:00:00",
        //     "headway_secs": "600",
        // }

        onSubmitForm(data)

        if (onClickCancel) {
            onClickCancel()
        } else {
            setStartTime(null)
            setEndtime(null)
            setHeadway(null)
        }
    }

    return (
        <Row>
            <Col className={` ${!edit ? 'pt-3' : ''} `} lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Create New Headway</Card.Title>
                            }
                            <Row>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Time From</Form.Label>
                                        <Form.Control type='time' onChange={(e) => setStartTime(e.currentTarget.value)} value={startTime ? startTime : defaultValues.start_time} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Time To</Form.Label>
                                        <Form.Control type='time' value={endtime ? endtime : defaultValues.end_time} onChange={(e) => setEndtime(e.currentTarget.value)} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Headway / Service Frequency (secs)</Form.Label>
                                        <Form.Control type='number' min='0' step='1' value={headway ? headway : defaultValues.headway_secs} onChange={(e) => setHeadway(e.currentTarget.value)} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            {
                                edit &&
                                <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            }
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Create' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row >
    )
}

const EditorialSchedule = () => {
    const [route, setRoute] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [frequency, setFrequency] = useState(null)
    const [refresh, setRefresh] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showEditFreqModal, setShowEditFreqModal] = useState(false)
    const [selectedScheduleIndex, setSelectedScheduleIndex] = useState(null)
    const [selectedFreqIndex, setSelectedFreqIndex] = useState(null)

    const { routeId } = useParams()

    const handleGetRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET}/${routeId}`
        }).then((data) => {
            
            
            setRoute(data)
            setSchedule(data.schedule)
            setFrequency(data.frequency)
        }).catch(e => { })
    }

    const handleScheduleUpdate = (data) => {
        const newSche = []
        schedule.forEach(({ day, first, last, active }) => {
            newSche.push({ day, first, last, active })
        })
        newSche[selectedScheduleIndex] = data
        

        const newFreq = []
        frequency.forEach(({ time, end_time, frequency, start_time, headway_secs }) => {
            newFreq.push({ time, end_time, frequency, start_time, headway_secs })
        })

        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULE_UPDATE}/${routeId}`,
            data: {
                schedule: newSche,
                frequency: newFreq
            }
        }).then(() => {
            toast.success('Schedule Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleScheduleCreate = (data) => {
        const newSche = []
        schedule.forEach(({ day, first, last, active }) => {
            newSche.push({ day, first, last, active })
        })
        newSche.push(data)
        

        const newFreq = []
        frequency.forEach(({ time, end_time, frequency, start_time, headway_secs }) => {
            newFreq.push({ time, end_time, frequency, start_time, headway_secs })
        })

        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULE_CREATE}/${routeId}`,
            data: {
                schedule: newSche,
                frequency: newFreq
            }
        }).then(() => {
            toast.success(`New Schedule Created!`)
            setRefresh(!refresh)
        }).catch(e => { })

        handleClickCancel()
    }

    const handleFrequencyUpdate = (data) => {
        const newFreq = []
        frequency.forEach(({ time, end_time, frequency, start_time, headway_secs }) => {
            newFreq.push({ time, end_time, frequency, start_time, headway_secs })
        })
        newFreq[selectedFreqIndex] = data
        

        const newSche = []
        schedule.forEach(({ day, first, last, active }) => {
            newSche.push({ day, first, last, active })
        })

        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULE_UPDATE}/${routeId}`,
            data: {
                frequency: newFreq,
                schedule: newSche
            }
        }).then(() => {
            toast.success('Frequency Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleFrequencyCreate = (data) => {
        const newFreq = []
        frequency.forEach(({ time, end_time, frequency, start_time, headway_secs }) => {
            newFreq.push({ time, end_time, frequency, start_time, headway_secs })
        })
        newFreq.push(data)
        

        const newSche = []
        schedule.forEach(({ day, first, last, active }) => {
            newSche.push({ day, first, last, active })
        })

        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.SCHEDULE_CREATE}/${routeId}`,
            data: {
                frequency: newFreq,
                schedule: newSche
            }
        }).then(() => {
            toast.success(`New Frequency Created!`)
            setRefresh(!refresh)
        }).catch(e => { })

        handleClickCancel()
    }

    useEffect(() => {
        handleGetRoute()
    }, [refresh])

    const handleEdit = (index) => {
        setSelectedScheduleIndex(index)
        setShowEditModal(true)
    }

    const handleFreqEdit = (index) => {
        setSelectedFreqIndex(index)
        setShowEditFreqModal(true)
    }

    const scheduleTableHeader = useMemo(() => [
        {
            Header: 'Day',
            accessor: 'day',
            // disableFilters: true
        },
        {
            Header: 'First',
            accessor: 'first',
            // disableFilters: true
        },
        {
            Header: 'Last',
            accessor: 'last',
            // disableFilters: true
        },
        {
            Header: 'Active',
            accessor: 'isActive_',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        }
    ], [])
    const frequencyTableHeader = useMemo(() => [
        // {
        //     Header: 'Start Time',
        //     accessor: 'start_time',
        //     // disableFilters: true
        // },
        // {
        //     Header: 'End Time',
        //     accessor: 'end_time',
        //     // disableFilters: true
        // },
        {
            Header: 'Time of the day',
            accessor: 'time',
            // disableFilters: true
        },
        {
            Header: 'Headway (secs)',
            accessor: 'headway_secs',
            // disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        }
    ], [])

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['isActive_'] = String(row.active)
        })
        return data
    }

    const appendFreqAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleFreqEdit(i)} />
        })
        return data
    }

    const handleClickCancel = () => {
        setShowEditModal(false)
        setShowEditFreqModal(false)
        setSelectedScheduleIndex(null)
        setSelectedFreqIndex(null)
    }

    const goTo = useHistory()

    return (
        <ContentWrapper>
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <Card>
                <Card.Body>
                    {
                        route &&
                        <h4>{route.shortName} - {route.name}</h4>
                    }
                </Card.Body>
            </Card>
            <Card className='h-100 mt-3' >
                <Card.Body>
                    <Card.Title >Schedule Table</Card.Title>

                    <ScheduleForm onSubmitForm={handleScheduleCreate} />
                    {
                        route && route.schedule.length > 0 ?
                        <Table columns={scheduleTableHeader} data={appendAction(route.schedule)} />
                        :
                        <Alert variant='info' className='py-0 mt-3' >No schedule added on this route</Alert>
                    }
                </Card.Body>
            </Card>
            <Card className='h-100 mt-3' >
                <Card.Body>
                    <Card.Title >Headway / Service Frequency Table</Card.Title>
                    <FrequencyForm onSubmitForm={handleFrequencyCreate} />

                    {
                        route && route.frequency.length > 0 ?
                        <Table columns={frequencyTableHeader} data={appendFreqAction(route.frequency)} />
                        :
                        <Alert variant='info' className='py-0 mt-3' >No headway added on this route</Alert>
                    }
                </Card.Body>
            </Card>
            {
                route && route.schedule.length > 0 &&
                <Modal title='Edit Schedule Instance' show={showEditModal} onHide={handleClickCancel} >
                    <ScheduleForm edit onSubmitForm={handleScheduleUpdate} onClickCancel={handleClickCancel} defaultValues={route.schedule[selectedScheduleIndex]} />
                </Modal>
            }

            {
                route && route.frequency.length > 0 &&
                <Modal title='Edit Headway Instance' show={showEditFreqModal} onHide={handleClickCancel} >
                    <FrequencyForm edit onSubmitForm={handleFrequencyUpdate} onClickCancel={handleClickCancel} defaultValues={route.frequency[selectedFreqIndex]} />
                </Modal>
            }
        </ContentWrapper>
    )
}

export default EditorialSchedule