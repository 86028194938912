import React, { useEffect, useState } from 'react'
import { Check, PlusSquare, X } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'
import { GENERAL } from '../../config'
import './PhotoProfile.css'

const PhotoProfile = ({ imageUrl, name, onConfirm }) => {
    const [photo, setPhoto] = useState(null)
    const [photoUrl, setPhotoUrl] = useState(null)

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        const limSize = GENERAL.MAX_PHOTO_UPLOAD_SIZE
        if (file.size > limSize) return toast.error(`Photo size exceeded max size ${Number(limSize * 0.00000095367432).toPrecision(1)} MB`)
        setPhoto(file)
    }

    useEffect(() => {
        if (!photo) return null
        const reader = new FileReader();
        reader.readAsDataURL(photo)
        reader.onload = (e) => setPhotoUrl(e.target.result)
    }, [photo])

    const handlePhotoCancel = () => {
        setPhoto(null)
        setPhotoUrl(null)
    }

    const handlePhotoConfirm = () => {
        onConfirm(name, photo)
        handlePhotoCancel()
    }

    return (
        <div className='profile-photo-wrapper' >
            <div>
                {
                    photoUrl !== null ?
                        <div >
                            <img title='New Agency Logo' className='profile-photo-resize' src={photoUrl} alt='new-profile-photo' />
                            <div>
                                <span onClick={handlePhotoCancel} title='Cancel Photo' >
                                    <X className='btn btn-outline-danger rounded-circle m-1' size={50} />
                                </span>
                                <span onClick={handlePhotoConfirm} title='Confirm Photo' >
                                    <Check className='btn btn-outline-success rounded-circle m-1' size={50} />
                                </span>
                            </div>
                        </div>
                        :
                        <label title='upload profile photo' className='profile-photo-label' htmlFor='photo-input' >
                            {
                                imageUrl ?
                                    <img title='Change Agency Logo' className='profile-photo-resize' src={imageUrl} alt='current-profile-photo' />
                                    :
                                    <div className='border border-primary rounded pt-3' >
                                        <PlusSquare size={30} />
                                        <p>Upload Your Agency Logo Here</p>
                                    </div>
                            }
                        </label>
                }
                <input name={name} onChange={handleFileChange} accept='image/*' className='profile-photo-input' id='photo-input' type='file' />
            </div>
        </div>
    )
}

export default PhotoProfile