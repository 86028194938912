import { connect } from "react-redux";
import SettlementPage from "./SettlementPage";
import { requestUserUploadAgencyLogo } from "../../redux/actions";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    { requestUserUploadAgencyLogo }
)(SettlementPage)