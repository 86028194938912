import { INITIAL_STATE } from '../store'
import { ACTION_TYPES } from '../../config'

export const TripSchedulerReducer = (state = INITIAL_STATE.tripScheduler, action) => {
  switch (action.type) {
    case ACTION_TYPES.TRIP_SCHEDULER_REQUESTED:
      return state

    case ACTION_TYPES.TRIP_SCHEDULER_SUCCEEDED:
      return [
        ...state,
        ...action.payload
      ];

    case ACTION_TYPES.TRIP_SCHEDULER_FAILED:
      return state

    case ACTION_TYPES.TRIP_SCHEDULER_CREATE_REQUESTED:
      return state

    case ACTION_TYPES.TRIP_SCHEDULER_CREATE_SUCCEEDED:
      //push
      return [...state, action.payload]

    case ACTION_TYPES.TRIP_SCHEDULER_CREATE_FAILED:
      return state

    case ACTION_TYPES.TRIP_SCHEDULER_UPDATE_REQUESTED:
      return state

    case ACTION_TYPES.TRIP_SCHEDULER_UPDATE_SUCCEEDED:
      //find the trip id and replace to updated data
      const { id } = action.payload
      const idx = state.findIndex(({ id: uid }) => uid === id)
      if (action.payload.isActive) {
        state[idx] = action.payload
      } else {
        state.splice(idx, 1)
      }
      return [...state]

    case ACTION_TYPES.TRIP_SCHEDULER_UPDATE_FAILED:
      return state

    default:
      return state
  }
};
