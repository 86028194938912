import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap'
import { Clipboard, Globe, PencilSquare, PlusCircle } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import { ContentWrapper, Modal, QRVehiclePoster, Table } from '../../components'
import { API_END_POINTS, GENERAL } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import { NoDataInfo } from '../../components/Alert'
import { Link } from 'react-router-dom'

// vehicles
// - name
// - description
// - registrarion_number
// - qr_code
// - agency_id
// - is_active
// - isElectric

const VehicleForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { name: '', registrationNumber: '', description: '', isActive: '', isElectric: '',vehicleAge:'' } }) => {
    const [name, setname] = useState(null)
    const [vehicleAge, setVehicleAge] = useState(null)
    const [registrationNumber, setRegistrationNumber] = useState(null)
    const [isActive, setIsActive] = useState(null)
    const [description, setDescription] = useState(null)
    const [isElectric, setIsElectric] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (name !== null) {
            data.name = name
        }
        if (registrationNumber !== null) {
            data.registrationNumber = registrationNumber
        }
        if (description !== null) {
            data.description = description
        }
        if (isActive !== null) {
            data.isActive = isActive
        }
        if (isElectric !== null) {
            data.isElectric = isElectric
        }
        if (vehicleAge !== null) {
            data.vehicleAge = vehicleAge
        }

        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Add New Vehicle</Card.Title>
                            }
                            <Row>
                                <Col lg={8} md={6} >
                                    <Form.Group>
                                        <Form.Label >Vehicle Name</Form.Label>
                                        <Form.Control onChange={(e) => setname(e.currentTarget.value)} required={!edit ? true : false} placeholder='Scania' defaultValue={defaultValues.name} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={6} >
                                    <Form.Group>
                                        <Form.Label >Registration Number</Form.Label>
                                        <Form.Control className='text-uppercase' onChange={(e) => setRegistrationNumber(String(e.currentTarget.value).toUpperCase())} required={!edit ? true : false} placeholder='W9292E' defaultValue={defaultValues.registrationNumber} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={edit ? 6 : 8} >
                                    <Form.Group>
                                        <Form.Label >Description</Form.Label>
                                        <Form.Control as="textarea" onChange={(e) => setDescription(e.currentTarget.value)} required={!edit ? true : false} placeholder='Scania Model B' defaultValue={defaultValues.description} />
                                    </Form.Group>
                                </Col>
                                <Col md={edit ? 3 : 4} sm={6} >
                                    <Form.Group>
                                        <Form.Label />
                                        <Form.Check custom label='Electric Powered' type='checkbox' onChange={(e) => setIsElectric(e.target.checked)} defaultChecked={defaultValues.isElectric} id='edit-changes-is-electric' />
                                        <Form.Text>Check the box if this vehicle is powered by electric</Form.Text>
                                    </Form.Group>
                                </Col>
                                {
                                    edit &&
                                    <Col md={3} sm={6} >
                                        <Form.Group>
                                            <Form.Label />
                                            <Form.Check label='Active' type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active-stop' />
                                            <Form.Text>Disable vehicle</Form.Text>
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            <h5>Fill the below details if applicable:</h5>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label >Vehicle Mfg. Year(eg. 2010):</Form.Label>
                                        <Form.Control type="number" step={1} min="0" onChange={(e) => setVehicleAge(e.currentTarget.value)} defaultValue={defaultValues.vehicleAge} />
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Add Vehicle' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const VehiclePreviewModal = ({ title, show, onHide, vehicleData: { name, description, registrationNumber, qrCode } }) => {
    const [agencyData, setAgencyData] = useState(null)
    const handleGetAgency = () => {
        const agency = global.store.getState().user.agency
        setAgencyData(agency)
    }

    useEffect(() => {
        handleGetAgency()
    }, [])

    return (
        <Modal size='md' title={title} show={show} onHide={onHide}  >
            {
                agencyData &&
                <QRVehiclePoster agencyLogoSrc={GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL == null ? agencyData.imageUrl : `${GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL}/${agencyData.imageUrl}`} agencyName={agencyData.name} vehicleNum={registrationNumber} qrValue={qrCode} />
            }
        </Modal>
    )
}

const EditorialVehicle = () => {
    const [add, setAdd] = useState(false)
    const [addCollapse, setAddCollapse] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [vehicles, setVehicles] = useState(null)
    const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showNewAccessCodeModal, setShowNewAccessCodeModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleEdit = (index) => {
        setShowEditModal(true)
        setSelectedVehicleIndex(index)
    }

    const handlePreviewVehicle = (index) => {
        setSelectedVehicleIndex(index)
        setShowPreviewModal(true)
    }

    const handleClickCancel = () => {
        setSelectedVehicleIndex(null)
        setAdd(false)
        setShowEditModal(false)
        setShowNewAccessCodeModal(false)
        setShowPreviewModal(false)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['onPreview'] = <Globe className='g-hover-pointer text-primary' onClick={() => handlePreviewVehicle(i)} />
            row['onTrips'] = <Link to={`/vehicle-trips/${row?.id}`} ><Clipboard /></Link>
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['modifiedAt_'] = <p>{row.updatedAt ? `${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}` : ''}</p>
        })
        return data
    }

    const handleGetVehicles = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`
        }).then((data) => {
            // 
            setVehicles(data)
        }).catch(e => { })
    }

    const handleVehicleUpdate = (id, data) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.VEHICLE_UPDATE}/${id}`,
            data
        }).then(() => {
            toast.success('Vehicle Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleVehicleCreate = (id, data) => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.VEHICLE_CREATE}`,
            data
        }).then(() => {
            toast.success(`${data.name} Vehicle Created!`)
            setRefresh(!refresh)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetVehicles()
    }, [refresh])

    const tableHeader = useMemo(() => [
        {
            Header: 'Vehicle Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Description',
            accessor: 'description',
            // disableFilters: true

        },
        {
            Header: 'Registration No.',
            accessor: 'registrationNumber',
            // disableFilters: true
        },
        {
            Header: 'Vehicle MFG. Year',
            accessor: 'vehicleAge',
            // disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            disableFilters: true
        },
        {
            Header: 'Last Modified',
            accessor: 'modifiedAt_',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'View',
            accessor: 'onPreview',
            disableFilters: true
        },
        {
            Header: 'Trips',
            accessor: 'onTrips',
            disableFilters: true
        }
    ], [])

    return (
        <>
            <ContentWrapper>
                <h2 className='mb-3' >Vehicle Editorial</h2>
                <div className='divider' />
                <Row >
                    <Col className='pt-3 text-dark' md={10} sm={12} >
                        <Card className='h-100' >
                            <Card.Body>
                                <Card.Title >Active Vehicle Count</Card.Title>
                                {
                                    vehicles &&
                                    <h1>{vehicles.length}</h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pt-3' md={2} sm={12} >
                        <Card onClick={handleToggleAddForm} className='bg-primary btn btn-outline-light h-100' >
                            <Card.Body className='d-flex justify-content-center align-items-center' >
                                <PlusCircle size={50} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Collapse in={add} onEnter={() => setAddCollapse(true)} onExited={() => setAddCollapse(false)} >
                    <div>
                        {
                            addCollapse &&
                            <VehicleForm onClickCancel={handleClickCancel} onSubmitForm={handleVehicleCreate} />
                        }
                    </div>
                </Collapse>
                {
                    vehicles && vehicles.length > 0 ?
                        <Table numbering title='List of Added Vehicle' columns={tableHeader} data={appendAction(vehicles)} />
                        :
                        <NoDataInfo>
                            No vehicle added yet.<br />
                            To add a vehicle simply click the <PlusCircle /> icon above.
                        </NoDataInfo>
                }
            </ContentWrapper>
            {
                vehicles && selectedVehicleIndex !== null &&
                <Modal title={`Edit Vehicle ${vehicles[selectedVehicleIndex].name}`} show={showEditModal} onHide={handleClickCancel} >
                    <VehicleForm edit defaultValues={vehicles[selectedVehicleIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleVehicleUpdate} />
                </Modal>
            }
            {
                vehicles && selectedVehicleIndex !== null &&
                <VehiclePreviewModal vehicleData={vehicles[selectedVehicleIndex]} title='Vehicle Poster' show={showPreviewModal} onHide={handleClickCancel} />
            }
        </>
    )
}

export default EditorialVehicle