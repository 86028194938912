import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, SettlementReportTemplate, Table } from '../../components'
import { Tab, Tabs, Card, Alert } from 'react-bootstrap'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS, Utils, GENERAL } from '../../config'

import './SettlementPage.css'
import { ListCheck, Printer, InfoCircle } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'

const SettlementPage = ({ user }) => {
    const [settlement, setSettlement] = useState(null)
    const [selectedDownloadableReport, setSelectedDownloadableReport] = useState(null)

    const goTo = useHistory()

    useEffect(() => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SETTLEMENT_GET_ALL}`
        }).then(data => {
            // 
            const onlinePaymentType = data.filter(({ paymentType }) => paymentType === GENERAL.PAYMENT_TYPE_ONLINE)
            const sortedData = _.orderBy(onlinePaymentType, [({ createdAt }) => new Date(createdAt)], ['desc'])
            setSettlement(sortedData)
        }).catch(e => { })
    }, [])

    const summaryHeaderDaily = useMemo(() => [
        {
            Header: 'Date',
            accessor: 'date',
            // disableFilters: true
        },
        // {
        //     Header: 'Settlement ID',
        //     accessor: 'settlement_id_',
        //     // disableFilters: true
        // },
        // {
        //     Header: 'Settlement Status',
        //     accessor: 'settlement_status_',
        //     // disableFilters: true
        // },
        {
            Header: 'Ridership',
            accessor: 'total_ridership',
            // disableFilters: true
        },
        {
            Header: 'Net Payable',
            accessor: 'net_payable',
            // disableFilters: true
        },
        {
            Header: 'Transaction',
            accessor: 'view_',
            disableFilters: true
        },
        {
            Header: 'Report',
            accessor: 'print_',
            disableFilters: true
        }
    ], [])

    const summaryHeaderMonthly = useMemo(() => [
        {
            Header: 'Month',
            accessor: 'month',
            // disableFilters: true
        },
        // {
        //     Header: 'Settlement ID',
        //     accessor: 'settlement_id_',
        //     // disableFilters: true
        // },
        // {
        //     Header: 'Settlement Status',
        //     accessor: 'settlement_status_',
        //     // disableFilters: true
        // },
        {
            Header: 'Ridership',
            accessor: 'total_ridership',
            // disableFilters: true
        },
        {
            Header: 'Net Payable',
            accessor: 'net_payable',
            // disableFilters: true
        },
        {
            Header: 'Transaction',
            accessor: 'view_',
            disableFilters: true
        },
        {
            Header: 'Report',
            accessor: 'print_',
            disableFilters: true
        }
    ], [])

    const handleGoToTransactionReportPage = (data, date, summary) => {
        goTo.push(`/transaction-report/${date}`, { data, date, summary })
    }

    const appendAction = (data) => {
        if (!data.length > 0) return []
        data.map((row, i) => {
            const netPayable = +row.total_amount - Utils.getDeductionValue(row.total_amount)
            row['net_payable'] = Utils.currencyFormatter(netPayable)
            const summary = {
                grossFare: row.total_amount,
                netPayable,
                ridership: row.total_ridership
            }

            if (row.date || row.month) {
                row['settlement_id_'] = `SR_${row.date || row.month}_${user?.agency?.name}`
            }
            row['settlement_status_'] = 'PENDING'

            row['view_'] = <ListCheck className='g-hover-pointer text-primary' onClick={() => handleGoToTransactionReportPage(row.transactions, row.date || row.month, summary)} />
            row['print_'] = <Printer className='g-hover-pointer text-primary' onClick={() => setSelectedDownloadableReport(row)} />
        })
        return data
    }

    const groupSettlementByDay = useMemo(() => {
        if (!settlement) return []
        const rawObj = _.groupBy(settlement, ({ createdAt }) => moment(createdAt).format('DD-MM-YYYY'))
        const allDailySummaries = []
        Object.keys(rawObj).forEach((date) => {
            const transactions = rawObj[date]
            const daySummary = {
                date,
                transactions,
                agency: user.agency
            }

            let total_amount = 0
            let total_ridership = 0
            transactions.forEach(({ amount, noOfPax }) => {
                total_amount += amount
                total_ridership += noOfPax
            })

            daySummary['total_amount'] = +total_amount.toFixed(2)
            daySummary['total_ridership'] = total_ridership

            allDailySummaries.push(daySummary)
        })
        return allDailySummaries
    }, [settlement])

    const groupSettlementByMonth = useMemo(() => {
        if (!settlement) return {}
        const rawObj = _.groupBy(settlement, ({ createdAt }) => moment(createdAt).format('MMMM YYYY'))
        const allDailySummaries = []
        Object.keys(rawObj).forEach((key) => {
            const transactions = rawObj[key]
            const daySummary = {
                month: key.toUpperCase(),
                transactions,
                agency: user.agency
            }

            let total_amount = 0
            let total_ridership = 0
            transactions.forEach(({ amount, noOfPax }) => {
                total_amount += amount
                total_ridership += noOfPax
            })

            daySummary['total_amount'] = +total_amount.toFixed(2)
            daySummary['total_ridership'] = total_ridership

            allDailySummaries.push(daySummary)
        })
        return allDailySummaries
        return
    }, [settlement])

    const onCloseDetails = () => {
        setSelectedDownloadableReport(null)
    }

    const handlePersistActiveTabValue = (tab) => {
        localStorage.setItem('persisted:settlement_page_tab', tab)
    }

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Settlement Report</h2>
            <Alert className='align-items-center' variant='info' > <InfoCircle /> Note: Settlement only applicable for cashless transaction via our JustNaik App </Alert>
            <Tabs defaultActiveKey={localStorage.getItem('persisted:settlement_page_tab') || 'daily'} id='settlement-report-tab' >
                <Tab eventKey='daily' title='Daily' onEntered={() => handlePersistActiveTabValue('daily')} >
                    <Card>
                        <Card.Body>
                            {
                                settlement && settlement.length > 0 ?
                                    <Table numbering columns={summaryHeaderDaily} data={appendAction(groupSettlementByDay)} />
                                    :
                                    <JustnaikAppIntro />
                            }
                        </Card.Body>
                    </Card>
                </Tab>
                <Tab eventKey='monthly' title='Monthly' onEntered={() => handlePersistActiveTabValue('monthly')} >
                    <Card>
                        <Card.Body>
                            {
                                settlement && settlement.length > 0 ?
                                    <Table numbering columns={summaryHeaderMonthly} data={appendAction(groupSettlementByMonth)} />
                                    :
                                    <JustnaikAppIntro />
                            }
                        </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
            {
                selectedDownloadableReport &&
                <Modal size='xl' show={selectedDownloadableReport !== null} onHide={onCloseDetails} >
                    <SettlementReportTemplate data={selectedDownloadableReport} />
                </Modal>
            }
        </ContentWrapper>
    )
}

export default SettlementPage