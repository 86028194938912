import { ACTION_TYPES } from "../../config";

// get user exp
export function requestTripScheduler(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_REQUESTED,
    payload
  };
}

export function tripSchedulerSuccess(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_SUCCEEDED,
    payload
  };
}

export function tripSchedulerFailure(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_FAILED,
    payload
  };
}

// Create case
export function requestTripSchedulerCreate(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_CREATE_REQUESTED,
    payload
  };
}

export function tripSchedulerCreateSuccess(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_CREATE_SUCCEEDED,
    payload
  };
}

export function tripSchedulerCreateFailure(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_CREATE_FAILED,
    payload
  };
}

// Update case
export function requestTripSchedulerUpdate(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_UPDATE_REQUESTED,
    payload
  };
}

export function tripSchedulerUpdateSuccess(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_UPDATE_SUCCEEDED,
    payload
  };
}

export function tripSchedulerUpdateFailure(payload) {
  return {
    type: ACTION_TYPES.TRIP_SCHEDULER_UPDATE_FAILED,
    payload
  };
}