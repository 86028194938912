import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import _ from 'lodash'
import PolylineUtils from '@mapbox/polyline'

import { ContentWrapper, RealTimeMap, StaticMap } from '../../components'

import './RealTimePage.css'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS, GENERAL } from '../../config'

const RealTimePage = () => {
    const [locationData, setLocationData] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [stops, setStops] = useState(null)

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`
        }).then((data) => {
            // 
            setRoutes(data)
        }).catch(e => { })
    }

    const handleGetAllStop = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.STOP_GET_ALL}`
        }).then((data) => {
            // 
            setStops(data)
        }).catch(e => { })
    }

    const handleGetLocationData = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ACTIVE_TRIP}`
        }, true).then((data) => {
            // 
            setLocationData(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetLocationData()
        handleGetAllRoute()
        handleGetAllStop()

        const timer = setInterval(() => {
            handleGetLocationData()
        }, GENERAL.REALTIME_INTERVAL_MS)

        return () => clearInterval(timer)
    }, [])

    const filterByRoute = useMemo(() => {
        if (!locationData) return null
        return _.groupBy(locationData, 'routeShortName')
    }, [locationData])
    // 
    // 

    const handleDecodedPolyline = (data) => {
        if (!data.polygon) return null
        return PolylineUtils.decode(data.polygon)
    }

    const AllPolylines = useMemo(() => {
        if (!routes) return null
        const data = {
            polylines: [],
            polylinesPathOpt: [],
            routeNames: []
        }
        routes.forEach(route => {
            // 
            const decodedPolyline = handleDecodedPolyline(route)
            const pathColor = route.colorCode

            if (decodedPolyline) {
                data.polylines.push(decodedPolyline)
            }
            data.polylinesPathOpt.push({ color: pathColor || 'grey' })
            data.routeNames.push(`${route.shortName} - ${route.name}`)
        })

        return data
    }, [routes])

    const AllStops = useMemo(() => {
        if (!stops) return null
        const data = []
        stops.forEach(({ latitude, longitude, name }) => {
            data.push([latitude, longitude, name])
        })
        return data
    }, [stops])

    // 

    return (
        <div style={{ zIndex: 0 }} className='position-relative text-light' >
            <div className='g-page-title bg-primary' />
            <ContentWrapper >
                <h2 className='mb-3' >Real-Time Dashboard</h2>
                <StaticMap markers={AllStops} polylineNames={AllPolylines?.routeNames || []} polylines={AllPolylines?.polylines || []} polylinesPathOpt={AllPolylines?.polylinesPathOpt || [{ color: 'red' }]} vehicleMarker={locationData} title='Monitor' />
                <Row >
                    <Col className='pt-3' sm={9} xs={12} >
                        {/* <Card className='h-100' >
                            <Card.Body>
                                <Card.Title className='text-dark' >Destination</Card.Title>
                                <Table responsive className='text-dark'>
                                    <thead>
                                        <tr>
                                            <th>Vehicle Number</th>
                                            <th>Current Destination</th>
                                            <th>Next Destination</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            locationData && locationData.length > 0 &&
                                            locationData.map(({ vehicleRegistrationNumber, location }, key) => (
                                                <tr key={key} >
                                                    <td>{vehicleRegistrationNumber}</td>
                                                    <td>{location ? location.stopName : '-'}</td>
                                                    <td>Next stop</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card> */}
                    </Col>
                    <Col className='pt-3' sm={3} xs={12} >
                        <Card className='bg-primary' >
                            <Card.Body>
                                <Card.Title className='text-light' >Active Vehicle</Card.Title>
                                {
                                    locationData ?
                                        <h1>{locationData.length}</h1>
                                        :
                                        <h1> - </h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {
                        filterByRoute &&
                        Object.entries(filterByRoute).map(([routeShortName, data], key) => (
                            <Col key={key} className='pt-3' lg={4} sm={6} xs={12} >
                                <Card className='bg-dark' >
                                    <Card.Body>
                                        <Card.Title>{routeShortName}</Card.Title>
                                        {
                                            data.map(({ driverName, vehicleRegistrationNumber }, key2) => <p key={key2} >{driverName} - {vehicleRegistrationNumber}</p>)
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </ContentWrapper>
        </div >
    )
}

export default RealTimePage