import { API_END_POINTS } from "../../config";
import { ApiRequest } from "../../helpers";

export function userLogin(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.USER_LOGIN}`,
    data: params
  });
}

export function userSignUp(params) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.USER_SIGN_UP}`,
    data: params
  });
}

export function userVerifyEmail(emailCode) {
  return ApiRequest.fetch({
    method: 'get',
    url: `${API_END_POINTS.USER_VERIFY_EMAIL}/${emailCode}`
  });
}

export function userVerifyNewBasic(emailCode, data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.VERIFY_BASIC_USER}/${emailCode}`,
    data
  });
}

export function userSubmitForgotPassword(email) {
  return ApiRequest.fetch({
    method: 'PUT',
    url: API_END_POINTS.USER_RESET_PASSWORD,
    data: { email }
  })
}

export function userUpdateForgotPassword(emailCode, data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.USER_UPDATE_FORGOT_PASSWORD}/${emailCode}`,
    data
  });
}

export function userAgencyUploadAgencyLogo(data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.AGENCY_UPLOAD_PHOTO}`,
    data
  });
}

export function updateSupervisor(data) {
  return ApiRequest.fetch({
    method: 'post',
    url: `${API_END_POINTS.UPDATE_SUPERVISOR}`,
    data
  });
}

export function updateUserProfile(data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.USER_UPDATE}`,
    data
  });
}

export function updateAgencyProfile(data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.AGENCY_UPDATE_INFO}`,
    data
  });
}