import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { API_END_POINTS, Utils, GENERAL } from '../../../config'
import { ApiRequest } from '../../../helpers'
import { DateRangePicker, LoadingModal, SimpleChart } from '../../../components'

const todayDay = moment()
const fromDateDefault = moment().subtract(1, 'weeks') //.subtract(+todayDay.format('DD') - 1, 'days')

const Ridership = () => {
    const [settlement, setSettlement] = useState(null)
    const [fromFilterDate, setFromFilterDate] = useState(fromDateDefault)
    const [toFilterDate, setToFilterDate] = useState(todayDay)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SETTLEMENT_GET_ALL}`
        }).then(data => {
            const sortedData = _.orderBy(data, [({ createdAt }) => new Date(createdAt)], ['desc'])
            setSettlement(sortedData)
        }).catch(e => { })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    const filteredSettlement = useMemo(() => {
        if (!settlement) return null
        let filterred = [...settlement]

        if (fromFilterDate) {
            const minDate = fromFilterDate.valueOf()
            filterred = _.filter(filterred, ({ createdAt }) => moment(createdAt).valueOf() > minDate)
        }

        if (toFilterDate) {
            const maxDate = toFilterDate.valueOf()
            filterred = _.filter(filterred, ({ createdAt }) => moment(createdAt).valueOf() < maxDate)
        }

        return filterred

    }, [settlement, fromFilterDate, toFilterDate])

    const groupSummaryByDay = useMemo(() => {
        if (!filteredSettlement) return null
        const rawObj = _.groupBy(filteredSettlement, ({ createdAt }) => moment(createdAt).format('DD MMM YY'))

        const datesArr = []
        if (fromFilterDate && toFilterDate) {
            const from = moment(fromFilterDate)
            const to = moment(toFilterDate)
            //populate dates in between start and end date selected
            const numOfDays = Math.abs(to.diff(from, 'days'))
            for (let i = 0; i < numOfDays; i++) {
                datesArr.push(to.subtract(1, 'day').format('DD MMM YY'))
            }
        }

        const summary = {
            allDailySummaries: [],
            totalCollected: 0,
            totalCollectedOnline: 0,
            totalCollectedOffline: 0,
            totalRidershipOnline: 0,
            totalRidershipOffline: 0,
            totalRidership: 0,
            lastDate: datesArr[0],
            beginDate: datesArr[datesArr.length - 1],
            totalTransactionCount: 0,
            totalNetPayable: 0,
            graphFinanceData: [],
            graphTransactionData: [],
            graphRidershipData: []
        }

        const graphGrossDataDay = {
            label: 'Gross Cashless Revenue',
            data: [],
            color: '#362fa9'
        }

        const graphNetDataDay = {
            label: 'Net Cashless Payable',
            data: [],
            color: '#1a73e8'
        }

        const graphCashFareDataDay = {
            label: 'Gross Cash Revenue',
            data: [],
            color: 'red'
        }

        const graphRidershipDayOnline = {
            label: 'Cashless Ridership',
            data: [],
        }

        const graphRidershipDayOffline = {
            label: 'Cash Ridership',
            data: [],
        }

        datesArr.forEach((date) => {
            const transactions = rawObj[date] || []
            const daySummary = {
                date,
                transactions
            }

            let total_amount = 0
            let total_amount_online = 0
            let total_amount_offline = 0
            let total_ridership_online = 0
            let total_ridership_offline = 0
            let total_ridership = 0

            transactions.forEach(({ amount, noOfPax, paymentType }) => {
                total_amount += amount
                total_ridership += noOfPax

                if (paymentType === GENERAL.PAYMENT_TYPE_ONLINE) {
                    total_amount_online += amount
                    total_ridership_online += noOfPax
                }

                if (paymentType === GENERAL.PAYMENT_TYPE_OFFLINE) {
                    total_amount_offline += amount
                    total_ridership_offline += noOfPax
                }
            })

            daySummary['total_amount'] = +total_amount.toFixed(2)
            daySummary['total_amount_online'] = +total_amount_online.toFixed(2)
            daySummary['total_amount_offline'] = +total_amount_offline.toFixed(2)

            daySummary['total_ridership_online'] = total_ridership_online
            daySummary['total_ridership_offline'] = total_ridership_offline
            daySummary['total_ridership'] = total_ridership
            //net payable is the amount after deduction eg: comission
            const deduction = Utils.getDeductionValue(total_amount_online)
            const netPay = total_amount_online - deduction
            daySummary['net_payable'] = +netPay.toFixed(2)

            summary.allDailySummaries.push(daySummary)
            summary.totalCollected += +total_amount.toFixed(2)
            summary.totalCollectedOnline += +total_amount_online.toFixed(2)
            summary.totalCollectedOffline += +total_amount_offline.toFixed(2)

            summary.totalRidershipOnline += total_ridership_online
            summary.totalRidershipOffline += total_ridership_offline
            summary.totalRidership += total_ridership
            summary.totalTransactionCount += transactions.length
            summary.totalNetPayable += +netPay.toFixed(2)

            graphGrossDataDay.data.push([date, total_amount_online])
            graphCashFareDataDay.data.push([date, total_amount_offline])
            graphRidershipDayOnline.data.push([date, total_ridership_online])
            graphRidershipDayOffline.data.push([date, total_ridership_offline])
        })

        graphGrossDataDay.data.reverse()
        graphCashFareDataDay.data.reverse()
        graphRidershipDayOnline.data.reverse()
        graphRidershipDayOffline.data.reverse()

        summary.graphFinanceData.push(graphGrossDataDay)
        summary.graphFinanceData.push(graphCashFareDataDay)

        summary.graphRidershipData.push(graphRidershipDayOnline)
        summary.graphRidershipData.push(graphRidershipDayOffline)

        return summary
    }, [filteredSettlement])
    return (
        <div>
            <h4 className='mb-3' >Daily Ridership</h4>
            <DateRangePicker fromDate={fromDateDefault} onFromChanged={setFromFilterDate} onToChanged={setToFilterDate} />
            {
                groupSummaryByDay &&
                <SimpleChart tooltip title='Ridership' stacked type='bar' data={groupSummaryByDay.graphRidershipData} />
            }
            <LoadingModal loading={loading} />
        </div>
    )
}

export default Ridership