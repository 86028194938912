import { connect } from "react-redux";
import AgencyProfilePage from "./AgencyProfilePage";
import { requestUserUploadAgencyLogo, requestUpdateAgencyProfile } from "../../redux/actions";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    { requestUserUploadAgencyLogo, requestUpdateAgencyProfile }
)(AgencyProfilePage)