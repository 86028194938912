import { API_END_POINTS } from "../../config";
import { ApiRequest } from "../../helpers";

export function getTripScheduler() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.TRIP_SCHEDULER,
  });
}

export function createTripScheduler(data) {
  return ApiRequest.fetch({
    method: 'post',
    url: API_END_POINTS.TRIP_SCHEDULER,
    data
  });
}

export function updateTripScheduler(tripId, data) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.TRIP_SCHEDULER}/${tripId}`,
    data
  });
}