import { useEffect, useState, Fragment } from 'react'
import { Button, Card, Row } from 'react-bootstrap'
import { ConfirmModal, ContentWrapper, StatCard } from "../../components"
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers'
import "./SchedulesV2EditorialPage.css";
import { Link, useHistory, useParams } from 'react-router-dom'
import _ from 'lodash'
import { Tabs, Tab, Form, Col, Alert, Modal, } from "react-bootstrap";
import { toast } from 'react-toastify'
import Table from 'react-bootstrap/Table';
import moment from "moment";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { InfoCircle } from 'react-bootstrap-icons'


const SchedulesV2EditorialPage = () => {
    const format = 'h:mm a';

    const { routeId } = useParams()
    const goTo = useHistory()
    const [timetable, setTimetable] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [drivers, setDrivers] = useState([])

    const [mondayOutbound, setMondayOutbound] = useState([])
    const [tuesdayOutbound, setTuesdayOutbound] = useState([])
    const [wednesdayOutbound, setWednesdayOutbound] = useState([])
    const [thursdayOutbound, setThursdayOutbound] = useState([])
    const [fridayOutbound, setFridayOutbound] = useState([])
    const [saturdayOutbound, setSaturdayOutbound] = useState([])
    const [sundayOutbound, setSundayOutbound] = useState([])
    const [mondayInbound, setMondayInbound] = useState([])
    const [tuesdayInbound, setTuesdayInbound] = useState([])
    const [wednesdayInbound, setWednesdayInbound] = useState([])
    const [thursdayInbound, setThursdayInbound] = useState([])
    const [fridayInbound, setFridayInbound] = useState([])
    const [saturdayInbound, setSaturdayInbound] = useState([])
    const [sundayInbound, setSundayInbound] = useState([])
    const [mondayLoop, setMondayLoop] = useState([])
    const [tuesdayLoop, setTuesdayLoop] = useState([])
    const [wednesdayLoop, setWednesdayLoop] = useState([])
    const [thursdayLoop, setThursdayLoop] = useState([])
    const [fridayLoop, setFridayLoop] = useState([])
    const [saturdayLoop, setSaturdayLoop] = useState([])
    const [sundayLoop, setSundayLoop] = useState([])
    const [routesArr, setRoutesArr] = useState([])
    const [currentRoute, setCurrentRoute] = useState('')
    const [currentStops, setCurrentStops] = useState([])
    const [currentStopsOptions, setCurrentStopsOptions] = useState([])
    const [currentDirection, setCurrentDirection] = useState('')
    const [currentTimetableScheduleId, setCurrentTimetableScheduleId] = useState('')
    const [currentStartId, setCurrentStartId] = useState('')
    const [currentStopId, setCurrentStopId] = useState('')
    const [allTimetable, setAllTimetable] = useState({})
    const [currentEditSchedule, setCurrentEditSchedule] = useState('')
    const [currentEditScheduleId, setCurrentEditScheduleId] = useState('')
    const [refresh, setRefresh] = useState(true)
    const [schedules, setSchedules] = useState([])
    const [outBoundStartId, setOutBoundStartId] = useState()
    const [inBoundStartId, setInBoundStartId] = useState()
    const [loopStartId, setLoopStartId] = useState()
    const [outBoundStopId, setOutBoundStopId] = useState()
    const [inBoundStopId, setInBoundStopId] = useState()
    const [loopStopId, setLoopStopId] = useState()
    const [outScheduleId, setOutScheduleId] = useState()
    const [inScheduleId, setInScheduleId] = useState()
    const [loopScheduleId, setLoopScheduleId] = useState()
    const [outScheduleIdO, setOutScheduleIdO] = useState('')
    const [inScheduleIdO, setInScheduleIdO] = useState('')
    const [loopScheduleIdO, setLoopScheduleIdO] = useState('')
    const [routeType, setRouteType] = useState()

    const [isTimetableActive, setIsTimetableActive] = useState(true);

    const [duplicateFromList, setDuplicateFromList] = useState([]);

    // Define a mapping for days and directions to determine the state variable and array
    const dayMap = {
        'monday': 'Monday',
        'tuesday': 'Tuesday',
        'wednesday': 'Wednesday',
        'thursday': 'Thursday',
        'friday': 'Friday',
        'saturday': 'Saturday',
        'sunday': 'Sunday',
    };

    const directionMap = {
        0: 'Loop',
        1: 'Outbound',
        2: 'Inbound',
    };

    // Define state update mapping to dynamically set the right state
    const stateSetterMap = {
        mondayOutbound: setMondayOutbound,
        mondayInbound: setMondayInbound,
        tuesdayOutbound: setTuesdayOutbound,
        tuesdayInbound: setTuesdayInbound,
        wednesdayOutbound: setWednesdayOutbound,
        wednesdayInbound: setWednesdayInbound,
        thursdayOutbound: setThursdayOutbound,
        thursdayInbound: setThursdayInbound,
        fridayOutbound: setFridayOutbound,
        fridayInbound: setFridayInbound,
        saturdayOutbound: setSaturdayOutbound,
        saturdayInbound: setSaturdayInbound,
        sundayOutbound: setSundayOutbound,
        sundayInbound: setSundayInbound,
        mondayLoop: setMondayLoop,
        tuesdayLoop: setTuesdayLoop,
        wednesdayLoop: setWednesdayLoop,
        thursdayLoop: setThursdayLoop,
        fridayLoop: setFridayLoop,
        saturdayLoop: setSaturdayLoop,
        sundayLoop: setSundayLoop,
    };

    const handleToggleIsActive = (id) => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET}/${id}`,
        }).then((data) => {
            let id_list = [];

            data.forEach(schedule => {
                const _id = schedule['id'];
                id_list.push(_id);
            });

            const params = {
                idList: id_list,
                isActive: !isTimetableActive,
            }

            ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_TOGGLE_IS_ACTIVE}`,
                data: params,
            }).then((data) => {
                setRefresh(!refresh);
            }).catch((e) => {
                console.error(e);
            });
        }).catch((e) => {
            console.error(e);
        });
    }

    useEffect(() => {
        //   fetch direction on current route
        setCurrentStops([])
        setCurrentStopsOptions([])
        if (routeId) {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ROUTE_GET_STOPS}/${routeId}`
            }).then((data) => {
                setCurrentStops(data.stops)
                if (data.stops[0].directionId == 0) {
                    setRouteType("Loop")
                    // setCurrentStopsOptions((prevState) => [...prevState, "Loop"])
                } else {
                    setRouteType("Twoway")

                    // setCurrentStopsOptions((prevState) => [...prevState, "Inbound", "Outbound"])
                }

            }).catch(e => { })
        }
    }, [routeId])


    const handleGetVehicles = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`
        }).then((data) => {
            setVehicles(data.map(({ registrationNumber, id }) => ({ name: registrationNumber, id })))
        }).catch(e => { })
    }


    const handleGetDrivers = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DRIVER_GET_ALL}`
        }).then((data) => {
            setDrivers(data.map(({ firstName, lastName, id }) => ({ name: `${firstName} ${lastName}`, id })))
        }).catch(e => { })
    }
    useEffect(() => {
        handleGetDrivers()
        handleGetVehicles()
    }, [])

    const handleDriverSelection = (driver_id, sct_id, details) => {
        if (!driver_id) {
            toast.error("please select Driver to update!")
        }

        const day = details[0].day;
        const directionId = details[0].direction_id;
    
        const dayName = dayMap[day.toLowerCase()];
        const directionName = directionMap[directionId];
    
        // Check for invalid day or direction
        if (!dayName || !directionName) {
            console.error('Invalid day or direction.');
            return toast.error('Something went wrong!');
        }
    
        const stateVariableName = `${dayName.toLowerCase()}${directionName}`;
        const sourceArray = eval(stateVariableName);

        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE}`,
            data: { scheduleId: sct_id, driverId: driver_id }

            // params: { schedule_id : schedule_id}
        }).then((data) => {
            const driverName = `${data.driver_first_name} ${data.driver_last_name}`

            const updatedArray = sourceArray.map(item =>
                item.schedule_uuid === sct_id
                ? 
                { 
                    ...item, 
                    driver_id: data.driver_id,
                    driver_first_name: data.driver_first_name,
                    driver_last_name: data.driver_last_name,
                }
                : item
            );
            
            const setStateFunction = stateSetterMap[stateVariableName];
            
            if (setStateFunction) {
                setStateFunction(updatedArray);
                toast.success("Driver Assigned!");
            } else {
                console.error("Invalid state variable name:", stateVariableName);
                toast.error('Something went wrong!');
            }
        }).catch(error => {
            console.error("Error updating vehicle:", error);
            toast.error('Error updating vehicle!');
        });
    }

    const handleVehicleSelection = (vehicle_id, sct_id, details) => {
        if (!vehicle_id) {
            toast.error("Please select Driver to update!");
            return;
        }
    
        const day = details[0].day;
        const directionId = details[0].direction_id;
    
        const dayName = dayMap[day.toLowerCase()];
        const directionName = directionMap[directionId];
    
        // Check for invalid day or direction
        if (!dayName || !directionName) {
            console.error('Invalid day or direction.');
            return toast.error('Something went wrong!');
        }
    
        const stateVariableName = `${dayName.toLowerCase()}${directionName}`;
        const sourceArray = eval(stateVariableName);
    
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE}`,
            data: { scheduleId: sct_id, vehicleId: vehicle_id }
        }).then((data) => {
            // Update the relevant item in the array without mutating state directly
            const updatedArray = sourceArray.map(item =>
                item.schedule_uuid === sct_id
                    ? { ...item, vehicle_id: data.vehicle_id, vehicle_registration_number: data.vehicle_registration_number }
                    : item
            );
    
            const setStateFunction = stateSetterMap[stateVariableName];
            
            if (setStateFunction) {
                setStateFunction(updatedArray);
                toast.success("Vehicle Assigned!");
            } else {
                console.error("Invalid state variable name:", stateVariableName);
                toast.error('Something went wrong!');
            }
        }).catch(error => {
            console.error("Error updating vehicle:", error);
            toast.error('Error updating vehicle!');
        });
    };

    const handleRouteUpdate = (id) => {
        setMondayOutbound([])
        setTuesdayOutbound([])
        setWednesdayOutbound([])
        setThursdayOutbound([])
        setFridayOutbound([])
        setSaturdayOutbound([])
        setSundayOutbound([])
        setMondayInbound([])
        setTuesdayInbound([])
        setWednesdayInbound([])
        setThursdayInbound([])
        setFridayInbound([])
        setSaturdayInbound([])
        setSundayInbound([])
        setMondayLoop([])
        setTuesdayLoop([])
        setWednesdayLoop([])
        setThursdayLoop([])
        setFridayLoop([])
        setSaturdayLoop([])
        setSundayLoop([])

        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET}/${id}`,
        }).then((data) => {

            let grouped_data = _.groupBy(data, "direction_id");
            setTimetable(grouped_data)
            // 1 - outbound

            // outbound
            let outbound_data_days = _.groupBy(grouped_data[1], "day");
            // 
            // 
            // grouped_data[1].length>0 && setOutScheduleIdO(outbound_data_days[Object.keys(outbound_data_days)[0]][0].schedule_id)

            let inbound_data_days = _.groupBy(grouped_data[2], "day");
            // grouped_data[2].length>0 && setInScheduleIdO(inbound_data_days[Object.keys(inbound_data_days)[0]][0].schedule_id)

            let loop_data_days = _.groupBy(grouped_data[0], "day");
            // grouped_data[0].length>0 && setLoopScheduleIdO(loop_data_days[Object.keys(loop_data_days)[0]][0].schedule_id)


            if (Object.keys(outbound_data_days).length !== 0) {
                // check if timetable is active. 1 route is enough to determine
                for (const day in outbound_data_days) {
                    const daySchedules = outbound_data_days[day];
                    daySchedules.forEach(schedule => {
                        if (schedule.is_active) {
                            setIsTimetableActive(true);
                            return;
                        } else {
                            setIsTimetableActive(false);
                            return;
                        }
                    });
                }
            }

            if (Object.keys(loop_data_days).length !== 0) {
                for (const day in loop_data_days) {
                    const daySchedules = loop_data_days[day];
                    daySchedules.forEach(schedule => {
                        if (schedule.is_active) {
                            setIsTimetableActive(true);
                            return;
                        } else {
                            setIsTimetableActive(false);
                            return;
                        }
                    });
                }
            }

            const completeData = { 'inbound': inbound_data_days, 'outbound': outbound_data_days, 'loop': loop_data_days };

            let result = new Set();
            const dayOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
            for (const direction in completeData) {
                for (const day in completeData[direction]) {
                    // Get the list of schedules for the current day and direction
                    const schedules = completeData[direction][day];

                    schedules.forEach(schedule => {
                        result.add(`${day} ${direction}`);
                    });
                }
            }

            result = Array.from(result).sort((a, b) => {
                const [dayA] = a.split(' ');
                const [dayB] = b.split(' ');
                return dayOrder.indexOf(dayA) - dayOrder.indexOf(dayB);
            });

            result.unshift('select');
            setDuplicateFromList(result);

            outbound_data_days.monday && setMondayOutbound((prevState) => [...prevState, ...outbound_data_days.monday])
            outbound_data_days.tuesday && setTuesdayOutbound((prevState) => [...prevState, ...outbound_data_days.tuesday])
            outbound_data_days.wednesday && setWednesdayOutbound((prevState) => [...prevState, ...outbound_data_days.wednesday])
            outbound_data_days.thursday && setThursdayOutbound((prevState) => [...prevState, ...outbound_data_days.thursday])
            outbound_data_days.friday && setFridayOutbound((prevState) => [...prevState, ...outbound_data_days.friday])
            outbound_data_days.saturday && setSaturdayOutbound((prevState) => [...prevState, ...outbound_data_days.saturday])
            outbound_data_days.sunday && setSundayOutbound((prevState) => [...prevState, ...outbound_data_days.sunday])
            inbound_data_days.monday && setMondayInbound((prevState) => [...prevState, ...inbound_data_days.monday])
            inbound_data_days.tuesday && setTuesdayInbound((prevState) => [...prevState, ...inbound_data_days.tuesday])
            inbound_data_days.wednesday && setWednesdayInbound((prevState) => [...prevState, ...inbound_data_days.wednesday])
            inbound_data_days.thursday && setThursdayInbound((prevState) => [...prevState, ...inbound_data_days.thursday])
            inbound_data_days.friday && setFridayInbound((prevState) => [...prevState, ...inbound_data_days.friday])
            inbound_data_days.saturday && setSaturdayInbound((prevState) => [...prevState, ...inbound_data_days.saturday])
            inbound_data_days.sunday && setSundayInbound((prevState) => [...prevState, ...inbound_data_days.sunday])
            loop_data_days.monday && setMondayLoop((prevState) => [...prevState, ...loop_data_days.monday])
            loop_data_days.tuesday && setTuesdayLoop((prevState) => [...prevState, ...loop_data_days.tuesday])


            loop_data_days.wednesday && setWednesdayLoop((prevState) => [...prevState, ...sort_by_key(loop_data_days.wednesday, "start_time")])


            loop_data_days.thursday && setThursdayLoop((prevState) => [...prevState, ...loop_data_days.thursday])
            loop_data_days.friday && setFridayLoop((prevState) => [...prevState, ...loop_data_days.friday])
            loop_data_days.saturday && setSaturdayLoop((prevState) => [...prevState, ...loop_data_days.saturday])
            loop_data_days.sunday && setSundayLoop((prevState) => [...prevState, ...loop_data_days.sunday])
            grouped_data[1].length > 0 && setOutScheduleIdO(outbound_data_days[Object.keys(outbound_data_days)[0]][0].schedule_id)
            grouped_data[2].length > 0 && setInScheduleIdO(inbound_data_days[Object.keys(inbound_data_days)[0]][0].schedule_id)
            grouped_data[0].length > 0 && setLoopScheduleIdO(loop_data_days[Object.keys(loop_data_days)[0]][0].schedule_id)

            // toast.success('Route Updated!')
            // setRefresh(!refresh)
        }).catch(e => { })
    }

    useEffect(() => {
        handleRouteUpdate(routeId)
        handleRouteSelection(routeId)
    }, [routeId, refresh])

    const populateSchedule = (e) => {
        e.preventDefault()

        // if (!currentRoute ||  (!currentDirection && currentDirection !== 0) || !currentTimetableScheduleId) {
        //     toast.error("Fill all data to create a Timetable")
        // }
        if (routeType == 'Twoway') {
            if ((!outScheduleId && !outScheduleIdO) && (!inScheduleId && !inScheduleIdO)) {


                toast.error("Fill all data to create a Timetable")

            } else if (outScheduleId && inScheduleId) {



                ApiRequest.fetch({
                    method: 'post',
                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
                    data: { route_id: routeId, direction_id: 1, schedule_id: outScheduleId, start_stop_id: outBoundStartId, end_stop_id: outBoundStopId }
                }).then(() => {
                    ApiRequest.fetch({
                        method: 'post',
                        url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
                        data: { route_id: routeId, direction_id: 2, schedule_id: inScheduleId, start_stop_id: inBoundStartId, end_stop_id: inBoundStopId }
                    }).then(() => {
                        setOutScheduleId('')
                        setInScheduleId('')
                        toast.success("Timetable Added!")
                        setRefresh(!refresh)
                    }).catch(e => { })

                }).catch(e => { })
            } else if (outScheduleId) {

                ApiRequest.fetch({
                    method: 'post',
                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
                    data: { route_id: routeId, direction_id: 1, schedule_id: outScheduleId, start_stop_id: outBoundStartId, end_stop_id: outBoundStopId }
                }).then(() => {
                    toast.success("Timetable Added!")
                    setOutScheduleId('')
                    setInScheduleId('')
                    setRefresh(!refresh)
                }).catch(e => { })
            } else if (inScheduleId) {

                ApiRequest.fetch({
                    method: 'post',
                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
                    data: { route_id: routeId, direction_id: 2, schedule_id: inScheduleId, start_stop_id: inBoundStartId, end_stop_id: inBoundStopId }
                }).then(() => {
                    toast.success("Timetable Added!")
                    setOutScheduleId('')
                    setInScheduleId('')
                    setRefresh(!refresh)
                }).catch(e => { })

            } else {
                return
            }
        }
        else {
            // for loop condition

            if (!loopScheduleId && !loopScheduleIdO) {


                toast.error("Selected schedule already exists!")

            } else if (loopScheduleId) {
                ApiRequest.fetch({
                    method: 'post',
                    url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_CREATE}`,
                    data: { route_id: routeId, direction_id: 0, schedule_id: loopScheduleId, start_stop_id: loopStartId, end_stop_id: loopStopId }
                }).then(() => {
                    setLoopScheduleId('')
                    toast.success("Timetable Added!")
                    setRefresh(!refresh)

                }).catch(e => { })
            } else {
                return
            }
        }
    }

    function sort_by_key(array, key) {
        return array.sort(function (a, b) {
            var x = moment(a[key], 'h:mm a').format('X'); var y = moment(a = b[key], 'h:mm a').format('X');
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    useEffect(() => {
        handleGetAllRoute()

    }, [])

    const handleRouteSelection = (id) => {


        setCurrentRoute(id)

    }

    const handleDirectionSelection = (dir) => {
        if (dir == 'Outbound') {
            const result = currentStops.filter(obj => {
                return obj.directionId == 1
            })

            setCurrentStartId(result[0].id)
            setCurrentStopId(result[result.length - 1].id)

            setCurrentDirection(1)
        } else if (dir == 'Inbound') {
            const result = currentStops.filter(obj => {
                return obj.directionId == 2
            })

            setCurrentStartId(result[0].id)
            setCurrentStopId(result[result.length - 1].id)

            setCurrentDirection(2)

        }
        else if (dir == 'Loop') {
            setCurrentDirection(0)
            setCurrentStartId(currentStops[0].id)
            setCurrentStopId(currentStops[currentStops.length - 2].id)

        }
        else {

        }
    }

    const handleScheduleSelection1 = (id) => {
        setOutScheduleId(id)
    }

    const handleScheduleSelection0 = (id) => {
        setLoopScheduleId(id)
    }

    const handleScheduleSelection2 = (id) => {
        setInScheduleId(id)
    }

    const handleGetAllRoute = async () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,

        }).then(async (data) => {
            await setRoutesArr(data)
            // handleRouteData()
        }).catch(e => { })
    }

    const handleDuplicateFrom = async (source, target) => {
        if (source === 'select') return;

        const [source_day, source_direction] = source.split(' ');
        const [target_day, target_direction] = target.split(' ');
    
        // Map to store the reference arrays
        const stateMap = {
            mondayOutbound, mondayInbound, mondayLoop,
            tuesdayOutbound, tuesdayInbound, tuesdayLoop,
            wednesdayOutbound, wednesdayInbound, wednesdayLoop,
            thursdayOutbound, thursdayInbound, thursdayLoop,
            fridayOutbound, fridayInbound, fridayLoop,
            saturdayOutbound, saturdayInbound, saturdayLoop,
            sundayOutbound, sundayInbound, sundayLoop
        };
    
        // Construct keys dynamically
        const sourceKey = `${source_day}${capitalize(source_direction)}`;
        const targetKey = `${target_day}${capitalize(target_direction)}`;
    
        const sourceArray = stateMap[sourceKey];
        const targetArray = stateMap[targetKey];
    
        // Check if source and target arrays are valid
        if (!sourceArray || !targetArray) {
            console.error('Invalid source or target array.');
            toast.error("Invalid source or target selection");
            return;
        }

        let iteration;
        if (sourceArray.length === targetArray.length) iteration = sourceArray.length;
        else if (sourceArray.length > targetArray.length) iteration = targetArray.length;
        else if (sourceArray.length < targetArray.length) iteration = sourceArray.length;
    
        // Prepare params based on minimum array length to avoid index issues
        const params = [];
        for (let i = 0; i < iteration; i++) {
            params.push({
                scheduleId: targetArray[i].schedule_uuid,
                vehicleId: sourceArray[i].vehicle_id,
                driverId: sourceArray[i].driver_id
            });
        }

        try {
            const response = await ApiRequest.fetch({
                method: 'put',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_UPDATE_BULK}`,
                data: params
            });
    
            if (response) {
                const updatedArray = targetArray.map(item => {
                    const param = params.find(p => p.scheduleId === item.schedule_uuid);
                
                    if (param) {
                        return {
                            ...item,
                            vehicle_id: param.vehicleId !== null ? param.vehicleId : item.vehicle_id, // Update if `vehicleId` is not null
                            driver_id: param.driverId !== null ? param.driverId : item.driver_id      // Update if `driverId` is not null
                        };
                    }
                
                    return item;
                });
    
                // Map state update functions for each variable name
                const setStateMap = {
                    mondayOutbound: setMondayOutbound,
                    mondayInbound: setMondayInbound,
                    mondayLoop: setMondayLoop,
                    tuesdayOutbound: setTuesdayOutbound,
                    tuesdayInbound: setTuesdayInbound,
                    tuesdayLoop: setTuesdayLoop,
                    wednesdayOutbound: setWednesdayOutbound,
                    wednesdayInbound: setWednesdayInbound,
                    wednesdayLoop: setWednesdayLoop,
                    thursdayOutbound: setThursdayOutbound,
                    thursdayInbound: setThursdayInbound,
                    thursdayLoop: setThursdayLoop,
                    fridayOutbound: setFridayOutbound,
                    fridayInbound: setFridayInbound,
                    fridayLoop: setFridayLoop,
                    saturdayOutbound: setSaturdayOutbound,
                    saturdayInbound: setSaturdayInbound,
                    saturdayLoop: setSaturdayLoop,
                    sundayOutbound: setSundayOutbound,
                    sundayInbound: setSundayInbound,
                    sundayLoop: setSundayLoop
                };
    
                const setStateFunction = setStateMap[targetKey];
                if (setStateFunction) {
                    setStateFunction(updatedArray);
                    source = '';
                    toast.success("Timetable Assigned!");
                } else {
                    console.error("Invalid target state key:", targetKey);
                }
            }
        } catch (error) {
            toast.error("Something went wrong");
            console.error("Error updating timetable:", error);
        }
    };
    
    // Helper function to capitalize the first letter of a direction (e.g., 'outbound' -> 'Outbound')
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
    

    useEffect(() => {
        if (routeType && currentStops) {
            if (routeType == 'Twoway') {
                const result = currentStops.filter(obj => {
                    return obj.directionId == 1
                })
                if (result.length > 0) {
                    setOutBoundStartId(result[0].id)
                    setOutBoundStopId(result[result.length - 1].id)
                }
                const result2 = currentStops.filter(obj => {
                    return obj.directionId == 2
                })
                if (result2.length > 0) {
                    setInBoundStartId(result2[0].id)
                    setInBoundStopId(result2[result2.length - 1].id)
                }
            }
            else {
                if (currentStops.length > 0) {

                    setLoopStartId(currentStops[0].id)
                    setLoopStopId(currentStops[currentStops.length - 2].id)

                }
            }
        }
    }, [routeType, currentStops])

    const getAllSchedule = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SCHEDULEV2_GET_ALL}`,
            // data: { datan: datan, scheduleName: scheduleName }
        }).then((data) => {

            setSchedules(data)
        }).catch(e => { })
    }

    useEffect(() => {
        getAllSchedule()
    }, [refresh])

    // operation table

    function OperationTable({ start_time, end_time, vehicles }) {
        return (
            <Table bordered size="sm" style={{ width: '450px' }}>

                <tbody>
                    <tr>
                        <th rowSpan={2} style={{ rowSpan: '2' }}>Operation Hours</th>
                        <th>Start Time</th>
                        <TimePicker
                            showSecond={false}
                            defaultValue={moment(start_time, 'h:mm a')}
                            className="xxx"
                            disabled

                            format={format}
                            use12Hours
                            allowEmpty={false}

                        />
                    </tr>
                    <tr>

                        <th>End Time</th>
                        <TimePicker style={{ border: '0px' }}
                            showSecond={false}
                            defaultValue={moment(end_time, 'h:mm a')}
                            className="xxx"
                            disabled
                            format={format}
                            use12Hours
                            allowEmpty={false}

                        />

                    </tr>
                    <tr>
                        <th style={{ colSpan: '2' }} colSpan={2}>Number of Vehicles</th>
                        <td> {vehicles}</td>


                    </tr>

                </tbody>
            </Table>
        );
    }

    return (
        <ContentWrapper>

            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <h2 className='mb-3'> Assign:</h2>

            <Form onSubmit={(e) => { populateSchedule(e) }}>

                <Row style={{ display: "flex", flexWrap: "wrap" }} className='m-3 '>
                    <Col lg={4} md={2} >

                        <Form.Group>
                            <Form.Label><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#2c278b" className="bi bi-1-square" viewBox="0 0 16 16">
                                <path d="M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z" />
                                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
                            </svg> <h4 style={{ display: 'inline-block' }}>Route:</h4></Form.Label>
                            <Form.Control required as='select' disabled defaultValue={-1} >

                                <option disabled value={-1} key={-1}> </option>

                                {routesArr.length > 0 && routesArr.map((route) =>

                                    <option key={route.id} selected={route.id == routeId ? true : false} value={route.id}>{route.name} </option>

                                )}

                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={2} >
                        {routeType && routeType == 'Twoway' &&
                            <> <Form.Group>
                                <Form.Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#2c278b" className="bi bi-2-square" viewBox="0 0 16 16">
                                        <path d="M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306Z" />
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
                                    </svg> <h4 style={{ display: 'inline-block' }}> Direction:</h4></Form.Label>
                                <Form.Control disabled as='select' defaultValue={-1} >
                                    <option value={0} key={1}> Outbound</option>


                                </Form.Control>

                                {/* <Form.Control  type='number' step='1' min='1' max='100'  value={seniorDiscount} onChange={(e)=>setSeniorDiscount(e.target.value)} /> */}
                            </Form.Group>
                                <Form.Group>

                                    <Form.Control disabled as='select' defaultValue={-1} >
                                        <option value={0} key={1}> Inbound</option>


                                    </Form.Control>

                                    {/* <Form.Control  type='number' step='1' min='1' max='100'  value={seniorDiscount} onChange={(e)=>setSeniorDiscount(e.target.value)} /> */}
                                </Form.Group>
                            </>
                        }
                        {routeType && routeType == 'Loop' && <>
                            <Form.Group>
                                <Form.Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#2c278b" className="bi bi-2-square" viewBox="0 0 16 16">
                                        <path d="M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306Z" />
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
                                    </svg> <h4 style={{ display: 'inline-block' }}> Direction:</h4></Form.Label>
                                <Form.Control disabled as='select' defaultValue={-1} >
                                    <option value={0} key={1}> Loop</option>


                                </Form.Control>

                                {/* <Form.Control  type='number' step='1' min='1' max='100'  value={seniorDiscount} onChange={(e)=>setSeniorDiscount(e.target.value)} /> */}
                            </Form.Group>
                            <Form.Group></Form.Group>
                        </>

                        }
                    </Col>
                    <Col lg={4} md={2} >
                        {routeType && routeType == 'Twoway' && <>
                            <Form.Group>
                                <Form.Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#2c278b" className="bi bi-3-square" viewBox="0 0 16 16">
                                        <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z" />
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
                                    </svg>  <h4 style={{ display: 'inline-block' }}>Schedule:</h4></Form.Label>
                                <Form.Control custom required as='select' value={outScheduleId || mondayOutbound[0]?.schedule_id || tuesdayOutbound[0]?.schedule_id || wednesdayOutbound[0]?.schedule_id || thursdayOutbound[0]?.schedule_id || fridayOutbound[0]?.schedule_id || saturdayOutbound[0]?.schedule_id || sundayOutbound[0]?.schedule_id || -1} onChange={(e) => handleScheduleSelection1(e.target.value)}>

                                    <option disabled value={-1} key={-1}> </option>

                                    {schedules.length > 0 && schedules.map((sc, index) =>

                                        <option key={sc.schedule_id} value={sc.schedule_id}>{sc.name} </option>

                                    )}

                                </Form.Control>

                                {/* <Form.Control type='number' step='1' min='1' max='100' value={okuDiscount} onChange={(e)=>setOkuDiscount(e.target.value)}/> */}
                            </Form.Group>
                            <Form.Group>

                                <Form.Control custom required as='select' value={inScheduleId || mondayInbound[0]?.schedule_id || tuesdayInbound[0]?.schedule_id || wednesdayInbound[0]?.schedule_id || thursdayInbound[0]?.schedule_id || fridayInbound[0]?.schedule_id || saturdayInbound[0]?.schedule_id || sundayInbound[0]?.schedule_id || -1} onChange={(e) => handleScheduleSelection2(e.target.value)}>

                                    <option disabled value={-1} key={-1}> </option>

                                    {schedules.length > 0 && schedules.map((sc, index) =>

                                        <option key={sc.schedule_id} value={sc.schedule_id}>{sc.name} </option>

                                    )}

                                </Form.Control>

                                {/* <Form.Control type='number' step='1' min='1' max='100' value={okuDiscount} onChange={(e)=>setOkuDiscount(e.target.value)}/> */}
                            </Form.Group>
                        </>}
                        {routeType && routeType == 'Loop' && <>
                            <Form.Group>
                                <Form.Label>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#2c278b" className="bi bi-3-square" viewBox="0 0 16 16">
                                        <path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z" />
                                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
                                    </svg>  <h4 style={{ display: 'inline-block' }}>Schedule:</h4></Form.Label>
                                <Form.Control custom required as='select' value={loopScheduleId || mondayLoop[0]?.schedule_id || tuesdayLoop[0]?.schedule_id || wednesdayLoop[0]?.schedule_id || thursdayLoop[0]?.schedule_id || fridayLoop[0]?.schedule_id || saturdayLoop[0]?.schedule_id || sundayLoop[0]?.schedule_id || -1} onChange={(e) => handleScheduleSelection0(e.target.value)}>

                                    <option disabled value={-1} key={-1}> </option>

                                    {schedules.length > 0 && schedules.map((sc, index) =>

                                        <option key={sc.schedule_id} value={sc.schedule_id}>{sc.name} </option>

                                    )}

                                </Form.Control>

                                {/* <Form.Control type='number' step='1' min='1' max='100' value={okuDiscount} onChange={(e)=>setOkuDiscount(e.target.value)}/> */}
                            </Form.Group>

                        </>}
                    </Col>
                </Row>
                <Alert variant='info' > <InfoCircle /> The System creates Trip based on the timetable everyday at 03:15 AM(GMT+8).<br></br>
                    <InfoCircle /> Driver and Vehicle should be Assigned for trip creation. <br></br>
                    <InfoCircle /> After trip creation(i.e after 03:15 AM),if needed to change Vehicle and/or Driver, can edit under "Scheduled Trips" tab. <br></br>
                    <InfoCircle /> The trips in the Timetable can be edited from the respective schedule under "Manage Schedules" or new schedule can be assigned by updating the schedule from the dropdown.
                </Alert>
                <Row style={{ display: "flex", justifyContent: "flex-end" }}>

                    <button className='fare-discount-button col-md-2 mr-4 mb-2' type="submit">Create or update existing Timetable</button>

                    {
                        isTimetableActive ?
                            <Button className='col-md-2 mr-4 mb-2 btn-success' onClick={() => handleToggleIsActive(routeId)}>Timetable is active. Click to disable</Button>
                            :
                            <Button className='col-md-2 mr-4 mb-2 btn-danger' onClick={() => handleToggleIsActive(routeId)}>Timetable is disabled. Click to activate</Button>
                    }

                </Row>
            </Form>

            {(mondayLoop.length > 0) && mondayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Monday</h4></div>}
            {/* new start */}
            {mondayLoop.length > 0 && mondayLoop[0].is_active && <OperationTable start_time={sort_by_key(mondayLoop, "start_time")[0].start_time} end_time={sort_by_key(mondayLoop, "end_time")[mondayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(mondayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {/* {( mondayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(mondayLoop).length} </Form.Label>} */}
            {mondayLoop.length > 0 && mondayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayLoop && mondayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayLoop && mondayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'monday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(mondayLoop).length > 0 && Object.keys(sort_by_key(mondayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={mondayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(mondayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(mondayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={mondayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" custom required as='select' value={mondayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, mondayLoop[key].schedule_uuid, mondayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={mondayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, mondayLoop[key].schedule_uuid, mondayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>



                                        </Form.Group>




                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(mondayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(mondayLoop).length} </Form.Label>}
                </Row>

            }
            {/* start  loop*/}
            {/* start  loop*/}

            {(tuesdayLoop.length > 0) && tuesdayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Tuesday</h4></div>}
            {/* new start */}
            {tuesdayLoop.length > 0 && tuesdayLoop[0].is_active && <OperationTable start_time={sort_by_key(tuesdayLoop, "start_time")[0].start_time} end_time={sort_by_key(tuesdayLoop, "end_time")[tuesdayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(tuesdayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {tuesdayLoop.length > 0 && tuesdayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayLoop && tuesdayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayLoop && tuesdayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'tuesday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(tuesdayLoop).length > 0 && Object.keys(sort_by_key(tuesdayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={tuesdayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(tuesdayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(tuesdayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={tuesdayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={tuesdayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, tuesdayLoop[key].schedule_uuid, tuesdayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={tuesdayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, tuesdayLoop[key].schedule_uuid, tuesdayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(tuesdayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(tuesdayLoop).length} </Form.Label>}
                </Row>

            }
            {/* end loop */}
            {/* start  loop*/}


            {/* start  loop*/}

            {(wednesdayLoop.length > 0) && wednesdayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Wednesday</h4></div>}
            {/* new start */}
            {wednesdayLoop.length > 0 && wednesdayLoop[0].is_active && <OperationTable start_time={sort_by_key(wednesdayLoop, "start_time")[0].start_time} end_time={sort_by_key(wednesdayLoop, "end_time")[wednesdayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(wednesdayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {wednesdayLoop.length > 0 && wednesdayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayLoop && wednesdayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayLoop && wednesdayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'wednesday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(wednesdayLoop).length > 0 && Object.keys(sort_by_key(wednesdayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={wednesdayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(wednesdayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(wednesdayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={wednesdayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={wednesdayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, wednesdayLoop[key].schedule_uuid, wednesdayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={wednesdayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, wednesdayLoop[key].schedule_uuid, wednesdayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>


                    {(wednesdayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(wednesdayLoop).length} </Form.Label>}

                </Row>

            }
            {/* end loop */}
            {(thursdayLoop.length > 0) && thursdayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Thursday</h4></div>}
            {/* new start */}
            {thursdayLoop.length > 0 && thursdayLoop[0].is_active && <OperationTable start_time={sort_by_key(thursdayLoop, "start_time")[0].start_time} end_time={sort_by_key(thursdayLoop, "end_time")[thursdayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(thursdayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {thursdayLoop.length > 0 && thursdayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayLoop && thursdayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayLoop && thursdayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'thursday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(thursdayLoop).length > 0 && Object.keys(sort_by_key(thursdayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={thursdayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(thursdayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(thursdayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={thursdayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={thursdayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, thursdayLoop[key].schedule_uuid, thursdayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={thursdayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, thursdayLoop[key].schedule_uuid, thursdayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(thursdayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(thursdayLoop).length} </Form.Label>}
                </Row>

            }
            {/* end loop */}


            {/* start  loop*/}

            {(fridayLoop.length > 0) && fridayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Friday</h4></div>}
            {/* new start */}
            {fridayLoop.length > 0 && fridayLoop[0].is_active && <OperationTable start_time={sort_by_key(fridayLoop, "start_time")[0].start_time} end_time={sort_by_key(fridayLoop, "end_time")[fridayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(fridayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {fridayLoop.length > 0 && fridayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayLoop && fridayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayLoop && fridayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'friday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(fridayLoop).length > 0 && Object.keys(sort_by_key(fridayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={fridayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(fridayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(fridayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={fridayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" custom required as='select' value={fridayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, fridayLoop[key].schedule_uuid, fridayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={fridayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, fridayLoop[key].schedule_uuid, fridayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>


                    {(fridayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(fridayLoop).length} </Form.Label>}

                </Row>

            }
            {/* end loop */}
            {/* start  loop*/}

            {(saturdayLoop.length > 0) && saturdayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Saturday</h4></div>}
            {/* new start */}
            {saturdayLoop.length > 0 && saturdayLoop[0].is_active && <OperationTable start_time={sort_by_key(saturdayLoop, "start_time")[0].start_time} end_time={sort_by_key(saturdayLoop, "end_time")[saturdayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(saturdayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {saturdayLoop.length > 0 && saturdayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayLoop && saturdayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayLoop && saturdayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'saturday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>

                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(saturdayLoop).length > 0 && Object.keys(sort_by_key(saturdayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={saturdayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(saturdayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(saturdayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={saturdayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={saturdayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, saturdayLoop[key].schedule_uuid, saturdayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={saturdayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, saturdayLoop[key].schedule_uuid, saturdayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(saturdayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(saturdayLoop).length} </Form.Label>}
                </Row>

            }
            {/* end loop */}
            {/* start  loop*/}

            {(sundayLoop.length > 0) && sundayLoop[0].is_active && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Sunday</h4></div>}
            {/* new start */}
            {sundayLoop.length > 0 && sundayLoop[0].is_active && <OperationTable start_time={sort_by_key(sundayLoop, "start_time")[0].start_time} end_time={sort_by_key(sundayLoop, "end_time")[sundayLoop.length - 1].start_time} vehicles={(_.uniq(_.map(sundayLoop, 'vehicle_id')).filter(item => item)).length} />}

            {sundayLoop.length > 0 && sundayLoop[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>LOOP</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayLoop && sundayLoop[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayLoop && sundayLoop[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'sunday loop')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>

                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(sundayLoop).length > 0 && Object.keys(sort_by_key(sundayLoop, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={sundayLoop[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(sundayLoop[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(sundayLoop[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={sundayLoop[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={sundayLoop[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, sundayLoop[key].schedule_uuid, sundayLoop)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={sundayLoop[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, sundayLoop[key].schedule_uuid, sundayLoop)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(sundayLoop.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(sundayLoop).length} </Form.Label>}
                </Row>

            }
            {/* end loop */}
            {/* start  loop*/}



            {(mondayOutbound.length > 0 || mondayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Monday</h4></div>}
            {/* new start */}
            {/* changed margin top :100 */}
            {mondayOutbound.concat(mondayInbound).length > 0 && <OperationTable start_time={sort_by_key(mondayOutbound.concat(mondayInbound), "start_time")[0].start_time} end_time={sort_by_key(mondayOutbound.concat(mondayInbound), "end_time")[mondayOutbound.concat(mondayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(mondayOutbound.concat(mondayInbound), 'vehicle_id')).filter(item => item)).length} />}
            {mondayOutbound.length > 0 && mondayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2' >

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>

                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayOutbound && mondayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayOutbound && mondayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'monday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>

                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>



                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(mondayOutbound).length > 0 && Object.keys(sort_by_key(mondayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(mondayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            {/* <Form.Control disabled style={{fontSize : '15px'}} type='time' value={mondayOutbound[key].start_time} /> */}

                                            <Form.Group className="mt-2">
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(mondayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control style={{fontSize : '15px'}}  disabled type='time' value={mondayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control style={{ fontSize: '15px' }} className="mb-4" required as='select' custom value={mondayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, mondayOutbound[key].schedule_uuid, mondayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control style={{ fontSize: '15px' }} required as='select' value={mondayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, mondayOutbound[key].schedule_uuid, mondayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>


                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(mondayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(mondayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {mondayInbound.length > 0 && <OperationTable start_time={sort_by_key(mondayInbound, "start_time")[0].start_time} end_time={sort_by_key(mondayInbound, "end_time")[mondayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(mondayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {mondayInbound.length > 0 && mondayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2' >

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayInbound && mondayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={mondayInbound && mondayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'monday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(mondayInbound).length > 0 && Object.keys(sort_by_key(mondayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(mondayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            {/* <Form.Control disabled type='time' value={mondayInbound[key].start_time} /> */}

                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(mondayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={mondayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={mondayInbound[key]?.vehicle_id || -1}
                                                onChange={(e) => handleVehicleSelection(e.target.value, mondayInbound[key].schedule_uuid, mondayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={mondayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, mondayInbound[key].schedule_uuid, mondayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>

                    {(mondayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(mondayInbound).length} </Form.Label>}


                </Row>

            }
            {(tuesdayOutbound.length > 0 || tuesdayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Tuesday</h4></div>}
            {tuesdayOutbound.concat(tuesdayInbound).length > 0 && <OperationTable start_time={sort_by_key(tuesdayOutbound.concat(tuesdayInbound), "start_time")[0].start_time} end_time={sort_by_key(tuesdayOutbound.concat(tuesdayInbound), "end_time")[tuesdayOutbound.concat(tuesdayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(tuesdayOutbound.concat(tuesdayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {tuesdayOutbound.length > 0 && tuesdayOutbound[0].is_active &&

                <Row className='mt-5' border border-top-0 border-bottom-2 mb-2>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayOutbound && tuesdayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayOutbound && tuesdayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'tuesday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(tuesdayOutbound).length > 0 && Object.keys(sort_by_key(tuesdayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(tuesdayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            {/* <Form.Control disabled type='time' value={tuesdayOutbound[key].start_time} /> */}

                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(tuesdayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={tuesdayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={tuesdayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, tuesdayOutbound[key].schedule_uuid, tuesdayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={tuesdayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, tuesdayOutbound[key].schedule_uuid, tuesdayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(tuesdayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(tuesdayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {tuesdayInbound.length > 0 && <OperationTable start_time={sort_by_key(tuesdayInbound, "start_time")[0].start_time} end_time={sort_by_key(tuesdayInbound, "end_time")[tuesdayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(tuesdayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {tuesdayInbound.length > 0 && tuesdayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayInbound && tuesdayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={tuesdayInbound && tuesdayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'tuesday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(tuesdayInbound).length > 0 && Object.keys(sort_by_key(tuesdayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={tuesdayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(tuesdayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}

                                                {/* <Form.Control disabled type='time' value={tuesdayInbound[key].end_time} /> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(tuesdayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={tuesdayInbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, tuesdayInbound[key].schedule_uuid, tuesdayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={tuesdayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, tuesdayInbound[key].schedule_uuid, tuesdayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(tuesdayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(tuesdayInbound).length} </Form.Label>}
                </Row>

            }
            {/* new start */}
            {(wednesdayOutbound.length > 0 || wednesdayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Wednesday</h4></div>}
            {wednesdayOutbound.concat(wednesdayInbound).length > 0 && <OperationTable start_time={sort_by_key(wednesdayOutbound.concat(wednesdayInbound), "start_time")[0].start_time} end_time={sort_by_key(wednesdayOutbound.concat(wednesdayInbound), "end_time")[wednesdayOutbound.concat(wednesdayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(wednesdayOutbound.concat(wednesdayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {wednesdayOutbound.length > 0 && wednesdayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayOutbound && wednesdayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayOutbound && wednesdayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'wednesday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(wednesdayOutbound).length > 0 && Object.keys(sort_by_key(wednesdayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(wednesdayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            {/* <Form.Control disabled type='time' value={wednesdayOutbound[key].start_time} /> */}

                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(wednesdayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={wednesdayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={wednesdayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, wednesdayOutbound[key].schedule_uuid, wednesdayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={wednesdayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, wednesdayOutbound[key].schedule_uuid, wednesdayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(wednesdayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(wednesdayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {wednesdayInbound.length > 0 && <OperationTable start_time={sort_by_key(wednesdayInbound, "start_time")[0].start_time} end_time={sort_by_key(wednesdayInbound, "end_time")[wednesdayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(wednesdayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {wednesdayInbound.length > 0 && wednesdayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayInbound && wednesdayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={wednesdayInbound && wednesdayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'wednesday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(wednesdayInbound).length > 0 && Object.keys(sort_by_key(wednesdayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={wednesdayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(wednesdayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(wednesdayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={wednesdayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={wednesdayInbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, wednesdayInbound[key].schedule_uuid, wednesdayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={wednesdayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, wednesdayInbound[key].schedule_uuid, wednesdayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(wednesdayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(wednesdayInbound).length} </Form.Label>}
                </Row>

            }
            {/* new start */}
            {(thursdayOutbound.length > 0 || thursdayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Thursday</h4></div>}
            {thursdayOutbound.concat(thursdayInbound).length > 0 && <OperationTable start_time={sort_by_key(thursdayOutbound.concat(thursdayInbound), "start_time")[0].start_time} end_time={sort_by_key(thursdayOutbound.concat(thursdayInbound), "end_time")[thursdayOutbound.concat(thursdayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(thursdayOutbound.concat(thursdayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {thursdayOutbound.length > 0 && thursdayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayOutbound && thursdayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayOutbound && thursdayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'thursday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(thursdayOutbound).length > 0 && Object.keys(sort_by_key(thursdayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={thursdayOutbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(thursdayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(thursdayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={thursdayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={thursdayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, thursdayOutbound[key].schedule_uuid, thursdayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={thursdayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, thursdayOutbound[key].schedule_uuid, thursdayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>
                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(thursdayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(thursdayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {thursdayInbound.length > 0 && <OperationTable start_time={sort_by_key(thursdayInbound, "start_time")[0].start_time} end_time={sort_by_key(thursdayInbound, "end_time")[thursdayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(thursdayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {thursdayInbound.length > 0 && thursdayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayInbound && thursdayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={thursdayInbound && thursdayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'thursday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(thursdayInbound).length > 0 && Object.keys(sort_by_key(thursdayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={thursdayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(thursdayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(thursdayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={thursdayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={thursdayInbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, thursdayInbound[key].schedule_uuid, thursdayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={thursdayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, thursdayInbound[key].schedule_uuid, thursdayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(thursdayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(thursdayInbound).length} </Form.Label>}
                </Row>

            }
            {/* new start */}
            {(fridayOutbound.length > 0 || fridayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Friday</h4></div>}
            {fridayOutbound.concat(fridayInbound).length > 0 && <OperationTable start_time={sort_by_key(fridayOutbound.concat(fridayInbound), "start_time")[0].start_time} end_time={sort_by_key(fridayOutbound.concat(fridayInbound), "end_time")[fridayOutbound.concat(fridayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(fridayOutbound.concat(fridayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {fridayOutbound.length > 0 && fridayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayOutbound && fridayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayOutbound && fridayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'friday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(fridayOutbound).length > 0 && Object.keys(sort_by_key(fridayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={fridayOutbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(fridayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(fridayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={fridayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={fridayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, fridayOutbound[key].schedule_uuid, fridayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={fridayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, fridayOutbound[key].schedule_uuid, fridayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(fridayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold', marginLeft: '50px' }}>Total Trips: {Object.keys(fridayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {fridayInbound.length > 0 && <OperationTable start_time={sort_by_key(fridayInbound, "start_time")[0].start_time} end_time={sort_by_key(fridayInbound, "end_time")[fridayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(fridayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {fridayInbound.length > 0 && fridayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayInbound && fridayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={fridayInbound && fridayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'friday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(fridayInbound).length > 0 && Object.keys(sort_by_key(fridayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={fridayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(fridayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(fridayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={fridayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={fridayInbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, fridayInbound[key].schedule_uuid, fridayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={fridayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, fridayInbound[key].schedule_uuid, fridayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(fridayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(fridayInbound).length} </Form.Label>}
                </Row>

            }
            {/* new start */}
            {(saturdayOutbound.length > 0 || saturdayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Saturday</h4></div>}
            {saturdayOutbound.concat(saturdayInbound).length > 0 && <OperationTable start_time={sort_by_key(saturdayOutbound.concat(saturdayInbound), "start_time")[0].start_time} end_time={sort_by_key(saturdayOutbound.concat(saturdayInbound), "end_time")[saturdayOutbound.concat(saturdayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(saturdayOutbound.concat(saturdayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {saturdayOutbound.length > 0 && saturdayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayOutbound && saturdayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayOutbound && saturdayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'saturday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(saturdayOutbound).length > 0 && Object.keys(sort_by_key(saturdayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={saturdayOutbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(saturdayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(saturdayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={saturdayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={saturdayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, saturdayOutbound[key].schedule_uuid, saturdayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={saturdayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, saturdayOutbound[key].schedule_uuid, saturdayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(saturdayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(saturdayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {saturdayInbound.length > 0 && <OperationTable start_time={sort_by_key(saturdayInbound, "start_time")[0].start_time} end_time={sort_by_key(saturdayInbound, "end_time")[saturdayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(saturdayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {saturdayInbound.length > 0 && saturdayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayInbound && saturdayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={saturdayInbound && saturdayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'saturday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(saturdayInbound).length > 0 && Object.keys(sort_by_key(saturdayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={saturdayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(saturdayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(saturdayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={saturdayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={saturdayInbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, saturdayInbound[key].schedule_uuid, saturdayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={saturdayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, saturdayInbound[key].schedule_uuid, saturdayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(saturdayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(saturdayInbound).length} </Form.Label>}
                </Row>

            }
            {/* new start */}

            {(sundayOutbound.length > 0 || sundayInbound.length > 0) && <div style={{ width: '100%', height: '40px', border: '2px solid black', marginBottom: '15px', textAlign: 'center' }}><h4>Sunday</h4></div>}
            {sundayOutbound.concat(sundayInbound).length > 0 && <OperationTable start_time={sort_by_key(sundayOutbound.concat(sundayInbound), "start_time")[0].start_time} end_time={sort_by_key(sundayOutbound.concat(sundayInbound), "end_time")[sundayOutbound.concat(sundayInbound).length - 1].start_time} vehicles={(_.uniq(_.map(sundayOutbound.concat(sundayInbound), 'vehicle_id')).filter(item => item)).length} />}

            {sundayOutbound.length > 0 && sundayOutbound[0].is_active &&

                <Row className='mt-5 border border-top-0 border-bottom-2 mb-2'>

                    <Col sm={2} lg={2} xl={1} style={{
                        width: '7.499999995%',
                        flex: '0 0 7.499%', maxWidth: '7.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>OUTBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col sm={2} lg={2} xl={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayOutbound && sundayOutbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayOutbound && sundayOutbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'sunday outbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col sm={2} lg={1} xl={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col sm={6} lg={7} xl={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(sundayOutbound).length > 0 && Object.keys(sort_by_key(sundayOutbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={sundayOutbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(sundayOutbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(sundayOutbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={sundayOutbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={sundayOutbound[key]?.vehicle_id || -1} onChange={(e) => handleVehicleSelection(e.target.value, sundayOutbound[key].schedule_uuid, sundayOutbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={sundayOutbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, sundayOutbound[key].schedule_uuid, sundayOutbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(sundayOutbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(sundayOutbound).length} </Form.Label>}
                </Row>

            }
            {/* {sundayInbound.length > 0 && <OperationTable start_time={sort_by_key(sundayInbound, "start_time")[0].start_time} end_time={sort_by_key(sundayInbound, "end_time")[sundayInbound.length - 1].end_time} vehicles={(_.uniq(_.map(sundayInbound, 'vehicle_id')).filter(item => item)).length} />} */}

            {sundayInbound.length > 0 && sundayInbound[0].is_active &&

                <Row className='mt-3 border border-top-0 border-bottom-2 mb-2'>

                    <Col xs={1} style={{
                        width: '6.499999995%',
                        flex: '0 0 6.499%', maxWidth: '6.499%',
                    }}>
                        <Form.Group style={{ width: '100px', marginLeft: '0px' }} >
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', textDecoration: 'underline' }}>INBOUND</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Stop</Form.Label>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px', marginTop: '30px' }}>Duplicate From</Form.Label>
                        </Form.Group>

                        {/* <p>{index}-{(JSON.parse(JSON.stringify(allTimetable[key][key2][key3])),reviver)[0].start_time}</p> */}
                        {/* <p>{index}-{allTimetable[key][key2][key3].map(obj => Object.values(obj))[0][10]}</p> */}

                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '110px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '10px' }}>Destination</Form.Label><br></br>
                            <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayInbound && sundayInbound[0].start_stop_name} />

                            <Form.Group className="mt-2">

                                <Form.Control disabled type='text' style={{ fontSize: '10px', wordBreak: 'break-all', fontWeight: 'bold' }} value={sundayInbound && sundayInbound[0].end_stop_name} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control style={{ fontSize: '15px', marginTop: '30px' }} required as='select' onChange={(e) => handleDuplicateFrom(e.target.value, 'sunday inbound')}>
                                    {
                                        duplicateFromList &&
                                        duplicateFromList.map((day) =>
                                            <option key={day} value={day}>{day}</option>
                                        )
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form.Group>
                    </Col>
                    <Col xs={1} >
                        <Form.Group style={{ width: '90px', marginLeft: '0px' }} >
                            {/* <Form.Label style={{ fontWeight: 'bold', marginLeft: '45px' }}>Trip Number</Form.Label><br></br> */}
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Trip Number</Form.Label><br></br>
                            <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Start Time</Form.Label><br></br>

                            <Form.Group className="mt-2">

                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>End Time</Form.Label><br></br>

                            </Form.Group>
                            <Form.Group className="mt-2">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>


                            </Form.Group>
                            <Form.Group className="mt-4">
                                <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>


                            </Form.Group>


                        </Form.Group>
                    </Col>
                    <Col xs={9}>
                        <div className="scroll">
                            <Row className="align-items-center scroll2">

                                {Object.keys(sundayInbound).length > 0 && Object.keys(sort_by_key(sundayInbound, "start_time")).map((key, index) =>
                                    <Fragment key={key}>
                                        <Form.Group style={{ width: '100px', marginRight: '10px', marginLeft: '0px' }} >
                                            <Form.Label style={{ fontWeight: 'bold', marginLeft: '35px' }}>{index + 1}</Form.Label><br></br>

                                            {/* <Form.Label style={{ fontWeight: 'bold' }}>Start Time</Form.Label> */}
                                            {/* <Form.Control disabled type='time' value={sundayInbound[key].start_time} /> */}
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={moment(sundayInbound[key].start_time, 'h:mm a')}
                                                disabled
                                                format={format}
                                                use12Hours
                                                allowEmpty={false}
                                            />
                                            <Form.Group className="mt-2">
                                                {/* <Form.Label style={{ fontWeight: 'bold' }}>End Time</Form.Label> */}
                                                <TimePicker
                                                    showSecond={false}
                                                    defaultValue={moment(sundayInbound[key].end_time, 'h:mm a')}
                                                    disabled
                                                    format={format}
                                                    use12Hours
                                                    allowEmpty={false}
                                                />
                                                {/* <Form.Control disabled type='time' value={sundayInbound[key].end_time} /> */}
                                            </Form.Group>

                                            <Form.Control className="mb-4" required as='select' custom value={sundayInbound[key]?.vehicle_id || -1}
                                                onChange={(e) => handleVehicleSelection(e.target.value, sundayInbound[key].schedule_uuid, sundayInbound)}>

                                                <option disabled value={-1} key={-1} > </option>

                                                {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                    <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                                )}

                                            </Form.Control>
                                            <Form.Control required as='select' value={sundayInbound[key]?.driver_id || -1} onChange={(e) => handleDriverSelection(e.target.value, sundayInbound[key].schedule_uuid, sundayInbound)}>

                                                <option disabled value={-1} key={-1}> </option>

                                                {drivers.length > 0 && drivers.map((driver) =>

                                                    <option key={driver.id} value={driver.id} >{driver.name} </option>

                                                )}

                                            </Form.Control>

                                        </Form.Group>





                                    </Fragment>
                                )}
                            </Row>
                        </div>
                    </Col>



                    {(sundayInbound.length > 0) && <Form.Label style={{ fontWeight: 'bold' }}>Total Trips: {Object.keys(sundayInbound).length} </Form.Label>}
                </Row>

            }

        </ContentWrapper>
    )
}

export default SchedulesV2EditorialPage
