const ACTION_TYPES = {
  USER_LOGIN_REQUESTED                   : 'USER_LOGIN_REQUESTED',
  USER_LOGIN_SUCCEEDED                   : 'USER_LOGIN_SUCCEEDED',
  USER_LOGIN_FAILED                      : 'USER_LOGIN_FAILED',

  USER_SIGN_UP_REQUESTED                 : 'USER_SIGN_UP_REQUESTED',
  USER_SIGN_UP_SUCCEEDED                 : 'USER_SIGN_UP_SUCCEEDED',
  USER_SIGN_UP_FAILED                    : 'USER_SIGN_UP_FAILED',

  USER_UPDATE_PROFILE_REQUESTED          : 'USER_UPDATE_PROFILE_REQUESTED',
  USER_UPDATE_PROFILE_SUCCEEDED          : 'USER_UPDATE_PROFILE_SUCCEEDED',
  USER_UPDATE_PROFILE_FAILED             : 'USER_UPDATE_PROFILE_FAILED',

  USER_LOGOUT_REQUESTED                  : 'USER_LOGOUT_REQUESTED',
  USER_LOGOUT_SUCCEEDED                  : 'USER_LOGOUT_SUCCEEDED',
  USER_LOGOUT_FAILED                     : 'USER_LOGOUT_FAILED',

  USER_VERIFY_EMAIL_REQUESTED            : 'USER_VERIFY_EMAIL_REQUESTED',
  USER_VERIFY_EMAIL_SUCCEEDED            : 'USER_VERIFY_EMAIL_SUCCEEDED',
  USER_VERIFY_EMAIL_FAILED               : 'USER_VERIFY_EMAIL_FAILED',

  USER_UPLOAD_AGENCY_PHOTO_REQUESTED     : 'USER_UPLOAD_AGENCY_PHOTO_REQUESTED',
  USER_UPLOAD_AGENCY_PHOTO_SUCCEEDED     : 'USER_UPLOAD_AGENCY_PHOTO_SUCCEEDED',
  USER_UPLOAD_AGENCY_PHOTO_FAILED        : 'USER_UPLOAD_AGENCY_PHOTO_FAILED',

  UPDATE_SUPERVISOR_REQUESTED            : 'UPDATE_SUPERVISOR_REQUESTED',
  UPDATE_SUPERVISOR_SUCCEEDED            : 'UPDATE_SUPERVISOR_SUCCEEDED',
  UPDATE_SUPERVISOR_FAILED               : 'UPDATE_SUPERVISOR_FAILED',

  UPDATE_AGENCY_INFO_REQUESTED           : 'UPDATE_AGENCY_INFO_REQUESTED',
  UPDATE_AGENCY_INFO_SUCCEEDED           : 'UPDATE_AGENCY_INFO_SUCCEEDED',
  UPDATE_AGENCY_INFO_FAILED              : 'UPDATE_AGENCY_INFO_FAILED',

  USER_EXP_REQUESTED                     : 'USER_EXP_REQUESTED',
  USER_EXP_SUCCEEDED                     : 'USER_EXP_SUCCEEDED',
  USER_EXP_FAILED                        : 'USER_EXP_FAILED',

  PJCITYBUS_USER_EXP_REQUESTED           : 'PJCITYBUS_USER_EXP_REQUESTED',
  PJCITYBUS_USER_EXP_SUCCEEDED           : 'PJCITYBUS_USER_EXP_SUCCEEDED',
  PJCITYBUS_USER_EXP_FAILED              : 'PJCITYBUS_USER_EXP_FAILED',

  USER_EXP_UPDATE_REQUESTED              : 'USER_EXP_UPDATE_REQUESTED',
  USER_EXP_UPDATE_SUCCEEDED              : 'USER_EXP_UPDATE_SUCCEEDED',
  USER_EXP_UPDATE_FAILED                 : 'USER_EXP_UPDATE_FAILED',

  TRIP_SCHEDULER_REQUESTED               : 'TRIP_SCHEDULER_REQUESTED',
  TRIP_SCHEDULER_SUCCEEDED               : 'TRIP_SCHEDULER_SUCCEEDED',
  TRIP_SCHEDULER_FAILED                  : 'TRIP_SCHEDULER_FAILED',

  TRIP_SCHEDULER_CREATE_REQUESTED        : 'TRIP_SCHEDULER_CREATE_REQUESTED',
  TRIP_SCHEDULER_CREATE_SUCCEEDED        : 'TRIP_SCHEDULER_CREATE_SUCCEEDED',
  TRIP_SCHEDULER_CREATE_FAILED           : 'TRIP_SCHEDULER_CREATE_FAILED',

  TRIP_SCHEDULER_UPDATE_REQUESTED        : 'TRIP_SCHEDULER_UPDATE_REQUESTED',
  TRIP_SCHEDULER_UPDATE_SUCCEEDED        : 'TRIP_SCHEDULER_UPDATE_SUCCEEDED',
  TRIP_SCHEDULER_UPDATE_FAILED           : 'TRIP_SCHEDULER_UPDATE_FAILED'
};

export default ACTION_TYPES