import { connect } from "react-redux"
import { requestUserExp, requestUserExpUpdate } from "../../redux/actions"
import SchedulesV2EditorialPage from "./SchedulesV2EditorialPage"

const mapStateToProps = ({ userExp = [] }) => {
    const feedback = userExp.filter(({ feedback: f }) =>
        Boolean(f !== null && f !== "-")
    )
    const tripExperience = userExp.length > 0 ? userExp : null

    return {
        tripExperience,
        feedback
    }
}

export default connect(mapStateToProps, {
    requestUserExp, requestUserExpUpdate
})(SchedulesV2EditorialPage)