import { API_END_POINTS } from "../../config";
import { ApiRequest } from "../../helpers";

export function getUserExp() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.USER_EXPERIENCE,
  });
}

export function getPJCityBusUserExp() {
  return ApiRequest.fetch({
    method: 'get',
    url: API_END_POINTS.USER_EXPERIENCE_PJCITYBUS,
  });
}

export function updateUserExp(caseId, params) {
  return ApiRequest.fetch({
    method: 'put',
    url: `${API_END_POINTS.UPDATE_USER_EXPERIENCE_CASE}/${caseId}`,
    data: params
  });
}