import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, InputGroup, Row } from 'react-bootstrap'
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap, useMapEvent } from 'react-leaflet'
import L from 'leaflet'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import geoPinRed from '../../assets/svgIcon/geoPinRed.svg'
import geoPinGreen from '../../assets/svgIcon/geoPinGreen.svg'

import 'leaflet/dist/leaflet.css'
import { Trash } from 'react-bootstrap-icons';
import { async } from 'regenerator-runtime';
import { Utils } from '../../config';
import { toast } from 'react-toastify';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [20, 30],
    iconAnchor: [10, 30],
    shadowSize: [20, 30],
    shadowAnchor: [10, 30],
    popupAnchor: [0, -31]
});

let GeoPinRed = L.icon({
    iconUrl: geoPinRed,
    iconSize: [30, 30],
    iconAnchor: [15, 27],
    popupAnchor: [0, -31]
});

let GeoPinGreen = L.icon({
    iconUrl: geoPinGreen,
    iconSize: [30, 30],
    iconAnchor: [15, 27],
    popupAnchor: [0, -31]
});

const CreateMarkerOnClick = ({ onMarkerCreated, resetPos }) => {
    const [position, setPosition] = useState(null)

    const markerRef = useRef(null)

    const map = useMapEvent('click', (e) => {
        onMarkerCreated(e.latlng)
        setPosition(e.latlng)
        map.flyTo(e.latlng, map.getZoom())
    })

    useEffect(() => {
        setPosition(null)
    }, [resetPos])

    const onMarkerDragged = useMemo(() => ({
        dragend() {
            const marker = markerRef.current
            if (marker != null) {
                const latlng = marker.getLatLng()
                onMarkerCreated(latlng)
                setPosition(latlng)
            }
        }
    }), [])

    return position === null ? null : (
        <Marker title='New Marker Position' ref={markerRef} eventHandlers={onMarkerDragged} draggable icon={DefaultIcon} position={position} />
    )
}

const EditableMarkerPos = ({ onDragged, position, index }) => {
    const markerRef = useRef(null)
    const [isDrag, setIsDrag] = useState(false)

    const onMarkerDragged = useMemo(() => ({
        dragend() {
            const marker = markerRef.current
            if (marker != null) {
                const { lat, lng } = marker.getLatLng()
                onDragged([lat, lng], index)
            }
            setIsDrag(false)
        }
    }), [])

    const toggleDraggable = () => { setIsDrag(!isDrag) }

    return position === null ? null : (
        <Marker ref={markerRef} eventHandlers={onMarkerDragged} draggable={isDrag} icon={isDrag ? GeoPinGreen : GeoPinRed} position={position} >
            <Popup >
                <span onClick={toggleDraggable}>
                    <b>Location {index}</b><br />
                    <small className='p-1 bg-primary text-light g-hover-pointer' >
                        {isDrag
                            ? 'Marker is draggable'
                            : 'Click here to make marker draggable'
                        }
                    </small>
                </span>
            </Popup>
        </Marker>
    )
}

const PolylinesEdit = ({ data, index, onDeleted }) => {
    return data === null ? null : (
        <InputGroup size='sm' className='mb-1' >
            <InputGroup.Prepend>
                <InputGroup.Text>{index}</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control readOnly value={`${data[0]} , ${data[1]}`} />
            <InputGroup.Append>
                <Button onClick={() => { onDeleted(index) }} variant='danger' ><Trash /></Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

const RecenterPos = ({ newCenter, onDoneRecenter = () => { } }) => {
    const map = useMap()
    map.flyTo(newCenter)
    onDoneRecenter()
    return null
}

const EditorMap = ({ title, editPolyline = false, editMarker = false, onMarkerAdded = () => { }, onNewPolylinePosAdded = () => { }, onPolylineChanged = () => { }, preloadPolyline = [], preloadMarker = [], recenter = [], center = [3.1390, 101.6869] }) => {
    const [polylinesPos, setPolylinesPos] = useState([])
    const [recenterState, setRecenterState] = useState(null)

    const [onClickMarkerPos, setOnClickMarkerPos] = useState(null)
    const [resetCreateMarker, setResetCreateMarker] = useState(false)
    const [lat, setLat] = useState(null)
    const [long, setLong] = useState(null)
    useEffect(() => {
        onPolylineChanged(polylinesPos)
    }, [polylinesPos])

    useEffect(() => {
        if (preloadPolyline && preloadPolyline.length > 0) {
            setPolylinesPos(preloadPolyline)
        }

        if (!recenter.length > 0) {
            if (preloadPolyline.length > 0) {
                setRecenterState(preloadPolyline[0])
            } else if (preloadMarker.length > 0) {
                setRecenterState(preloadMarker)
            }
        } else {
            setRecenterState(recenter)
        }
    }, [])

    const handleAddPolylineLocation = async () => {
        if (!onClickMarkerPos && !(lat && long)) return alert('Click location you wanna add or add Manually')
        if (lat && long) {
            if (!Utils.isLatitude(lat)) {
                toast.error("Latitude is invalid")
                setLat(null)
                return
            }
            if (!Utils.isLongitude(long)) {
                toast.error("longitude is invalid")
                setLong(null)
                return
            }
            // 
            await setOnClickMarkerPos([lat, long])
            await onMarkerAdded([lat, long])
            await onPolylineChanged()
            await setPolylinesPos(prev => [...prev, [lat, long]])
            await setResetCreateMarker(!resetCreateMarker)
            await setOnClickMarkerPos(null)
            await setLat(null)
            await setLong(null)
        }
        // 
        if (!lat && !long) {
            onPolylineChanged()
            setPolylinesPos(prev => [...prev, onClickMarkerPos])
            setResetCreateMarker(!resetCreateMarker)
            setOnClickMarkerPos(null)
            setLat(null)

            setLong(null)
        }
    }
    //callback after marker is created 
    const handleNewMarkerCreated = ({ lat, lng }) => {
        // 
        // 
        setOnClickMarkerPos([lat, lng])
        onMarkerAdded([lat, lng])
    }

    const handlePolylinesDelete = (index) => {
        if (index === null) return null
        const curr = polylinesPos
        curr.splice(index, 1)
        setPolylinesPos([...curr])
    }

    const handleClearPolyPos = () => {
        setPolylinesPos([])
    }

    const handleEditableMarkerNewPos = (latlng, index) => {
        if (editPolyline) {
            setPolylinesPos(prev => {
                prev[index] = latlng
                return [...prev]
            })
        }
    }

    const handleDoneRecenter = () => {
        // reset value so it wont recenter every time
        setRecenterState(null)
    }

    return (
        <Card className='text-dark' >
            <Card.Body>
                {
                    title &&
                    <Card.Title>{title}</Card.Title>
                }
                <Row >
                    <Col>
                        <MapContainer style={{ height: '40vh' }} center={center} zoom={13} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                // url="https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png"
                                // url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                                url="http://a.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
                            />
                            {
                                recenterState && recenterState.length > 0 &&
                                <RecenterPos newCenter={recenterState} onDoneRecenter={handleDoneRecenter} />
                            }

                            {
                                editPolyline && polylinesPos.length > 0 &&
                                <Polyline pathOptions={{ color: 'red' }} positions={polylinesPos} />
                            }

                            {
                                editPolyline && polylinesPos.length > 0 &&
                                polylinesPos.map((pos, key) => (<EditableMarkerPos index={key} onDragged={handleEditableMarkerNewPos} key={key * 10} position={pos} />))
                            }

                            <CreateMarkerOnClick resetPos={resetCreateMarker} onMarkerCreated={handleNewMarkerCreated} />
                            {
                                preloadMarker && preloadMarker.length > 0 &&
                                <Marker position={preloadMarker} icon={GeoPinRed}>
                                    <Popup>{preloadMarker[2]}</Popup>
                                </Marker>
                            }
                        </MapContainer>
                    </Col>
                    {
                        editPolyline &&
                        <Col lg={4} sm={12} >
                            <FormGroup>
                                <InputGroup className='my-3' >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            Latitude
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control onChange={e => {
                                       
                                         setLat(e.target.value) 

                                    }} value={onClickMarkerPos ? onClickMarkerPos[0] : lat ? lat : ''} />
                                </InputGroup>
                                <InputGroup className='mb-3' >
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            Longitude
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control onChange={e => {
                                       
                                        setLong(e.target.value) 

                                    }} value={onClickMarkerPos ? onClickMarkerPos[1] : long ? long : ''} />
                                </InputGroup>
                                <Button className='w-100 mb-3' onClick={handleAddPolylineLocation} >Add Location</Button>

                                <div style={{ maxHeight: 150, overflowY: 'scroll' }} >
                                    {
                                        polylinesPos.length > 0 &&
                                        polylinesPos.map((data, key) => (
                                            <PolylinesEdit onDeleted={handlePolylinesDelete} data={data} key={key} index={key} />
                                        ))
                                    }
                                </div>
                                {
                                    polylinesPos.length > 0 &&
                                    <Button className='w-100 mt-3' size='sm' variant='outline-danger' onClick={handleClearPolyPos} >Clear All Locations</Button>
                                }

                            </FormGroup>
                        </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default EditorMap