import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap'
import { Calendar, CashStack, Geo, Globe, PencilSquare, PlusCircle, Box } from 'react-bootstrap-icons'
import PolylineUtils from '@mapbox/polyline'
import { ToggleButton } from "./toggleButton";
import './EditorialPage.css'

import { ContentWrapper, EditorMap, Modal, PreviewMapModal, StaticMap, Table } from '../../components'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { NoDataInfo } from '../../components/Alert'

// agencyId: 1
// colorCode: "#CC3366"
// createdAt: "2020-09-16T02:39:33.451Z"
// id: 1
// isActive: true
// minBalance: 1
// name: "Petaling Jaya Route 02"
// polygon: null
// shortName: "PJ02"
// updatedAt: "2020-11-18T05:25:53.100Z"


const RouteForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { name: '', shortName: '', minBalance: '0.00', isActive: '', colorCode: '', polygon: '', isFixedFare: 'true', isFreeFare: '', kmInbound: '', kmLoop: '', kmOutbound: '', kmRate: '', apadPolygon: '', isZonalFare: false} }) => {

    const [name, setname] = useState(null)
    const [ibName, setIbName] = useState(null)
    const [obName, setObName] = useState(null)
    const [kmInbound, setKmInbound] = useState(null)
    const [kmOutbound, setKmOutbound] = useState(null)
    const [kmLoop, setKmLoop] = useState(null)
    const [kmRate, setKmRate] = useState(null)
    const [apadPolygon, setApadPolygon] = useState(null)
    const [shortName, setShortName] = useState(null)
    const [minBalance, setMinBalance] = useState(null)
    const [colorCode, setColorCode] = useState(null)
    const [polyline, setPolyline] = useState([])
    // const [apadPolyline, setApadPolyline] = useState([])
    const [isActive, setIsActive] = useState(null)
    const [isFixedFare, setIsFixedFare] = useState(defaultValues.isFixedFare)
    const [isFreeFare, setIsFreeFare] = useState(null)
    const [isZonalFare, setIsZonalFare] = useState(null);

    // console.log("default: ", defaultValues)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()
        let polygon = null
        if (polyline && polyline.length > 0) {
            // 
            polygon = PolylineUtils.encode(polyline)
        }
        let apadPolyline = null
        if (apadPolygon && apadPolygon.length > 0) {
            // 
            apadPolyline = PolylineUtils.encode(apadPolygon)
        }

        const data = {}

        if (name !== null) {
            data.name = name
        }
        if (shortName !== null) {
            data.shortName = shortName
        }
        if (minBalance !== null) {
            data.minBalance = minBalance
        }
        if (colorCode !== null) {
            data.colorCode = colorCode
        }
        if (polygon !== null) {
            data.polygon = polygon
        }
        if (isActive !== null) {
            data.isActive = isActive
        }
        if (isFixedFare !== null) {
            data.isFixedFare = isFixedFare
        }
        if (isFreeFare !== null) {
            data.isFreeFare = isFreeFare
        }
        if (kmInbound !== null && kmInbound !== '') {
            data.kmInbound = kmInbound
        }
        if (kmOutbound !== null && kmOutbound !== '') {
            data.kmOutbound = kmOutbound
        }
        if (kmLoop !== null && kmLoop !== '') {
            data.kmLoop = kmLoop
        }
        if (kmRate !== null && kmRate !== '') {
            data.kmRate = kmRate
        }
        if (apadPolygon !== null && apadPolygon !== '') {
            data.apadPolygon = apadPolyline
        }
        if (ibName !== null ) {
            data.ibName = ibName
        }
        if (obName !== null) {
            data.obName = obName
        }

        if (isZonalFare !== null) {
            data.isZonalFare = isZonalFare;
        }

        const dataId = defaultValues.id
        // 
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    const handleApadPolylineChanged = (polylinePoints) => {
        setApadPolygon(polylinePoints)
    }
    const handlePolylineChanged = (polylinePoints) => {
        setPolyline(polylinePoints)
    }

    const preloadPolylinePos = useMemo(() => {
        if (defaultValues.polygon && defaultValues.polygon !== '') {
            const decodedPolyline = PolylineUtils.decode(defaultValues.polygon)
            //  
            return decodedPolyline
        } else {
            return []
        }
    }, [defaultValues.polygon])
    const preloadPolylinePosApad = useMemo(() => {
        if (defaultValues.apadPolygon && defaultValues.apadPolygon !== '') {
            const decodedPolyline = PolylineUtils.decode(defaultValues.apadPolygon)
            //  
            return decodedPolyline
        } else {
            return []
        }
    }, [defaultValues.apadPolygon])

    return (
        <Row>
            <Col className={` ${!edit ? 'pt-3' : ''} `} lg={12} >
                <Form onSubmit={handleBeforeSubmit}>
                    <Card className={` ${!edit ? 'bg-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Add New Route</Card.Title>
                            }
                            <Row>
                                <Col md={4} >
                                    <Form.Group>
                                        <Form.Label >Route Name</Form.Label>
                                        <Form.Control onChange={(e) => setname(e.currentTarget.value)} required={!edit ? true : false} placeholder='Petaling Jaya Route 1' defaultValue={defaultValues.name} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} >
                                    <Form.Group>
                                        <Form.Label >Route Short Name</Form.Label>
                                        <Form.Control onChange={(e) => setShortName(e.currentTarget.value)} required={!edit ? true : false} placeholder='PJ05' defaultValue={defaultValues.shortName} />
                                    </Form.Group>
                                </Col>
                                <Col md={4} >
                                    <Form.Group>
                                        <Form.Label >Color Code</Form.Label>
                                        <Form.Control onChange={(e) => setColorCode(e.currentTarget.value)} required={!edit ? true : false} type='color' defaultValue={defaultValues.colorCode} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={2} >
                                    <Form.Group>
                                        <Form.Label >Currency</Form.Label>
                                        <Form.Control custom as="select" required={!edit ? true : false} defaultValue="MYR">
                                            <option>MYR</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={4} >
                                    <Form.Group>
                                        <Form.Label >Minimum Fare</Form.Label>
                                        <Form.Control disabled={isFreeFare ? true : false} onChange={(e) => setMinBalance(e.currentTarget.value)} required={!edit ? true : false} type="number" step='.01' min="0" defaultValue={defaultValues.minBalance} />
                                    </Form.Group>
                                </Col>
                                
                                <Col md={2} >
                                    <Form.Group>
                                        <Form.Label >Fare Type</Form.Label>

                                        <ToggleButton
                                            selected={isFixedFare}
                                            toggleSelected={() => {
                                                setIsFixedFare(!isFixedFare);
                                            }}
                                        />
                                        {/* <Form.Check type='switch' onChange={(e) => setIsFixedFare(e.target.checked)} defaultChecked={defaultValues.isFixedFare} id='edit-changes-isFixedFare' /> */}
                                    </Form.Group>
                                </Col>
                                <Col md={1} >
                                    <Form.Group>
                                        <Form.Label >Free Fare</Form.Label>
                                        <Form.Check type='switch' onChange={(e) => setIsFreeFare(e.target.checked)} defaultChecked={defaultValues.isFreeFare} id='edit-changes-isFreeFare' />
                                    </Form.Group>
                                </Col>
                                <Col md={1} >
                                    <Form.Group>
                                        <Form.Label >Zonal Fare</Form.Label>
                                        <Form.Check type='switch' onChange={(e) => setIsZonalFare(e.target.checked)} defaultChecked={defaultValues.isZonalFare} id='edit-changes-isZonalFare' />
                                    </Form.Group>
                                </Col>
                                {
                                    edit &&
                                    <Col md={1} >
                                        <Form.Group>
                                            <Form.Label >Active</Form.Label>
                                            <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active' />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col md={6} >
                                    <Form.Group>
                                        <Form.Label >Outbound Route Name(optional):</Form.Label>
                                        <Form.Control onChange={(e) => setObName(e.currentTarget.value)}  placeholder='' defaultValue={defaultValues.nameOutbound} />

                                    </Form.Group>
                                </Col>
                                <Col md={6} >
                                    <Form.Group>
                                        <Form.Label >Inbound Route Name(optional):</Form.Label>
                                        <Form.Control onChange={(e) => setIbName(e.currentTarget.value)}  placeholder='' defaultValue={defaultValues.nameInbound} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <EditorMap title='Interactive Path Mapping' editPolyline onPolylineChanged={handlePolylineChanged} preloadPolyline={preloadPolylinePos} recenter={preloadPolylinePos.length > 0 ? preloadPolylinePos[0] : []} />
                            <h5 className='mt-3'>Fill the below details if applicable:</h5>
                            <Row>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label >KM INBOUND:</Form.Label>
                                        <Form.Control type="number" step="0.001" min="0" onChange={(e) => setKmInbound(e.currentTarget.value)} defaultValue={defaultValues.kmInbound} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label >KM OUTBOUND:</Form.Label>
                                        <Form.Control type="number" step="0.001" min="0" onChange={(e) => setKmOutbound(e.currentTarget.value)} defaultValue={defaultValues.kmOutbound} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label >KM LOOP:</Form.Label>
                                        <Form.Control type="number" step="0.001" min="0" onChange={(e) => setKmLoop(e.currentTarget.value)} defaultValue={defaultValues.kmLoop} />
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label >KM Rate:</Form.Label>
                                        <Form.Control type="number" step="0.001" min="0" onChange={(e) => setKmRate(e.currentTarget.value)} defaultValue={defaultValues.kmRate} />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={12}>
                                    {/* <Form.Group>
                                        <Form.Label >APAD POLYGON:</Form.Label>
                                        <Form.Control onChange={(e) => setApadPolygon(e.currentTarget.value)} defaultValue={defaultValues.apadPolygon} />
                                    </Form.Group> */}
                                    <EditorMap title='Virtual checkpoints' editPolyline onPolylineChanged={handleApadPolylineChanged} preloadPolyline={preloadPolylinePosApad} recenter={preloadPolylinePosApad.length > 0 ? preloadPolylinePosApad[0] : []} />

                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant='primary' type='submit' >{!edit ? 'Save' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const EditorialRoute = () => {
    const [add, setAdd] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addCollapse, setAddCollapse] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [routes, setRoutes] = useState(null)
    const [selectedRouteIndex, setSelectedRouteIndex] = useState(null)

    const goTo = useHistory()

    const tableHeader = useMemo(() => [
        {
            Header: 'Route Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Short Name',
            accessor: 'shortName',
            // disableFilters: true
        },
        {
            Header: 'Min Fare',
            accessor: 'minBalance',
            // disableFilters: true
        },
        {
            Header: 'Fixed Fare',
            accessor: 'isFixedFare_',
            // disableFilters: true
        },
        {
            Header: 'Free Fare',
            accessor: 'isFreeFare_',
            // disableFilters: true
        },
        {
            Header: 'KM OUTBOUND',
            accessor: 'kmOutbound',
            disableFilters: true
        },
        {
            Header: 'KM INBOUND',
            accessor: 'kmInbound',
            disableFilters: true
        },
        {
            Header: 'KM LOOP',
            accessor: 'kmLoop',
            disableFilters: true
        },
        {
            Header: 'KM RATE',
            accessor: 'kmRate',
            disableFilters: true
        },
        {
            Header: 'Color Code',
            accessor: 'colorCode_',
            disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            disableFilters: true
        },
        {
            Header: 'Last Modified',
            accessor: 'modifiedAt_',
            disableFilters: true
        },
        {
            Header: 'Timetable',
            accessor: 'goToSchedule',
            disableFilters: true
        },
        {
            Header: 'Waypoint',
            accessor: 'goToWaypoint',
            disableFilters: true
        },
        {
            Header: 'Zone',
            accessor: 'goToZone',
            disableFilters: true
        },
        {
            Header: 'Fare',
            accessor: 'goToFare',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'View',
            accessor: 'onPreview',
            disableFilters: true
        }
    ], [])

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`
        }).then((data) => {
            // 
            setRoutes(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetAllRoute()
    }, [refresh])

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleEdit = (index) => {
        setShowEditModal(true)
        setSelectedRouteIndex(index)
    }

    const handlePreviewRoute = (index) => {
        setShowPreviewModal(true)
        setSelectedRouteIndex(index)
    }

    const handleRouteUpdate = (id, data) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.ROUTE_UPDATE}/${id}`,
            data
        }).then(() => {
            toast.success('Route Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleClickCancel = () => {
        setSelectedRouteIndex(null)
        setAdd(false)
        setShowEditModal(false)
    }

    const handleRouteCreate = (id, data) => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.ROUTE_CREATE}`,
            data
        }).then(() => {
            toast.success(`${data.name} Route Created!`)
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleGoToFare = (index) => {
        const routeId = routes[index].id
        goTo.push(`/editorial/fare/${routeId}`)
    }

    const handleGoToSchedule = (index) => {
        const routeId = routes[index].id
        goTo.push(`/editorial/schedule/${routeId}`)
    }

    const handleGoToSWaypoint = (index) => {
        const routeId = routes[index].id
        goTo.push(`/editorial/waypoint/${routeId}`)
    }

    const handleGoToZone = (index) => {
        const routeId = routes[index].id
        goTo.push(`/editorial/zone/${routeId}`)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['goToFare'] = <CashStack className='g-hover-pointer text-primary' onClick={() => handleGoToFare(i)} />
            row['goToSchedule'] = <Calendar className='g-hover-pointer text-primary' onClick={() => handleGoToSchedule(i)} />
            row['goToWaypoint'] = <Geo className='g-hover-pointer text-primary' onClick={() => handleGoToSWaypoint(i)} />
            row['goToZone'] = <Box className='g-hover-pointer text-primary' onClick={() => handleGoToZone(i)} />
            row['onPreview'] = <Globe className='g-hover-pointer text-primary' onClick={() => handlePreviewRoute(i)} />
            row['colorCode_'] = row.colorCode !== '' ? <Form.Control disabled type='color' value={row.colorCode} /> : <p>Not Provided</p>
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['modifiedAt_'] = <p>{row.updatedAt ? `${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}` : ''}</p>
            row['isFixedFare_'] = String(row.isFixedFare).toUpperCase()
            row['isFreeFare_'] = String(row.isFreeFare).toUpperCase()
        })
        return data
    }

    return (
        <>
            <ContentWrapper>
                <h2 className='mb-3' >Route Editorial</h2>
                <div className='divider' />
                <Row >
                    <Col className='pt-3 text-dark' md={10} sm={12} >
                        <Card className='h-100' >
                            <Card.Body>
                                <Card.Title >Active Route Count</Card.Title>
                                {
                                    routes &&
                                    <h1>{routes.length}</h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pt-3' md={2} sm={12} >
                        <Card onClick={handleToggleAddForm} className='bg-primary btn btn-outline-light h-100' >
                            <Card.Body className='d-flex justify-content-center align-items-center' >
                                <PlusCircle size={50} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Collapse in={add} onEnter={() => setAddCollapse(true)} onExited={() => setAddCollapse(false)} >
                    <div>
                        {
                            addCollapse &&
                            <RouteForm onClickCancel={handleClickCancel} onSubmitForm={handleRouteCreate} />
                        }
                    </div>
                </Collapse>
                {
                    routes && routes.length > 0 ?
                        <Table numbering title='List of Added Route' columns={tableHeader} data={appendAction(routes)} />
                        :
                        <NoDataInfo>
                            No route added yet.<br />
                            To add a route simply click the <PlusCircle /> icon above.<br /><br />
                            Or if you have GTFS routes.txt file, we suggest using our <Link to='/quick-start-gtfs' >Quick Start GTFS</Link>
                        </NoDataInfo>
                }
            </ContentWrapper>

            {
                routes && selectedRouteIndex !== null &&
                <PreviewMapModal title={`Path preview for Route ${routes[selectedRouteIndex].name}`} route={routes[selectedRouteIndex]} show={showPreviewModal} onHide={() => setShowPreviewModal(false)} />
            }

            {
                routes && selectedRouteIndex !== null &&
                <Modal size='xl' title={`Edit Route ${(selectedRouteIndex !== null) ? routes[selectedRouteIndex].name : ''}`} show={showEditModal} onHide={() => setShowEditModal(false)} >
                    <RouteForm edit defaultValues={routes[selectedRouteIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleRouteUpdate} />
                </Modal>
            }
        </>
    )
}

export default EditorialRoute