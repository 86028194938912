// import { connect } from "react-redux";
import SuperPage from "./SuperPage";

// const mapStateToProps = (state) => {
//     return {
//         user: state.user
//     }
// }

// export default connect(
//     mapStateToProps,
//     {}
// )(SuperPage)
export default SuperPage