import { INITIAL_STATE } from '../store';
import { ACTION_TYPES } from '../../config';

export const UserReducer = (state = INITIAL_STATE.user, action) => {
  switch (action.type) {
    // user login
    case ACTION_TYPES.USER_LOGIN_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case ACTION_TYPES.USER_LOGIN_FAILED:
      return {
        ...state,
        loading: false
      };

    case ACTION_TYPES.USER_SIGN_UP_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_SIGN_UP_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case ACTION_TYPES.USER_SIGN_UP_FAILED:
      return {
        ...state,
        loading: false
      };

    // Logout
    case ACTION_TYPES.USER_LOGOUT_REQUESTED:
      return {
        ...state
      };

    case ACTION_TYPES.USER_LOGOUT_SUCCEEDED:
      return INITIAL_STATE.user;

    case ACTION_TYPES.USER_LOGOUT_FAILED:
      return {
        ...state
      };

    // agency logo uplod
    case ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_SUCCEEDED:
      return {
        ...state,
        agency: action.payload,
        loading: false
      };

    case ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_FAILED:
      return {
        ...state
      };

    // update supervisor
    case ACTION_TYPES.UPDATE_SUPERVISOR_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.UPDATE_SUPERVISOR_SUCCEEDED:
      return {
        ...state,
        agency: action.payload,
        loading: false
      };

    case ACTION_TYPES.UPDATE_SUPERVISOR_FAILED:
      return {
        ...state,
        loading: false
      };

    // update user profile
    case ACTION_TYPES.USER_UPDATE_PROFILE_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.USER_UPDATE_PROFILE_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        loading: false
      };

    case ACTION_TYPES.USER_UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false
      };

    // update agency profile
    case ACTION_TYPES.UPDATE_AGENCY_INFO_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case ACTION_TYPES.UPDATE_AGENCY_INFO_SUCCEEDED:
      state.agency = action.payload
      return {
        ...state,
        loading: false
      };

    case ACTION_TYPES.UPDATE_AGENCY_INFO_FAILED:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
