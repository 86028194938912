import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import EventBus from 'eventing-bus'
import { ACTION_TYPES } from '../../config';

const LogoutPage = (props) => {
    const [logoutSuccess, setLogoutSuccess] = useState(false)

    useEffect(() => {
        const delay = setTimeout(() => {
            props.requestUserLogout()
            clearTimeout(delay)
        }, 3000)
        const logoutSuccess = EventBus.on(ACTION_TYPES.USER_LOGOUT_SUCCEEDED, () => setLogoutSuccess(true))
        return () => {
            logoutSuccess()
        }
    }, [])

    if (logoutSuccess) return <Redirect to='/' />

    return (
        <div style={{ height: '100vh' }} className='d-flex flex-column justify-content-center align-items-center' >
            <Spinner animation='border' variant='primary' />
            <h5>Logging out...</h5>
        </div>
    )
}

export default LogoutPage