import { useEffect } from "react";
import { useCallback, useState } from "react";
import './SimpleSwitch.css'

const SimpleSwitch = ({ defaultValue = false, onChange = () => { }, trueText = 'ON', falseText = 'OFF', readOnly = false }) => {
  const [selected, toggleSelected] = useState(defaultValue)

  const onHandleChange = useCallback(() => {
    if (readOnly) return null
    onChange(!selected)
    toggleSelected(!selected)
  }, [selected, readOnly])

  return (
    <div className={`toggle-container-1 ${readOnly ? "disabled-1" : ""}`} onClick={onHandleChange}>
      <div className={`dialog-button-1 ${selected ? "" : "off-1"} ${readOnly ? "disabled-1" : ""}`}>
        {selected ? trueText : falseText}
      </div>
    </div>
  );
}

export default SimpleSwitch