import { connect } from "react-redux";
import { requestUserLogout } from "../../redux/actions";
import LogoutPage from "./LogoutPage";

const mapStateToProp = (state) => (
    {
        user: state.user
    }
)

export default
    connect(mapStateToProp, {
        requestUserLogout
    }
    )(LogoutPage)