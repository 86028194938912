import { persistCombineReducers, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import { UserReducer } from './user.reducer';
import { UserExpReducer } from './userExp.reducer';
import { TripSchedulerReducer } from './tripScheduler.reducer';
import { PJCityBusUserExpReducer } from './pjCityBusUserExp.reducer';

import JWT from 'jsonwebtoken'
const browser_tkn = '27a7da8a-4ceb-47dc-8c3a-a4c36b6aa981'

const UserEncryption = createTransform(
  (inboundState) => {
    return JWT.sign(inboundState, browser_tkn);
  },
  (outboundState) => {
    if (JWT.verify(outboundState, browser_tkn)) {
      return JWT.decode(outboundState);
    }
    return ''
  },
);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
  transforms: [UserEncryption]
}

const rootReducer = persistCombineReducers(persistConfig, {
  user: UserReducer,
  userExp: UserExpReducer,
  tripScheduler: TripSchedulerReducer,
  pjCityBusUserExp: PJCityBusUserExpReducer
});

export default rootReducer;