import { DatePicker } from "@material-ui/pickers"
import _ from "lodash"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { LoadingModal, SimpleChart, SimpleInputGroup, StatCard } from "../../../components"
import { API_END_POINTS, Utils } from '../../../config'
import { ApiRequest } from '../../../helpers'
// import TripLogByDateSample from './TripLogByDateSample.json'
const ServiceFrequency = () => {
    const [tripLogs, setTripLogs] = useState([])
    const [originalLog, setOriginalLog] = useState([])
    const [selectedDate, setSelecteddate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [operaionHours, setOperaionHours] = useState(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])
    const [wholeDay, setWholeDay] = useState(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])
    const [allHours, setAllHours] = useState(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])
    const [startHour, setStartHour] = useState("00")
    const [endHour, setEndHour] = useState("23")

    // 


    useEffect(() => {
        setOriginalLog()
        setTripLogs()
        setStartHour("00")
        setEndHour("23")
        // setWholeDay(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])
    }, [selectedDate])




    const handleGetLogs = useCallback(async () => {
        setLoading(true)
        try {

            // setWholeDay(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])

            if (selectedDate) {
                const data = await ApiRequest.fetch({
                    method: 'get',
                    url: API_END_POINTS.GET_DATE_TRIP_LOG,
                    params: {
                        timestamp: moment(selectedDate).valueOf()
                    }
                })

                setOriginalLog(data)
                // 
                const orderByTripId = _.orderBy(data, ['routeId'], 
                ['asc'])
                const groupByrouteId = _.groupBy(orderByTripId, 'shortName')
                const routeOrder = Object.keys(groupByrouteId)
                // 
                // 
                const routeLogsPair = []
                Object.entries(groupByrouteId).map(([k, v]) => {
                    // 
                    const [_maxLength, idx] = v.reduce(([a, i], { logs: l }, idx) => [Math.max(a, l.length), l.length > a ? idx : i], [0, 0])
                    // 
                    const route3Sequence = Utils.getAllSegmentFocusStops(v[idx].logs)
                    // 
                    if (route3Sequence?.length) {
                        const selectedStopsByTripId = v.map(({ logs }) => {
                            const logsfiltered = logs.filter(({ sequence }) => route3Sequence.includes(sequence))
                            return logsfiltered?.length > 0 && logsfiltered
                        })
                        // 
                        routeLogsPair.push({ shortName: k, logs: _.sortBy(selectedStopsByTripId, ['tripId'], ['asc']) })
                    }

                })
                // 

                const groupedByRouteAndSequence = routeLogsPair.map(({ shortName, logs }) => {
                    const concat = logs.reduce((p, c) => _.concat(p, c), []).filter((l) => l)
                    return { shortName, logs: _.groupBy(concat, 'sequence') }
                })
                // 



                let groupedAvgTimeDiffByRoute = []
                // 
                // const wholeDay = ["06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"]
                // const wholeDay = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]
                groupedByRouteAndSequence.map(({ shortName, logs }) => {
                    return {
                        shortName, averageTimeList: Object.entries(logs).map(([stopSeq, log], ind) => {
                            // 
                            const sortedLog = _.sortBy(log, ['timestamp'], ['asc'])
                            // 
                            const avgTimeDiff = []
                            // const timeGroup = []
                            // just for reference : return { avgTimeDiff: [...avgTimeDiff, { timeDiff, timeGroup: ts, shortName, stopSeq }], shortName, stopSeq, timeGroup: tg }
                            const avgSFreqBySeq = wholeDay.map((dayHour, i) => {
                                const index = sortedLog.findIndex(
                                    (n) => moment(moment(n.timestamp, "x").format("HH:mm"), "HH:mm").format("x") >= +moment(dayHour + ":00", "HH:mm").format("x")
                                );

                                // 

                                const timeGroup = dayHour
                                if (index !== -1) {
                                    // 
                                    // 
                                    // 
                                    let from = Date.parse("01/01/2011 " + dayHour + ":00");
                                    let to = Date.parse("01/01/2011 " + moment(sortedLog[index].timestamp, "x").format("HH:mm"));
                                    let nextDayTo = Date.parse("01/02/2011 " + moment(sortedLog[index].timestamp, "x").format("HH:mm"));
                                    if (to > from) {
                                        // 
                                        const timeDiff = String((to - from) / 60000)
                                        // 
                                        // 
                                        return { avgTimeDiff: [...avgTimeDiff, { timeDiff, timeGroup, shortName, stopSeq }], shortName, stopSeq, timeGroup }
                                    } else {
                                        // 
                                        const timeDiff = String((nextDayTo - from) / 60000)
                                        // 
                                        // 
                                        return { avgTimeDiff: [...avgTimeDiff, { timeDiff, timeGroup, shortName, stopSeq }], shortName, stopSeq, timeGroup }

                                    }
                                    // return sortedLog2[index];
                                }
                                if (index === -1) {

                                    // 
                                    // 
                                    // 
                                    // 
                                    let from = Date.parse("01/01/2011 " + dayHour + ":00");
                                    let to = Date.parse("01/01/2011 " + moment(sortedLog[0].timestamp, "x").format("HH:mm"));
                                    let nextDayTo = Date.parse("01/02/2011 " + moment(sortedLog[0].timestamp, "x").format("HH:mm"));
                                    if (to > from) {
                                        // 
                                        const timeDiff = String((to - from) / 60000)
                                        // 
                                        // 
                                        return { avgTimeDiff: [...avgTimeDiff, { timeDiff, timeGroup, shortName, stopSeq }], shortName, stopSeq, timeGroup }

                                    } else {
                                        // 
                                        const timeDiff = String((nextDayTo - from) / 60000)
                                        // 
                                        // 
                                        return { avgTimeDiff: [...avgTimeDiff, { timeDiff, timeGroup, shortName, stopSeq }], shortName, stopSeq, timeGroup }

                                    }
                                    // return sortedLog2[0];
                                }
                            })

                            // 
                            avgSFreqBySeq.forEach((data) => {

                                groupedAvgTimeDiffByRoute = _.concat(groupedAvgTimeDiffByRoute, data?.avgTimeDiff)
                            })
                            return avgSFreqBySeq
                        })
                    }
                })
                // 

                // test 2 ends
                const timeDiffByGroup = _.groupBy(groupedAvgTimeDiffByRoute, 'shortName')
                // 
                const timeDiffByRoute = Object.entries(timeDiffByGroup).map(([k, v]) => {
                    return { [k]: _.groupBy(v, 'timeGroup') }
                })
                // 
                // function preferredOrder(obj, order) {
                //     var newObject = {};
                //     for (var i = 0; i < order.length; i++) {
                //         if (obj.hasOwnProperty(order[i])) {
                //             newObject[order[i]] = obj[order[i]];
                //         }
                //     }
                //     return newObject;
                // }
                // const sortedTimeDiffByRoute = preferredOrder(timeDiffByRoute,routeOrder)
                // 
                setTripLogs(timeDiffByRoute)
            }
        } catch (error) {
            setLoading(false)

        }
        setLoading(false)
    }, [selectedDate, wholeDay])


    useEffect(() => {
        handleGetLogs()
        // setWholeDay(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])

    }, [selectedDate, wholeDay])

    const findRouteColor = useCallback((sName) => {
        if (originalLog) {
            return originalLog.find(({ shortName }) => String(shortName).toLowerCase() === String(sName).toLowerCase()).colorCode
        }
        return 'blue'
    }, [originalLog])

    const chartData = useMemo(() => {
        const cd = []
        if (tripLogs && tripLogs.length > 0) {
            // 
            tripLogs.forEach((obj) => {
                const d = { data: [], label: '', color: '' } //data = [[x,y], [x,y]]
                Object.entries(obj).forEach(([routeName, timeObj]) => {
                    Object.entries(timeObj).forEach(([timeGroup, list]) => {
                        // 
                        if (list.length > 0) {
                            const totalAvgTime = list.reduce((p, c) => p + +c?.timeDiff, 0)
                            d.data.push([timeGroup, +(totalAvgTime / list.length).toFixed(2)])
                        }
                    })
                    // 
                    d.data = d.data.sort(([a], [b]) => a - b)
                    // 
                    d.label = routeName
                    d.color = `${findRouteColor(routeName)}aa`
                })
                cd.push(d)
            })
            // 
            return cd
        }
        return null
    }, [tripLogs, originalLog])
    const handleStartHour = (startHour) => {
        // 
        setStartHour(startHour)

    }
    const handleEndHour = (endHour) => {
        // 
        setEndHour(endHour)

    }


    const updateFilter = () => {
        if (!startHour || !endHour) {
            return toast.error("Please fill operation hours to Filter!")
        }
        if (Number(startHour) >= Number(endHour)) {
            return toast.error("Operation's End hour should be greater than Start hour!")
        }
        if (startHour && endHour) {
            const tempArray = allHours
            const filteredHours = tempArray.filter((hour) => Number(hour) >= Number(startHour) && Number(hour) <= Number(endHour))
            setWholeDay(filteredHours)
        }
    }

    return (
        <div>
            <LoadingModal loading={loading} />
            <div className="d-flex justify-content-between align-items-center" >
                <h4 className='mb-3' >Service Frequency</h4>


                <DatePicker
                    autoOk
                    label="Select date"
                    value={selectedDate}
                    onChange={(value, e) => {
                        // 
                        if (moment(value).format("MMM Do YY") === moment(selectedDate).format("MMM Do YY")) return
                        setWholeDay(["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"])

                        setSelecteddate(value)
                    }}
                    disableFuture
                    disableToolbar
                    variant='inline'
                    inputVariant='outlined'
                    className='ml-3 mt-3'
                    maxDate={moment().subtract(1, 'day')}
                />
            </div>
            {tripLogs && tripLogs.length > 0 && startHour && endHour &&
                <div className="d-flex row justify-content-center mt-3">
                    <h5 className="mt-2 mr-2">Select Hours of Operation:</h5>
                    <Form.Control className="mr-3" required as='select' style={{ width: 90 }} value={startHour || -1} onChange={(e) => handleStartHour(e.target.value)}>

                        <option disabled value={-1} key={-1}> </option>

                        {operaionHours.length > 0 && operaionHours.map((hour) =>

                            <option key={hour} value={hour}>{hour} </option>

                        )}

                    </Form.Control>
                    <h1 className="mr-3"><span style={{ position: 'relative', top: -10 }}>-</span></h1>
                    <Form.Control className="ml-6 mr-4" required style={{ width: 90 }} as='select' value={endHour || -1} onChange={(e) => handleEndHour(e.target.value)}>

                        <option disabled value={-1} key={-1}> </option>

                        {operaionHours.length > 0 && operaionHours.map((hour) =>

                            <option key={hour} value={hour}>{hour} </option>

                        )}

                    </Form.Control>

                    <button className='filter-button py-0 mt-0 mx-0 px-2 mb-2' style={{ height: 45 }} onClick={updateFilter}>Filter Hours</button>
                </div>
            }
            {
                chartData &&
                <Card className='mt-3 pb-4' >
                    <Card.Body>
                        <SimpleChart tooltip title='Average Service Frequency (min)' type='bar' data={chartData} />
                    </Card.Body>
                </Card>
            }
            {
                chartData && chartData.map(({ data, label, color }) =>
                    <Card key={label} className='mt-3 pb-4' >
                        <Card.Body>
                            <Row style={{ alignItems: 'center' }} >
                                <Col lg={8} md={6} sm={12}>
                                    <SimpleChart style={{ marginBottom: 30 }} tooltip title={label} type='bar' data={[{ data, color }]} />
                                </Col>
                                <StatCard bgColor={color} cardClassname='align-items-center text-center' lg={4} md={6} sm={12} data={`${(data?.reduce((p, [b, c]) => +p + +c, 0) / data.length).toFixed(0)} min`} text={label} />
                            </Row>
                        </Card.Body>
                    </Card>
                )
            }
        </div>
    )
}

export default ServiceFrequency
