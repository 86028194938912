import React, { useEffect,useMemo, useCallback, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'

import { API_END_POINTS, Utils, GENERAL } from '../../../config'
import { ApiRequest } from '../../../helpers'
import { DateRangePicker, LoadingModal, SimpleChart, SimpleInputGroup } from '../../../components'
import HeatMap from '../../../components/HeatMap'
import PolylineUtils from '@mapbox/polyline'
import { toast } from 'react-toastify'

const todayDay = moment()
const fromDateDefault = moment().subtract(1, 'weeks') //.subtract(+todayDay.format('DD') - 1, 'days')

const RidershipHeatmap = () => {
    const [route, setRoute] = useState(null)
    const [direction, setDirection] = useState(null)
    const [routesArr, setRoutesArr] = useState(null)
    const [selectToDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [selectFromDate, setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    const [transactions, setTransactions] = useState()
    const [filteredTransactions, setFilteredTransactions] = useState()
    const [loading, setLoading] = useState(false)
    const [mapAllStops, setMapAllStops] = useState([])
    const [addressPoints, setAddressPoints] = useState([])
    const [canFilter, setCanFilter] = useState(false)

    useEffect(() => {
        setLoading(true)
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.TRANSACTION_RIDERSHIP_GET_ALL}`,
            params: {
                timestamp: new Date()
            }
        }).then(data => {
            // console.log("data", data)
            // const sortedData = _.orderBy(data, [({ createdAt }) => new Date(createdAt)], ['desc'])
            setTransactions(data)
        }).catch(e => { })
            .finally(() => {
            })

        setLoading(false)
    }, [])

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: true }
        }).then(async (data) => {
            await setRoutesArr(data)
            // handleRouteData()
        }).catch(e => { })
    }
    useEffect(() => {

        handleGetAllRoute()
        return () => {

        }
    }, [])



    const setperiod = async (e) => {
        // 
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)
        setCanFilter(true)
    }
    const handleSubmitQuery = (e) => {
        e.preventDefault()
        if(!route){
            toast.error("please select Route!")
            return
        }
        if(!canFilter){
            toast.error("please select Month!")
            return
        }
        // if(!direction){
        //     toast.error("please select Direction!")
        //     return
        // }
        const query = { route, selectFromDate, selectToDate, direction }
        // 
        // 
        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!direction || direction === 'null') { query['direction'] = null }

        onSubmitQuery(query)
        // 
    }
    const onSubmitQuery = useCallback(({ route, selectFromDate, selectToDate, direction }) => {
        if (!transactions) return []
        // if (!route) {
        //     toast.warning("Please select the route to fetch records!")
        //     return
        // }

        const filtered = transactions.filter(({ obIb, createdAt, startedAt, scheduledAt, routeShortName, driverName, vehicleRegistrationNumber, userId, tripId }, index) => {
            // console.log("tripId,startedAt,scheduledAt,selectFromDate,selectToDate",tripId,startedAt,scheduledAt,selectFromDate,selectToDate);
            let returnVal = true


            if (selectFromDate) {
                // 
                returnVal = createdAt ? new Date(createdAt).valueOf() >= new Date(selectFromDate).valueOf() : ''
                if (!returnVal) return false
                // returnVal = startedAt ? moment(startedAt) >= moment(selectFromDate)  :  moment(scheduledAt)  >= moment(selectFromDate)
                // if (!returnVal) return false
            }

            if (selectToDate) {
                returnVal = createdAt ? new Date(createdAt).valueOf() <= new Date(selectToDate).valueOf() : ''
                if (!returnVal) return false
                // returnVal = startedAt ? moment(startedAt) <= moment(selectFromDate)  :  moment(scheduledAt).format('X')  <= moment(selectFromDate)
                // if (!returnVal) return false
            }
            if (direction) {

                returnVal = obIb == direction
                if (!returnVal) return false
                // returnVal = startedAt ? moment(startedAt) <= moment(selectFromDate)  :  moment(scheduledAt).format('X')  <= moment(selectFromDate)
                // if (!returnVal) return false
            }

            // if(!route && index == 0){
            //     toast.warning("Please select the route to fetch records!")
            //     return
            // }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false

            }


            return true
        })
        const filteredByStartStop = _.groupBy(filtered, item => `${item.startStopId}+${item.startStopName}+${item.start_lat}+${item.start_long}`)
        Object.keys(filteredByStartStop).forEach((item) => {
            let currentPax = 0
            // console.log(data)
            filteredByStartStop[item].forEach((item)=> {currentPax  = currentPax + +item.noOfAdult + +item.noOfChild + +item.noOfSenior + +item.noOfOku + +item.noOfForeignAdult + +item.noOfForeignChild})
            filteredByStartStop[item] = currentPax
        })
        setFilteredTransactions(filteredByStartStop)
    }, [transactions])


    const handleClear = () => {
        document.getElementById('date-from').value = ''
        // document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setDirection(null)
        setCanFilter(false)
        setFilteredTransactions(null)
        setMapAllStops([])
        setAddressPoints([])
        
        onClearQuery()
    }

    const onClearQuery = useCallback(() => {


    }, [])
    const handleDecodedPolyline = (data) => {
        if (!data.polygon) return null
        return PolylineUtils.decode(data.polygon)
    }

    const AllPolylines = useMemo(() => {
// console.log("route",route);
        if (!route) return null
        const data = {
            polylines: [],
            polylinesPathOpt: [],
            routeNames: []
        }
        routesArr.filter((item) => item.shortName == route)?.forEach(route => {
            // 
            const decodedPolyline = handleDecodedPolyline(route)
            const pathColor = route.colorCode

            if (decodedPolyline) {
                data.polylines.push(decodedPolyline)
            }
            data.polylinesPathOpt.push({ color: pathColor || 'grey' })
            data.routeNames.push(`${route.shortName} - ${route.name}`)
        })
// console.log("allpoly",data);
        return data
    }, [route,filteredTransactions])

    const AllStops = useMemo(() => {
        const data = []
        if(filteredTransactions){
            // console.log("current dire",direction);
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET}/${routesArr.filter((item) => item.shortName == route)[0].id}`
        }).then((route) => {
            // console.log("route with stops",route);
            // const newdata = []
            // console.log("just stops",route.stops);
            // console.log("after process",route.stops.filter((item)=>item.directionId == direction));
        if (direction && direction != 'null') {
            
            // console.log("filteredtra",filteredTransactions);
            route.stops.filter((item)=>item.directionId == direction).map(({ latitude, longitude, name,id }) => {
                let searchKey = `${id}+${name}+${latitude}+${longitude}`
                // console.log("main test",id,name,latitude,longitude,`${name}(PAX-${filteredTransactions[searchKey]})`);
               if (filteredTransactions[searchKey]) {
                
                   data.push([String(latitude), String(longitude), `${name}(PAX-${filteredTransactions[searchKey]})`])
               } else {
                data.push([String(latitude), String(longitude), `${name}(PAX-0)`])
                
               }
            })
        }
        else{
            route.stops.map(({ latitude, longitude, name,id }) => {
                let searchKey = `${id}+${name}+${latitude}+${longitude}`
                // console.log("main test",id,name,latitude,longitude,`${name}(PAX-${filteredTransactions[searchKey]})`);
               if (filteredTransactions[searchKey]) {
                
                   data.push([String(latitude), String(longitude), `${name}(PAX-${filteredTransactions[searchKey]})`])
               } else {
                data.push([String(latitude), String(longitude), `${name}(PAX-0)`])
                
               }
            })
        }
            // setStops(newdata)
        }).catch(e => { }).finally(()=>{
            // console.log("allstops",data);
            setMapAllStops(data)
            return data

        })

    }

        // if (!filteredTransactions) return null
        // const data = []
        // Object.keys(filteredTransactions).forEach((item)=> {data.push([item.split("+")[2],item.split("+")[3],item.split("+")[1]])})
        // console.log("all stops old",data);
        // return data
    }, [filteredTransactions])

    useEffect(() => {
        // console.log("here inside useeffect",filteredTransactions);
        const data = []
        if (filteredTransactions) {
            const maxPax = Math.max(...Object.values(filteredTransactions))
          Object.keys(filteredTransactions).forEach((item)=> {data.push([item.split("+")[2],item.split("+")[3],(filteredTransactions[item]/maxPax).toFixed(1)])})
        //  console.log("address points",data);
          setAddressPoints(data)
        }
    
      return () => {
       
      }
    }, [filteredTransactions])
    



    return (
        <div>
            <h4 className='mb-3' >Ridership Heatmap</h4>
            <h5>Search</h5>
            {routesArr?.length > 0 && <Row className='mb-3'>
                <Col lg={4} md={12} sm={12} ></Col>
                <Col lg={8} md={12} sm={12} >
                    <Form onSubmit={handleSubmitQuery} className='text-right' >


                        <SimpleInputGroup preappendText='Route' >
                            <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                <option value={'null'} >select...</option>
                                {routesArr.map(({ shortName }) => shortName).map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                            </Form.Control>
                        </SimpleInputGroup>
                        <SimpleInputGroup preappendText='Direction' >
                            <Form.Control value={direction || 'null'} onChange={(e) => setDirection(e.currentTarget.value)} custom as='select' >
                                <option value={'null'} >ALL</option>
                                <option value={0} >LOOP</option>
                                <option value={1} >OUTBOUND</option>
                                <option value={2} >INBOUND</option>
                            </Form.Control>
                        </SimpleInputGroup>
                        <Row>
                            <Col>
                                <SimpleInputGroup labelWidth={10} preappendText='Month:'>
                                    <Form.Control id='date-from' max={moment().format('YYYY-MM')} onChange={setperiod
                                    } type='month' />
                                </SimpleInputGroup>
                            </Col>

                        </Row>

                        <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                        <Button className='ml-2' type='submit' >Search</Button>
                    </Form>
                </Col>
            </Row>

}
{
    <HeatMap addressPoints={addressPoints} markers={mapAllStops} polylineNames={AllPolylines?.routeNames || []} polylines={AllPolylines?.polylines || []} polylinesPathOpt={AllPolylines?.polylinesPathOpt || [{ color: 'red' }]}  title='Ridership HeatMap(Monthly)' />
}
            {/* <DateRangePicker fromDate={fromDateDefault} onFromChanged={setFromFilterDate} onToChanged={setToFilterDate} />
            {
                groupSummaryByDay &&
                <SimpleChart tooltip title='Ridership' stacked type='bar' data={groupSummaryByDay.graphRidershipData} />
            }
            <LoadingModal loading={loading} /> */}
        </div>
    )
}

export default RidershipHeatmap
