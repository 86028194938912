import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import validator from 'validator'
import { toast } from 'react-toastify'
import EventBus from 'eventing-bus'

import ellipseBG from '../../assets/image/Ellipse-signup-bg.svg'
import logoPrimary from '../../assets/logo/logo-primary.png'
import products from '../../assets/image/justnaik-product.png'
import { ACTION_TYPES } from '../../config'
import { FailedAlert } from '../../components'

import './SignIn.css'
import { userSubmitForgotPassword } from '../../redux/api'

const SignInCard = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailHelper, setEmailHelper] = useState(null)
    const [passwordHelper, setPasswordHelper] = useState(null)

    const handleSubmitLogin = (e) => {
        e.preventDefault()
        setPasswordHelper(null)
        setEmailHelper(null)

        if (!email || validator.isEmpty(email)) return toast.error('email is required')
        if (!validator.isEmail(email)) return toast.error('email is not valid')
        if (!password || validator.isEmpty(password)) return toast.error('password is required')

        props.requestUserLogin({
            email,
            password
        })
    }

    useEffect(() => {
        // const loginSuccess = EventBus.on(ACTION_TYPES.USER_LOGIN_SUCCEEDED, handleLoginSuccess)
        const loginFailed = EventBus.on(ACTION_TYPES.USER_LOGIN_FAILED, handleLoginFailed)

        return () => {
            // loginSuccess()
            loginFailed()
        }
    }, [])

    const handleLoginFailed = ({ errorMessages }) => {
        if (errorMessages === "Invalid password") {
            setPasswordHelper(errorMessages)
        }

        if (errorMessages === 'Admin user not found') {
            setEmailHelper(errorMessages)
        }
    }
    return (
        <Card.Body>
            <Card.Title>Welcome Back</Card.Title>
            <Form onSubmit={handleSubmitLogin}>
                <Form.Group >
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={email} onChange={(evt) => setEmail(evt.currentTarget.value)} required type='email' placeholder='info@hexondata.com' />
                    {
                        emailHelper &&
                        <FailedAlert text={emailHelper} />
                    }
                </Form.Group>
                <Form.Group >
                    <Form.Label>Password</Form.Label>
                    <Form.Control value={password} onChange={(evt) => setPassword(evt.currentTarget.value)} required id='password' type='password' />
                    {
                        passwordHelper &&
                        <FailedAlert text={passwordHelper} />
                    }
                </Form.Group>
                <Button type='submit' className='w-100' >Sign In</Button>
            </Form>
            <h6 className='mt-3' >Don't have an account? <Link to='/signup' >Sign Up</Link></h6>
        </Card.Body>
    )
}

const ForgetPasswordCard = () => {
    const [email, setEmail] = useState('')
    const [done, setDone] = useState(false)

    const handlePostResetPassword = async () => {
        try {
            await userSubmitForgotPassword(email)
            toast.success('Please check your email to continue')
            setDone(true)
        } catch (e) {
            toast.error(e.response?.data || e.message)
        } finally {
            setEmail('')
        }
    }

    const handleSubmitForgetPassword = (e) => {
        e.preventDefault()
        if (!email || validator.isEmpty(email)) return toast.error('email is required')
        handlePostResetPassword()
    }

    return (
        <Card.Body>
            <Card.Title>Password Reset</Card.Title>
            {
                done ?
                    <Alert variant='success' >
                        Your reset password link has been sent to your email
                    </Alert>
                    :
                    <Form onSubmit={handleSubmitForgetPassword}>
                        <Form.Group >
                            <Form.Label>Account Email</Form.Label>
                            <Form.Control value={email} onChange={(evt) => setEmail(evt.currentTarget.value)} required type='email' placeholder='info@hexondata.com' />
                        </Form.Group>
                        <Button type='submit' className='w-100' >Reset My Password</Button>
                    </Form>
            }
        </Card.Body>
    )
}

const FORM_MODE_ENUM = {
    SIGN_UP: 'SIGN_UP',
    RESET_PASSWORD: 'RESET_PASSWORD'
}

const SignIn = (props) => {
    const [formMode, setFormMode] = useState(FORM_MODE_ENUM.SIGN_UP)
    if (props.user.userType === 'APAD') {
        toast.success(`Welcome ${props.user.firstName}!`)
        return <Redirect to='/agency-select' />
    }
    if (props.user.jwtToken) {
        toast.success(`Welcome ${props.user.firstName}!`)
        return <Redirect to='/' />
    }

    const handleToggleForm = () => {
        setFormMode((prevForm) => {
            if (prevForm === FORM_MODE_ENUM.SIGN_UP) {
                return FORM_MODE_ENUM.RESET_PASSWORD
            }
            return FORM_MODE_ENUM.SIGN_UP
        })
    }

    return (
        <div className='signin-main' >
            <img className="signin-bg" src={ellipseBG} alt='bg' />
            <Container>
                <div className='logo-primary' >
                    <img width={200} src={logoPrimary} alt='logo-primary' />
                </div>
                <Row className='h-100' >
                    <Col lg={6} md={12} xs={12} className='h-100 pt-3 d-flex flex-column justify-content-center align-items-center' >
                        <h1 className='text-primary product-text-signin' >Your ultimate <br /> mobility partner </h1>
                        <img className='products-img-signin' src={products} alt='products' />
                    </Col>
                    <Col lg={{ span: 6, order: 'last' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} className='h-100 d-flex justify-content-center align-items-center' >
                        <Card className='w-100' >
                            {
                                formMode === FORM_MODE_ENUM.RESET_PASSWORD ?
                                    <ForgetPasswordCard />
                                    :
                                    <SignInCard {...props} />
                            }
                            <Button variant='link' size='sm' onClick={handleToggleForm} className='mb-3 mx-4' >{formMode === FORM_MODE_ENUM.RESET_PASSWORD ? 'Back to Sign In' : 'Forgot Password?'}</Button>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignIn