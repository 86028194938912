import { Button } from 'react-bootstrap'
import React, { Component } from 'react'
import QRCode from 'qrcode-svg-table'

// import qrPosterTemplate from '../../assets/qr_poster_template/Just Naik Ads.ai.svg'
import qrPosterTemplate from '../../assets/qr_poster_template/qr_vehicle_poster.png'

export default class QRVehiclePoster extends Component {

    constructor(props) {
        super(props)
        this.agencyName = props.agencyName || 'Agency Name'
        this.agencyLogoSrc = props.agencyLogoSrc || null
        this.vehicleNum = props.vehicleNum || 'W 2278k'
        this.qrValue = props.qrValue || 'VEHICLE_CODE_1'
        this.w = 620
        this.h = 825
        this.qrSize = this.w * .6
        this.margin = 90
        this.newText = 'Rate'
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const canvas = document.querySelector('#poster-canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        // 
        image.src = qrPosterTemplate

        image.onload = () => {
            canvas.width = this.w
            canvas.height = this.h
            ctx.drawImage(image, 0, 0, this.w, this.h)
            ctx.fillStyle = 'black'
            ctx.font = "bold 25px Arial"
            ctx.fillText(this.agencyName, 10, 40);
            ctx.font='1000 45px Arial';
            
ctx.fillText('Scan To Ride', 150,145);
            ctx.font = "bold 20px Arial"
            ctx.fillText(this.newText, this.w-230, this.h -28);
            ctx.textAlign = 'center'
            ctx.font = "bold 40px Arial"
            ctx.fillText(this.vehicleNum, this.w / 2, this.h / 2 + this.qrSize - this.margin * 2);

            if (this.agencyLogoSrc) {
                const logo = new Image()
                if(process.env.NODE_ENV==='development'){
                    logo.crossOrigin = "Anonymous"; //since in dev we are using local static server
                }
                logo.src = this.agencyLogoSrc
                logo.onload = () => {
                    ctx.drawImage(logo, this.w - (200 + 10), 0, 200, 90); //logo placement
                }
            }

            const test = new QRCode({
                content: this.qrValue,
                padding: 1
            }).svg()
            const b64 = window.btoa(test)
            const qrImage = new Image()
            qrImage.src = `data:image/svg+xml;base64,${b64}`
            qrImage.onload = () => {
                ctx.font = "40px Arial"
                ctx.drawImage(qrImage, this.w / 2 - this.qrSize / 2, this.h / 2 - this.qrSize / 2 - 50, this.qrSize, this.qrSize);
            }
        }
    }

    downloadPoster(vehicleNum) {
        const poster = document.querySelector('#poster-canvas')
        // var dataURL = poster.toDataURL("image/png");
        var dataURL = poster.toDataURL("image/png");
        const temp = document.createElement('a')
        temp.download = `${vehicleNum}_poster.png`
        temp.href = dataURL
        temp.click()
    }

    render() {
        return (
            <div style={{height:'auto'}} className='d-flex flex-column' >
                <canvas id='poster-canvas' className='bg-light' />
                <Button onClick={() => this.downloadPoster(this.vehicleNum)} >Download Poster</Button>
            </div>
        )
    }
}