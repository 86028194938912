import React, { useEffect, useState, useMemo, useCallback } from "react";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { PencilSquare, Link } from "react-bootstrap-icons";
import { Tabs, Tab, Card } from "react-bootstrap";

import {
    ContentWrapper,
    JustnaikAppIntro,
    Modal,
    Table,
} from "../../components";
import FeedbackDetailsModal from "./FeedbackDetailsModal";

import "./TripExperiencePage.css";
import eventingBus from "eventing-bus";
import { ACTION_TYPES, API_END_POINTS } from "../../config";
import { ApiRequest } from "../../helpers";

const TripExperiencePage = ({ tripExperience, pjCityBusExperience, feedback, requestUserExp, requestUserExpUpdate, requestPJCityBusUserExp, agencyId }) => {
    const [loading, setLoading] = useState(false);
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [selectedFCase, setSelectedFCase] = useState(null);

    useEffect(() => {
        requestUserExp()
        // agencyId === 5 && requestPJCityBusUserExp()
        const expReq = eventingBus.on(ACTION_TYPES.USER_EXP_REQUESTED, () => setLoading(true))
        const expSuccess = eventingBus.on(ACTION_TYPES.USER_EXP_SUCCEEDED, () => setLoading(false))
        const expFailed = eventingBus.on(ACTION_TYPES.USER_EXP_FAILED, () => setLoading(false))

        const expUpdateSuccess = eventingBus.on(ACTION_TYPES.USER_EXP_UPDATE_SUCCEEDED, () => setLoading(false))
        const expUpdateFailed = eventingBus.on(ACTION_TYPES.USER_EXP_UPDATE_FAILED, () => setLoading(false))
        return () => {
            expReq()
            expSuccess()
            expFailed()
            expUpdateSuccess()
            expUpdateFailed()
        }
    }, []);

    const ratingTableHeader = useMemo(
        () => [
            {
                Header: "Route ID",
                accessor: "routeShortName",
                // disableFilters: true
            },
            {
                Header: "User Scan IN",
                accessor: "scanInTime_",
                // disableFilters: true
            },
            {
                Header: "User Scan OUT",
                accessor: "scanOutTime_",
                // disableFilters: true
            },
            {
                Header: "Driver Name",
                accessor: "driverName",
                // disableFilters: true
            },
            {
                Header: "Vehicle Number",
                accessor: "vehicleRegistrationNumber",
                // disableFilters: true
            },
            {
                Header: "Rating (1-7)",
                accessor: "rating",
                // disableFilters: true
            },
            {
                Header: "Experience",
                accessor: "ratingText",
                // disableFilters: true
            },
        ],
        []
    );

    const feedbackTableHeader = useMemo(
        () => [
            {
                Header: "Case ID",
                accessor: "caseId_",
                // disableFilters: true
            },
            {
                Header: "Case Category",
                accessor: "title",
                // disableFilters: true
            },
            {
                Header: "User Feedback",
                accessor: "feedback",
                // disableFilters: true
            },
            {
                Header: "User Rating",
                accessor: "ratingText",
                // disableFilters: true
            },
            {
                Header: "Date Created",
                accessor: "dateCreated_",
                // disableFilters: true
            },
            {
                Header: "User",
                accessor: "userName",
                // disableFilters: true
            },
            {
                Header: "Attachment",
                accessor: "attachment_",
                disableFilters: true,
            },
            {
                Header: "source",
                accessor: "source_",
                disableFilters: true,
            },
            {
                Header: "Case Status",
                accessor: "caseStatus",
                // disableFilters: true
            },
            {
                Header: "Resolution",
                accessor: "caseNote",
                // disableFilters: true
            },
            {
                Header: "Action",
                accessor: "action_",
                disableFilters: true,
            },
        ],
        []
    );

    const pjCityBusfeedbackTableHeader = useMemo(
        () => [
            {
                Header: "Case ID",
                accessor: "caseId_",
                // disableFilters: true
            },
            {
                Header: "Case Category",
                accessor: "title",
                // disableFilters: true
            },
            {
                Header: "User Feedback",
                accessor: "feedback",
                // disableFilters: true
            },
            {
                Header: "User Rating",
                accessor: "ratingText",
                // disableFilters: true
            },
            {
                Header: "Date Created",
                accessor: "dateCreated_",
                // disableFilters: true
            },
            {
                Header: "Attachment",
                accessor: "attachment_",
                disableFilters: true
            }
            // {
            //     Header: "Case Status",
            //     accessor: "status",
            //     // disableFilters: true
            // },
            // {
            //     Header: "Resolution",
            //     accessor: "notes",
            //     // disableFilters: true
            // },
            // {
            //     Header: "Action",
            //     accessor: "action_",
            //     disableFilters: true,
            // },
        ],
        []
    );

    const appendActionRating = (data) => {
        if (!data.length > 0) return [];
        const dataWithJourney = data.filter(trip => trip.journeyId > 0)
        dataWithJourney.map((trip) => {
            trip["scanInTime_"] = moment(trip.scanInTime).format("YYYY-MM-DD HH:mm");
            trip["scanOutTime_"] = moment(trip.scanOutTime).format(
                "YYYY-MM-DD HH:mm"
            );
        });
        return dataWithJourney;
    };

    const handleOpenFeedbackModal = (index) => {
        setSelectedFCase(index);
        setOpenFeedbackModal(true);
    };

    const handleCloseFeedbackModal = () => {
        setSelectedFCase(null);
        setOpenFeedbackModal(false);
    };
    

    const appendActionFeedback = (data) => {
        if (!data.length > 0) return [];
        data.map((trip, i) => {
            trip["dateCreated_"] = moment(trip.postedOn).format("YYYY-MM-DD HH:mm");
            // trip["caseStatus_"] = trip.caseStatus === null ? "Open" : "Closed";
            trip["caseId_"] = `CASE_${trip.caseId}`;
            trip["action_"] = (
                <PencilSquare
                    className="g-hover-pointer text-primary"
                    onClick={() => handleOpenFeedbackModal(i)}
                />
            );
            trip["source_"] = trip.tenantId === 1 ? `JustNaik APP` : `PJ CityBus APP`;
            if (trip.attachment) {
                trip["attachment_"] = (
                    <Link
                        className="g-hover-pointer text-primary"
                        onClick={() => handleViewImg(trip.attachment)}
                    />
                )
            }
        });
        return data;
    };

    const handleGetAttachmentUrl = useCallback(async (att) => {
        const { url } = await ApiRequest.fetch({
            method: "get",
            url: `${API_END_POINTS.GET_ATTACHMENT_URL}/${att}`,
        })
        return url
    }, []);

    const handleViewImg = useCallback(async (att) => {
        const url = await handleGetAttachmentUrl(att)
        window.open(url, '_blank')
    }, [])

    const appendActionPJCityBusFeedback = useCallback((data) => {
        if (!data.length > 0) return [];
        data.map(async (trip) => {
            trip["dateCreated_"] = moment(trip.createdAt).format("YYYY-MM-DD HH:mm");
            // trip["caseStatus_"] = trip.caseStatus === null ? "Open" : "Closed";
            trip["caseId_"] = `CASE_${trip.id}`;
            // trip["action_"] = (
            //     <PencilSquare
            //         className="g-hover-pointer text-primary"
            //         onClick={() => handleOpenFeedbackModal(i)}
            //     />
            // );
            if (trip.attachment) {
                trip["attachment_"] = (
                    <Link
                        className="g-hover-pointer text-primary"
                        onClick={() => handleViewImg(trip.attachment)}
                    />
                )
            }
        });
        return data;
    }, []);

    const handleSubmitUpdate = (modifiedData) => {
        
        requestUserExpUpdate(modifiedData)
        setLoading(true)
    }

    return (
        <ContentWrapper>
            <h2 className="mb-3">Trip Experience</h2>
            <Tabs defaultActiveKey={"feedback"} id="settlement-report-tab">
                <Tab eventKey="feedback" title="Feedback">
                    <Card>
                        <Card.Body>
                            <h4 className="mb-3">User Feedback</h4>
                            {feedback && feedback.length > 0 ? (
                                <Table
                                    numbering
                                    columns={feedbackTableHeader}
                                    data={appendActionFeedback(feedback)}
                                />
                            ) : (
                                <JustnaikAppIntro />
                            )}
                        </Card.Body>
                    </Card>
                </Tab>
                <Tab eventKey="rating" title="Rating">
                    <Card>
                        <Card.Body>
                            <h4 className="mb-3">User Rating</h4>
                            {tripExperience && tripExperience.length > 0 ? (
                                <Table
                                    numbering
                                    columns={ratingTableHeader}
                                    data={appendActionRating(tripExperience)}
                                />
                            ) : (
                                <JustnaikAppIntro />
                            )}
                        </Card.Body>
                    </Card>
                </Tab>
                {/* {
                    agencyId && +agencyId === 5 &&
                    <Tab eventKey="pjcitybus" title="PJ City Bus">
                        <Card>
                            <Card.Body>
                                <h4 className="mb-3">General Feedback</h4>
                                {pjCityBusExperience && pjCityBusExperience.length > 0 ? (
                                    <Table
                                        numbering
                                        columns={pjCityBusfeedbackTableHeader}
                                        data={appendActionPJCityBusFeedback(pjCityBusExperience)}
                                    />
                                ) : (
                                    <JustnaikAppIntro />
                                )}
                            </Card.Body>
                        </Card>
                    </Tab>
                } */}
            </Tabs>
            <Modal backdropClassName='loading-modal' size="sm" centered show={loading}>
                <div className="text-center">
                    <CircularProgress size={70} />
                </div>
            </Modal>
            {feedback && selectedFCase !== null && (
                <FeedbackDetailsModal
                    data={feedback[selectedFCase]}
                    open={openFeedbackModal}
                    onClose={handleCloseFeedbackModal}
                    submitUpdate={handleSubmitUpdate}
                />
            )}
        </ContentWrapper>
    );
};

export default TripExperiencePage;
