import { ACTION_TYPES } from "../../config";

// get user exp
export function requestUserExp(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_REQUESTED,
    payload
  };
}

export function userExpSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_SUCCEEDED,
    payload
  };
}

export function userExpFailure(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_FAILED,
    payload
  };
}

// get PJCityBus user exp
export function requestPJCityBusUserExp(payload) {
  return {
    type: ACTION_TYPES.PJCITYBUS_USER_EXP_REQUESTED,
    payload
  };
}

export function pjCityBusUserExpSuccess(payload) {
  return {
    type: ACTION_TYPES.PJCITYBUS_USER_EXP_SUCCEEDED,
    payload
  };
}

export function pjCityBusUserExpFailure(payload) {
  return {
    type: ACTION_TYPES.PJCITYBUS_USER_EXP_FAILED,
    payload
  };
}

// Update case
export function requestUserExpUpdate(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_UPDATE_REQUESTED,
    payload
  };
}

export function userExpUpdateSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_UPDATE_SUCCEEDED,
    payload
  };
}

export function userExpUpdateFailure(payload) {
  return {
    type: ACTION_TYPES.USER_EXP_UPDATE_FAILED,
    payload
  };
}