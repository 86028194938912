import { CircularProgress } from "@material-ui/core"
import { Modal, ModalBody } from "react-bootstrap"

const LoadingModal = ({ loading = false }) => (
    <Modal onHide={()=>{}} size='sm' centered show={loading} >
        <ModalBody className='text-center' >
            <CircularProgress size={70} />
        </ModalBody>
    </Modal>
)

export default LoadingModal