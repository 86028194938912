import React from 'react'
import { Alert, Card, Button } from 'react-bootstrap'
import { EmojiDizzy } from 'react-bootstrap-icons'
import { JustnaikAppCarousel } from '..'

const JustnaikAppIntro = () => {
    return (
        <Card>
            <Card.Body>
                <Alert variant='info' >
                    <Alert.Heading> <EmojiDizzy className='mr-1' size={30} /> No Transaction yet?</Alert.Heading>
                    <p>
                        Maybe your users just need a little introduction!<br/>
                        Manage your transits with our cashless and contactless payment via Justnaik App
                    </p>
                    <Button><a className='text-decoration-none text-light' href='https://justnaik.com' target='_blank' >Try Justnaik App Now!</a></Button>
                </Alert>
                <JustnaikAppCarousel />
            </Card.Body>
        </Card>
    )
}

export default JustnaikAppIntro