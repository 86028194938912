import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Col, Collapse, Form, Row } from 'react-bootstrap'
import { Globe, PencilSquare, PlusCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ContentWrapper, EditorMap, Modal, StaticMap, Table } from '../../components'
import { NoDataInfo } from '../../components/Alert'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'

// stops
// - name
// - address
// -latitude: 3.10406750238446
// -longitude: 101.645327729448
// - agency_id
// - is_active

const StopForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { name: '', address: '', isActive: '', latitude:'', longitude:'' ,stopId:'',stopIdPublic:''} }) => {
    const [name, setname] = useState(null)
    const [stopId, setStopId] = useState(null)
    const [stopIdPublic, setStopIdPublic] = useState(null)
    const [address, setAddress] = useState(null)
    const [isActive, setIsActive] = useState(null)
    const [newMarker, setNewMarker] = useState(null)
    const [lat, setLat] = useState(defaultValues.latitude || null)
    const [long, setLong] = useState(defaultValues.longitude || null)
    

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (name !== null) {
            data.name = name
        }
        if (address !== null) {
            data.address = address
        }
        if (isActive !== null) {
            data.isActive = isActive
        }
        if (stopId !== null) {
            data.stopId = stopId
        }
        if (stopIdPublic !== null) {
            data.stopIdPublic = stopIdPublic
        }
        if (lat !== null && long !== null) {

            data.latitude = lat
            data.longitude = long
        }
        if (newMarker !== null) {
            data.latitude = newMarker[0]
            data.longitude = newMarker[1]
        }
       
        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    const handleNewMarkerAdded = (pos) => {
        setNewMarker(pos)
    }

    const preloadMarkerPos = useMemo(()=>{
        const latitude = defaultValues.latitude
        const longitude = defaultValues.longitude
        const name = defaultValues.name
        if(latitude !== '' && longitude !== ''){
            return [latitude, longitude, name]
        }else{
            return []
        }
    },[defaultValues])

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit}  >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Add New Stop</Card.Title>
                            }
                            <EditorMap title='Select New Stop Location' editMarker onMarkerAdded={handleNewMarkerAdded} preloadMarker={preloadMarkerPos} />
                            <Form.Row className='mt-3' >
                                <Col className='m-0' md={8} >
                                    <Form.Group>
                                        <Form.Label >Stop Name</Form.Label>
                                        <Form.Control onChange={(e) => setname(e.currentTarget.value)} required={!edit ? true : false} placeholder='Jaya One' defaultValue={defaultValues.name} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label >Description</Form.Label>
                                        <Form.Control as='textarea' required={!edit ? true : false} onChange={(e) => setAddress(e.currentTarget.value)} placeholder='Description' defaultValue={defaultValues.address} />
                                    </Form.Group>
                                    {
                                        edit &&
                                        <Form.Group>
                                            <Form.Label >Active</Form.Label>
                                            <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active-stop' />
                                        </Form.Group>
                                    }
                                </Col>
                                <Col className='m-0' md={4} >
                                    <Form.Group>
                                        <Form.Label >Latitude</Form.Label>
                                        <Form.Control required={!edit ? true : false} placeholder='Latitude' onChange={e => setLat(e.target.value)}  value={newMarker !== null ? newMarker[0] : lat !== null ? lat : defaultValues.latitude} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label >Longitude</Form.Label>
                                        <Form.Control required={!edit ? true : false} placeholder='Longitude' onChange={e => setLong(e.target.value)}  value={newMarker !== null ? newMarker[1] : long  !== null ? long : defaultValues.longitude} />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <h5>Fill the below details if applicable:</h5>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label >STOP ID:</Form.Label>
                                        <Form.Control onChange={(e) => setStopId(e.currentTarget.value)} defaultValue={defaultValues.stopId} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label >STOP ID PUBLIC:</Form.Label>
                                        <Form.Control  onChange={(e) => setStopIdPublic(e.currentTarget.value)} defaultValue={defaultValues.stopIdPublic} />
                                    </Form.Group>
                                </Col>
                             

                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Add Stop' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const EditorialStop = () => {
    const [add, setAdd] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addCollapse, setAddCollapse] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)

    const [selectedStopIndex, setSelectedStopIndex] = useState(null)
    const [stops, setStops] = useState(null)

    const tableHeader = useMemo(() => [
        {
            Header: 'Stop Name',
            accessor: 'name'
            // disableFilters: true,
        },
        {
            Header: 'Address',
            accessor: 'address',
            // disableFilters: true

        },
        {
            Header: 'Coordinate',
            accessor: 'coordinates_',
            disableFilters: true
        },
        {
            Header: 'Stop Id',
            accessor: 'stopId',
            disableFilters: true
        },
        {
            Header: 'Stop Id Public',
            accessor: 'stopIdPublic',
            disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            disableFilters: true
        },
        {
            Header: 'Last Modified',
            accessor: 'modifiedAt_',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'View',
            accessor: 'onPreview',
            disableFilters: true
        }
    ], [])

    const handleGetStops = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.STOP_GET_ALL}`
        }).then((data) => {
            // 
            setStops(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetStops()
    }, [refresh])

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleClickCancel = () => {
        setSelectedStopIndex(null)
        setAdd(false)
        setShowEditModal(false)
    }

    const handleStopUpdate = (id, data) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.STOP_UPDATE}/${id}`,
            data
        }).then(() => {
            toast.success('Stop Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleStopCreate = (id, data) => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.STOP_CREATE}`,
            data
        }).then(() => {
            toast.success(`${data.name} Stop Created!`)
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleEdit = (index) => {
        setShowEditModal(true)
        setSelectedStopIndex(index)
    }

    const handlePreviewRoute = (index) => {
        setShowPreviewModal(true)
        setSelectedStopIndex(index)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['onPreview'] = <Globe className='g-hover-pointer text-primary' onClick={() => handlePreviewRoute(i)} />
            row['coordinates_'] = <p>[{row.latitude}, {row.longitude}]</p>
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['modifiedAt_'] = <p>{row.updatedAt ? `${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}` : ''}</p>
        })
        return data
    }

    return (
        <>
            <ContentWrapper>
                <h2 className='mb-3' >Stop Editorial</h2>
                <div className='divider' />
                <Row >
                    <Col className='pt-3 text-dark' md={10} sm={12} >
                        <Card className='h-100' >
                            <Card.Body>
                                <Card.Title >Active Stop Count</Card.Title>
                                {
                                    stops &&
                                    <h1>{stops.length}</h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pt-3' md={2} sm={12} >
                        <Card onClick={handleToggleAddForm} className='bg-primary btn btn-outline-light h-100' >
                            <Card.Body className='d-flex justify-content-center align-items-center' >
                                <PlusCircle size={50} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Collapse in={add} onEnter={() => setAddCollapse(true)} onExited={() => setAddCollapse(false)} >
                    <div>
                        {
                            addCollapse &&
                            <StopForm onClickCancel={handleClickCancel} onSubmitForm={handleStopCreate} />
                        }
                    </div>
                </Collapse>
                {
                    stops && stops.length > 0 ?
                        <Table numbering title='List of Added Stop' columns={tableHeader} data={appendAction(stops)} />
                        :
                        <NoDataInfo>
                            No stop added yet.<br />
                            To add a stop simply click the <PlusCircle /> icon above.<br /><br />
                            Or if you have GTFS stops.txt file, we suggest using our <Link to='/quick-start-gtfs' >Quick Start GTFS</Link>
                        </NoDataInfo>
                }
            </ContentWrapper>
            {
                stops && selectedStopIndex !== null &&
                <Modal title={`${stops[selectedStopIndex].name}`} show={showPreviewModal} onHide={() => setShowPreviewModal(false)} >
                    <StaticMap zoom={15} center={[stops[selectedStopIndex].latitude, stops[selectedStopIndex].longitude]} markers={[[stops[selectedStopIndex].latitude, stops[selectedStopIndex].longitude, stops[selectedStopIndex].name]]} />
                </Modal>
            }

            {
                stops && selectedStopIndex !== null &&
                <Modal size='xl' title={`Edit Stop ${stops[selectedStopIndex].name}`} show={showEditModal} onHide={() => setShowEditModal(false)} >
                    <StopForm edit defaultValues={stops[selectedStopIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleStopUpdate} />
                </Modal>
            }
        </>
    )
}

export default EditorialStop