import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row, Table } from 'react-bootstrap'
import { ContentWrapper, RealTimeMap, StaticMap } from '../../components'

import './ApadHomePage.css'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS, GENERAL } from '../../config'

const ApadHomePage = () => {
   

    return (
            <ContentWrapper >
        <div className='overlay d-flex justify-content-center align-items-center' >
            
                <h2 className='mb-3' >Welcome to JustNaik Dashboard!</h2>
                
        </div >
            </ContentWrapper>
    )
}

export default ApadHomePage