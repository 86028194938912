import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'
import calculate from 'calculate-coordinates'
import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'

import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useRef } from 'react'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { },user }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')
    const [canFilter, setCanFilter] = useState(false)
    const handleSubmitQuery = (e) => {
        e.preventDefault()
        if (!canFilter) {
            return toast.error("Please select the month to search")
            
        }
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }
        // 
        // 
        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        // document.getElementById('date-to').value = ''
        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        setCanFilter(false)
        onClearQuery()
    }
    const setperiod = async (e) => {
        // 
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)
        setCanFilter(true)

    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                            <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='Month:'>
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM')} onChange={setperiod
                                        } type='month' />
                                    </SimpleInputGroup>
                                </Col>
                                {/* <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='From Date' >
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM-DD')} min={user.userType != 'SUPER_ADMIN' ? moment().subtract(3, 'months').format('YYYY-MM-DD') : moment().subtract(12, 'months').format('YYYY-MM-DD')} onChange={(e) => setFromDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='To Date' >
                                        <Form.Control id='date-to' min={moment(selectFromDate).add(1, 'day').format('YYYY-MM-DD')} max={moment().format('YYYY-MM-DD')} onChange={(e) => setToDate(e.currentTarget.value ? `${e.currentTarget.value} 23:59:59` : e.currentTarget.value)} type='date' />
                                    </SimpleInputGroup>
                                </Col> */}
                            </Row>
                            {/* <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="Weekend / Weekday" >
                                <Form.Control value={weekendWeekday} onChange={(e) => setWeekendWeekday(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Weekend</option>
                                    <option>Weekday</option>
                                </Form.Control>
                            </SimpleInputGroup> */}
                            <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            {/* <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Paid By' >
                                <Form.Control value={paidBy} onChange={(e) => setPaidBy(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>Cash</option>
                                    <option>Cashless</option>
                                </Form.Control>
                            </SimpleInputGroup> */}
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const BusTransferReportPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)
    const [toDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    const [routesData, setRoutesData] = useState({})
    const [tripLog, setTripLog] = useState({})
    const [tripLogWithS, setTripLogWithS] = useState({})
    const [tripLogStart, setTripLogStart] = useState({})
    const [tripLogStops, setTripLogStops] = useState({})
    const [routeStops, setRouteStops] = useState({})
    const [canFetch, setCanFetch] = useState(false)
    const isLoading = useRef(false);

    const [tripLogKM, setTripLogKM] = useState({})
    // const tripLog = {}
    //     var csvString = 'ı,ü,ü,ğ,ş,#Hashtag,ä,ö';
    // var universalBOM = "\uFEFF";

    const calculateTravelledDistance = (logs) => {
        let totalDistance = 0
        logs.forEach(({ latitude, longitude }, i) => {
            if (i > 0 && i < logs.length - 1) {
                const prev = [logs[i - 1].latitude, logs[i - 1].longitude]
                const curr = [latitude, longitude]
                totalDistance += calculate.fromCoordinatesReturningKM(prev, curr)
            }
        })
        return totalDistance.toFixed(3)
    }
    function preferredOrder(obj, order) {
        var newObject = {};
        for (var i = 0; i < order.length; i++) {
            if (obj.hasOwnProperty(order[i])) {
                newObject[order[i]] = obj[order[i]];
            }
        }
        return newObject;
    }
    const handleGetTransactionHistory = async () => {
            setTransaction({data:'datadescription'})
                 setLoading(false)
    
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then(async (data) => {
            await setRoutesArr(data)
            // handleRouteData()
        }).catch(e => { })
    }


    useEffect(() => {
        const routeWithStops = {}
        // 
        routesArr && routesArr.forEach(({ id }) => {
            
        })
        setRoutesData(routesData => ({ ...routesData, ...routeWithStops }))
        // 
        // 

    }, [routesArr]);



    

    useEffect(() => {
        handleGetTransactionHistory()
        handleGetAllRoute()

    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Route ID',
            accessor: 'routeId',
            // disableFilters: true
        },
        {
            Header: 'OD',
            accessor: 'routeName',
            // disableFilters: true
        },
        {
            Header: 'Date',
            accessor: 'direction',
            // disableFilters: true
        },
        {
            Header: 'Trip ID',
            accessor: 'tripId',
            // disableFilters: true
        },
        {
            Header: 'Bus Plate Number',
            accessor: 'busPlate',
            // disableFilters: true
        },
        {
            Header: 'Type',
            accessor: 'busAge',
            // disableFilters: true
        },
        {
            Header: 'Bus Stops Travel',
            accessor: 'busStops',
            // disableFilters: true
        },
        {
            Header: 'Total Transfer',
            accessor: 'kmApad',
            // disableFilters: true
        },
        {
            Header: 'Total On',
            accessor: 'totalClaim',
            // disableFilters: true
        },
        {
            Header: 'Monthly Pass',
            accessor: 'monthlyPass',
            // disableFilters: true
        },
        {
            Header: 'Adult',
            accessor: 'noOfAdult',
            // disableFilters: true
        },
        {
            Header: 'Child',
            accessor: 'noOfChild',
            // disableFilters: true
        },
        {
            Header: 'Senior',
            accessor: 'noOfSenior',
            // disableFilters: true
        },
        {
            Header: 'student',
            accessor: 'noOfStudent',
            // disableFilters: true
        },
        {
            Header: 'OKU',
            accessor: 'noOfOku',
            // disableFilters: true
        },
        {
            Header: 'JKM',
            accessor: 'jkm',
            // disableFilters: true
        },
        {
            Header: 'MAIM',
            accessor: 'maim',
            // disableFilters: true
        },

    ], [])

    // const tabulated = useMemo(() => {
    //     const returnData = []
    //     const mainData = filteredTripCollection || tripCollection
    //     if (!mainData) return []

    //     Object.entries(mainData).forEach(([localTimeGroup, trxs]) => {
    //         const sortedData = _.orderBy(trxs, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['desc', 'desc'])
    //         // 

    //         const addedLocalTime = sortedData?.map((d) => {
    //             d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
    //             return d
    //         })
    //         let addedLocalTimeOrdered = _.orderBy(addedLocalTime, ['obIb'],
    //             ['asc']);
    //         const groupedTest = _.groupBy(addedLocalTimeOrdered, item => `"${item.localDate}+${item.routeId}+${item.obIb}"`)
    //         // 
    //         const groupedData = _.groupBy(addedLocalTimeOrdered, 'localDate')
    //         Object.entries(groupedData).forEach(([localTimeGroup, trxs]) => {
    //             // 
    //             const accumulativeTrip = {
    //                 'datetime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
    //                 'checkoutTime_': moment(trxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss'),
    //                 'uniqueTrip_': new Set(),
    //                 'totalTripCount_': 0,
    //                 'uniqueDriver_': new Set(),
    //                 'totalUniqueDriverCount_': 0,
    //                 'uniqueVehicle_': new Set(),
    //                 'totalUniqueVehicleCount_': 0,
    //                 'uniqueJourney_': new Set(),
    //                 'totalTransaction_': 0,
    //                 'totalAmount_': 0,
    //                 'noOfAdult': 0,
    //                 'noOfChild': 0,
    //                 'noOfSenior': 0,
    //                 'totalChild': 0,
    //                 'totalSenior': 0,
    //                 'totalAdult': 0,
    //                 'noOfOku': 0,
    //                 'noOfForeignAdult': 0,
    //                 'noOfForeignChild': 0,
    //                 'totalRidership_': 0,
    //                 'cashTotalAmount_': 0,
    //                 'cashTotalRidership_': 0,
    //                 'cashlessTotalAmount_': 0,
    //                 'cashlessTotalRidership_': 0,
    //             }
    //             trxs.map((row) => {
    //                 const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
    //                 accumulativeTrip['uniqueDriver_'].add(row.driverName)
    //                 accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
    //                 accumulativeTrip['uniqueTrip_'].add(row.tripId)
    //                 accumulativeTrip['uniqueJourney_'].add(row.journeyId)
    //                 accumulativeTrip['totalAmount_'] += +row.amount
    //                 accumulativeTrip['noOfAdult'] += +row.noOfAdult
    //                 accumulativeTrip['noOfChild'] += +row.noOfChild
    //                 accumulativeTrip['noOfSenior'] += +row.noOfSenior
    //                 accumulativeTrip['noOfOku'] += +row.noOfOku
    //                 accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
    //                 accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
    //                 accumulativeTrip['totalRidership_'] += totalPax

    //                 accumulativeTrip['cashTotalAmount_'] += row.userId ? 0 : +row.amount
    //                 accumulativeTrip['cashTotalRidership_'] += row.userId ? 0 : totalPax

    //                 accumulativeTrip['cashlessTotalAmount_'] += row.userId ? +row.amount : 0
    //                 accumulativeTrip['cashlessTotalRidership_'] += row.userId ? totalPax : 0
    //             })

    //             accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
    //             accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
    //             accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
    //             accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
    //             accumulativeTrip['totalAdult'] = accumulativeTrip.noOfAdult
    //             accumulativeTrip['localTimeGroup_'] = localTimeGroup.split("+")[0]
    //             accumulativeTrip['trxs'] = trxs


    //             //format amount
    //             accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
    //             accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
    //             accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

    //             returnData.push(accumulativeTrip)
    //         })
    //     })
    //     // 
    //     return returnData
    // }, [tripCollection, filteredTripCollection])
    // // here

    // claim report data start
    const claimData = useMemo(() => {
        const returnData = []

         
        return returnData
    }, []);
    //   
    // claim report data end

    const handleFilterTransactionData = useCallback(({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        if (!transaction) return []
        // if (!route) {
        //     toast.warning("Please select the route to fetch records!")
        //     return
        // }

            if (selectFromDate) {
                setFromDate(moment(selectFromDate).format('DD-MM-YYYY'))
                // // 
                // returnVal = new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf()
                // if (!returnVal) return false
            }

            if (selectToDate) {
                setToDate(moment(selectToDate).format('DD-MM-YYYY'))
                // returnVal = new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf()
                // if (!returnVal) return false
            }

        
// console.log("route",route);
            if (fromDate) {
                // console.log("inside true fetch");
                setCanFetch(true)
                isLoading.current = true;
            }

        setFilteredTripCollection({data:"data"})
    }, [transaction])

    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
        setCanFetch(false)
        isLoading.current = false;

    }, [])

    const generateExportCsvData = useCallback(() => {
      
        // console.log("canFetch",canFetch);
        if (!isLoading.current) return toast.warning('Nothing to export!')
    
        const reportNameHeader = 'Bus Transfer Report' + `\r\n\n`
        const networkOperator = 'Network Area:, EMPTY' + '\r\n'
        const networkArea = `Network Operator:, ${user.agency.name}\r\n`
        const datesGeneratedFor = fromDate && toDate ? `Reporting Period:, ${fromDate} - ${toDate}\r\n` : ""
        const dateGenerated = `Generated At:, ${moment().format("DD/MM/YYYY")}\r\n`
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}\r\n\n`
        // nst header = "Route ID, Route Name, IB/OB,Trip No., Trip ID,Service Date, Start Point, RPH No., Bus Plate Number, Bus Age,Charge/KM, Driver ID, Bus Stops Travel,Travel(KM), Total Claim,Travel(KM) GPS, Total Claim GPS, Status,status of the trip (duplicate/trip outside schedule/no gps tracking/breakdown/replacement),KM as per BOP, Claim as per BOP, Start Point, Service Start Time, Actual Start Time, Sales Start Time, Service End Time, Actual End Time, Sales End Time, Punctuality,Passengers Boarding Count, Total Sales Amount(MYR),Total On, Transfer Count, Monthly pass,Adult,Child, Senior,Student,OKU, JKM,MAIM,Remark,Status(JustNaik),\r\n"
        let data = reportNameHeader + networkArea + networkOperator + datesGeneratedFor + dateGenerated + generatedBy
        // 
        // new start
        data += `Route No.,OD,Date,Trip No.,Bus Plate Number,Type,Bus Stop Travel,Total Transfer,Total On,Monthly Pass,Adult,Child,Senior,Student,OKU,JKM,MAIM`
    
        var BOM = "\uFEFF";
        var csvContent = BOM + data;
        exportData(csvContent, `Bus Transfer Report-${moment(fromDate,"DD-MM-YYYY").format('MMMYYYY')}-${user.agency.name}.csv`, 'text/csv;charset=utf-8;')

    }, [fromDate,toDate])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Bus Transfer Report</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        true ?
                            <div>
                                {
                                    routesArr ?
                                        <TransactionQuery routeOpt={routesArr.map(({ shortName }) => shortName)} onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData}  agencyNameOpt={[user?.agency?.name]} user ={user}/>
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' > <InfoCircle /> Claim Details Report only shows data for the past 1 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={claimData} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default BusTransferReportPage

// change
// change