import ToggleAgencyPage from "./ToggleAgencyPage";
import {requestUserLogout,requestUpdateUserProfile} from "../../redux/actions";

import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {requestUserLogout,requestUpdateUserProfile}
)(ToggleAgencyPage)