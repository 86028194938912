import React, { useState, useMemo, useCallback } from 'react'
import _ from 'lodash'
import { ContentWrapper, Table, Modal, SimpleInputGroup } from '../../components'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS, Utils } from '../../config'
import { Button, Form } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'

const HistoryLogPage = () => {
    const [data, setData] = useState({ gpsLogKeys: [], trips: [] })
    const [startEpoch, setStartEpoch] = useState(null)
    const [endEpoch, setEndEpoch] = useState(null)
    const [loading, setLoading] = useState(false)

    const tableHeader = useMemo(() => [
        {
            Header: 'Trip ID',
            accessor: 'id',
            // disableFilters: true
        },
        {
            Header: 'Driver ID',
            accessor: 'driverId',
            //disableFilters: true
        },
        {
            Header: 'Agency ID',
            accessor: 'agencyId',
            //disableFilters: true
        },
        {
            Header: 'Vehicle ID',
            accessor: 'vehicleId',
            //disableFilters: true
        },
        {
            Header: 'Route ID',
            accessor: 'routeId',
            //disableFilters: true
        }, {
            Header: 'Scheduled Start Time',
            accessor: 'scheduled_start',
            // disableFilters: true
        }, {
            Header: 'Scheduled End Time',
            accessor: 'scheduled_end',
            // disableFilters: true
        },
        {
            Header: 'Start Time',
            accessor: 'start_time',
            //disableFilters: true
        },
        {
            Header: 'End Time',
            accessor: 'end_time',
            // disableFilters: true
        },
        {
            Header: 'Is Log Available',
            accessor: 'isLogKeyAvailable',
            // disableFilters: true
        },
        {
            Header: 'Is Log Empty',
            accessor: 'is_log_empty',
            // disableFilters: true
        },
        {
            Header: 'File Size',
            accessor: '_filesize',
            // disableFilters: true
        },
        {
            Header: 'Log Key',
            accessor: 'Key',
            // disableFilters: true
        }
    ], [])

    const getTripsWithLogsList = useCallback(async () => {
        setLoading(true)
        const a = await ApiRequest.fetch({
            method: 'get',
            url: API_END_POINTS.CHECK_GPS_LOG,
            params: {
                start_epoch_s: new Date(startEpoch).getTime() / 1000,
                end_epoch_s: new Date(endEpoch).getTime() / 1000
            }
        })
        setData(a)
        setLoading(false)
    }, [startEpoch, endEpoch])

    const handleSubmit = (e) => {
        e.preventDefault()
        getTripsWithLogsList()
    }

    const tableData = useMemo(() => {
        const { gpsLogKeys = [], trips = [] } = data
        if (trips?.length > 0) {
            return trips.map((t) => {
                const logKey = gpsLogKeys?.find((k) => k?.Key && `TRIP_GPS_LOG_${t.id}.csv` === k.Key) || 'N/A'
                t['start_time'] = t.startedAt && moment(t.startedAt).format('YYYY-MM-DD HH:mm')
                t['end_time'] = t.endedAt && moment(t.endedAt).format('YYYY-MM-DD HH:mm')
                t['scheduled_start'] = t.scheduledAt && moment(t.scheduledAt).format('YYYY-MM-DD HH:mm')
                t['scheduled_end'] = t.scheduledEndTime && moment(t.scheduledEndTime).format('YYYY-MM-DD HH:mm')
                t['is_log_empty'] = logKey?.Size > 0 ? 'FALSE' : 'TRUE'
                t['_filesize'] = Utils.humanFileSize(logKey?.Size || 0)
                return { ...t, ...logKey, isLogKeyAvailable: logKey !== 'N/A' ? 'TRUE' : 'FALSE' }
            })
        }
        return []
    }, [data])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Check Trip Logs</h2>
            <Form onSubmit={handleSubmit} >
                <SimpleInputGroup preappendText='Start Date' >
                    <Form.Control isInvalid={new Date(startEpoch).getTime() >= new Date(endEpoch).getTime()} required id='date-start' onChange={(e) => setStartEpoch(e.currentTarget.value)} type='datetime-local' />
                </SimpleInputGroup>
                <SimpleInputGroup preappendText='End Date' >
                    <Form.Control isInvalid={new Date(endEpoch).getTime() <= new Date(startEpoch).getTime()} required id='date-end' onChange={(e) => setEndEpoch(e.currentTarget.value)} type='datetime-local' />
                </SimpleInputGroup>
                <Button type='submit' >Search</Button>
            </Form>
            {
                tableData.length &&
                <Table numbering columns={tableHeader} data={tableData} />
            }
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default HistoryLogPage