import moment from "moment"
import { useEffect } from "react"
import { useMemo } from "react"
import { useState } from "react"
import { useCallback } from "react"
import { Button } from "react-bootstrap"
import { useHistory, useParams } from "react-router-dom"
import { ContentWrapper, LoadingModal, Table } from "../components"
import { API_END_POINTS } from "../config"
import { ApiRequest } from "../helpers"

const VehicleTripsPage = () => {
    const { vehicleId } = useParams()
    const [trips, setTrips] = useState([])
    const [loading, setLoading] = useState(false)

    const goTo = useHistory()

    const fetchVehicleTrips = useCallback(async () => {
        setLoading(true)
        try {
            const data = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.GET_VEHICLE_TRIPS}`,
                params: {
                    vehicleId
                }
            })
            setTrips(data)
        } finally {
            setLoading(false)
        }
    }, [vehicleId])

    useEffect(() => {
        if (vehicleId) {
            fetchVehicleTrips()
        }
    }, [vehicleId])

    const tableHeader = useMemo(() => [
        {
            Header: 'Trip ID',
            accessor: 'id',
            // disableFilters: true
        },
        {
            Header: 'Plate No.',
            accessor: 'vehicleRegistrationNumber',
            // disableFilters: true
        },
        {
            Header: 'Route Name',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'Direction',
            accessor: 'direction',
            // disableFilters: true
        },
        {
            Header: 'Driver',
            accessor: 'driverName',
            //disableFilters: true
        },
        {
            Header: 'Start Time',
            accessor: 'start_time',
            //disableFilters: true
        },
        {
            Header: 'End Time',
            accessor: 'end_time',
            // disableFilters: true
        },
        {
            Header: 'Last Odometer Reading',
            accessor: 'endOdometer',
            // disableFilters: true
        }
    ], [])

    const tripData = useMemo(() => {
        trips.forEach((trip) => {
            trip['start_time'] = moment(trip?.startedAt).format('YYYY-MM-DD HH:mm:ss')
            trip['end_time'] = moment(trip?.endedAt).format('YYYY-MM-DD HH:mm:ss')
            trip['direction'] = trip?.directionId == 0 ? 'Loop' : trip?.directionId == 1 ? 'Outbound' : 'Inbound';
        });
        
        return trips;
    }, [trips])

    return (
        <ContentWrapper>
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <h2 className='mb-3' >Vehicle Trips Record</h2>
            {
                tripData.length > 0 &&
                <Table numbering columns={tableHeader} data={tripData} />
            }
            <LoadingModal loading={loading} />
        </ContentWrapper>
    )
}

export default VehicleTripsPage