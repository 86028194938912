import React, { useEffect, useMemo, useState } from 'react'
import { useFilters, usePagination, useTable } from 'react-table'
import { Button, Card, Col, Form, Row, Table as BTable } from 'react-bootstrap'
import { ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
        <Form.Control
            size='sm'
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`search`}
        />
    )
}

const ZoneTable = ({ columns, data, title, numbering, titleLeft }) => {
    const [displayPagination, setDisplayPagination] = useState(false)

    useEffect(() => {
        if (data?.length > 10) {
            setDisplayPagination(true)
        }
    }, [data])

    const filterTypes = useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            initialState: { pageIndex: 0 },
        },
        useFilters,
        usePagination,
    )

    return (
        <Card className='mt-3' >
            <Card.Body>
                {
                    title &&
                    <div className='d-flex align-items-center' >
                        <Card.Title className='flex-grow-1' >{title}</Card.Title>
                        <Card.Title>{titleLeft}</Card.Title>
                    </div>
                }
                <BTable responsive striped bordered hover size='sm' {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    numbering &&
                                    <th>No.</th>
                                }
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}

                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}
                                    className = { row.original.isStopInBetween  || row.original.isStopSelected ? 'bg-info' : '' } 
                                    style={{ color: row.original.isStopInserted ? '#CCCCCC' : 'inherit' }}
                                    >
                                    {
                                        numbering &&
                                        <td>{i + 1}</td>
                                    }
                                    {row.cells.map(cell => {
                                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </BTable>
            </Card.Body>
            {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            {
                displayPagination &&
                <Card.Footer>
                    <Row >
                        <Col className='my-1' sm={4} xs={12}>
                            <Form.Control
                                className='w-100'
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </Col>
                        <Col className='text-center my-1' sm={4} xs={12} >
                            <Button size='sm' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <ChevronDoubleLeft />
                            </Button>{' '}
                            <Button size='sm' onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <ChevronLeft />
                            </Button>{' '}
                            <Button size='sm' onClick={() => nextPage()} disabled={!canNextPage}>
                                <ChevronRight />
                            </Button>{' '}
                            <Button size='sm' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <ChevronDoubleRight />
                            </Button>{' '}
                        </Col>
                        <Col className='my-1' sm={4} xs={12} >
                            <Form.Control
                                as='select'
                                custom
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Row>
                    <span>
                        Page{' '}
                        <strong>
                            {pageIndex + 1} of {pageOptions.length}
                        </strong>{' '}
                    </span>
                </Card.Footer>
            }
        </Card>
    )
}

export default ZoneTable;