import React from 'react'
import { Card, Col } from 'react-bootstrap'

const StatCard = ({ data, text, cardClassname, bgColor, ...rest }) => (
    <Col {...rest} >
        <Card style={{ backgroundColor: bgColor }} className={`${bgColor ? '' : 'bg-primary'} text-light mt-3 ${cardClassname}`}>
            <Card.Body>
                <h1 className='mb-0 font-weight-bold' >{data}</h1>
                <Card.Text style={{ fontFamily: 'monospace' }} >{text}</Card.Text>
            </Card.Body>
        </Card>
    </Col>
)

export default StatCard