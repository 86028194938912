import React from 'react'
import { Alert } from 'react-bootstrap'
import { InfoCircle, PlusCircle } from 'react-bootstrap-icons'

const NoDataInfo = ({ children }) => (
    <Alert variant='info' className='mt-3' >
        <Alert.Heading><InfoCircle /></Alert.Heading>
        {children}
    </Alert>
)

export default NoDataInfo