import { connect } from "react-redux";
import ProfilePage from "./ProfilePage";
import { requestUpdateUserProfile } from "../../redux/actions";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    { requestUpdateUserProfile }
)(ProfilePage)