import { useState } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { ContentWrapper } from '../../components'
import Ridership from './chartsComponent/Ridership'
import RidershipHeatmap from './chartsComponent/RidershipHeatmap'
import ServiceFrequency from './chartsComponent/ServiceFrequency'

const TABS = [
    {
        eventKey: 'heatmap',
        title: 'Ridership Heatmap',
        content: <RidershipHeatmap />
    },
    {
        eventKey: 'ridership',
        title: 'Ridership',
        content: <Ridership />
    },
    {
        eventKey: 'service-frequency',
        title: 'Service Frequency',
        content: <ServiceFrequency />
    }
]

const AnalyticsPage = () => {
    const [key, setKey] = useState('heatmap');
    return (
        <ContentWrapper>
            <h2 className='mb-3' >Analytics</h2>
            <Tabs
                id="analytics-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                unmountOnExit={true}
    mountOnEnter={true}
    transition={false}
            >
                {
                    TABS.map(({ eventKey, title, content }) => (
                        <Tab key={eventKey} eventKey={eventKey} title={title}>
                            <Card className='mt-3 pb-4' >
                                <Card.Body>
                                    {content}
                                </Card.Body>
                            </Card>
                        </Tab>

                    ))
                }
            </Tabs>
        </ContentWrapper>
    )
}

export default AnalyticsPage