import DriverCollectionPage from "./DriverCollectionPage";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {}
)(DriverCollectionPage)