import React, { useEffect, useState } from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { BellFill, PersonSquare, People } from 'react-bootstrap-icons'
import { Link, useHistory } from 'react-router-dom';
// import { async } from 'regenerator-runtime';
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import './Appbar.css'


const Appbar = ({ user, requestUpdateUserProfile }) => {
    const [agencies, setAgencies] = useState()
    const { agencyId,userType } = user;
 
    const navigation = useHistory()
    const handleLogout = () => {
        navigation.push('/logout')
    }
    const GetAllAgency = () => {
        // 
        if(userType === 'SUPER_ADMIN' ){
    
            // 
            // 
            if(global.store.getState().user){
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.AGENCY_ALL}`
        }).then((data) => {
            setAgencies(data)
            // 
        }).catch(e => { })
    }
}
    }
    useEffect(() => {
        if(userType === 'SUPER_ADMIN' ){
        GetAllAgency()}
    },[user])

const handleAgencyUpdate = async(id) =>{
    // 
    
    const editedData = {}
    editedData.newId = id
    if (Object.keys(editedData).length > 0) {
      await  requestUpdateUserProfile(editedData)
    }
    setTimeout(() => {
        // if(userType === 'SUPER_ADMIN' ){
        //       requestUpdateUserProfile(editedData)
        //     }
      if( String(window.location.href).split('/')[3] != 'profile'){

        
        window.location.reload()}
    }, 2500);


}
const [url, setUrl] = useState(null);

useEffect(() => {
  let url = String(window.location.href).split('/')
  setUrl(url[3])
}, [window.location.href]);

  

    return (
        <Navbar className="justify-content-end" sticky='top' bg='primary' variant='dark' expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="align-items-end pr-2" >
                    <NavDropdown alignRight title={<BellFill size={20} />} className='text-right w-100'>
                        <NavDropdown.Item >Notification</NavDropdown.Item>
                    </NavDropdown>
                    { userType == 'SUPER_ADMIN' && url!= 'agency' &&
                    <NavDropdown alignRight title={<People size={20} />} className='text-right w-100'>
                    {
                         agencies &&   agencies.map((data) =>
    <NavDropdown.Item key={data.id} className={agencyId == data.id ? 'bg-primary text-light': ''} onClick={()=>handleAgencyUpdate(data.id)}>{data.name} </NavDropdown.Item>
    )
                        }
                    </NavDropdown>
                    }
                    <NavDropdown alignRight title={<PersonSquare size={20} />} className='text-right w-100'>
                      {userType != 'APAD' &&  <NavDropdown.Item as={Link} to='/profile' >Profile</NavDropdown.Item>}
                        <NavDropdown.Item >Settings</NavDropdown.Item>
        
                        <NavDropdown.Divider />
                        <NavDropdown.Item className='bg-danger text-light' onClick={handleLogout} >Sign Out</NavDropdown.Item>
                    </NavDropdown>
                   
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Appbar