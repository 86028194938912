import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'
import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'
import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'



const TransactionQuery = ({ onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(moment().startOf('month').format("YYYY-MM-DD 00:00:00"))
    const [selectToDate, setToDate] = useState(moment().endOf('month').format("YYYY-MM-DD 23:59:59"))
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }

        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
    }

    const handleClear = () => {
        document.getElementById('date-from').value = ''
        setFromDate(moment().startOf('month').format("YYYY-MM-DD 00:00:00"))
        setToDate(moment().endOf('month').format("YYYY-MM-DD 23:59:59"))
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        onClearQuery()
    }
    const setperiod = async (e) => {
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)

    }

    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText='Month:'>
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM')} onChange={setperiod
                                        } type='month' />
                                    </SimpleInputGroup>
                                </Col>

                            </Row>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const TotalKmPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)
    const handleGetTransactionHistory = async () => {
        setLoading(true)
        try {

            const TravelData = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.TRAVEL_COLLECTION}`,
                params: {
                    timestamp: new Date()
                }
            })


            setTransaction(TravelData)

        } catch (error) { }
        finally {
            setLoading(false)
        }
    }


    useEffect(() => {
        handleGetTransactionHistory()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Vehicle',
            accessor: 'Vehicle_no',
            // disableFilters: true
        },
        {
            Header: 'Total KM',
            accessor: 'totalTravelKMCount_',
            // disableFilters: true
        },



    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = filteredTripCollection
        if (!mainData) return []

        // console.log("mainData", mainData);
        Object.entries(mainData).forEach(([vehicleInfo, trxs]) => {
            const accumulativeTrip = {

                'totalTravelCount_': 0,

            }
            trxs.map((row, index) => {
                // 
                // 

                if (row.obIb == 2) {
                    if (row.trip_mileage > 0) {
                        accumulativeTrip['totalTravelCount_'] += +row.trip_mileage

                    } else {
                        accumulativeTrip['totalTravelCount_'] += +row.km_inbound

                    }
                }
                if (row.obIb == 1) {
                    if (row.trip_mileage > 0) {
                        accumulativeTrip['totalTravelCount_'] += +row.trip_mileage

                    } else {
                        accumulativeTrip['totalTravelCount_'] += +row.km_outbound
                    }
                }
                if (row.obIb == 0) {
                    if (row.trip_mileage > 0) {
                        accumulativeTrip['totalTravelCount_'] += +row.trip_mileage

                    } else {
                        accumulativeTrip['totalTravelCount_'] += +row.km_loop
                    }
                }
            })

            accumulativeTrip['totalTravelKMCount_'] = (accumulativeTrip['totalTravelCount_']).toFixed(2)
            accumulativeTrip['Vehicle_no'] = vehicleInfo
            returnData.push(accumulativeTrip)
        })
        return returnData
    }, [tripCollection, filteredTripCollection])


    const handleFilterTransactionData = useCallback(({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        if (!transaction) return []
        const transactionF = transaction.filter(({ startedAt }) => startedAt !== null);

        const filtered = transactionF.filter(({ startedAt, scheduledAt, routeShortName, driverName, vehicleRegistrationNumber, userId }) => {
            let returnVal = true


            if (selectFromDate) {

                returnVal = new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                returnVal = new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }
            return true
        })


        const groupedData = _.groupBy(filtered, 'vehicleRegistrationNumber')
        setFilteredTripCollection(groupedData)
    }, [transaction])



    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
        handleGetTransactionHistory()
    }, [])

    const generateExportCsvData = useCallback(() => {
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')
        let monthName
        const reportNameHeader = 'Total KM Report' + `\r\n\n`

        const networkArea = `Network Operator:, ${user.agency.name}\r\n`
        const dateGenerated = `Report Month:, ${moment(Object.values(filteredTripCollection)[0][0].startedAt).endOf("month").format("YYYY-MM")}\r\n`
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}\r\n\n`

        const mainData = filteredTripCollection
        let strartFrom = moment(Object.values(mainData)[0][0].scheduledAt).startOf('month').format("YYYY-DD-MM");
        let endTo = moment(Object.values(mainData)[0][0].scheduledAt).endOf("month").format("YYYY-DD-MM")
        monthName = moment(Object.values(mainData)[0][0].scheduledAt).endOf("month").format("YYYY-MM")


        let data = reportNameHeader + networkArea + dateGenerated + generatedBy + '\r\n\n';
        if (!mainData) return []
        //
        data += `No.,Vehicle Registration No. ,Total KM\r\n`
        let totalGrandKm = 0
        Object.entries(mainData).forEach(([vehicleInfo, trxs], index) => {
            const accumulativeTrip = {

                'totalTravelCount_': 0,

            }
            trxs.map((row, index) => {

                if (row.obIb == 2) {
                    accumulativeTrip['totalTravelCount_'] += +row.km_inbound
                }
                if (row.obIb == 1) {
                    accumulativeTrip['totalTravelCount_'] += +row.km_outbound
                }
                if (row.obIb == 0) {
                    accumulativeTrip['totalTravelCount_'] += +row.km_loop
                }

            })

            accumulativeTrip['totalTravelKMCount_'] = (accumulativeTrip['totalTravelCount_']).toFixed(2)
            totalGrandKm += Number(accumulativeTrip['totalTravelKMCount_'])
            accumulativeTrip['Vehicle_no'] = vehicleInfo
            data += `${index + 1},${accumulativeTrip['Vehicle_no']} ,${accumulativeTrip['totalTravelKMCount_']}\r\n`
        })


        data += `\nGrand Total(KM),,${totalGrandKm}\r\n`

        var BOM = "\uFEFF";
        var csvContent = BOM + data;
        exportData(csvContent, `Total KM Collection ${moment().format('YYYYMMDD_HHmmss')}.csv`, 'text/csv;charset=utf-8;')
    }, [tabulated])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Total KM Report</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                        transaction && transaction.length > 0 ?
                            <div>

                                <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} agencyNameOpt={[user?.agency?.name]} />

                                <Alert className='mt-3' variant='info' > <InfoCircle /> Travel KM Report only shows data for the past 3 months - Contact <a href="mailto: info@justnaik.com" >info@justnaik.com</a> for more</Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={tabulated} />
                            </div>
                            :
                            <JustnaikAppIntro />
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default TotalKmPage