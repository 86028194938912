import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SimpleInputGroup } from '../../components'
import { GENERAL } from '../../config'

const SimpleInput = ({ label, value, readOnly = null, ...props }) => (
    <SimpleInputGroup preappendText={label} >
        <Form.Control size='sm' readOnly={readOnly} value={value} {...props} />
    </SimpleInputGroup>
)

const ProfilePage = ({ user, requestUpdateUserProfile }) => {
    const [editedFirstName, setEditedFirstName] = useState(null)
    const [editedLastName, setEditedLastName] = useState(null)

    const handleCloseEdit = () => {
        setEditedFirstName(null)
        setEditedLastName(null)
    }

    const handleUpdateProfile = (e) => {
        e.preventDefault()
        const editedData = {}
        if (editedFirstName) { editedData.firstName = editedFirstName }
        if (editedLastName) { editedData.firstName = editedLastName }

        if (Object.keys(editedData).length > 0) {
            requestUpdateUserProfile(editedData)
        }
        handleCloseEdit()
    }

    return (
        <Container className='mt-5' >
            <h2 >Profile</h2>
            <Row>
                <Col sx={12} >
                    <Card className='mt-3' >
                        <Card.Body>
                            <Card.Title>Hello {user.firstName} {user.lastName}</Card.Title>
                            <Form onSubmit={handleUpdateProfile} >
                                <Form.Row>
                                    <Col >
                                        <SimpleInput onChange={e => setEditedFirstName(e.target.value)} label='First Name' value={editedFirstName || user.firstName} />
                                    </Col>
                                    <Col >
                                        <SimpleInput onChange={e => setEditedLastName(e.target.value)} label='Last Name' value={editedLastName || user.lastName} />
                                    </Col>
                                </Form.Row>
                                <Form.Row className='mt-3' >
                                    <Col >
                                        <SimpleInput label='Email' readOnly value={user.email} />
                                    </Col>
                                    <Col >
                                        <SimpleInput label='Role' readOnly value={user.userType} />
                                    </Col>
                                </Form.Row>
                                {
                                    (editedFirstName || editedLastName) &&
                                    <Card.Footer className='text-right' >
                                        <Button variant='warning' className='mr-3' onClick={handleCloseEdit} >Cancel</Button>
                                        <Button type='submit' variant='primary' >Update</Button>
                                    </Card.Footer>
                                }
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                {
                    user.agency !== undefined &&
                    <Col xs={12} >
                        <Card className='mt-3' >
                            <Card.Body>
                                <Card.Title className='d-flex justify-content-between align-items-center' >Agency Profile {user.isAdmin && <Link to='/agency' ><Button>Edit</Button></Link>}</Card.Title>
                                {
                                    user?.agency?.imageUrl &&
                                    <img width={150} src={GENERAL.IS_PRODUCTION ? user.agency.imageUrl : `${GENERAL.AGENCY_PHOTO_LOCAL_BASE_URL}/${user.agency.imageUrl}`} alt='basic-agency-profile' />
                                }
                                <Form>
                                    <SimpleInput label='Agency Name' readOnly value={user.agency.name} />
                                    <SimpleInput
                    label="Fasspay Developer ID"
                    readOnly
                    value={user.agency.fasspayDeveloperId}
                  />
                                    <SimpleInput label='Estimated Fleet Size' readOnly value={user.agency.fleetSize} />
                                    <SimpleInput label='Phone Number' readOnly value={user.agency.phoneNumber} />
                                    <SimpleInput label='Support Hotline' readOnly value={user.agency.supportHotline} />
                                    <SimpleInput label='Support Email' readOnly value={user.agency.supportEmail} />
                                    <SimpleInput label='Subscription' readOnly value={user.agency.maxVehicleCount > 1 ? "Custom Subscription" : "Free"} />
                                    <SimpleInput readOnly label="Agency Vehicle Limit" value={user.agency.maxVehicleCount} />
                                    <SimpleInput readOnly label="Agency Driver Limit" value={user.agency.maxDriverCount} />
                                    <SimpleInput readOnly label="Agency Route Limit" value={user.agency.maxRouteCount} />
                                    <SimpleInput readOnly label="Agency Trip Limit" value={user.agency.maxTripCount} />
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
        </Container>
    )
}

export default ProfilePage