import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'
import calculate from 'calculate-coordinates'

import { ContentWrapper, JustnaikAppIntro, Table, PreviewMapModal, Modal, SimpleInputGroup } from '../../components'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS } from '../../config'

import './HistoryLogPage.css'
import { ClipboardData } from 'react-bootstrap-icons'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { CircularProgress } from '@material-ui/core'

const calculateTravelledDistance = (logs) => {
    let totalDistance = 0
    logs.forEach(({ latitude, longitude }, i) => {
        if (i > 0 && i < logs.length - 1) {
            const prev = [logs[i - 1].latitude, logs[i - 1].longitude]
            const curr = [latitude, longitude]
            totalDistance += calculate.fromCoordinatesReturningKM(prev, curr)
        }
    })
    return totalDistance
}

const HistoryLogQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], agencyNameOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [direction, setDirection] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [selectDate, setSelectDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [agency, setAgency] = useState(null)

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        const query = { route, amPm, selectDate, vehicle, driver, agency ,direction}

        if (!route || route === 'null') { query['route'] = null }
        if (!direction || direction === 'null') { query['direction'] = null }
        if (!selectDate || selectDate === 'null') { query['selectDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        if (!agency || agency === 'null') { query['agency'] = null }
        onSubmitQuery(query)
        // 
    }

    const handleClear = () => {
        document.getElementById('date').value = ''
        setSelectDate(null)
        setRoute(null)
        setDirection(null)
        setAmPm('All')
        setVehicle(null)
        setDriver(null)
        setAgency(null)
        onClearQuery()
    }

    return (
        <Card>
            <Card.Body >
                <h5>Search log</h5>
                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >
                            <SimpleInputGroup preappendText='Date' >
                                <Form.Control id='date' onChange={(e) => setSelectDate(e.currentTarget.value ? `${e.currentTarget.value} 00:00:00` : e.currentTarget.value)} type='date' />
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText="AM/PM" >
                                <Form.Control value={amPm} onChange={(e) => setAmPm(e.currentTarget.value)} custom as='select' >
                                    <option>All</option>
                                    <option>AM</option>
                                    <option>PM</option>
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt.split("+")[0]} >{`${opt.split("+")[1]} ${opt.split("+")[0]}`}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Direction' >
                                <Form.Control value={direction || 'null'} onChange={(e) => setDirection(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    <option key={1} value={1} >Outbound</option>
                                    <option key={2} value={2} >Inbound</option>
                                    <option key={3} value={0} >Loop</option>
                                    {/* {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)} */}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Vehicle' >
                                <Form.Control value={vehicle || 'null'} onChange={(e) => setVehicle(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {vehicleOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Driver' >
                                <Form.Control value={driver || 'null'} onChange={(e) => setDriver(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {driverOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <SimpleInputGroup preappendText='Agency Name' >
                                <Form.Control value={agency || 'null'} onChange={(e) => setAgency(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {agencyNameOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup>
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const HistoryLogPage = ({ user }) => {
    const [agencyTrips, setAgencyTrips] = useState(null)
    const [routes, setRoutes] = useState(null)
    const [routesArr, setRoutesArr] = useState(null)
    const [selectedTripIndex, setSelectedTripIndex] = useState(null)
    const [showLogModal, setShowLogModal] = useState(false)
    const [filteredTripData, setFilteredTripData] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleGetAgencyTrips = () => {
        setLoading(true)
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.AGENCY_TRIP}`
        }).then(data => {
            // 
            setAgencyTrips(data)
        }).catch(e => { })
            .finally(() => {
                setLoading(false)
            })
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then((data) => {
            setRoutesArr(data)
            const routeObjById = _.groupBy(data, 'id')
            setRoutes(routeObjById)
            // 
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetAgencyTrips()
        handleGetAllRoute()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Trip ID',
            accessor: 'id',
            // disableFilters: true
        },
        {
            Header: 'Direction',
            accessor: 'directionName',
            // disableFilters: true
        },
        {
            Header: 'Driver',
            accessor: 'driverName',
            //disableFilters: true
        },
        {
            Header: 'Adhoc / Scheduled',
            accessor: 'adhoc_scheduled',
            //disableFilters: true
        },
        {
            Header: 'Start Time',
            accessor: 'start_time',
            //disableFilters: true
        },
        {
            Header: 'End Time',
            accessor: 'end_time',
            // disableFilters: true
        },
        {
            Header: 'Trip Duration (HH:MM:SS)',
            accessor: 'tripDuration',
            disableFilters: true
        },
        // {
        //     Header: 'Mileage (KM)',
        //     accessor: 'mileage',
        //     // disableFilters: true
        // },
        {
            Header: 'Last Odometer Reading',
            accessor: 'endOdometer',
            // disableFilters: true
        },
        {
            Header: 'Ridership',
            accessor: '_totalRidership',
            // disableFilters: true
        },
        // {
        //     Header: 'Avg. Speed (Km/h)',
        //     accessor: 'average_speed',
        //     // disableFilters: true
        // },
        {
            Header: 'Plate No.',
            accessor: 'vehicleRegistrationNumber',
            // disableFilters: true
        },
        // {
        //     Header: 'Compliance (%)',
        //     accessor: 'compliance_',
        //     // disableFilters: true
        // },
        {
            Header: 'Route Name',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'Logs',
            accessor: 'logs_',
            disableFilters: true
        }
    ], [])

    const handleLogButton = (index) => {
        setSelectedTripIndex(index)
        setShowLogModal(true)
    }

    const tripData = useMemo(() => {
        if (!agencyTrips || !routes) return []
        const agencyTripsCopy = _.orderBy((filteredTripData || agencyTrips), ['startedAt'], ['desc'])
        agencyTripsCopy.forEach((trip, index) => {
            // const aSpeed = trip.logs?.reduce((sum, { speed }) => sum + +speed, 0) / trip.logs?.length || 0
            // const visitedStops = trip.logs?.filter(({ stopId }) => (stopId && stopId !== "null"))
            // const totalwaypoint = routes[trip.routeId]?.length > 0 ? routes[trip.routeId][0]?.waypoints.length : null
            // const comliancePerc = totalwaypoint ? (visitedStops.length / totalwaypoint) : 0
            trip['start_time'] = moment(trip.startedAt).format('YYYY-MM-DD HH:mm')
            trip['end_time'] = moment(trip.endedAt).format('YYYY-MM-DD HH:mm')
            trip['tripDuration'] = moment.utc(moment(trip.endedAt).diff(moment(trip.startedAt))).format("HH:mm:ss")

            trip['adhoc_scheduled'] = (trip.startedAt && !trip.scheduledAt) ? 'Ad-hoc' : 'Scheduled';

            trip['directionName'] = trip.obIb === 0 ? 'Loop' : trip.obIb === 1 ? 'Outbound' : 'Inbound'
            // trip['mileage'] = calculateTravelledDistance(trip.logs).toFixed(2)
            // trip['average_speed'] = aSpeed.toFixed(2)
            // trip['compliance_'] = (comliancePerc * 100).toFixed(2)
            trip['agencyName'] = user?.agency?.name
            trip['_totalRidership'] = Number(trip.totalNoOfPax) + Number(trip.totalNoOfSenior) + Number(trip.totalNoOfChild) + Number(trip.totalNoOfOku) + Number(trip.totalNoOfForeignAdult) + Number(trip.totalNoOfForeignChild);
            trip['logs_'] = <ClipboardData onClick={() => handleLogButton(index)} className={'text-primary g-hover-pointer'} />
        })
        return agencyTripsCopy
    }, [agencyTrips, filteredTripData, routes])

    const handleCloseModal = () => {
        setSelectedTripIndex(null)
        setShowLogModal(false)
    }

    const handleFilterTripData = ({ route, amPm, selectDate, vehicle, driver, agency, direction }) => {
        if (!agencyTrips) return []
        const filtered = agencyTrips.filter(({ createdAt,startedAt, routeName, driverName, agencyName, vehicleRegistrationNumber,obIb }) => {
           let returnVal = true
            if (amPm !== 'All') {
                returnVal = String(moment(startedAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
                if (!returnVal) return false
            }

            if (selectDate) {
                returnVal = String(moment(startedAt).format('DDMMYY')) === String(moment(selectDate).format('DDMMYY'))
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeName === route
                if (!returnVal) return false
            }
            if (direction) {
                returnVal = direction == obIb
                if (!returnVal) return false
            }

            if (vehicle) {
                returnVal = vehicleRegistrationNumber === vehicle
                if (!returnVal) return false
            }

            if (driver) {
                returnVal = driverName === driver
                if (!returnVal) return false
            }

            if (agency) {
                returnVal = agencyName === agency
                if (!returnVal) return false
            }

            return true
        })
        setFilteredTripData(filtered)
    }

    const handleClearQuery = () => {
        setFilteredTripData(null)
    }

    const [driverList, vehicleList] = useMemo(() => {
        if (!agencyTrips) return []
        const drivers = []
        const vehicles = []
        const agencyTripsCopy = agencyTrips.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        agencyTripsCopy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [agencyTrips])

    return (
        <ContentWrapper >
            <h2 className='mb-3' >History Log</h2>
            {


                agencyTrips && agencyTrips.length > 0  && routesArr.length > 0  ?
                    <>
                        <HistoryLogQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTripData} routeOpt={routesArr?.map(({ name ,shortName}) => `${name}+${shortName}`)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />

                        <Table numbering columns={tableHeader} data={tripData} />
                    </>
                    :
                    <JustnaikAppIntro />
            }
            {
                selectedTripIndex !== null && routes[tripData[selectedTripIndex].routeId] &&
                <PreviewMapModal tripId={tripData[selectedTripIndex].id} title={`${tripData[selectedTripIndex].id} ${tripData[selectedTripIndex].start_time} ${tripData[selectedTripIndex].vehicleRegistrationNumber}`} route={routes[tripData[selectedTripIndex].routeId][0]} show={showLogModal} onHide={handleCloseModal} />
            }
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default HistoryLogPage