import { Button } from 'react-bootstrap'
import React, { Component } from 'react'
import QRCode from 'qrcode-svg-table'

// import qrPosterTemplate from '../../assets/qr_poster_template/Just Naik Ads.ai.svg'
import qrPosterTemplate from '../../assets/qr_poster_template/qr_driver_poster.png'

export default class QRDriverPoster extends Component {

    constructor(props) {
        super(props)
        this.agencyName = props.agencyName || 'Agency Name';
        this.agencyLogoSrc = props.agencyLogoSrc || null;
        this.phoneNumber = props.phoneNumber || '+60123456789';
        this.staffId = props.staffId || '';
        this.firstName = props.firstName;
        this.name = props.name || 'John Smith';
        this.qrValue = props.qrValue || 'VEHICLE_CODE_1';
        this.w = 620;
        this.h = 825;
        this.qrSize = this.w * .7;
        this.margin = 90;

        this.state = {
            loading: false
        }
    }

    // Function to wrap text based on max width
    wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let lines = [];

        for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + ' ';
            let metrics = context.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                lines.push(line);
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }

        lines.push(line);

        context.textAlign = 'center';

        for (let i = 0; i < lines.length; i++) {
            context.fillText(lines[i], x, y + (i * lineHeight));
        }
    }

    componentDidMount() {
        const canvas = document.querySelector('#poster-canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        // 
        image.src = qrPosterTemplate

        const phoneNumContent = `Contact: ${this.phoneNumber}`;
        const staffIdContent =  `Staff ID: ${this.staffId}`;

        image.onload = () => {
            canvas.width = this.w
            canvas.height = this.h
            ctx.drawImage(image, 0, 0, this.w, this.h)
            ctx.fillStyle = 'black'
            ctx.font = "bold 25px Arial"

            const maxWidth = this.w - 20; 
            const lineHeight = 40;
            this.wrapText(ctx, this.agencyName, this.w / 2, 40, maxWidth, lineHeight)
            
            ctx.textAlign = 'center'
            ctx.font = "bold 30px Arial"
            ctx.fillText(this.name, this.w / 2, this.h / 2 + this.qrSize - this.margin * 1.8);
            // ctx.textAlign = 'left';
            ctx.font = "25px Arial";
            ctx.fillText(phoneNumContent, this.w / 2, this.h / 2 + this.qrSize - this.margin * 1.3);

            // Draw "Staff ID" line aligned to the left
            ctx.fillText(staffIdContent, this.w / 2, this.h / 2 + this.qrSize - this.margin * 0.9);

            if (this.agencyLogoSrc) {
                const logo = new Image()
                if (process.env.NODE_ENV === 'development') {
                    logo.crossOrigin = "Anonymous"; //since in dev we are using local static server
                }
                logo.src = this.agencyLogoSrc
                logo.onload = () => {
                    ctx.drawImage(logo, this.w - (200 + 10), 0, 200, 90); //logo placement
                }
            }

            const test = new QRCode({
                content: this.qrValue,
                padding: 1
            }).svg()
            const b64 = window.btoa(test)
            const qrImage = new Image()
            qrImage.src = `data:image/svg+xml;base64,${b64}`
            qrImage.onload = () => {
                ctx.font = "40px Arial"
                ctx.drawImage(qrImage, this.w / 2 - this.qrSize / 2, this.h / 2 - this.qrSize / 2 - 35, this.qrSize, this.qrSize);
            }
        }
    }

    downloadPoster() {
        const poster = document.querySelector('#poster-canvas')
        // var dataURL = poster.toDataURL("image/png");
        var dataURL = poster.toDataURL("image/png");

        const temp = document.createElement('a');
        temp.download = `${this.firstName}_${this.staffId}_poster.png`
        temp.href = dataURL
        temp.click()
    }

    render() {
        return (
            <div style={{ height: 'auto' }} className='d-flex flex-column' >
                <canvas id='poster-canvas' className='bg-light' />
                <Button onClick={() => this.downloadPoster()} >Download Poster</Button>
            </div>
        )
    }
}