import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';
import { ACTION_TYPES } from '../../config';
import { updateSupervisor, userAgencyUploadAgencyLogo, userLogin, userSignUp, updateUserProfile, updateAgencyProfile } from '../api';
import {
  userLoginSuccess,
  userLoginFailure,
  userSignUpSuccess,
  userSignUpFailure,
  userLogoutSuccess,
  userLogoutFailure,
  userUploadAgencyLogoSuccess,
  userUploadAgencyLogoFailure,
  updateSupervisorSuccess,
  updateSupervisorFailed,
  updateUserProfileSuccess,
  updateUserProfileFailed,
  updateAgencyProfileSuccess,
  updateAgencyProfileFailed,
} from '../actions';
import { toast } from 'react-toastify'

export function* watchUserRequests() {
  yield takeLatest(ACTION_TYPES.USER_LOGIN_REQUESTED, requestUserLoginG);
  yield takeLatest(ACTION_TYPES.USER_SIGN_UP_REQUESTED, requestUserSignUpG);
  yield takeLatest(ACTION_TYPES.USER_LOGOUT_REQUESTED, requestUserLogoutG);
  yield takeLatest(ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_REQUESTED, requestUserUploadAgencyLogoG);
  yield takeLatest(ACTION_TYPES.UPDATE_SUPERVISOR_REQUESTED, requestUpdateSupervisorG);
  yield takeLatest(ACTION_TYPES.USER_UPDATE_PROFILE_REQUESTED, requestUpdateUserProfileG);
  yield takeLatest(ACTION_TYPES.UPDATE_AGENCY_INFO_REQUESTED, requestUpdateAgencyProfileG);
}

function* requestUserLoginG(action) {
  try {
    const user = yield call(userLogin, action.payload);
    yield put(userLoginSuccess(user));
  } catch (e) {
    yield put(userLoginFailure(e));
  }
}

function* requestUserSignUpG(action) {
  try {
    const user = yield call(userSignUp, action.payload);
    yield put(userSignUpSuccess(user));
  } catch (e) {
    yield put(userSignUpFailure(e));
  }
}

function* requestUserLogoutG(action) {
  try {
    yield put(userLogoutSuccess());
    toast.success(`Successfully logging out!`)
    // window.location = '/signin';
  } catch (e) {
    yield put(userLogoutFailure(e));
  }
}

function* requestUserUploadAgencyLogoG(action) {
  try {
    const agency = yield call(userAgencyUploadAgencyLogo, action.payload);
    yield put(userUploadAgencyLogoSuccess(agency));
    toast.success('Logo Updated')
  } catch (e) {
    yield put(userUploadAgencyLogoFailure(e));
  }
}

function* requestUpdateSupervisorG(action) {
  try {
    const agency = yield call(updateSupervisor, action.payload);
    yield put(updateSupervisorSuccess(agency));
    toast.success('Supervisor info updated!')
  } catch (e) {
    yield put(updateSupervisorFailed(e));
  }
}

function* requestUpdateUserProfileG(action) {
  try {
    const agency = yield call(updateUserProfile, action.payload);
    yield put(updateUserProfileSuccess(agency));
    toast.success('Profile updated!')
  } catch (e) {
    yield put(updateUserProfileFailed(e));
  }
}

function* requestUpdateAgencyProfileG(action) {
  try {
    const agency = yield call(updateAgencyProfile, action.payload);
    yield put(updateAgencyProfileSuccess(agency));
    toast.success('Agency updated!')
  } catch (e) {
    yield put(updateAgencyProfileFailed(e));
  }
}