import React from 'react'
import { InputGroup } from 'react-bootstrap'

const SimpleInputGroup = ({ preappendText, preappendTextClassName, children, labelWidth = 150, style }) => {
    return (
        <InputGroup style={style} size='sm' className='my-2' >
            <InputGroup.Prepend >
                <InputGroup.Text className={preappendTextClassName} style={{ minWidth: labelWidth, alignItems: 'flex-start' }} >{preappendText}</InputGroup.Text>
            </InputGroup.Prepend>
            {children}
        </InputGroup>
    )
}

export default SimpleInputGroup