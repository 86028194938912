import { ACTION_TYPES } from "../../config";

// User Login
export function requestUserLogin(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_REQUESTED,
    payload
  };
}

export function userLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_SUCCEEDED,
    payload
  };
}

export function userLoginFailure(payload) {
  return {
    type: ACTION_TYPES.USER_LOGIN_FAILED,
    payload
  };
}

// User Signup
export function requestUserSignUp(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_REQUESTED,
    payload
  };
}

export function userSignUpSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_SUCCEEDED,
    payload
  };
}

export function userSignUpFailure(payload) {
  return {
    type: ACTION_TYPES.USER_SIGN_UP_FAILED,
    payload
  };
}

// User Logout
export function requestUserLogout(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_REQUESTED,
    payload
  };
}

export function userLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_SUCCEEDED,
    payload
  };
}

export function userLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.USER_LOGOUT_FAILED,
    payload
  };
}

// upload agency photo
export function requestUserUploadAgencyLogo(payload) {
  return {
    type: ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_REQUESTED,
    payload
  };
}

export function userUploadAgencyLogoSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_SUCCEEDED,
    payload
  };
}

export function userUploadAgencyLogoFailure(payload) {
  return {
    type: ACTION_TYPES.USER_UPLOAD_AGENCY_PHOTO_FAILED,
    payload
  };
}

// update supervisor info
export function requestUpdateSupervisor(payload) {
  return {
    type: ACTION_TYPES.UPDATE_SUPERVISOR_REQUESTED,
    payload
  };
}

export function updateSupervisorSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_SUPERVISOR_SUCCEEDED,
    payload
  };
}

export function updateSupervisorFailed(payload) {
  return {
    type: ACTION_TYPES.UPDATE_SUPERVISOR_FAILED,
    payload
  };
}

// update user info
export function requestUpdateUserProfile(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_PROFILE_REQUESTED,
    payload
  };
}

export function updateUserProfileSuccess(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_PROFILE_SUCCEEDED,
    payload
  };
}

export function updateUserProfileFailed(payload) {
  return {
    type: ACTION_TYPES.USER_UPDATE_PROFILE_FAILED,
    payload
  };
}

// update agency info
export function requestUpdateAgencyProfile(payload) {
  return {
    type: ACTION_TYPES.UPDATE_AGENCY_INFO_REQUESTED,
    payload
  };
}

export function updateAgencyProfileSuccess(payload) {
  return {
    type: ACTION_TYPES.UPDATE_AGENCY_INFO_SUCCEEDED,
    payload
  };
}

export function updateAgencyProfileFailed(payload) {
  return {
    type: ACTION_TYPES.UPDATE_AGENCY_INFO_FAILED,
    payload
  };
}