import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Alert, Card, Col, Form, Row, Button } from "react-bootstrap";
import _ from "lodash";
import { InfoCircle } from "react-bootstrap-icons";
import moment from "moment";
import PolylineUtils from "@mapbox/polyline";
import * as geolib from "geolib";
import {
    ContentWrapper,
    JustnaikAppIntro,
    Modal,
    SimpleInputGroup,
    Table,
    ErrorModal
} from "../../components";
import { ApiRequest, exportData } from "../../helpers";
import { API_END_POINTS } from "../../config";
import calculate from "calculate-coordinates";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import zlib from 'zlib';

const config = {
    region: 'ap-southeast-1',
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET
    },
};
// Create a Lambda client
const client = new LambdaClient(config);

const TransactionQuery = ({
    onSubmitQuery = () => { },
    onClearQuery = () => { },
    user,
}) => {
    const [route, setRoute] = useState(null);
    const [amPm, setAmPm] = useState("All");
    const [weekendWeekday, setWeekendWeekday] = useState("All");
    const [selectFromDate, setFromDate] = useState(
        moment().startOf("month").format("YYYY-MM-DD 00:00:00")
    );
    const [selectToDate, setToDate] = useState(
        moment().endOf("month").format("YYYY-MM-DD 23:59:59")
    );
    const [vehicle, setVehicle] = useState(null);
    const [driver, setDriver] = useState(null);
    const [paidBy, setPaidBy] = useState("All");

    const [canFilter, setCanFilter] = useState(false);

    const handleSubmitQuery = (e) => {
        e.preventDefault();
        if (!canFilter) {
            return toast.error("Please select the month to search");
        }
        const query = {
            route,
            amPm,
            selectFromDate,
            selectToDate,
            vehicle,
            driver,
            weekendWeekday,
            paidBy,
        };

        if (!route || route === "null") {
            query["route"] = null;
        }
        if (!selectFromDate || selectFromDate === "null") {
            query["selectFromDate"] = null;
        }
        if (!selectToDate || selectToDate === "null") {
            query["selectToDate"] = null;
        }
        if (!vehicle || vehicle === "null") {
            query["vehicle"] = null;
        }
        if (!driver || driver === "null") {
            query["driver"] = null;
        }
        onSubmitQuery(query);
        //
    };

    const handleClear = () => {
        document.getElementById("date-from").value = "";
        setFromDate(moment().startOf("month").format("YYYY-MM-DD 00:00:00"));
        setToDate(moment().endOf("month").format("YYYY-MM-DD 23:59:59"));
        setRoute(null);
        setAmPm("All");
        setWeekendWeekday("All");
        setVehicle(null);
        setDriver(null);
        setPaidBy("All");
        setCanFilter(false);
        onClearQuery();
    };
    const setperiod = async (e) => {
        const mon = e.currentTarget.value;
        const start = await moment(mon)
            .startOf("month")
            .format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf("month").format("YYYY-MM-DD 23:59:59");
        await setFromDate(start);
        setToDate(end);
        setCanFilter(true);
    };

    return (
        <Card>
            <Card.Body>
                <h5>Search</h5>
                <Row>
                    <Col lg={4} md={12} sm={12}></Col>
                    <Col lg={8} md={12} sm={12}>
                        <Form onSubmit={handleSubmitQuery} className="text-right">
                            <Row>
                                <Col>
                                    <SimpleInputGroup labelWidth={10} preappendText="Month:">
                                        <Form.Control
                                            id="date-from"
                                            max={moment().format("YYYY-MM")}
                                            onChange={setperiod}
                                            min={
                                                user.userType !== "SUPER_ADMIN"
                                                    ? moment().subtract(3, "months").format("YYYY-MM")
                                                    : moment().subtract(12, "months").format("YYYY-MM")
                                            }
                                            type="month"
                                        />
                                    </SimpleInputGroup>
                                </Col>
                            </Row>
                            <Button className="ml-2" onClick={handleClear} variant="warning">
                                Clear
                            </Button>
                            <Button className="ml-2" type="submit">
                                Search
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

const IsbsfReportPage = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [routesArr, setRoutesArr] = useState(null);
    const [filteredTripCollection, setFilteredTripCollection] = useState(null);
    const [ultraFilteredTripCollection, setUltraFilteredTripCollection] =
        useState(null);
    const [routesData, setRoutesData] = useState({});
    const [routeStops, setRouteStops] = useState({});
    const [tripLog, setTripLog] = useState({});
    const [mainApiData, setMainApiData] = useState({})

    const [errorModal, setErrorModal] = useState(false);

    const calculateTravelledDistance = (logs) => {
        let totalDistance = 0;
        logs.forEach(({ latitude, longitude }, i) => {
            if (i > 0 && i < logs.length - 1) {
                const prev = [logs[i - 1].latitude, logs[i - 1].longitude];
                const curr = [latitude, longitude];
                totalDistance += calculate.fromCoordinatesReturningKM(prev, curr);
            }
        });
        return totalDistance.toFixed(3);
    };
    const handleGetTransactionHistory = async () => { };

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: "get",
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: true },
        })
            .then(async (data) => {
                await setRoutesArr(data);
            })
            .catch((e) => { });
    };

    useEffect(() => {
        const routeWithStops = {};
        //
        routesArr &&
            routesArr.forEach(({ id }) => {
                if (!routeWithStops[id]) {
                    ApiRequest.fetch({
                        method: "get",
                        url: `${API_END_POINTS.STOP_GET_ALL}`,
                        params: {
                            routeId: id,
                        },
                    }).then((data) => {
                        routeWithStops[id] = data;
                    });
                }
            });
        setRoutesData((routesData) => ({ ...routesData, ...routeWithStops }));
    }, [routesArr]);

    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return [];
        const drivers = [];
        const vehicles = [];
        const copy = transaction.reverse(); // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName);
            vehicles.push(vehicleRegistrationNumber);
        });

        return [_.uniq(drivers), _.uniq(vehicles)];
    }, [transaction]);

    useEffect(() => {
        handleGetTransactionHistory();
        handleGetAllRoute();
    }, []);

    const tableHeader = useMemo(
        () => [
            {
                Header: "Date",
                accessor: "localTimeGroup_",
                // disableFilters: true
            },
            {
                Header: "Total Planned Trip",
                accessor: "totalTripCount_",
                // disableFilters: true
            },
            {
                Header: "Completed Trip (Outbound)",
                accessor: "obTripCount_",
                // disableFilters: true
            },
            {
                Header: "Completed Trip (Inbound)",
                accessor: "ibTripCount_",
                // disableFilters: true
            },
            {
                Header: "Total Completed Trip",
                accessor: "completeTripCount_",
                // disableFilters: true
            },
            {
                Header: "Trip Compliance (%)",
                accessor: "tripCompilanceCount_",
                // disableFilters: true
            },
            {
                Header: "Total off route",
                accessor: "offRouteCount_",
                // disableFilters: true
            },
            {
                Header: "Route Compliance (%)",
                accessor: "routeCompilanceCount_",
                // disableFilters: true
            },
            {
                Header: "KM 1 way outbound",
                accessor: "kmob_",
                // disableFilters: true
            },
            {
                Header: "KM 1 way inbound",
                accessor: "kmib_",
                // disableFilters: true
            },
            {
                Header: "Total KM outbound",
                accessor: "tkmob_",
                // disableFilters: true
            },
            {
                Header: "Total KM inbound",
                accessor: "tkmib_",
                // disableFilters: true
            },
            {
                Header: "Total KM",
                accessor: "tkm_",
                // disableFilters: true
            },

            {
                Header: "Total Trip On Time",
                accessor: "punctuality",
                // disableFilters: true
            },
            {
                Header: "Punctuality Adherence (%)",
                accessor: "punctualityP",
                // disableFilters: true
            },
            {
                Header: "Total Trip Breakdown",
                accessor: "tripBreakdown",
                // disableFilters: true
            },
            {
                Header: "Reliability Compliance (%)",
                accessor: "tripBreakdownP",
                // disableFilters: true
            },
            {
                Header: "Number of Buses",
                accessor: "totalUniqueVehicleCount_",
                // disableFilters: true
            },
            {
                Header: "Farebox",
                accessor: "totalAmount_",
                // disableFilters: true
            },
            {
                Header: "Ridership",
                accessor: "totalRidership_",
                // disableFilters: true
            },
        ],
        []
    );

    // claim report data end

    const decompressData = (compressedData) => {
        const buffer = Buffer.from(compressedData, 'base64');
        const decompressedData = zlib.gunzipSync(buffer);
        return JSON.parse(decompressedData.toString());
    }

    const handleFilterTransactionData = useCallback(
        async ({
            route,
            selectFromDate,
            selectToDate,

        }) => {
            setLoading(true);

            if (process.env.REACT_APP_NODE_ENV != 'production') {
                // new api start
                const data = await ApiRequest.fetch({
                    method: "get",
                    url: `${API_END_POINTS.ISBSF_COLLECTION_BY_DATE_AT_BACKEND}`,
                    params: {
                        timestamp: new Date(),
                        from: selectFromDate,
                        to: selectToDate,
                    },
                });

                const decompressedData = decompressData(data);
                setMainApiData(decompressedData);
                setLoading(false);

                // new api end
                // start transaction process

            } else {
                // console.log('production')
                try {
                    const data = await ApiRequest.fetch({
                        method: "post",
                        url: `${API_END_POINTS.LAMBDA_QUEUE_START}`,
                        data: {
                            from: selectFromDate,
                            to: selectToDate,
                            agencyId: user.agency.id,
                            reportType: 'isbsf',
                        },
                    });

                    let { jobId, key, mode } = data;

                    if (mode === 'CACHE') {
                        try {
                            const data = await ApiRequest.fetch({
                                method: "get",
                                url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                            });

                            setMainApiData(data); // Use completed data
                            setLoading(false);
                        } catch(fetchError) {
                            console.error("Error fetching cached data:", fetchError);
                            setLoading(false);
                            setErrorModal(true);
                        }
                    } else {
                        const jobStatusInterval = setInterval(async () => {
                            // console.log('checking status');
                            try {
                                const statusResponse = await ApiRequest.fetch({
                                    method: "get",
                                    url: `${API_END_POINTS.LAMBDA_QUEUE_FIND}`,
                                    params: { jobId },
                                });
                                
                                if (statusResponse.status === 'COMPLETED') {
                                    // implement download file from S3
                                    const data = await ApiRequest.fetch({
                                        method: "get",
                                        url: `${API_END_POINTS.LAMBDA_REPORT}/${key}`,
                                    });
    
                                    clearInterval(jobStatusInterval); // Stop polling
                                    setMainApiData(data); // Use completed data
                                    setLoading(false);
                                } else if (statusResponse.status === 'FAILED') {
                                    clearInterval(jobStatusInterval); // Stop polling
                                    setLoading(false);
                                    setErrorModal(true);
                                }
                            } catch (pollingError) {
                                console.error("Error checking job status:", pollingError);
                                setLoading(false);
                                setErrorModal(true);
                            }
                        }, 5000);
                    }                    
                } catch(error) {
                    console.error('Error invoking lambda: ', error);
                    setLoading(false);
                    setErrorModal(true);
                }
            }
        },
        [transaction]
    );

    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null);
        handleGetTransactionHistory();
    }, []);

    const generateExportCsvData = useCallback(() => {
        if (!mainApiData.exportData) return toast.warning("Nothing to export!");
        const reportNameHeader = "ISBSF Report" + `\r\n\n`;

        const networkOperator = "Network Area:, EMPTY" + "\r\n";
        const networkArea = `Network Operator:, ${user.agency.name}\r\n`;
        // const datesGeneratedFor = selectFromDate && selectToDate ? `Reporting Period:, ${selectFromDate} - ${selectToDate}\r\n` : ""

        const dateGenerated = `Generated At:, ${moment().format(
            "DD/MM/YYYY hh:mm:ss"
        )}\r\n`;
        const generatedBy = `Generated By:, ${user.firstName} ${user.lastName}`;

        // latest start

        // const returnData = []
        const scheduledAt = moment(mainApiData?.returnData[0].datetime_, "DD-MM-YYYY HH:mm:ss (ddd)");

        const strartFrom = scheduledAt.clone().startOf("month").format("DD-MM-YYYY");
        const endTo = scheduledAt.clone().endOf("month").format("DD-MM-YYYY");

        const reportingPeriod = `Reporting Period:,${strartFrom} - ${endTo}\r\n`;

        let data =
            reportNameHeader +
            networkArea +
            networkOperator +
            reportingPeriod +
            dateGenerated +
            generatedBy +
            "\r\n\n";
        if (!mainApiData.exportData) return [];

        const currDate = moment(mainApiData?.returnData[0].datetime_, "DD-MM-YYYY HH:mm:ss (ddd)");
        const currYear = currDate.format("YYYY");
        const currMonthName = currDate.format("MMMM");
        const currMonth = currDate.month() + 1; // Moment.js months are 0-indexed
        const noOfDays = currDate.daysInMonth();
        const datesArr = Array.from(Array(noOfDays), (_, index) => index + 1);



        for (let index = 0; index < mainApiData?.exportData.length; index++) {
            let currLoopData = mainApiData?.exportData[index]
            if (index == 0) {
                data += `,,,,,,,,,,,, Day (${currMonthName} ${currYear}),\r\n`;
                // data += `,,,,,,,,,\r\n`
                data += `No,Route ,${datesArr.join(",")},Total\r\n`;
            }
            data += `${index + 1
                },${currLoopData?.shortNameForRoute.toUpperCase()}  ${currLoopData?.routeName.toUpperCase()},\r\n`;
            data += `,"Planned Trip",${currLoopData?.totalTripArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.totalTripArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Completed Trip (Outbound)",${currLoopData?.obTripArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.obTripArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Completed Trip (Inbound)",${currLoopData?.ibTripArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.ibTripArr.reduce((a, b) => a + b, 0)}\r\n`;
            // data += `,"Completed Trip Count(LOOP)",${obibTripArr.join(",")},${obibTripArr.reduce((a, b) => a + b, 0)}\r\n`
            data += `,"Total Completed Trip",${currLoopData?.completedTripArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.completedTripArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Trip Compliance (%)",${currLoopData?.tripComplianceArr
                .filter((x) => x != null)
                .join(",")},${parseFloat(
                    currLoopData?.tripComplianceArr.slice(1)?.reduce((a, b) => a + b, 0) /
                    (currLoopData?.tripComplianceArr.length - 1)
                ).toFixed(2)}\r\n`;
            data += `,"Total Off Route",${currLoopData?.offRouteCount_Arr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.offRouteCount_Arr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Route Compliance (%)",${currLoopData?.routeCompilanceCount_Arr
                .filter((x) => x != null)
                .join(",")},${parseFloat(
                    currLoopData?.routeCompilanceCount_Arr.slice(1).reduce((a, b) => a + b, 0) /
                    (currLoopData?.routeCompilanceCount_Arr.length - 1)
                ).toFixed(2)}\r\n`;
            data += `,"KM 1 Way Outbound",${currLoopData?.kmob_Arr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.kmob_Arr.reduce((a, b) => a + b, 0).toFixed(2)}\r\n`;
            data += `,"KM 1 Way Inbound",${currLoopData?.kmib_Arr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.kmib_Arr.reduce((a, b) => a + b, 0).toFixed(2)}\r\n`;
            // data += `,"KM 1 Way Loop",${kmloop_Arr.join(",")},${tkmob_Arr.reduce((a, b) => a + b, 0)}\r\n`
            data += `,"Total KM Outbound",${currLoopData?.tkmob_Arr
                .filter((x) => x != null)
                .join(",")},${parseFloat(currLoopData?.tkmob_Arr.reduce((a, b) => a + b, 0)).toFixed(
                    2
                )}\r\n`;
            data += `,"Total KM Inbound",${currLoopData?.tkmib_Arr
                .filter((x) => x != null)
                .join(",")},${parseFloat(currLoopData?.tkmib_Arr.reduce((a, b) => a + b, 0)).toFixed(
                    2
                )}\r\n`;
            // data += `,"Total KM Loop",${tkmloop_Arr.join(",")},${tkmloop_Arr.reduce((a, b) => a + b, 0)}\r\n`
            data += `,"Total KM",${currLoopData?.tkm_Arr
                .filter((x) => x != null)
                .join(",")},${parseFloat(currLoopData?.tkm_Arr.reduce((a, b) => a + b, 0)).toFixed(
                    2
                )}\r\n`;
            data += `,"Total Trip On Time",${currLoopData?.ontimeTripArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.ontimeTripArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Punctuality Adherence (%)",${currLoopData?.punctualPercentArr
                .filter((x) => x != null)
                .join(",")},${parseFloat(
                    currLoopData?.punctualPercentArr.slice(1).reduce((a, b) => a + b, 0) /
                    (currLoopData?.punctualPercentArr.length - 1)
                ).toFixed(2)}\r\n`;
            data += `,"Total Trip Breakdown",${currLoopData?.tripBreakdownArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.tripBreakdownArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Reliability Compliance (%)",${currLoopData?.tripBreakdownPArr
                .filter((x) => x != null)
                .join(",")},${parseFloat(
                    currLoopData?.tripBreakdownPArr.slice(1).reduce((a, b) => a + b, 0) /
                    (currLoopData?.tripBreakdownPArr.length - 1)
                ).toFixed(2)}\r\n`;
            data += `,"Number of Buses",${currLoopData?.vehicleArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.vehicleArr.reduce((a, b) => a + b, 0)}\r\n`;
            data += `,"Farebox",${currLoopData?.fareboxArr
                .filter((x) => x != null)
                .join(",")},${parseFloat(currLoopData?.fareboxArr.reduce((a, b) => a + b, 0)).toFixed(
                    2
                )}\r\n`;
            data += `,"Ridership",${currLoopData?.ridershipArr
                .filter((x) => x != null)
                .join(",")},${currLoopData?.ridershipArr.reduce((a, b) => a + b, 0)}\r\n`;

        }

        var BOM = "\uFEFF";
        var csvContent = BOM + data;
        exportData(
            csvContent,
            `ISBSF Report-${moment(strartFrom, "DD-MM-YYYY").format("MMMYYYY")}-${user.agency.name
            }.csv`,
            "text/csv;charset=utf-8;"
        );
    }, [mainApiData?.exportData]);

    return (
        <ContentWrapper>
            <h2 className="mb-3">ISBSF Report</h2>
            <Card className="mt-3">
                <Card.Body>
                    {
                        <div>
                            {routesArr ? (
                                <TransactionQuery
                                    onClearQuery={handleClearQuery}
                                    onSubmitQuery={handleFilterTransactionData}
                                    routeOpt={routesArr.map(({ shortName }) => shortName)}
                                    driverOpt={driverList}
                                    vehicleOpt={vehicleList}
                                    agencyNameOpt={[user?.agency?.name]}
                                    user={user}
                                />
                            ) : (
                                <div className="d-flex justify-content-center align-items-center">
                                    <CircularProgress />
                                </div>
                            )}
                            <Alert className="mt-3" variant="info">
                                {" "}
                                <InfoCircle /> ISBSF Report only shows data for the past 1
                                months - Contact{" "}
                                <a href="mailto: info@justnaik.com">info@justnaik.com</a> for
                                more
                            </Alert>
                            <div className="w-100 text-right">
                                <Button onClick={generateExportCsvData}>Export Data</Button>
                            </div>
                            {mainApiData?.returnData ?
                                <Table numbering columns={tableHeader} data={mainApiData?.returnData} />
                                :
                                <Table numbering columns={tableHeader} data={[]} />

                            }
                        </div>
                    }
                </Card.Body>
            </Card>
            <Modal size="sm" centered show={loading}>
                <div className="text-center">
                    <CircularProgress size={70} />
                </div>
            </Modal>
            <ErrorModal showError={errorModal} handleClose={() => setErrorModal(false)} />
        </ContentWrapper>
    );
};

export default IsbsfReportPage;
