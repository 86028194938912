import React from 'react'
import { Modal as BModal } from 'react-bootstrap'

const Modal = ({ show,titleChildren, children, title, onHide = () => { }, size = 'lg', closeButton = true, backdrop = true, centered = false, ...props }) => {
    return (
        <BModal {...props} centered={centered} backdrop={backdrop} style={{ zIndex: 9998 }} size={size} show={show} onHide={onHide} >
            {
                title &&
                <BModal.Header closeButton={closeButton}>
                    <BModal.Title>{title}</BModal.Title>
                </BModal.Header>
            }
            <BModal.Body>{children}</BModal.Body>
        </BModal>
    );
}

export default Modal