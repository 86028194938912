import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import moment from 'moment'

import { ContentWrapper, JustnaikAppIntro, Modal, Table } from '../../components'
import { ApiRequest } from '../../helpers'
import { API_END_POINTS } from '../../config'

import './FareCollectionPage.css'
import { CircularProgress } from '@material-ui/core'

const FareCollectionPage = () => {
    const [settlement, setSettlement] = useState(null)
    const [agencyTrips, setAgencyTrips] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleGetSettlementData = () => {
        setLoading(true)
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.SETTLEMENT_GET_ALL}`
        }).then(data => {
            // 
            setSettlement(data)
        }).catch(e => { })
    }

    const handleGetAgencyTrips = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.AGENCY_TRIP}`
        }).then(data => {
            // 
            setAgencyTrips(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetAgencyTrips()
        handleGetSettlementData()
    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Route ID',
            accessor: 'route_name',
            //disableFilters: true
        },
        {
            Header: 'Trip ID',
            accessor: 'tripId',
            //disableFilters: true
        },
        {
            Header: 'Serial Trip ID',
            accessor: 'agencyTripId',
            //disableFilters: true
        },
        {
            Header: 'Vehicle Number',
            accessor: 'vehicleRegistrationNumber',
            //disableFilters: true
        },
        {
            Header: 'Driver Name',
            accessor: 'driverName',
            //disableFilters: true
        },
        {
            Header: 'Trip Start',
            accessor: 'start_time',
            // disableFilters: true
        },
        {
            Header: 'Trip End',
            accessor: 'end_time',
            // disableFilters: true
        },
        {
            Header: 'Adult',
            accessor: 'adult_ridership_local',
            // disableFilters: true
        },
        {
            Header: 'Child',
            accessor: 'child_ridership_local',
            // disableFilters: true
        },
        {
            Header: 'Senior',
            accessor: 'senior_ridership',
            // disableFilters: true
        },
        {
            Header: 'OKU',
            accessor: 'disabled_ridership',
            // disableFilters: true
        },
        {
            Header: 'Foreign (Adult)',
            accessor: 'adult_ridership_foreigner',
            // disableFilters: true
        },
        {
            Header: 'Foreign (Child)',
            accessor: 'child_ridership_foreigner',
            // disableFilters: true
        },
        {
            Header: 'Total Issued',
            accessor: 'total_issued',
            disableFilters: true
        },
        {
            Header: 'Total Amount',
            accessor: 'total_amount',
            disableFilters: true
        }
    ], [])

    const groupByTripId = useMemo(() => {
        if (!settlement || !agencyTrips) return []
        setLoading(false)
        const rawObj = _.groupBy(settlement, 'tripId')
        // 
        const allDailySummaries = []

        agencyTrips.forEach(({ id: tripId, startedAt, endedAt, routeShortName, vehicleRegistrationNumber, driverName, agencyTripId }) => {
            const transactions = rawObj[tripId] || []
            const daySummary = {
                tripId,
                transactions,
                start_time: moment(startedAt).format('DD MMM YYYY, HH:mm'),
                end_time: moment(endedAt).format('DD MMM YYYY, HH:mm'),
                route_name: routeShortName,
                vehicleRegistrationNumber,
                driverName,
                agencyTripId
            }

            let total_amount = 0
            let adult_ridership_local = 0
            let adult_ridership_foreigner = 0
            let senior_ridership = 0
            let disabled_ridership = 0
            let child_ridership_local = 0
            let child_ridership_foreigner = 0

            transactions.forEach(({ amount, noOfAdult, noOfChild, noOfSenior, noOfOku, noOfForeignAdult, noOfForeignChild }) => {
                total_amount += amount
                //currently only have adult local
                // adult_ridership_local += noOfPax
                adult_ridership_local += noOfAdult
                adult_ridership_foreigner += noOfForeignAdult
                senior_ridership += noOfSenior
                disabled_ridership += noOfOku
                child_ridership_local += noOfChild
                child_ridership_foreigner += noOfForeignChild
            })

            daySummary['total_amount'] = +total_amount.toFixed(2)
            daySummary['adult_ridership_local'] = adult_ridership_local
            daySummary['adult_ridership_foreigner'] = adult_ridership_foreigner
            daySummary['senior_ridership'] = senior_ridership
            daySummary['disabled_ridership'] = disabled_ridership
            daySummary['child_ridership_local'] = child_ridership_local
            daySummary['child_ridership_foreigner'] = child_ridership_foreigner
            daySummary['total_issued'] = adult_ridership_local + adult_ridership_foreigner + child_ridership_local + senior_ridership + disabled_ridership + child_ridership_foreigner

            allDailySummaries.push(daySummary)
        })
        return _.sortBy(allDailySummaries, 'tripId').reverse()
    }, [settlement, agencyTrips])
    // 
    return (
        <ContentWrapper >
            <h2 className='mb-3' >Fare Collection</h2>
            {
                groupByTripId && groupByTripId.length > 0 ?
                    <Table numbering columns={tableHeader} data={groupByTripId} />
                    :
                    <JustnaikAppIntro />
            }
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default FareCollectionPage