import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { Card, Form, Row, Col, Button, Nav ,} from 'react-bootstrap'
import { CheckCircle, Circle, CircleFill, PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ContentWrapper, Table, Modal, StaticMap, ConfirmModal, LoadingModal, ZoneTable } from '../../components'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'

import PolylineUtils from '@mapbox/polyline'

const CreateZoneModal = ({show, onSubmit, onSubmitEdit, zoneName, setZoneName, zoneDesc, setZoneDesc, handleClickCancel, tableData, tableHeader, editTableHeader, isEdit}) => {
    return (
    <Modal size='xl' title={isEdit ? "Update Zone" : "Create new zone"} show={show} onHide={handleClickCancel}>
        <Form onSubmit={isEdit ? onSubmitEdit : onSubmit}>
            <Form.Group controlId='formCreateZoneName'>
                <Form.Label>Zone Name</Form.Label>
                <Form.Control 
                    required
                    type="text"
                    placeholder="Enter zone name"
                    value={zoneName}
                    onChange={(e) => setZoneName(e.target.value)} />
            </Form.Group>

            <Form.Group controlId='formCreateZoneDesc'>
                <Form.Label>Zone Description</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter zone description"
                    value={zoneDesc}
                    onChange={(e) => setZoneDesc(e.target.value)} />
            </Form.Group>

            <Form.Group>
                <Form.Label>Select Start and End Stop</Form.Label>
                <ZoneTable columns={ isEdit ? editTableHeader : tableHeader} data={tableData} isEdit={isEdit}></ZoneTable>
            </Form.Group>

            <div className='d-flex justify-content-end mt-3'>
                <Button className='mr-2' variant='secondary' onClick={handleClickCancel}>Close</Button>
                <Button className='ms-2' variant='primary' type='submit'>{isEdit ? 'Update' : 'Create'}</Button>
            </div>
        </Form>
    </Modal>
    )
}

const ZoneTabs = ({ zones, activeZone, setActiveZone, onDeleteZone, onEditZone, getWaypoints }) => {
    return (
        <div className="d-flex flex-column h-100">
            <Nav variant="pills" className="flex-column">
                {
                    zones.map((zone, index) => {
                        return (
                        <Nav.Item key={index} className="d-flex align-items-center border-bottom pb-2 mb-2">
                            <div
                                className={`nav-link d-flex justify-content-between align-items-center flex-grow-1 ${activeZone.id === zone.id ? 'square border border-primary' : ''}`}
                                onClick={() => {
                                    setActiveZone(zone);
                                    getWaypoints(zone.id);
                                }}
                                style={{ cursor: 'pointer', border: activeZone.id !== zone.id ? '1px solid transparent' : 'none' }}
                            >
                                <span>{zone.name}</span>

                                <div>
                                    <Button
                                        variant="info"
                                        size="sm"
                                        onClick={(e) => {
                                        e.stopPropagation();
                                        onEditZone(zone.id);
                                        }}
                                        className="ms-2 mx-1"
                                    >
                                        <PencilSquare />
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={(e) => {
                                        e.stopPropagation();
                                        onDeleteZone(zone.id);
                                        }}
                                        className="ms-2"
                                    >
                                        <Trash />
                                    </Button>
                                </div>
                            </div>
                        </Nav.Item>
                        )
                    })
                }
            </Nav>
        </div>
    );
};

const EditorialZone = () => {

    const [route, setRoute] = useState(null)
    const [refresh, setRefresh] = useState(null)
    const [loading, setLoading] = useState(false);

    // * ---------------------- *
    // * states for stops table *
    // * ---------------------- *
    const [routeStops, setRouteStops] = useState([]);
    const [selectedStartStop, setSelectedStartStop] = useState(null);
    const [selectedEndStop, setSelectedEndStop] = useState(null);
    const [editZone, setEditZone] = useState(false);

    // * ---------------------- *
    // * states for zones table *
    // * ---------------------- *
    const [allZones, setAllZones] = useState(null);
    const [activeZone, setActiveZone] = useState(null);
    const [allWaypointsInZone, setAllWaypointsInZone] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [confirmRemoveFinalStop, setConfirmRemoveFinalStop] = useState(null);

    // * --------------- *
    // * states for form *
    // * --------------- *
    const [zoneName, setZoneName] = useState('');
    const [zoneDescription, setZoneDesciption] = useState('');
    const [showCreateZoneModal, setShowCreateZoneModal] = useState(false)
    const [selectedStops, setSelectedStops] = useState([]);
    const [stopsInsertedIntoZone, setStopsInsertedIntoZone] = useState([]);
    const [currentZone, setCurrentZone] = useState(null);

    const goTo = useHistory()
    const { routeId } = useParams()
    const activeZoneRef = useRef(null)

    // * ------------------ *
    // * Handle API request *
    // * ------------------ *

    const handleCreateZone = async (data) => {
        const params = { name: data.name, description: data.description };
        
        try {
            const response = await ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.ZONE_CREATE}`,
                data: params
            });
    
            return response; // Return the response from the API call
        } catch (error) {
            console.error(error);
            throw error; // Rethrow the error to be caught by the caller
        }
    }

    const handleCreateZoneStopRelation = async (data) => {
        const params = { zoneId: data.id, stops: selectedStops };
        setLoading(true);
        try {
            ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.ZONE_STOP_RELATION_CREATE_BULK}/${routeId}`,
                data: params 
            }).then((response) => {
                // console.log(response);
    
                if (!editZone) toast.success("Zone Created!")
                activeZoneRef.current = { id: data.id }
                handleCancelCreateZone();
                setRefresh(!refresh)
    
            }).catch(e => {
                console.error(e);
            });
        } catch (error) {
            console.error("error creating zone stop relation: ", error);
        } finally {
            setLoading(false);
        }
    }

    const handleGetRoute = useCallback(() => {
        setLoading(true);
        try {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ROUTE_GET}/${routeId}`
            }).then((data) => {
                setRoute(data);
                setRouteStops(data.stops);
            }).catch(e => {
                console.error(e);
            });
        } catch(error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }, [routeId]);

    const handleGetAllZonesByRoute = () => {
        setLoading(true);
        try {
            ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.ZONE_GET_BY_ROUTE_ID}/${routeId}`
            }).then(async (data) => {
                await setAllZones(data);
            }).catch(e => {
                console.error(e);
            });
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleGetAllWaypointsInZone = (zoneId) => {
        const waypoints = stopsInsertedIntoZone?.filter(zone => zone.zoneId === zoneId);

        setAllWaypointsInZone(waypoints);
    }

    const handleGetStopsInsertedIntoZone = () => {
        setLoading(true);
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ZONE_STOP_RELATION_GET_STOPS_INSERTED_INTO_ZONE}/${routeId}`,
        }).then((data) => {
            setStopsInsertedIntoZone(data);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setLoading(false)
        });
    }

    const handleEditZone = (zoneId) => {
        const zone = allZones.filter(z => z.id === zoneId);

        // set current zone name & desc before update
        setCurrentZone(zone[0]);
        setZoneName(zone[0].name);
        setZoneDesciption(zone[0].description);

        setEditZone(true);
        handleShowCreateZoneModal();
    }

    const handleDeleteZone = (zoneId) => {
        setLoading(true);

        try {
            setConfirmDelete(null);
            setConfirmRemoveFinalStop(null);
            ApiRequest.fetch({
                method: 'delete',
                url: `${API_END_POINTS.ZONE_DELETE_ALL}/${zoneId}`
            }).then((data) => {
                toast.success("Zone Deleted!");

                if (allZones[0].id == zoneId) activeZoneRef.current = allZones[1];
                else activeZoneRef.current = allZones[0];
                setRefresh(!refresh);
            }).catch(e => {
                console.error(e);
            });
        } catch (error) {
            console.error("error deleting zone: ", error);
        } finally {
            setLoading(false);
        }
    }

    // * ------------------ *
    // * form handling area *
    // * ------------------- *
    const onSubmitForm = (e) => {
        e.preventDefault();

        // check if user already have zone with the submitted zone name
        const nameAlreadyExist = allZones.some(zone => zone.name === zoneName);

        if (nameAlreadyExist) return toast.warn("Zone with this name already exist!");
        if (!selectedStops || selectedStops.length <= 0) return toast.warn("Please select stops to create a zone!");

        handleClickCreateZone();
    }

    const onSubmitEditForm = (e) => {
        e.preventDefault();

        let actionTaken = false;
        const updateParams = { id: currentZone.id };

        // check if zone name & desc is the same as before or already updated with new value
        if (zoneName != currentZone.name) {
            const nameAlreadyExist = allZones.some(zone => zone.name === zoneName);
            if (nameAlreadyExist) return toast.warn("Zone with this name already exist!");

            updateParams.name = zoneName;
        }
        
        if (zoneDescription != currentZone.description) {
            updateParams.description = zoneDescription;
        }

        if (selectedStops !== null && selectedStops.length > 0) {
            const params = { id: currentZone.id, stops: selectedStops };
            handleCreateZoneStopRelation(params);
            actionTaken = true;
        }

        // only call the API if either one of the field is present
        if (updateParams.hasOwnProperty('name') || updateParams.hasOwnProperty('description')) {
            setLoading(true);

            try {
                ApiRequest.fetch({
                    method: 'put',
                    url: `${API_END_POINTS.ZONE_UPDATE}`,
                    data: updateParams
                }).then((data) => {
                    handleCancelCreateZone();
                    setRefresh(!refresh);
                }).catch(e => {
                    console.error(e);
                });
            } catch (error) {
                console.error("error updating zone: ", error);
            } finally {
                setLoading(false);
                actionTaken = true;
            }
        }
        
        if (!actionTaken) toast.warn('Nothing to update!');
        else toast.success("Zone Updated!");
    }

    const handleClickCreateZone = async () => {
        let data = { name: zoneName, description: zoneDescription }
        // console.log("OnSubmitForm: \n", data);

        try {
            setLoading(true);

            const response = await handleCreateZone(data);
            await handleCreateZoneStopRelation(response);
        } catch(error) {
            console.error("error creating zone\n", error);
        } finally {
            setLoading(false);
        }
    }

    const handleCancelCreateZone = () => {
        setEditZone(false);
        setShowCreateZoneModal(false)
        setZoneDesciption('');
        setZoneName('');
        setSelectedStartStop(null);
        setSelectedEndStop(null);
        setSelectedStops([]);
    }

    const handleShowCreateZoneModal = async () => {
        await handleGetStopsInsertedIntoZone();
        setShowCreateZoneModal(true);
    }

    const handleStopSelect = (stop, cancelStop) => {
        if (editZone) {
            if (cancelStop) {
                // if cancelling start stop, 
                // set the current end stop as current, delete the value for end stop
                if (selectedStops.some(row => row.id === cancelStop.id)) {
                    const filteredList = selectedStops.filter(element => element?.id !== cancelStop.id);
                    setSelectedStops(filteredList);
                }
            } else {
                setSelectedStops((prevStops) => [...prevStops, stop]);
            }
        } else {
            // get the sequcnce of the stop
            let seq;
            if (stop) seq = stop.sequence;

            // if selecting previously selected start / end
            if (cancelStop) {
                // if cancelling start stop, 
                // set the current end stop as current, delete the value for end stop
                if (cancelStop === selectedStartStop) {
                    setSelectedEndStop(stop);
                    setSelectedStartStop(selectedEndStop);
                }
                    
                if (cancelStop === selectedEndStop)
                    setSelectedEndStop(stop);
            }

            // no start and stop
            else if (!selectedStartStop && !selectedEndStop) {
                // console.log("No start and end");
                setSelectedStartStop(stop);
            }

            // start has been selected
            else if (selectedStartStop && !selectedEndStop) {
                // console.log("Only start");
                // check if selected stop seq > start stop
                if (stop.sequence > selectedStartStop.sequence) {
                    setSelectedEndStop(stop);
                } else {
                    setSelectedStartStop(stop);
                    setSelectedEndStop(selectedStartStop);
                }
            } 
            
            // both stop has been selected
            else {
                // check if the sequnce of selected stop is nearer to which
                const startStopSequence = selectedStartStop.sequence;
                const endStopSequence = selectedEndStop.sequence;

                const distanceFromStart = Math.abs(seq - startStopSequence);
                const distanceFromEnd = Math.abs(seq - endStopSequence);

                if (distanceFromStart < distanceFromEnd) {
                    setSelectedStartStop(stop);
                } else if (distanceFromEnd < distanceFromStart) {
                    setSelectedEndStop(stop);
                } else {
                    setSelectedStartStop(stop);
                }
            }
        }
    };

    const handleRemoveStopFromZone = async (stop, data) => {
        if (data.length == 1) {
            // if only 1 stops left, prompt user for confirm zone deletion
            setConfirmRemoveFinalStop(stop.zoneId);
        } else {
            setLoading(true);
            ApiRequest.fetch({
                method: 'delete',
                url: `${API_END_POINTS.ZONE_REMOVE_STOP_FROM_ZONE}/${stop.zsrId}`,
            }).then((response) => {
                toast.success("Stop removed!")
                setRefresh(!refresh)
            }).catch(e => {
                console.error(e);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    // * -------------------------- *
    // * Table header and data area *
    // * -------------------------- *
    const routeStopsTableHeader = useMemo(() => [
        {
            Header: 'Sequence',
            accessor: 'sequence',
            disableFilters: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Address',
            accessor: 'address',
            disableFilters: true
        },
        {
            Header: 'Select',
            accessor: 'onSelect',
            disableFilters: true
        },
    ], []);

    const editZoneStopsTableHeader = useMemo(() => [
        {
            Header: 'Sequence',
            accessor: 'sequence',
            disableFilters: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Address',
            accessor: 'address',
            disableFilters: true
        },
        {
            Header: 'Included',
            accessor: 'onInclude',
            disableFilters: true
        },
        {
            Header: 'Select',
            accessor: 'onSelect',
            disableFilters: true
        },
    ], []);

    const zoneStopTableHeader = useMemo(() => [
        {
            Header: 'Sequence',
            accessor: 'sequence',
            disableFilters: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Delete',
            accessor: 'onDelete',
            disableFilters: true
        },
    ], []);

    const appendSelectToStopsData = (data) => {
        if (!data) return [];

        // if editing zone
        const indices = selectedStops.map(selectedStop => {
            return data.findIndex(row => row.id === selectedStop?.id);
        });

        const startIndex = data.findIndex(row => row === selectedStartStop)
        const endIndex = data.findIndex(row => row === selectedEndStop)

        const stopsInsertedSequences = stopsInsertedIntoZone?.map(stop => stop.sequence);

        return data.map((row, i) => {
            const _row = { ...row };
            const isSelectedStation = editZone ? indices.includes(i)
                                                : row === selectedStartStop || row === selectedEndStop;

            const isStopInBetween = (
                startIndex !== -1 &&
                endIndex !== -1 &&
                i > startIndex &&
                i < endIndex
            )

            const allStopsSelected = (
                startIndex !== -1 &&
                endIndex !== -1 &&
                i >= startIndex &&
                i <= endIndex
            )

            // Check if the sequence value of the current row is in stopsInsertedSequences
            const isStopInserted = stopsInsertedSequences?.includes(row.sequence);

            if (editZone) {
                _row['onSelect'] = (
                    isSelectedStation ? 
                    <CheckCircle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(null, row)} /> 
                    :
                    isStopInserted ? 
                    <CheckCircle
                    onClick={null} style={{cursor: 'default', color: '#ccc'}} /> 
                    :
                    <Circle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(row)} />
                )

                _row['onInclude'] = (
                    isStopInserted ? 
                    <CheckCircle
                    onClick={null} style={{cursor: 'default', color: '#ccc'}} /> 
                    :
                    <Circle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(row)} />
                );

                _row['isStopSelected'] = isSelectedStation;
            } else {
                _row['onSelect'] = (
                    isSelectedStation ? 
                    <CheckCircle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(null, row)} /> 
                    :
                    isStopInBetween ? 
                    <CheckCircle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(row)} /> 
                    :
                    isStopInserted ? 
                    <CheckCircle
                    onClick={null} style={{cursor: 'default', color: '#ccc'}} /> 
                    :
                    <Circle className='g-hover-pointer text-primary'
                    onClick={() => handleStopSelect(row)} />
                )

                _row['isStopInBetween'] = allStopsSelected; 
            }

            _row['isStopInserted'] = isStopInserted; 
        
            return _row;
        });
    }

    const appendActionToWaypointsInZone = (data) => {
        if (!data) return [];

        return data.map((row, i) => {
            const _row = { ...row };
            
            _row["onDelete"] = <Trash className='g-hover-pointer text-danger' onClick={() => handleRemoveStopFromZone(row, data)}/>

            return _row;
        });
    }

    // * -------------- *
    // * useEffect area *
    // * -------------- *
    useEffect(() => {
        if (stopsInsertedIntoZone?.length > 0 && activeZone) {
            handleGetAllWaypointsInZone(activeZone.id);
        }
    }, [stopsInsertedIntoZone, activeZone]);

    useEffect(() => {
        if (stopsInsertedIntoZone?.length > 0) {
            handleGetAllWaypointsInZone(activeZoneRef.current?.id);
        }
    }, [stopsInsertedIntoZone]);

    useEffect(() => {
        // console.log("selectedStartStop: ", selectedStartStop);
        // console.log("selectedEndStop: ", selectedEndStop);

        // allow single stop in a zone
        if (selectedStartStop && !selectedEndStop) {
            const data = [selectedStartStop];
            setSelectedStops(data);
        } else if (selectedStartStop && selectedEndStop) {
            const startSequence = selectedStartStop.sequence;
            const endSequence = selectedEndStop.sequence;

            const data = routeStops.filter((stop) => {
                return stop.sequence >= startSequence && stop.sequence <= endSequence;
            });

            setSelectedStops(data);
            // console.log("Data: \n", data);
        } else {
            setSelectedStops([]);
        }
    }, [selectedStartStop, selectedEndStop]);

    useEffect(() => {
        activeZoneRef.current = activeZone;
    }, [activeZone])
    
    // Get all zones available in the route currently in
    useEffect(() => {
        handleGetRoute();

        handleGetStopsInsertedIntoZone();
        handleGetAllZonesByRoute();
        
        // make sure to set active zone back to previous value after refresh
        if (allZones && allZones.length > 0) {
            setActiveZone(activeZoneRef.current);
            handleGetAllWaypointsInZone(activeZoneRef.current?.id);
        }
    }, [refresh])

    // Set default active zone to the first zone available when first load the page
    useEffect(() => {
        if (allZones && allZones.length > 0 && !activeZone) {
            setActiveZone(allZones[0]);
            handleGetAllWaypointsInZone(allZones[0].id);

            activeZoneRef.current = allZones[0];
        }
    }, [allZones, activeZone]);

    return (
        <ContentWrapper>
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <Card>
                <Card.Body>
                    {
                        route &&
                        <h4>{route.shortName} - {route.name}</h4>
                    }
                </Card.Body>
            </Card>
            <Row>
                <Col className='mt-3' lg={7} md={12}>
                    <Card>
                        <Card.Body>
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <Card.Title className="mb-0">Zone - Stop</Card.Title>
                            <Button variant="primary" onClick={handleShowCreateZoneModal}>
                            Add Zone
                            </Button>
                        </div>

                        {
                            allZones && allZones.length > 0 && activeZoneRef.current && activeZone &&
                            <Row>
                                <Col md={4}>
                                <ZoneTabs zones={allZones} activeZone={activeZoneRef.current} setActiveZone={setActiveZone} onDeleteZone={setConfirmDelete} onEditZone={handleEditZone} getWaypoints={handleGetAllWaypointsInZone}/>
                                </Col>
                                <Col md={8}>
                                <Table columns={zoneStopTableHeader} data={appendActionToWaypointsInZone(allWaypointsInZone)}/>
                                </Col>
                            </Row>
                        }
                        </Card.Body>
                    </Card>
                </Col>
                <Col className='mt-3' lg={5} md={12}>
                    <Card >
                        <Card.Body>
                            <Card.Title>Preview</Card.Title>
                            <StaticMap markers={[]} polylines={route && route.polygon ? [PolylineUtils.decode(route.polygon)] : []} polylinesPathOpt={[{ color: route && route.colorCode ? route.colorCode : 'red' }]} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {
                confirmDelete && <ConfirmModal description={`Are you sure to delete zone? Action cant be undo.`} title="Confirm delete zone?" open={!!confirmDelete} onCancel={() => setConfirmDelete(null)} onConfirm={() => handleDeleteZone(confirmDelete)} />
            }

            {
                confirmRemoveFinalStop && <ConfirmModal description={`Removing this stop will delete this zone itself. Are you sure?`} title="Confirm delete zone?" open={!!confirmRemoveFinalStop} onCancel={() => setConfirmRemoveFinalStop(null)} onConfirm={() => handleDeleteZone(confirmRemoveFinalStop)} />
            } 

            <CreateZoneModal 
                show={showCreateZoneModal} 
                handleClickCancel={handleCancelCreateZone}
                onSubmit={onSubmitForm}
                onSubmitEdit={onSubmitEditForm}
                zoneName={zoneName}
                setZoneName={setZoneName}
                zoneDesc={zoneDescription}
                setZoneDesc={setZoneDesciption}
                tableHeader={routeStopsTableHeader}
                editTableHeader={editZoneStopsTableHeader}
                tableData={appendSelectToStopsData(routeStops)}
                isEdit={editZone} />

            <LoadingModal loading={loading} />
        </ContentWrapper>
    )
}

export default EditorialZone;