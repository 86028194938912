import { INITIAL_STATE } from '../store'
import { ACTION_TYPES } from '../../config'

export const UserExpReducer = (state = INITIAL_STATE.userExp, action) => {
  switch (action.type) {
    case ACTION_TYPES.USER_EXP_REQUESTED:
      return state

    case ACTION_TYPES.USER_EXP_SUCCEEDED:
      return [
        ...state,
        ...action.payload
      ];

    case ACTION_TYPES.USER_EXP_FAILED:
      return state

    case ACTION_TYPES.USER_EXP_UPDATE_REQUESTED:
      return state

    case ACTION_TYPES.USER_EXP_UPDATE_SUCCEEDED:
      //find the case id and replace to updated data
      const { id, status, notes } = action.payload
      const idx = state.findIndex(({ caseId }) => caseId === id)
      if (idx > 0) {
        state[idx].caseStatus = status
        state[idx].caseNote = notes
      }
      return [...state]

    case ACTION_TYPES.USER_EXP_UPDATE_FAILED:
      return state

    default:
      return state
  }
};
