import React, { useMemo, useRef } from 'react'
import { Alert } from 'react-bootstrap'
import { Printer } from 'react-bootstrap-icons'
import ReactToPrint from 'react-to-print'

import JustnaikLogo from '../../assets/logo/logo-primary.png'
import { GENERAL, Utils } from '../../config'

import './SettlementReportTemplate.css'

const SettlementReportTemplate = ({ data }) => {
    // 
    const compToPrint = useRef()

    const calculateTotalEarnings = useMemo(() => {
        const fareGrossRevenue = data.total_amount || 0
        const tips = data.total_tips || 0
        const nonFareRevenue = data.total_nonFareRevenue || 0
        const other = data.total_other_earning || 0
        const totalEarning = fareGrossRevenue + tips + nonFareRevenue + other
        return {
            fareGrossRevenue: fareGrossRevenue,
            tips: tips,
            nonFareRevenue: nonFareRevenue,
            other: other,
            totalEarning: totalEarning
        }
    }, [data])

    const calculateTotalDeduction = useMemo(() => {
        const comission = data.total_amount * GENERAL.COMISSION_CUT || 0
        const other = data.total_other_deduction || 0
        const totalDeduction = comission + other
        return {
            comission: comission,
            other: other,
            totalDeduction: totalDeduction
        }
    }, [data])

    const calculateNetPay = () => {
        const net = +calculateTotalEarnings.totalEarning - +calculateTotalDeduction.totalDeduction
        return net
    }

    return (
        <div>
            <ReactToPrint trigger={() => <Printer size={30} className='g-hover-pointer w-100 text-right' />} content={() => compToPrint.current} documentTitle={`Settlement_Report_${data.date}`} />
            <Alert variant='info' className='py-0 m-0 mt-1' >Recommend print using Chrome Browser</Alert>
            {
                data &&
                <div ref={compToPrint} className='settlement-report-template' >
                    <div className='settlement-template-header' >
                        <div className='settlement-main-logo-wrapper' >
                            <img className='settlement-main-logo' src={JustnaikLogo} alt='justnaik-awesome-logo' />
                        </div>
                        <small className='settlement-address' >{GENERAL.HQ_ADDRESS}</small>
                    </div>
                    <div className='settlement-report-divider' />
                    <div className='settlement-template-header-2' >
                        <small className='info-detail-span' ><span className='info-detail-header' >PAY TO :</span><br />{String(data.agency.name).toUpperCase()}</small>
                        <small className='info-detail-span' ><span className='info-detail-header' >BANK NAME :</span><br />MAYBANK</small>
                        <small className='info-detail-span' ><span className='info-detail-header' >ACCOUNT NO. :</span><br />1234567890</small>
                        <small className='info-detail-span' ><span className='info-detail-header' >CURRENCY :</span><br />MYR</small>
                        <div className='info-detail-mini' >
                            <small className='info-detail-wrapper' ><span className='info-detail-header' >DATE :</span><span className='info-detail-text' >{data.date || data.month}</span></small>
                            {/* <small className='info-detail-wrapper' ><span className='info-detail-header' >INVOICE :</span><span className='info-detail-text' >6T32HBH231</span></small> */}
                            {/* <small className='info-detail-wrapper' ><span className='info-detail-header' >PERIOD :</span><span className='info-detail-text' >16 - 17 JUNE 2020</span></small> */}
                        </div>
                    </div>
                    <div className='itemized-report-container' >
                        <div className='itemized-report-body' >
                            <div className='itemized-report-col' >
                                <p className='itemized-report-header' >EARNINGS</p>
                                <div className='itemized-item-container' >
                                    <small className='info-detail-wrapper' ><span className='item-header' >FARE GROSS REVENUE</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalEarnings.fareGrossRevenue)}</span></small>
                                    <small className='info-detail-wrapper' ><span className='item-header' >TIPS</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalEarnings.tips)}</span></small>
                                    <small className='info-detail-wrapper' ><span className='item-header' >NON-FARE REVENUE</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalEarnings.nonFareRevenue)}</span></small>
                                    <small className='info-detail-wrapper' ><span className='item-header' >OTHERS</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalEarnings.other)}</span></small>
                                </div>
                                <div className='total-value-wrapper' >
                                    <p className='total-value-prefix' >MYR</p>
                                    <span className='total-value-text' >{Utils.currencyFormatter(calculateTotalEarnings.totalEarning)}</span>
                                </div>
                            </div>
                            <div className='report-vertical-divider' />
                            <div className='itemized-report-col' >
                                <p className='itemized-report-header' >DEDUCTIONS</p>
                                <div className='itemized-item-container' >
                                    <small className='info-detail-wrapper' ><span className='item-header' >COMMISIONS ({parseInt(GENERAL.COMISSION_CUT * 100)}%)</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalDeduction.comission)}</span></small>
                                    <small className='info-detail-wrapper' ><span className='item-header' >OTHERS</span><span className='item-text' >{Utils.currencyFormatter(calculateTotalDeduction.other)}</span></small>
                                </div>
                                <div className='total-value-wrapper' >
                                    <p className='total-value-prefix' >MYR</p>
                                    <span className='total-value-text' >{Utils.currencyFormatter(calculateTotalDeduction.totalDeduction)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='itemized-report-footer' >
                            <span className='item-header' >NET PAID</span>
                            <div className='total-value-wrapper' >
                                <p className='total-value-prefix' >MYR</p>
                                <span className='total-value-text' >{Utils.currencyFormatter(calculateNetPay())}</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SettlementReportTemplate