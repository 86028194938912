import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, FormFile, InputGroup, Row } from 'react-bootstrap'
import { Step, Stepper } from 'react-form-stepper'
import { Check, Dash, InfoCircle, Plus, QuestionCircle, X } from 'react-bootstrap-icons'
import { Prompt } from 'react-router-dom'
import { toast } from 'react-toastify'

import { ApiRequest } from '../../helpers'
import { API_END_POINTS } from '../../config'
import { ContentWrapper, Modal, Table } from '../../components'

import './GTFSPage.css'
import { CircularProgress } from '@material-ui/core'

const LoadingModal = ({ loading }) => (
    <Modal centered show={loading} >
        <Card className='text-center' >
            <Card.Body>
                <CircularProgress />
            </Card.Body>
        </Card>
    </Modal>
)

const GTFSPage = () => {
    const [activeStep, setActiveStep] = useState(0)
    const [started, setStarted] = useState(false)
    const [allowNext, setAllowNext] = useState(false)
    const [file, setFile] = useState(null)

    const [newRoutes, setNewRoutes] = useState(null)
    const [newStops, setNewStops] = useState(null)
    const [newFares, setNewFares] = useState(null)
    const [newSchedule, setNewSchedules] = useState(null)
    const [newShapes, setNewShapes] = useState(null)
    const [loading, setLoading] = useState(false)

    const steps = useMemo(() => [
        {
            stepName: 'Stops',
            done: !!newRoutes,
            // done: activeStep <= 0 ? false : true,
            setState: setNewStops,
            url: `${API_END_POINTS.STOP_UPLOAD_GTFS}`,
            forms: [
                {
                    name: 'gtfs_stop',
                    label: 'stops.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#stopstxt',
                    requiredFields: [
                        { field: 'stop_name', des: '' },
                        { field: 'stop_lat', des: '' },
                        { field: 'stop_lon', des: '' }
                    ],
                    additionalFields: [
                        { field: 'stop_desc', des: '' }
                    ]

                }
            ],
        },
        {
            stepName: 'Routes',
            // done: activeStep <= 1 ? false : true,
            done: !!newRoutes,
            setState: setNewRoutes,
            url: `${API_END_POINTS.ROUTE_UPLOAD_GTFS}`,
            forms: [
                {
                    name: 'gtfs_route',
                    label: 'routes.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#routestxt',
                    requiredFields: [
                        { field: 'route_short_name', des: '' },
                        { field: 'route_long_name', des: '' },
                        { field: 'route_color', des: '' }
                    ],
                    additionalFields: [
                        { field: 'route_min_balance', des: '' },
                        { field: 'route_polyline', des: '' }
                    ]

                }
            ],
        },
        {
            stepName: 'Shapes',
            // done: activeStep <= 2 ? false : true,
            done: !!newShapes,
            setState: setNewShapes,
            url: `${API_END_POINTS.SHAPE_UPLOAD_GTFS}`,
            forms: [
                {
                    name: 'gtfs_shape',
                    label: 'shapes.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#shapestxt',
                    requiredFields: [
                        { field: 'shape_id', des: 'Make sure this shape_id is identical as route_short_name in routes.txt' },
                        { field: 'shape_pt_lat', des: '' },
                        { field: 'shape_pt_lon', des: '' },
                        { field: 'shape_pt_sequence', des: '' }
                    ],
                    additionalFields: []

                }
            ],
        },
        {
            stepName: 'Schedules',
            // done: activeStep <= 3 ? false : true,
            done: !!newSchedule,
            setState: setNewSchedules,
            url: `${API_END_POINTS.SCHEDULE_UPLOAD_GTFS}`,
            forms: [
                {
                    name: 'gtfs_schedule',
                    label: 'calendar.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#calendartxt',
                    requiredFields: [
                        { field: 'service_id', des: 'Please make sure values under this field are the same with route_short_name in routes.txt' },
                        { field: 'monday', des: '' },
                        { field: 'tuesday', des: '' },
                        { field: 'wednesday', des: '' },
                        { field: 'thursday', des: '' },
                        { field: 'friday', des: '' },
                        { field: 'saturday', des: '' },
                        { field: 'sunday', des: '' }],
                    additionalFields: []
                },
                {
                    name: 'gtfs_frequency',
                    label: 'frequencies.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#frequenciestxt',
                    requiredFields: [
                        { field: 'trip_id', des: 'Please make sure values under this field are the same with route_short_name in routes.txt' },
                        { field: 'start_time', des: '' },
                        { field: 'end_time', des: '' },
                        { field: 'headway_secs', des: '' }
                    ],
                    additionalFields: []
                }
            ],
        },
        {
            stepName: 'Fares',
            // done: activeStep <= 4 ? false : true,
            done: !!newFares,
            setState: setNewFares,
            url: `${API_END_POINTS.FARE_UPLOAD_GTFS}`,
            forms: [
                { //fare_id,route_id,origin_id,destination_id,contains_id - gtfs fare rules
                    name: 'gtfs_fare_rules',
                    label: 'fare_rules.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#fare_rulestxt',
                    requiredFields: [
                        { field: 'fare_id', des: 'Make sure this id is identical as fare_attributes.txt' },
                        { field: 'route_id', des: 'Make sure this route id is identical as route_short_name in routes.txt' },
                        { field: 'origin_id', des: 'origin_id must be same with stop_name in stops.txt' },
                        { field: 'destination_id', des: 'destination_id must be same with stop_name in stops.txt' }
                    ],
                    additionalFields: []
                },
                {// fare_id,price,currency_type,payment_method,transfers,transfer_duration - gtfs fare att
                    name: 'gtfs_fare_attributes',
                    label: 'fare_attributes.txt',
                    referenceUrl: 'https://developers.google.com/transit/gtfs/reference#fare_attributestxt',
                    requiredFields: [
                        { field: 'fare_id', des: 'Make sure this id is identical as fare_rules.txt' },
                        { field: 'price', des: 'Adult fare amount for origin_id to destination_id' },
                        // { field: 'currency_type', des: '' }
                    ],
                    additionalFields: [
                        { field: 'child_price', des: 'Child fare amount for origin_id to destination_id' },
                        { field: 'senior_price', des: 'Senior fare amount for origin_id to destination_id' },
                        { field: 'oku_price', des: 'OKU fare amount for origin_id to destination_id' },
                        { field: 'foreign_adult_price', des: 'Foreigner Adult fare amount for origin_id to destination_id' },
                        { field: 'foreign_child_price', des: 'Foreigner Child fare amount for origin_id to destination_id' },
                    ]
                }
            ],
        }
    ], [activeStep])

    const routeTableHeader = useMemo(() => [
        {
            Header: 'Route Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Short Name',
            accessor: 'shortName',
            // disableFilters: true
        }
    ], [])

    const stopTableHeader = useMemo(() => [
        {
            Header: 'Stop Name',
            accessor: 'name'
            // disableFilters: true,
        },
        // {
        //     Header: 'Latitude',
        //     accessor: 'latitude',
        //     disableFilters: true
        // },
        // {
        //     Header: 'Longitude',
        //     accessor: 'longitude',
        //     disableFilters: true
        // }
    ], [])

    const fareTableHeader = useMemo(() => [
        {
            Header: 'Route Short Name',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'fareMessage',
            // disableFilters: true
        }
    ], [])

    const scheduleTableHeader = useMemo(() => [
        {
            Header: 'Route Short Name',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'Schedule Status',
            accessor: 'scheduleMessage',
            // disableFilters: true
        },
        {
            Header: 'Headway Status',
            accessor: 'frequencyMessage',
            // disableFilters: true
        }
    ], [])

    const shapeTableHeader = useMemo(() => [
        {
            Header: 'Route Short Name',
            accessor: 'routeShortName',
            // disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'message',
            // disableFilters: true
        }
    ], [])

    useEffect(() => {
        window.onbeforeunload = confirmExit;
        function confirmExit() {
            return "show warning";
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [])

    const handleSubmitFile = useCallback((e, index, fileIdx) => {
        const f = e?.target?.files[0]
        const name = e?.currentTarget?.name
        if (f) {
            const prev = file ? [...file] : []
            prev[fileIdx] = [name, f, steps[index]]
            setFile([...prev])
        } else {
            setFile(null)
        }
    }, [file])

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()

        const formData = new FormData()

        for (let i = 0; i < file.length; i++) {
            const d = file[i]
            if (!d) return toast.error('please provide the file required')
            const [name, data, url] = d
            formData.append(name, data)
            // 
        }

        // 
        const stepData = file[0][2]
        ApiRequest.fetch({
            method: 'post',
            url: stepData.url,
            data: formData
        }).then((data) => {
            // 
            stepData.setState(data)
            toast.success('Success!')
        }).catch((e) => {
            // 
        }).finally(() => {
            setFile(null)
            setAllowNext(true)
            setLoading(false)
        })

    }

    const handleGoNext = () => {
        if (activeStep + 1 <= steps.length - 1) {
            setActiveStep(activeStep + 1)
        } else {
            handleCancel()
        }
        setAllowNext(false)
    }

    const handleGoPrev = () => {
        setFile(null)
        if (activeStep - 1 >= 0) {
            setActiveStep(activeStep - 1)
        } else {
            handleCancel()
        }
        setAllowNext(false)
    }

    const handleClearFile = useCallback((index) => {
        const prev = [...file]
        prev[index] = null
        setFile([...prev])

        const clear = prev.find((e) => e !== null)
        if (!clear) {
            setFile(null)
        }
    }, [file])

    const handleCancel = (done = false) => {
        setActiveStep(0)
        setFile(null)
        setStarted(false)
        setNewFares(null)
        setNewRoutes(null)
        setNewSchedules(null)
        setNewStops(null)

        if (done) {
            toast.success('Congratulation! GTFS Successfully Loaded')
        }
    }

    return (
        <ContentWrapper fluid={false} className='mt-5' >
            <LoadingModal loading={loading} />
            <Card className='mb-3' >
                <Card.Body>
                    <Card.Title>Quick Start GTFS</Card.Title>
                    <Alert variant='info' >
                        <Alert.Heading ><InfoCircle className='m-1' /> What is GTFS?</Alert.Heading>
                        The General Transit Feed Specification (GTFS),
                        also known as GTFS static or static transit to differentiate it from the GTFS realtime extension,
                        defines a common format for public transportation schedules and associated geographic information.
                        GTFS "feeds" let public transit agencies publish their transit data and developers write applications that consume that data in an interoperable way.
                        <br />
                        <br />
                        <a href='https://developers.google.com/transit/gtfs/reference' target='_blank' >See how Google does this</a>
                        <br />
                        <a href='https://justnaik-assets.s3.ap-southeast-1.amazonaws.com/justnaik-gtfs-sample/JUSTNAIK+GTFS+EXAMPLE-v1.xlsx' target='_blank' >Download GTFS Samples (Excel)</a>
                        <br />
                        <a href='https://docs.google.com/spreadsheets/d/1A3lepOaeqZnBB7lX0B7JqEv1AZPzQFahNsUs5fdZPbo/edit?usp=sharing' target='_blank' >Download GTFS Samples (Google Sheet)</a>
                        <br />
                        <a href='https://justnaik-assets.s3.ap-southeast-1.amazonaws.com/justnaik-gtfs-sample/JUSTNAIK_GTFS-v1.zip' target='_blank' >Download GTFS Samples (Zip)</a>
                    </Alert>
                </Card.Body>
            </Card>
            <Row className='mb-3' >
                {
                    newStops &&
                    <Col className='mb-3' sm={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title>Added Stops</Card.Title>
                                {
                                    newStops.length > 0 ?
                                        <Table numbering columns={stopTableHeader} data={newStops} />
                                        :
                                        <p>No new stop was added</p>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {
                    newRoutes &&
                    <Col className='mb-3' sm={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title>Added Routes</Card.Title>
                                {
                                    newRoutes.length > 0 ?
                                        <Table numbering columns={routeTableHeader} data={newRoutes} />
                                        :
                                        <p>No new route was added</p>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {
                    newShapes &&
                    <Col className='mb-3' sm={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title>Added Polyline Shapes</Card.Title>
                                {
                                    newShapes.length > 0 ?
                                        <Table numbering columns={shapeTableHeader} data={newShapes} />
                                        :
                                        <p>No new shape was added</p>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {
                    newSchedule &&
                    <Col className='mb-3' sm={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title>Schedule & Headway Status</Card.Title>
                                {
                                    newSchedule.length > 0 ?
                                        <Table numbering columns={scheduleTableHeader} data={newSchedule} />
                                        :
                                        <p>No new schedule was added</p>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
                {
                    newFares &&
                    <Col className='mb-3' sm={12} md={6} >
                        <Card>
                            <Card.Body>
                                <Card.Title>Fares Status</Card.Title>
                                {
                                    newFares.length > 0 ?
                                        <Table numbering columns={fareTableHeader} data={newFares} />
                                        :
                                        <p>No new fare was added</p>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
            {
                started ?
                    <div>
                        <Card className='mb-3' >
                            {/* <Card.Body> */}
                            <Stepper className='overflow-auto' activeStep={activeStep}>
                                {
                                    steps &&
                                    steps.map(({ stepName, done }, key) =>
                                        <Step title={`${stepName} : ${done ? 'Checked' : `${activeStep > key ? 'Skipped' : 'Pending'}`}`} styleConfig={{ completedBgColor: done ? 'seagreen' : 'orange', activeBgColor: 'tomato' }} key={key} label={stepName} >
                                            {done ? <Check /> : activeStep > key ? <Dash /> : key + 1}
                                        </Step>
                                    )
                                }
                            </Stepper>
                            {/* </Card.Body> */}
                        </Card>
                        <Card className='mb-3' >
                            <Form onSubmit={handleSubmit} >
                                <Card.Body>
                                    <Alert variant='primary'>
                                        <Alert.Heading> <InfoCircle /> Pro Tip!</Alert.Heading>
                                        You can always use Excel or Other CSV Tool Editor to edit your data.<br />
                                        {/* Just make sure to always save it in <i>.txt</i> file */}
                                    </Alert>
                                    {
                                        steps &&
                                        steps[activeStep].forms.map(({ name, label, referenceUrl, requiredFields, additionalFields }, key) => (
                                            <div key={key}>
                                                <InputGroup className='mt-3' >
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text title='Help' ><a href={referenceUrl} target='_blank' ><QuestionCircle /></a></InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormFile required accept='text/plain, .csv' onChange={(e) => handleSubmitFile(e, activeStep, key)} key={key} custom label={file && file[key] ? file[key][1].name : label} name={name} id={name} />
                                                    {
                                                        file && file[key] &&
                                                        <InputGroup.Append>
                                                            <InputGroup.Text as='button' onClick={() => handleClearFile(key)} title='Clear' ><X color='red' /></InputGroup.Text>
                                                        </InputGroup.Append>
                                                    }
                                                </InputGroup>
                                                <Alert className='py-2 my-1' style={{ fontSize: 13 }} variant='warning' >
                                                    Required fields :<br />
                                                    {
                                                        requiredFields.map(({ field, des }, key2) => (
                                                            <p className='py-0 my-0' key={key2} > <Plus /> {field} {des ? " - " + des : ''}<br /></p>
                                                        ))
                                                    }
                                                </Alert>
                                                {
                                                    additionalFields && additionalFields.length > 0 &&
                                                    <Alert className='py-2 my-1' style={{ fontSize: 13 }} variant='info' >
                                                        Additional fields (Not compulsory):<br />
                                                        {
                                                            additionalFields.map(({ field, des }, key2) => (
                                                                <p className='py-0 my-0' key={key2} > <Plus /> {field} {des ? " - " + des : ''}<br /></p>
                                                            ))
                                                        }
                                                    </Alert>
                                                }
                                            </div>
                                        ))
                                    }
                                </Card.Body>
                                <Card.Footer className='text-right' >
                                    {
                                        (activeStep === 0 || activeStep === steps.length - 1) &&
                                        <Button className='ml-3' variant={activeStep === steps.length - 1 ? 'primary' : 'danger'} onClick={() => handleCancel(activeStep === steps.length - 1)} >{activeStep === steps.length - 1 ? 'Done' : 'Exit'}</Button>
                                    }
                                    {
                                        activeStep > 0 &&
                                        <Button className='ml-3' variant='warning' onClick={handleGoPrev} >Back to {steps[activeStep - 1].stepName}</Button>
                                    }
                                    {
                                        activeStep >= 0 && activeStep < steps.length - 1 && !allowNext &&
                                        <Button className='ml-3' variant='info' onClick={handleGoNext} >Skip to {steps[activeStep + 1].stepName}</Button>
                                    }
                                    {
                                        activeStep !== null &&
                                        <Button className='ml-3' disabled={file === null ? true : false} type='submit' >Submit {steps[activeStep].stepName}</Button>
                                    }
                                    {
                                        allowNext && activeStep !== steps.length - 1 &&
                                        <Button className='ml-3' onClick={handleGoNext} >Next</Button>
                                    }
                                </Card.Footer>
                            </Form>
                        </Card>
                    </div>
                    :
                    <Card>
                        <Card.Body className='text-center' >
                            <Button onClick={() => setStarted(true)} >Get Started!</Button>
                        </Card.Body>
                    </Card>
            }
            <Prompt when={started} message='This page is asking you to confirm that you want to leave - data you have entered may not be saved.' />
        </ContentWrapper>
    )
}

export default GTFSPage