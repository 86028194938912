import { GENERAL } from "."

class Utils {
    static currencyFormatter(num) {
        return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    static getDeductionValue(total_amount) {
        return +total_amount * GENERAL.COMISSION_CUT
    }

    static get3SegmentFocusStops = (rawStops = []) => {
        const stops = rawStops.filter(({ stopId }) => stopId && stopId !== 'null')
        const len = stops.length
        if (len >= 3) {
            return [stops[Math.round(len / 3)].sequence, stops[Math.round(len / 2)].sequence, stops[Math.floor(len / 1.15)].sequence]
        }
        //too short to implement calculation
        return null
    }
    static getAllSegmentFocusStops = (rawStops = []) => {
        const sequence = rawStops.filter(({ stopId }) => stopId && stopId !== 'null').map(a => a.sequence)
        
        //too short to implement calculation
        return sequence
    }

    static isLatitude(lat) {
        return isFinite(lat) && Math.abs(lat) <= 90;
    }

    static isLongitude(lng) {
        return isFinite(lng) && Math.abs(lng) <= 180;
    }

    static humanFileSize(size) {
        const i = +size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (+size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

}

export default Utils