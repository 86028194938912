import { call, put, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES } from '../../config'
import { getTripScheduler, createTripScheduler, updateTripScheduler } from '../api'
import { tripSchedulerFailure, tripSchedulerSuccess, tripSchedulerCreateFailure, tripSchedulerCreateSuccess, tripSchedulerUpdateFailure, tripSchedulerUpdateSuccess } from '../actions'
import { toast } from 'react-toastify'

export function* watchTripSchedulerRequests() {
  yield takeLatest(ACTION_TYPES.TRIP_SCHEDULER_REQUESTED, requestTripSchedulerG)
  yield takeLatest(ACTION_TYPES.TRIP_SCHEDULER_CREATE_REQUESTED, createTripSchedulerG)
  yield takeLatest(ACTION_TYPES.TRIP_SCHEDULER_UPDATE_REQUESTED, updateTripSchedulerG)
}

function* requestTripSchedulerG() {
  try {
    const ss = yield call(getTripScheduler)
    yield put(tripSchedulerSuccess(ss))
  } catch {
    yield put(tripSchedulerFailure())
  }
}

function* createTripSchedulerG(action) {
  try {
    const s = yield call(createTripScheduler, action.payload)
    yield put(tripSchedulerCreateSuccess(s))
    toast.success(`Scheduled Trip created`)
  } catch {
    yield put(tripSchedulerCreateFailure())
  }
}

function* updateTripSchedulerG(action) {
  const { id, ...data } = action.payload
  try {
    const s = yield call(updateTripScheduler, id, data)
    yield put(tripSchedulerUpdateSuccess(s))
    toast.success(`Scheduled Trip updated`)
  } catch {
    yield put(tripSchedulerUpdateFailure())
  }
}