import SignUp from "./SignUp";
import { connect } from "react-redux";
import { requestUserSignUp } from "../../redux/actions";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {
    requestUserSignUp
})(SignUp);