import React, { useMemo } from 'react'
import { Chart } from 'react-charts'

const SimpleChart = ({ data, type, title, tooltip = false, stacked = false, style = {} }) => {
    const axes = useMemo(() => [
        { primary: true, type: 'ordinal', position: 'bottom' },
        { type: 'linear', position: 'left', stacked }
    ], [])

    // 

    return (
        <div
            style={{
                width: '100%',
                height: '250px',
                ...style
            }}
        >
            {
                title &&
                <p className='text-uppercase' >{title}</p>
            }
            <Chart
                style={{ overflow: 'hidden', position: 'inherit' }}
                series={{ type }}
                data={data}
                axes={axes}
                tooltip={{ ...tooltip }}
            />
        </div>
    )
}

export default SimpleChart