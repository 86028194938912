import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Card, Col, Form, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { CloudUpload, InfoCircle } from 'react-bootstrap-icons'
import moment from 'moment'
import PolylineUtils from '@mapbox/polyline'
import * as geolib from 'geolib';
import { ContentWrapper, JustnaikAppIntro, Modal, SimpleInputGroup, Table } from '../../components'
import { ApiRequest, exportData } from '../../helpers'
import { API_END_POINTS } from '../../config'
import CSVReader from 'react-csv-reader'
import { CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

const WEEKEND_DAY_NUM = [0, 6]

const TransactionQuery = ({ routeOpt = [], vehicleOpt = [], driverOpt = [], onSubmitQuery = () => { }, onClearQuery = () => { } }) => {
    const [route, setRoute] = useState(null)
    const [amPm, setAmPm] = useState('All')
    const [weekendWeekday, setWeekendWeekday] = useState('All')
    const [selectFromDate, setFromDate] = useState(null)
    const [selectToDate, setToDate] = useState(null)
    const [vehicle, setVehicle] = useState(null)
    const [driver, setDriver] = useState(null)
    const [paidBy, setPaidBy] = useState('All')
    const [canFilter, setCanFilter] = useState(false);

    const handleSubmitQuery = (e) => {
        e.preventDefault()
        if(!canFilter){toast.error('please select the month to filter')
    return
    }
        const query = { route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }
        if (!route || route === 'null') { query['route'] = null }
        if (!selectFromDate || selectFromDate === 'null') { query['selectFromDate'] = null }
        if (!selectToDate || selectToDate === 'null') { query['selectToDate'] = null }
        if (!vehicle || vehicle === 'null') { query['vehicle'] = null }
        if (!driver || driver === 'null') { query['driver'] = null }
        onSubmitQuery(query)
    }

    const handleClear = () => {

        setFromDate(null)
        setToDate(null)
        setRoute(null)
        setAmPm('All')
        setWeekendWeekday('All')
        setVehicle(null)
        setDriver(null)
        setPaidBy('All')
        setCanFilter(false)

        onClearQuery()
    }
    const setperiod = async (e) => {
        // 
        const mon = e.currentTarget.value
        const start = await moment(mon).startOf('month').format("YYYY-MM-DD 00:00:00");
        const end = await moment(mon).endOf('month').format("YYYY-MM-DD 23:59:59");
        await setFromDate(start)
        setToDate(end)
        setCanFilter(true)
    }
    return (
        <Card>
            <Card.Body >
                <h5>Search</h5>

                <Row>
                    <Col lg={4} md={12} sm={12} ></Col>
                    <Col lg={8} md={12} sm={12} >
                        <Form onSubmit={handleSubmitQuery} className='text-right' >

                            {/* <SimpleInputGroup preappendText='Route' >
                                <Form.Control value={route || 'null'} onChange={(e) => setRoute(e.currentTarget.value)} custom as='select' >
                                    <option value={'null'} >select...</option>
                                    {routeOpt.map((opt, i) => <option key={i} value={opt} >{opt}</option>)}
                                </Form.Control>
                            </SimpleInputGroup> */}
                            
                                    <SimpleInputGroup labelWidth={10} preappendText='Month:'>
                                        <Form.Control id='date-from' max={moment().format('YYYY-MM')} onChange={setperiod
                                        } type='month' />
                                    </SimpleInputGroup>
                                
                            <Button className='ml-2' onClick={handleClear} variant='warning' >Clear</Button>
                            <Button className='ml-2' type='submit' >Search</Button>
                        </Form>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const ClaimReportPageApad = ({ user }) => {
    const [tripCollection, setTripCollection] = useState(null)
    const [transaction, setTransaction] = useState(null)
    const [loading, setLoading] = useState(false)
    const [routesArr, setRoutesArr] = useState(null)
    const [filteredTripCollection, setFilteredTripCollection] = useState(null)
    const [ultraFilteredTripCollection, setUltraFilteredTripCollection] = useState(null)
    const [toDate, setToDate] = useState(moment(new Date()).format('DD-MM-YYYY'));
    const [fromDate, setFromDate] = useState(moment(new Date()).subtract(1, 'months').format('DD-MM-YYYY'));
    const [routesData, setRoutesData] = useState({})
    const [tripLog, setTripLog] = useState({})
    const [tripLogWithS, setTripLogWithS] = useState({})
    const [tripLogStops, setTripLogStops] = useState({})
    const [routeStops, setRouteStops] = useState({})
    const [apadData, setApadData] = useState([])
    const [apadDataWithNotOk, setApadDataWithNotOk] = useState([])
    const [apadDataWithNotOkInvalidTrips, setApadDataWithNotOkInvalidTrips] = useState([])
    const [onlyInApadData, setOnlyInApadData] = useState([])
    const [tripIdWithApadDataChanged, setTripIdWithApadDataChanged] = useState(false)
    const [tripIdWithApadDataPopulated, setTripIdWithApadDataPopulated] = useState({})
    let tripIdWithApadData = {}
    const [tempFilterData, setTempFilterData] = useState([])

    const handleGetTransactionHistory = async () => {
        
    }

    const handleGetAllRoute = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET_ALL}`,
            params: { showNotActive: false }
        }).then(async (data) => {
            await setRoutesArr(data)
        }).catch(e => { })
    }


    useEffect(() => {
        const routeWithStops = {}
        routesArr && routesArr.forEach(({ id }) => {
            if (!routeWithStops[id]) {
                ApiRequest.fetch({
                    method: 'get',
                    url: `${API_END_POINTS.STOP_GET_ALL}`,
                    params: {
                        routeId: id
                    }
                }).then((data) => {
                    routeWithStops[id] = data
                })
            }
        })
        setRoutesData(routesData => ({ ...routesData, ...routeWithStops }))
    }, [routesArr]);



    const [driverList, vehicleList] = useMemo(() => {
        if (!transaction) return []
        const drivers = []
        const vehicles = []
        const copy = transaction.reverse() // not because i want this in reverse, but i just want to make a copy without temper the original value
        copy.forEach(({ driverName, vehicleRegistrationNumber }) => {
            drivers.push(driverName)
            vehicles.push(vehicleRegistrationNumber)
        })

        return [_.uniq(drivers), _.uniq(vehicles)]
    }, [transaction])

    useEffect(() => {
        handleGetTransactionHistory()
        handleGetAllRoute()

    }, [])

    const tableHeader = useMemo(() => [
        {
            Header: 'Route ID',
            accessor: 'routeId',
            // disableFilters: true
        },
        {
            Header: 'OD',
            accessor: 'routeName',
            // disableFilters: true
        },
        {
            Header: 'IB/OB',
            accessor: 'direction',
            // disableFilters: true
        },
        // {
        //     Header: 'Trip ID',
        //     accessor: 'tripId',
        //     // disableFilters: true
        // },
        {
            Header: 'Service Date',
            accessor: 'serviceDate',
            // disableFilters: true
        },
        {
            Header: 'Start Point',
            accessor: 'startPoint',
            // disableFilters: true
        },
        {
            Header: 'RPH No.',
            accessor: 'tripId',
            // disableFilters: true
        },
        {
            Header: 'Bus Plate Number',
            accessor: 'busPlate',
            // disableFilters: true
        },
        {
            Header: 'Bus Age',
            accessor: 'busAge',
            // disableFilters: true
        },
        {
            Header: 'Charge/KM',
            accessor: 'kmRate',
            // disableFilters: true
        },
        {
            Header: 'Driver ID',
            accessor: 'driverIdentification',
            // disableFilters: true
        },
        {
            Header: 'Bus Stops Travel',
            accessor: 'busStops',
            // disableFilters: true
        },
        {
            Header: 'Travel(KM) ',
            accessor: 'kmApad',
            // disableFilters: true
        },
        {
            Header: 'Total Claim ',
            accessor: 'totalClaim',
            // disableFilters: true
        },
        {
            Header: 'Travel(KM) GPS ',
            accessor: 'kmApadG',
            // accessor: 'kmGps',
            // disableFilters: true
        },
        {
            Header: 'Total Claim GPS',
            accessor: 'totalClaimG',
            // disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'status',
            // disableFilters: true
        },
        {
            Header: 'status of the trip (duplicate, trip outside schedule,no gps tracking, breakdown, replacement)',
            accessor: 'statusDetail',
            // disableFilters: true
        },
        {
            Header: 'KM as per BOP = ',
            accessor: 'kmApadB',
            // disableFilters: true
        },
        {
            Header: 'Claim as per BOP (RM)',
            accessor: 'kmRateB',
            // disableFilters: true
        },
        {
            Header: 'Start point',
            accessor: 'actualStartS',
            // disableFilters: true
        },
        {
            Header: 'Service Start Time',
            accessor: 'serviceStart',
            // disableFilters: true
        },
        {
            Header: 'Actual Start Time',
            accessor: 'actualStart',
            // disableFilters: true
        },
        {
            Header: 'Sales Start Time',
            accessor: 'salesStart',
            // disableFilters: true
        },
        {
            Header: 'Service End Time',
            accessor: 'serviceEnd',
            // disableFilters: true
        },
        {
            Header: 'Actual End Time',
            accessor: 'actualEnd',
            // disableFilters: true
        },
        {
            Header: 'Sales End Time',
            accessor: 'salesEnd',
            // disableFilters: true
        },
        // {
        //     Header: 'Price',
        //     accessor: 'fareTypeAmount_',
        //     // disableFilters: true
        // },
        {
            Header: 'Punctuality',
            accessor: 'punctuality',
            // disableFilters: true
        },
        {
            Header: 'Passsengers Boarding Count',
            accessor: 'passenger',
            // disableFilters: true
        },
        {
            Header: 'Total Sales Amount (RM)',
            accessor: 'totalAmount',
            // disableFilters: true
        },
        {
            Header: 'Total On',
            accessor: 'totalOn',
            // disableFilters: true
        },
        {
            Header: 'Transfer Count',
            accessor: 'transferCount',
            // disableFilters: true
        },
        {
            Header: 'Monthly Pass',
            accessor: 'monthlyPass',
            // disableFilters: true
        },
        {
            Header: 'Adult',
            accessor: 'noOfAdult',
            // disableFilters: true
        },
        {
            Header: 'Child',
            accessor: 'noOfChild',
            // disableFilters: true
        },
        {
            Header: 'Senior',
            accessor: 'noOfSenior',
            // disableFilters: true
        },
        {
            Header: 'student',
            accessor: 'noOfStudent',
            // disableFilters: true
        },
        {
            Header: 'OKU',
            accessor: 'noOfOku',
            // disableFilters: true
        },
        {
            Header: 'JKM',
            accessor: 'jkm',
            // disableFilters: true
        },
        {
            Header: 'MAIM',
            accessor: 'maim',
            // disableFilters: true
        },
        {
            Header: 'Status (justNaik)',
            accessor: 'statusJ',
            // disableFilters: true
        },


    ], [])

    const tabulated = useMemo(() => {
        const returnData = []
        const mainData = ultraFilteredTripCollection || filteredTripCollection || tripCollection
        if (!mainData) return []
        const sortedDataWithDirRoute = _.orderBy(mainData, ['routeId'], ['asc'])
        const sortedDataWithDir = _.orderBy(sortedDataWithDirRoute, ['obIb'], ['asc'])


        // Object.entries(mainData).forEach(([localTimeGroup, trxs]) => {
        //     const sortedData = _.orderBy(trxs, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['desc', 'desc'])
        const sortedData = _.orderBy(sortedDataWithDir, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['asc', 'asc'])    
        // const addedLocalTime = sortedData?.map((d) => {
        //         d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
        //         return d
        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        // let addedLocalTimeOrdered = _.orderBy(addedLocalTime, ['obIb'],
        //     ['asc']);
        const groupedTestByRoute = _.groupBy(addedLocalTime, item => `"${item.routeId}"`)
        function dict_reverse(obj) {
            let new_obj = {}
            let rev_obj = Object.keys(obj).reverse();
            rev_obj.forEach(function (i) {
                new_obj[i] = obj[i];
            })
            // let addedLocalTimeOrdered = _.orderBy(addedLocalTime, ['obIb'],
            //     ['asc']);
            // const groupedTest = _.groupBy(addedLocalTimeOrdered, item => `"${item.localDate}+${item.routeId}+${item.obIb}"`)
            return new_obj;
        }
        // const groupedTestByRouteRev = dict_reverse(groupedTestByRoute)
        // console.log("groupedTestByRouteRev", groupedTestByRouteRev);
        Object.entries(groupedTestByRoute).forEach(([localTimeGroup, trxs]) => {
            const groupedData = _.groupBy(trxs, 'localDate')
            // const groupedData = _.groupBy(addedLocalTimeOrdered, 'localDate')
            Object.entries(groupedData).forEach(([localTimeGroup, trxs]) => {
                const accumulativeTrip = {
                    'datetime_': moment(trxs[0].startedAt).format('DD-MM-YYYY HH:mm:ss (ddd)'),
                    'checkoutTime_': moment(trxs[0].endedAt).format('DD-MM-YYYY HH:mm:ss'),
                    'uniqueTrip_': new Set(),
                    'totalTripCount_': 0,
                    'uniqueDriver_': new Set(),
                    'totalUniqueDriverCount_': 0,
                    'uniqueVehicle_': new Set(),
                    'totalUniqueVehicleCount_': 0,
                    'uniqueJourney_': new Set(),
                    'totalTransaction_': 0,
                    'totalAmount_': 0,
                    'noOfAdult': 0,
                    'noOfChild': 0,
                    'noOfSenior': 0,
                    'totalChild': 0,
                    'totalSenior': 0,
                    'totalAdult': 0,
                    'noOfOku': 0,
                    'noOfForeignAdult': 0,
                    'noOfForeignChild': 0,
                    'totalRidership_': 0,
                    'cashTotalAmount_': 0,
                    'cashTotalRidership_': 0,
                    'cashlessTotalAmount_': 0,
                    'cashlessTotalRidership_': 0,
                }
                trxs.map((row) => {
                    const totalPax = row.noOfAdult + +row.noOfChild + +row.noOfSenior + +row.noOfOku + +row.noOfForeignAdult + +row.noOfForeignChild
                    accumulativeTrip['uniqueDriver_'].add(row.driverName)
                    accumulativeTrip['uniqueVehicle_'].add(row.vehicleRegistrationNumber)
                    accumulativeTrip['uniqueTrip_'].add(row.tripId)
                    accumulativeTrip['uniqueJourney_'].add(row.journeyId)
                    accumulativeTrip['totalAmount_'] += +row.amount
                    accumulativeTrip['noOfAdult'] += +row.noOfAdult
                    accumulativeTrip['noOfChild'] += +row.noOfChild
                    accumulativeTrip['noOfSenior'] += +row.noOfSenior
                    accumulativeTrip['noOfOku'] += +row.noOfOku
                    accumulativeTrip['noOfForeignAdult'] += +row.noOfForeignAdult
                    accumulativeTrip['noOfForeignChild'] += +row.noOfForeignChild
                    accumulativeTrip['totalRidership_'] += totalPax

                    accumulativeTrip['cashTotalAmount_'] += row.userId ? 0 : +row.amount
                    accumulativeTrip['cashTotalRidership_'] += row.userId ? 0 : totalPax

                    accumulativeTrip['cashlessTotalAmount_'] += row.userId ? +row.amount : 0
                    accumulativeTrip['cashlessTotalRidership_'] += row.userId ? totalPax : 0
                })

                accumulativeTrip['totalUniqueDriverCount_'] = accumulativeTrip.uniqueDriver_.size
                accumulativeTrip['totalUniqueVehicleCount_'] = accumulativeTrip.uniqueVehicle_.size
                accumulativeTrip['totalTripCount_'] = accumulativeTrip.uniqueTrip_.size
                accumulativeTrip['totalTransaction_'] = accumulativeTrip.uniqueJourney_.size
                accumulativeTrip['totalAdult'] = accumulativeTrip.noOfAdult
                accumulativeTrip['localTimeGroup_'] = localTimeGroup.split("+")[0]
                accumulativeTrip['trxs'] = trxs


                //format amount
                accumulativeTrip['totalAmount_'] = (accumulativeTrip['totalAmount_']).toFixed(2)
                accumulativeTrip['cashTotalAmount_'] = (accumulativeTrip['cashTotalAmount_']).toFixed(2)
                accumulativeTrip['cashlessTotalAmount_'] = (accumulativeTrip['cashlessTotalAmount_']).toFixed(2)

                returnData.push(accumulativeTrip)
            })
        })
        return returnData
    }, [tripCollection, filteredTripCollection,ultraFilteredTripCollection])
    const claimData = useMemo(() => {
        const returnData = []
        tabulated.forEach(({
            trxs,
            localTimeGroup_,
            totalAmount_,
            totalRidership_,
            totalTripCount_,
            cashTotalAmount_,
            cashTotalRidership_,
            cashlessTotalAmount_,
            cashlessTotalRidership_,
        }) => {

            const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))
            uniqueTrips.forEach((sameTripTrxs) => {
                const totalByTrip = {
                    totalPax: 0,
                    totalAmount: 0,
                    cash: 0,
                    cashPax: 0,
                    cashless: 0,
                    cashlessPax: 0,
                    cashAdult: 0,
                    cashChild: 0,
                    cashSenior: 0,
                    cashOku: 0,
                    cashFAdult: 0,
                    cashFChild: 0,
                    cashlessAdult: 0,
                    cashlessChild: 0,
                    cashlessSenior: 0,
                    cashlessOku: 0,
                    cashlessFAdult: 0,
                    cashlessFChild: 0,
                    noOfAdult: 0,
                    noOfChild: 0,
                    noOfSenior: 0,
                    noOfOku: 0,
                    trxsTime: []
                }
                sameTripTrxs.forEach(({
                    userId,
                    amount,
                    noOfAdult,
                    noOfChild,
                    noOfSenior,
                    noOfOku,
                    noOfForeignAdult,
                    noOfForeignChild,
                    journeyCreated,
                    journeyEnded
                }) => {
                    const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild
                    totalByTrip.routeId = sameTripTrxs[0].routeShortName
                    totalByTrip.routeName = sameTripTrxs[0].routeName
                    totalByTrip.tripId = sameTripTrxs[0].tripId
                    totalByTrip.actualStartS = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('HH:mm') : '-'
                    totalByTrip.actualStart = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('HH:mm') : '-'
                    totalByTrip.actualEnd = moment(sameTripTrxs[0].endedAt).isValid() ? moment(sameTripTrxs[0].endedAt).format('HH:mm') : '-'
                    totalByTrip.serviceStart = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('HH:mm') : '-'
                    totalByTrip.serviceEnd = moment(sameTripTrxs[0].scheduledEndTime).isValid() ? moment(sameTripTrxs[0].scheduledEndTime).format('HH:mm') : '-'
                    totalByTrip.status = 'No Complete'
                    totalByTrip.statusJ = 'No Complete'
                    totalByTrip.statusDetail = !tripLog[sameTripTrxs[0].tripId] ? 'No GPS Tracking' : sameTripTrxs[0].scheduledAt == null ? 'Trip outside schedule' : ''
                    totalByTrip.busPlate = sameTripTrxs[0].vehicleRegistrationNumber
                    totalByTrip.driverIdentification = sameTripTrxs[0].staffId
                    totalByTrip.direction = sameTripTrxs[0].obIb == 1 ? 'OB' : sameTripTrxs[0].obIb == 2 ? 'IB' : 'LOOP'
                    totalByTrip.noOfAdult += (Number(noOfAdult) + Number(noOfForeignAdult))
                    totalByTrip.noOfChild += (Number(noOfChild) + Number(noOfForeignChild))
                    totalByTrip.noOfSenior += Number(noOfSenior)
                    totalByTrip.noOfOku += Number(noOfOku)
                    totalByTrip.cash += userId ? 0 : amount
                    totalByTrip.cashPax += userId ? 0 : totalPax
                    totalByTrip.cashless += userId ? amount : 0
                    totalByTrip.totalAmount += amount
                    totalByTrip.cashlessPax += userId ? totalPax : 0
                    totalByTrip.cashAdult += userId ? 0 : noOfAdult
                    totalByTrip.cashChild += userId ? 0 : noOfChild
                    totalByTrip.cashSenior += userId ? 0 : noOfSenior
                    totalByTrip.cashOku += userId ? 0 : noOfOku
                    totalByTrip.cashFAdult += userId ? 0 : noOfForeignAdult
                    totalByTrip.cashFChild += userId ? 0 : noOfForeignChild
                    totalByTrip.cashlessAdult += userId ? noOfAdult : 0
                    totalByTrip.cashlessChild += userId ? noOfChild : 0
                    totalByTrip.cashlessSenior += userId ? noOfSenior : 0
                    totalByTrip.cashlessOku += userId ? noOfOku : 0
                    totalByTrip.cashlessFAdult += userId ? noOfForeignAdult : 0
                    totalByTrip.cashlessFChild += userId ? noOfForeignChild : 0
                    totalByTrip.punctuality = sameTripTrxs[0].scheduledAt && sameTripTrxs[0].startedAt && sameTripTrxs[0].scheduledEndTime && sameTripTrxs[0].endedAt && Math.abs(Math.abs(Number(moment(sameTripTrxs[0].scheduledAt).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].startedAt).format('X')))) <= 300 && Math.abs(Math.abs(Number(moment(sameTripTrxs[0].scheduledEndTime).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].endedAt).format('X')))) <= 300 ? 'ONTIME' : 'NOT PUNCTUAL'
                    totalByTrip.startPoint = routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length > 0 ? routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.reduce(function (res, obj) {
                        return (obj.sequence < res.sequence) ? obj : res;
                    })?.name : ''
                    totalByTrip.trxsTime.push(userId ? moment(journeyCreated).format('X') : moment(journeyEnded).format('X'))
                    totalByTrip.busAge = sameTripTrxs[0]?.VehicleAge ? moment().year() - sameTripTrxs[0]?.VehicleAge : ''
                    totalByTrip.kmApad = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                    totalByTrip.kmApadG = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                    totalByTrip.kmRate = sameTripTrxs[0]?.kmRate
                    totalByTrip.totalClaim = totalByTrip.kmRate && totalByTrip.kmApad ? totalByTrip.kmRate / totalByTrip.kmApad : ''
                    totalByTrip.totalClaimG = totalByTrip.totalClaim && totalByTrip.kmApadG ? totalByTrip.totalClaim * totalByTrip.kmApadG : ''
                    totalByTrip.kmApadB = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                    totalByTrip.kmRateB = sameTripTrxs[0]?.kmRate
                    totalByTrip.monthlyPass = '-'
                    totalByTrip.jkm = '-'
                    totalByTrip.maim = '-'
                    totalByTrip.passenger = 0
                    totalByTrip.totalOn = totalByTrip.noOfAdult + totalByTrip.noOfChild + totalByTrip.noOfSenior + totalByTrip.noOfOku
                    totalByTrip.noOfStudent = 0
                    totalByTrip.transferCount = 0
                    totalByTrip.dutyId = sameTripTrxs[0]?.deviceSerialNumber
                    totalByTrip.serviceDate = sameTripTrxs[0]?.scheduledAt ? moment(sameTripTrxs[0].scheduledAt).format('DD-MM-YYYY (ddd)') : sameTripTrxs[0]?.startedAt ? moment(sameTripTrxs[0].startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                    if (routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length > 0) {
                        const decodedPolyline = routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)
                        const arrIb = []
                        if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                            decodedPolyline?.forEach((poly, index) => {
                                for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                    const isNear = geolib.isPointWithinRadius(
                                        { latitude: poly.latitude, longitude: poly.longitude },
                                        { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                        200
                                    );
                                    if (isNear) {
                                        arrIb.push(poly.sequence)
                                        break
                                    }

                                }


                            });
                        }
                        const uniqueStop = [...new Set(arrIb)]
                        if (sameTripTrxs[0].endedAt && routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length * 15 / 100 <= uniqueStop.length) {
                            totalByTrip.statusJ = 'Complete'
                        }
                        totalByTrip.busStops = uniqueStop.length
                    }
                    if (sameTripTrxs[0]?.apadPolygon?.length > 0) {
                        const decodedPolyline = PolylineUtils.decode(sameTripTrxs[0].apadPolygon)
                        const arrIb = []
                        const arrIbBetween = []
                        if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                            decodedPolyline?.forEach((poly, index) => {
                                if(index === 0 || index === 5){
                                for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                    const isNear = geolib.isPointWithinRadius(
                                        { latitude: poly[0], longitude: poly[1] },
                                        { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                        200
                                    );
                                    // 
                                    if (isNear) {
                                        // check if inbound and scheduled?
                                        arrIb.push(poly)
                                        break
                                    }

                                }

                            }
                            });
                            for (let index = 1; index < decodedPolyline.length -1; index++) {
                                const element = decodedPolyline[index];
                                for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                    const isNear = geolib.isPointWithinRadius(
                                        { latitude: element[0], longitude: element[1] },
                                        { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                        200
                                    );
                                    // 
                                    if (isNear) {
                                        // check if inbound and scheduled?
                                        arrIbBetween.push(element)
                                        break
                                    }

                                }
                                
                            }
                        }
                       
                        if (arrIb?.length >= 2 && arrIbBetween?.length >=1) {

                            totalByTrip.status = 'Complete'
                        }
                    }

                })
                const numberArray = totalByTrip.trxsTime.map(Number);

                totalByTrip.salesStart = isNaN(moment.unix(Math.min(...numberArray))) ? '-' : moment.unix(Math.min(...numberArray)).format('HH:mm');
                totalByTrip.salesEnd = isNaN(moment.unix(Math.max(...numberArray))) ? '-' : moment.unix(Math.max(...numberArray)).format('HH:mm');

                returnData.push(totalByTrip);
            })
        })
        return returnData
    }, [tabulated]);

    const handleFilterTransactionData = useCallback(async({ route, amPm, selectFromDate, selectToDate, vehicle, driver, weekendWeekday, paidBy }) => {
        setOnlyInApadData([])
        setTripIdWithApadDataPopulated()
        tripIdWithApadData = {}


        let transactionWithScheduler
        setLoading(true)
        try {
            const claimdata2 = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.CLAIM_DETAILS_COLLECTION_BY_DATE}`,
                params: {
                    timestamp: new Date(),
                }
            })

            const timetableData = await ApiRequest.fetch({
                method: 'get',
                url: `${API_END_POINTS.SCHEDULEV2TIMETABLE_GET_ALL}`,
            })
            const transactionWithStartFiltered = claimdata2.filter(({ startedAt, endedAt, scheduledAt }) => (moment(endedAt).diff(moment(startedAt), 'minutes') >= 10 && moment(startedAt).isSameOrAfter(moment('2022-09-17 00:00:00'))) || (moment(scheduledAt).isSameOrAfter(moment('2022-09-17 00:00:00'))));
            transactionWithScheduler = transactionWithStartFiltered.map((trx) => {
                if (trx.startedAt && !trx.scheduledAt) {
                    let start_time = moment(String(trx.startedAt)).format('HH:mm:ss')
                    let dayOfTrip = moment(String(trx.startedAt)).format('YYYY-MM-DD')
                    let dayOfTripName = moment(String(trx.startedAt)).format('dddd')
                    let end_time = moment(String(trx.endedAt)).format('HH:mm:ss')
                    let goal = moment(`2022-01-01 ${start_time}`).format("X")
                    let goalEnd = moment(`2022-01-01 ${end_time}`).format("X")
                    let timetableDataTemp = timetableData.filter(({ day, direction_id, route_id }) => day.toLowerCase() == dayOfTripName.toLowerCase() && direction_id == trx.obIb && route_id == trx.routeId)
                    let closestStart = timetableDataTemp.reduce(function (prev, curr) {
                        let curr_time = moment(`2022-01-01 ${curr.start_time}`).format("X")
                        return (Math.abs(curr_time - goal) < Math.abs(prev - goal) ? curr_time : prev);
                    }, 0);
                    let closestEnd = timetableDataTemp.reduce(function (prev, curr) {
                        let curr_time = moment(`2022-01-01 ${curr.end_time}`).format("X")
                        return (Math.abs(curr_time - goalEnd) < Math.abs(prev - goalEnd) ? curr_time : prev);
                    }, 0);
                    const closestScheduledAt = `${dayOfTrip} ${moment.unix(closestStart).format("HH:mm:ss")}`
                    const closestScheduledEnd = moment(`${dayOfTrip} ${moment.unix(closestEnd).format("HH:mm:ss")}`).format()
                    return { ...trx, scheduledAt: closestScheduledAt, scheduledEndTime: closestScheduledEnd };
                } else {
                    return { ...trx }
                }

            })

            const uniqueRouteIds = [...new Set(transactionWithScheduler.map(obj => obj.routeId))];
            try {
                const routeFetch = uniqueRouteIds.map((routeId) =>
                    ApiRequest.fetch({
                        method: 'get',
                        url: `${API_END_POINTS.ROUTE_GET_STOPS}/${routeId}`
                    })
                )


                const result = await Promise.all(routeFetch.map(p => p.catch(e => e)));
                const routeResolve = result.filter(result => !(result instanceof Error));
                routeResolve.forEach(async (data) => {
                    if (data.stops.length > 0) {
                        setRouteStops(routeStops => ({ ...routeStops, [data?.stops[0]?.routeId]: data.stops }))
                    }
                })
            } catch (error) {

            }
            const uniqueTripIds = [...new Set(transactionWithScheduler.map(obj => obj.tripId))];
            await ApiRequest.fetch({
                method: 'post',
                url: `${API_END_POINTS.GET_TRIP_LOG_BULK}`,
                data: {
                    uniqueTripIds
                }
            }).then(async (tripResolve) => {
                tripResolve.forEach(async (data) => {
                    if (data.length > 0) {
                        const tripLogWithStop = data.filter(function (item) {
                            return item.stopId != "null";
                        })
                        const tripLogWithApad = data
                        const tripLogWithStartStop = tripLogWithStop.reduce(
                            (prev, current) =>
                                (prev?.sequence ?? current?.sequence) >= current?.sequence ? current : prev,
                            null,
                        );

                        tripLogWithApad.length > 0 && setTripLog(tripLog => ({ ...tripLog, [tripLogWithApad[0]?.tripId]: tripLogWithApad }))
                        setTripLogWithS(tripLogWithS => ({ ...tripLogWithS, [tripLogWithStop[0]?.tripId]: tripLogWithStop }))
                        setTripLogStops(tripLogStops => ({ ...tripLogStops, [data[0].tripId]: tripLogWithStop }))
                    }
                })
            }).catch(e => { })

            setTransaction(transactionWithScheduler)
            const sortedData = _.orderBy(transactionWithScheduler, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['desc', 'desc'])
            const addedLocalTime = sortedData?.map((d) => {
                d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
                return d
            })
            let addedLocalTimeOrdered = _.orderBy(addedLocalTime, ['obIb'],
                ['asc']);
            const groupedTest = _.groupBy(addedLocalTimeOrdered, item => `"${item.localDate}+${item.routeId}+${item.obIb}"`)
            const groupedData = _.groupBy(addedLocalTimeOrdered, 'localDate')
        } catch (error) { }
        finally {
            setLoading(false)
        }










        if (!transactionWithScheduler) return []
        // if (!route) {
        //     toast.warning("Please select the route to fetch records!")
        //     return
        // }
        if (apadData.length === 0) {
            toast.warning("Please upload the csv file and Try again!")
            return
        }

        let moments = apadData.map(({ date }) => moment(String(date), "DD-MM-YYYY"))
        // console.log("apad data",apadData);
        let apad_routes = apadData.map(a => `${a.route_short_name}+${moment(moment(String(a.date), "DD-MM-YYYY")).format('YYYY-MM-DD')}`)
        // console.log("apad_routes",apad_routes);
        const maxDate = moment.max(moments).format('YYYY-MM-DD')
        const minDate = moment.min(moments).format('YYYY-MM-DD')
        // console.log("tra",transaction);
        const filteredByDate = transactionWithScheduler.filter(({ startedAt, scheduledAt}) => startedAt ? moment(moment(startedAt).format('YYYY-MM-DD')).isSameOrAfter(minDate) && moment(moment(startedAt).format('YYYY-MM-DD')).isSameOrBefore(maxDate) : moment(moment(scheduledAt).format('YYYY-MM-DD')).isSameOrAfter(minDate) && moment(moment(scheduledAt).format('YYYY-MM-DD')).isSameOrBefore(maxDate))
        // console.log("filteredByDate",filteredByDate);
        const filteredByDateAndRoute = filteredByDate.filter(({ startedAt, scheduledAt,routeShortName})=> apad_routes.includes(`${routeShortName}+${startedAt ? moment(startedAt).format('YYYY-MM-DD'): moment(scheduledAt).format('YYYY-MM-DD')}`))
    //    console.log("filteredByDateAndRoute",filteredByDateAndRoute); 
        const filtered = filteredByDateAndRoute.filter(({ createdAt, startedAt, scheduledAt, routeShortName, driverName, vehicleRegistrationNumber, userId, tripId }, index) => {
            let returnVal = true
            if (amPm !== 'All') {
                returnVal = String(moment(startedAt).format('a')).toLowerCase() === String(amPm).toLowerCase()
                if (!returnVal) return false
            }

            if (weekendWeekday !== 'All') {
                const isWeekendWeekday = WEEKEND_DAY_NUM.includes(new Date(startedAt).getDay()) ? 'Weekend' : 'Weekday'
                returnVal = isWeekendWeekday === weekendWeekday
                if (!returnVal) return false
            }

            if (selectFromDate) {
                setFromDate(moment(selectFromDate).format('DD-MM-YYYY'))
                // 
                returnVal = startedAt ? new Date(startedAt).valueOf() >= new Date(selectFromDate).valueOf() : new Date(scheduledAt).valueOf() >= new Date(selectFromDate).valueOf()
                if (!returnVal) return false
            }

            if (selectToDate) {
                setToDate(moment(selectToDate).format('DD-MM-YYYY'))
                returnVal = startedAt ? new Date(startedAt).valueOf() <= new Date(selectToDate).valueOf() : new Date(scheduledAt).valueOf() <= new Date(selectToDate).valueOf()
                if (!returnVal) return false
            }

            if (route) {
                returnVal = routeShortName === route
                if (!returnVal) return false

            }

            if (vehicle) {
                returnVal = vehicleRegistrationNumber === vehicle
                if (!returnVal) return false
            }

            if (driver) {
                returnVal = driverName === driver
                if (!returnVal) return false
            }

            if (paidBy !== 'All') {
                returnVal = userId ? 'cashless' : 'cash' === String(paidBy).toLowerCase()
                if (!returnVal) return false
            }

            return true
        })

        setTempFilterData(filtered)


        const sortedData = _.orderBy(filtered, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['desc', 'desc'])

        const addedLocalTime = sortedData?.map((d) => {
            d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            return d
        })
        let addedLocalTimeOrdered = _.orderBy(addedLocalTime, ['obIb'],
            ['asc']);
        const groupedData = _.groupBy(addedLocalTimeOrdered, 'localDate')
        setFilteredTripCollection(groupedData)
        setUltraFilteredTripCollection(filtered)
    }, [transaction, apadData])

    useEffect(() => {
        const filteredData = Object.values(
            tempFilterData.reduce((acc, obj) => ({ ...acc, [obj.tripId]: obj }), {})
        );
        for (let index = 0; index < apadData.length; index++) {
            const currentData = apadData[index];
            let start_time = moment(currentData.departure, "HH:mm:ss").format("HH:mm:ss");
            let dayOfTrip = moment(currentData.date, "DD-MM-YYYY").format("YYYY-MM-DD");
            let dayOfTripName = moment(currentData.date, "DD-MM-YYYY").format("dddd");
            let goal = moment(`${dayOfTrip} ${start_time}`).format("X");
            // console.log("currentData",currentData)
            // console.log("filteredData",filteredData)
            let filteredDataTemp = filteredData.filter(
                ({
                    startedAt,routeShortName
                }) => {
                    return startedAt && currentData.route_short_name == routeShortName &&
                        moment(moment(String(startedAt)).format("YYYY-MM-DD")).isSame(
                            dayOfTrip,
                            "day"
                        )
                }
            );
            let closestStart = filteredDataTemp.reduce(function (prev, curr) {

                let curr_time = moment(curr.startedAt).format("X");
                return Math.abs(curr_time - goal) / 60 <= 20 && Math.abs(curr_time - goal) < Math.abs(prev - goal) ?
                    curr_time :
                    prev;
            }, 0);
            const closestStartObject = filteredDataTemp.filter(({
                startedAt
            }) => closestStart === moment(startedAt).format("X"))
            if (closestStart === 0) {
                setOnlyInApadData(onlyInApadData => [...onlyInApadData, currentData])
            } else {
                if (closestStartObject[0].tripId in tripIdWithApadData) {
                    const tempExistingTime = tripIdWithApadData[closestStartObject[0].tripId]
                    let start_time_e = moment(tempExistingTime.departure, "HH:mm:ss").format("HH:mm:ss");
                    let dayOfTrip_e = moment(tempExistingTime.date, "DD-MM-YYYY").format("YYYY-MM-DD");

                    let goal_existing = moment(`${dayOfTrip_e} ${start_time_e}`).format("X");
                    const tempCurrTripTime = moment(closestStartObject[0].startedAt).format("X")
                    if (Math.abs(tempCurrTripTime - goal) < Math.abs(tempCurrTripTime - goal_existing)) {
                        setOnlyInApadData(onlyInApadData => [...onlyInApadData, tempExistingTime])

                        tripIdWithApadData[closestStartObject[0].tripId] = currentData

                    } else {
                        setOnlyInApadData(onlyInApadData => [...onlyInApadData, currentData])

                    }


                } else {
                    tripIdWithApadData[closestStartObject[0].tripId] = currentData
                }
            }
        }
        setTripIdWithApadDataChanged(true)
        setTripIdWithApadDataPopulated(tripIdWithApadData)
    }, [apadData, apadDataWithNotOk, tempFilterData,apadDataWithNotOkInvalidTrips])


    const handleClearQuery = useCallback(() => {
        setFilteredTripCollection(null)
        setApadData([])
        setApadDataWithNotOk([])
        setApadDataWithNotOkInvalidTrips([])
        setOnlyInApadData([])
        setTripIdWithApadDataChanged(false)
        setTripIdWithApadDataPopulated({})
        setTempFilterData([])
        setUltraFilteredTripCollection(null)
        document.querySelector('.csv-input').value = ''
    }, [])

    const generateExportCsvData = useCallback(() => {
        if (tabulated?.length === 0) return toast.warning('Nothing to export!')

        const reportNameHeader = 'APAD Compare Report' + `\r\n\n`
        const networkOperator = 'Network Area:,EMPTY' + '\r\n'
        const networkArea = `Network Operator:,${user.agency.name}\r\n`
        // const datesGeneratedFor = fromDate && toDate ? `Reporting Period:, ${fromDate} - ${toDate}\r\n` : ""
        const datesGeneratedFor = fromDate && toDate ? `Reporting Period :,${moment(fromDate).format('MM/DD/YYYY')} - ${(toDate).split('-').join('/')}\r\n` : ""
        const dateGenerated = `Generated At:, ${moment().format("DD/MM/YYYY")}\r\n`
        const generatedBy = `Generated By:,${user.firstName} ${user.lastName}\r\n`
        const headderWithComma = `"status of the trip (duplicate, trip outside schedule,no gps tracking, breakdown, replacement)"`
        
        const headerPre = "\r,, ,, ,, , , ,,,,,, ,,,,,, ,Verified Data,, , , , ,,, ,,,, ,, ,ETM Boarding Passenger Count,,,,,,,,,\r\n"
        const header = `Route ID,OD,IB/OB,Trip No.,Apad Date,Apad Departure,Apad Arrival,Apad Direction,Apad VC-Check,Service Date,Start Point,RPH No.,Bus Plate Number,Bus Age,Charge/KM,Driver ID,Bus Stops Travel,Travel(KM),Total Claim,Travel(KM) GPS,Total Claim GPS,Status,${headderWithComma},KM as per BOP = ,Claim as per BOP (RM),Missed trip if no gps tracking,Start Point,Service Start Time,Actual Start Time,Sales Start Time,Service End Time,Actual End Time,Sales End Time,Punctuality,Passengers Boarding Count,Total Sales Amount (RM),Total On,Transfer Count,Monthly Pass,Adult,Child,Senior,Student,OKU,JKM,MAIM,Remark,Status(JustNaik),\r\n`
        let data = reportNameHeader + networkArea + networkOperator + datesGeneratedFor  + dateGenerated + generatedBy
        const returnData = []
        let totallTG = 0
        let totallCTG = 0
        let totallSTG = 0
        let totallOTG = 0
        let totalBusStopTG = 0
        let totalTravelKmTG = 0
        let totalClaimTG = 0
        let totalClaimGpsTG = 0
        let TravelGpsTG = 0
     
        let totallTGR = 0
        let totallCTGR = 0
        let totallSTGR = 0
        let totallOTGR = 0
        let totalBusStopTGR = 0
        let totalTravelKmTGR = 0
        let totalClaimTGR = 0
        let totalClaimGpsTGR = 0
        let TravelGpsTGR = 0
        let tAmountGR = 0
        let grandTotalRote
        let tAmountG = 0
        let currentRoute

        tabulated.forEach(({
            trxs,
            localTimeGroup_,
            totalAmount_,
            totalRidership_,
            totalTripCount_,
            cashTotalAmount_,
            cashTotalRidership_,
            cashlessTotalAmount_,
            cashlessTotalRidership_,
            totalAdult
        },indexTop) => {
            // console.log("trx from tabulated", trxs);
            // const sortedData = _.orderBy(trxs, [({ scheduledAt }) => new Date(scheduledAt), ({ startedAt }) => new Date(startedAt)], ['desc', 'desc'])
            // // 

            // const addedLocalTime = sortedData?.map((d) => {
            //     d['localDate'] = d?.scheduledAt ? moment(d.scheduledAt).format('DD-MM-YYYY (ddd)') : d?.startedAt ? moment(d.startedAt).format('DD-MM-YYYY (ddd)') : 'undefined'
            //     return d
            // })
            if (currentRoute != trxs[0].routeId && indexTop != 0) {
                data += `, ,,,Total For Route ${grandTotalRote} : ,,,,,,,,,,,,${totalBusStopTGR},${totalTravelKmTGR},${totalClaimTGR},${TravelGpsTGR},${totalClaimGpsTGR},,,,,,,,,,,,,,0,${tAmountGR},${totallTGR + totallCTGR + totallSTGR + totallOTGR},0,0,${totallTGR},${totallCTGR},${totallSTGR},0,${totallOTGR},0,0\r\n`

                totallTGR = 0
                totallCTGR = 0
                totallSTGR = 0
                totallOTGR = 0
                totalBusStopTGR = 0
                totalTravelKmTGR = 0
                totalClaimTGR = 0
                totalClaimGpsTGR = 0
                TravelGpsTGR = 0
                tAmountGR = 0
            }

            function preferredOrder(obj, order) {
                var newObject = {};
                for (var i = 0; i < order.length; i++) {
                    if (obj.hasOwnProperty(order[i])) {
                        newObject[order[i]] = obj[order[i]];
                    }
                }
                return newObject;
            }
            let tripCounter = 0
            let tripIdCounterList = {}
            const assignedData = trxs.reverse().map((trx) => {
                if (trx.scheduledAt && trx.scheduledEndTime) {
                    if (trx.tripId in tripIdCounterList) {
                        return { ...trx, tripNoReport: tripIdCounterList[trx.tripId] }
                    }
                    else {
                        tripCounter++
                        tripIdCounterList[trx.tripId] = tripCounter
                        return { ...trx, tripNoReport: tripCounter }
                    }

                }
                else {
                    return { ...trx }
                }

            })
            // let addedLocalTimeOrdered = _.orderBy(assignedData.reverse(), ['obIb'],
            //     ['asc']);
            const groupedTest = _.groupBy(assignedData, item => `"${item.obIb}"`)
            let groupedTestEdited = preferredOrder(groupedTest, [
                '"0"', '"1"', '"2"'
            ]);
            let index = 0
            let prevSchTime = ''
            let totallT = 0
            let totallCT = 0
            let totallST = 0
            let totallOT = 0
            let totalBusStopT = 0
            let totalTravelKmT = 0
            let totalClaimT = 0
            let totalClaimGpsT = 0
            let TravelGpsT = 0
            let sDateT
            let sDateA
            let tAmount = 0
            Object.entries(groupedTestEdited).forEach(([localTimeGroup, trxs]) => {
                let totall = 0
                let totallC = 0
                let totallS = 0
                let totallO = 0
                let totalBusStop = 0
                let totalTravelKm = 0
                let totalClaim = 0
                let totalClaimGps = 0
                let TravelGps = 0
                let routeName
                let routeSName
                let sDate
                let subTotal = 0
                const uniqueTrips = Object.values(_.groupBy(trxs, 'tripId'))

                function sort_by_key(array) {
                    return array.sort(function (a, b) {
                        var x = moment(a[0].scheduledAt).format('X'); var y = moment(a = b[0].scheduledAt).format('X');
                        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                    });

                }
                const uniqueTripsOrdered = sort_by_key(uniqueTrips)
                data += headerPre + header
                uniqueTripsOrdered.forEach((sameTripTrxs) => {
                    if ( !moment(prevSchTime)
                    .isSame(sameTripTrxs[0].scheduledAt)) {
                    // if (prevSchTime  != sameTripTrxs[0].scheduledAt) {
                        
                        index++
                    }
                    prevSchTime =  sameTripTrxs[0].scheduledAt
                    // const tripNumber = sameTripTrxs[0].scheduledAt != null && sameTripTrxs[0].scheduledEndTime != null && sameTripTrxs[0].tripNoReport ? 'T' + sameTripTrxs[0].tripNoReport : ''
                    const tripNumber = sameTripTrxs[0].scheduledAt != null && sameTripTrxs[0].scheduledEndTime != null  ? 'T' + index : ''
                    const totalByTrip = {
                        totalPax: 0,
                        totalAmount: 0,
                        cash: 0,
                        cashPax: 0,
                        cashless: 0,
                        cashlessPax: 0,
                        cashAdult: 0,
                        cashChild: 0,
                        cashSenior: 0,
                        cashOku: 0,
                        cashFAdult: 0,
                        cashFChild: 0,
                        cashlessAdult: 0,
                        cashlessChild: 0,
                        cashlessSenior: 0,
                        cashlessOku: 0,
                        cashlessFAdult: 0,
                        cashlessFChild: 0,
                        noOfAdult: 0,
                        noOfChild: 0,
                        noOfSenior: 0,
                        noOfOku: 0,
                        trxsTime: []
                    }
                    sameTripTrxs.forEach(({
                        userId,
                        amount,
                        noOfAdult,
                        noOfChild,
                        noOfSenior,
                        noOfOku,
                        noOfForeignAdult,
                        noOfForeignChild,
                        journeyCreated,
                        journeyEnded
                    }, index) => {
                        const totalPax = +noOfAdult + +noOfChild + +noOfSenior + +noOfOku + +noOfForeignAdult + +noOfForeignChild
                        totalByTrip.routeId = sameTripTrxs[0].routeShortName
                        totalByTrip.routeName = sameTripTrxs[0].routeName
                        totalByTrip.tripId = sameTripTrxs[0].tripId
                        totalByTrip.actualStartS = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('HH:mm') : '-'
                        totalByTrip.actualStart = moment(sameTripTrxs[0].startedAt).isValid() ? moment(sameTripTrxs[0].startedAt).format('HH:mm') : '-'
                        totalByTrip.actualEnd = moment(sameTripTrxs[0].endedAt).isValid() ? moment(sameTripTrxs[0].endedAt).format('HH:mm') : '-'
                        totalByTrip.serviceStart = moment(sameTripTrxs[0].scheduledAt).isValid() ? moment(sameTripTrxs[0].scheduledAt).format('HH:mm') : '-'
                        totalByTrip.serviceEnd = moment(sameTripTrxs[0].scheduledEndTime).isValid() ? moment(sameTripTrxs[0].scheduledEndTime).format('HH:mm') : '-'
                        totalByTrip.status = 'No Complete'
                        totalByTrip.statusJ = 'No Complete'
                        totalByTrip.busPlate = sameTripTrxs[0].vehicleRegistrationNumber
                        totalByTrip.driverIdentification = sameTripTrxs[0].staffId
                        totalByTrip.direction = sameTripTrxs[0].obIb == 1 ? 'OB' : sameTripTrxs[0].obIb == 2 ? 'IB' : 'LOOP'
                        totalByTrip.noOfAdult += (Number(noOfAdult) + Number(noOfForeignAdult))
                        totalByTrip.noOfChild += (Number(noOfChild) + Number(noOfForeignChild))
                        totalByTrip.noOfSenior += Number(noOfSenior)
                        totalByTrip.noOfOku += Number(noOfOku)
                        totalByTrip.cash += userId ? 0 : amount
                        totalByTrip.cashPax += userId ? 0 : totalPax
                        totalByTrip.cashless += userId ? amount : 0
                        totalByTrip.totalAmount += amount
                        totalByTrip.cashlessPax += userId ? totalPax : 0
                        totalByTrip.cashAdult += userId ? 0 : noOfAdult
                        totalByTrip.cashChild += userId ? 0 : noOfChild
                        totalByTrip.cashSenior += userId ? 0 : noOfSenior
                        totalByTrip.cashOku += userId ? 0 : noOfOku
                        totalByTrip.cashFAdult += userId ? 0 : noOfForeignAdult
                        totalByTrip.cashFChild += userId ? 0 : noOfForeignChild
                        totalByTrip.cashlessAdult += userId ? noOfAdult : 0
                        totalByTrip.cashlessChild += userId ? noOfChild : 0
                        totalByTrip.cashlessSenior += userId ? noOfSenior : 0
                        totalByTrip.cashlessOku += userId ? noOfOku : 0
                        totalByTrip.cashlessFAdult += userId ? noOfForeignAdult : 0
                        totalByTrip.cashlessFChild += userId ? noOfForeignChild : 0
                        totalByTrip.punctuality = sameTripTrxs[0].scheduledAt && sameTripTrxs[0].startedAt && sameTripTrxs[0].scheduledEndTime && sameTripTrxs[0].endedAt && Math.abs(Math.abs(Number(moment(sameTripTrxs[0].scheduledAt).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].startedAt).format('X')))) <= 300 && Math.abs(Math.abs(Number(moment(sameTripTrxs[0].scheduledEndTime).format('X'))) - Math.abs(Number(moment(sameTripTrxs[0].endedAt).format('X')))) <= 300 ? 'ONTIME' : 'NOT PUNCTUAL'
                        totalByTrip.startPoint = routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length > 0 ? routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.reduce(function (res, obj) {
                            return (obj.sequence < res.sequence) ? obj : res;
                        })?.name : ''
                        totalByTrip.trxsTime.push(userId ? moment(journeyCreated).format('X') : moment(journeyEnded).format('X'))
                        totalByTrip.busAge = sameTripTrxs[0]?.VehicleAge ? moment().year() - sameTripTrxs[0]?.VehicleAge : ''
                        totalByTrip.totalClaim = 0
                        totalByTrip.monthlyPass = '-'
                        totalByTrip.jkm = '-'
                        totalByTrip.maim = '-'
                        totalByTrip.passenger = 0
                        totalByTrip.totalOn = totalByTrip.noOfAdult + totalByTrip.noOfChild + totalByTrip.noOfSenior + totalByTrip.noOfOku
                        totalByTrip.noOfStudent = 0
                        totalByTrip.transferCount = 0
                        totalByTrip.rph = sameTripTrxs[0]?.deviceSerialNumber
                        totalByTrip.serviceDate = sameTripTrxs[0]?.scheduledAt ? moment(sameTripTrxs[0].scheduledAt).format('DD/MM/YYYY') : sameTripTrxs[0]?.startedAt ? moment(sameTripTrxs[0].startedAt).format('DD/MM/YYYY') : 'undefined'
                        totalByTrip.serviceDateForApad = sameTripTrxs[0]?.scheduledAt ? moment(sameTripTrxs[0].scheduledAt).format('YYYY-MM-DD') : sameTripTrxs[0]?.startedAt ? moment(sameTripTrxs[0].startedAt).format('YYYY-MM-DD') : 'undefined'
                        totalByTrip.kmApad = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                        totalByTrip.kmApadG = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                        totalByTrip.kmRate = sameTripTrxs[0]?.kmRate
                        totalByTrip.totalClaim = totalByTrip.kmRate && totalByTrip.kmApad ? totalByTrip.kmRate / totalByTrip.kmApad : ''
                        totalByTrip.totalClaimG = totalByTrip.totalClaim && totalByTrip.kmApadG ? totalByTrip.totalClaim * totalByTrip.kmApadG : ''
                        totalByTrip.kmApadB = sameTripTrxs[0]?.obIb == 0 ? sameTripTrxs[0]?.kmLoop : sameTripTrxs[0]?.obIb == 1 ? sameTripTrxs[0]?.kmOutbound : sameTripTrxs[0]?.kmInbound
                        totalByTrip.kmRateB = sameTripTrxs[0]?.kmRate
                        totalByTrip.statusDetail = !tripLog[sameTripTrxs[0].tripId] ? 'No GPS Tracking' : sameTripTrxs[0].scheduledAt == null ? 'Trip outside schedule' : ''

                        if (routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length > 0) {
                            const decodedPolyline = routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)
                            const arrIb = []
                            if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                                decodedPolyline?.forEach((poly, index) => {
                                    for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                        const isNear = geolib.isPointWithinRadius(
                                            { latitude: poly.latitude, longitude: poly.longitude },
                                            { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                            200
                                        );
                                        if (isNear) {
                                            arrIb.push(poly.sequence)
                                            break
                                        }
                                    }
                                });
                            }
                            const uniqueStop = [...new Set(arrIb)]
                            if (sameTripTrxs[0].endedAt && routeStops[sameTripTrxs[0].routeId]?.filter(({ directionId }) => directionId == sameTripTrxs[0].obIb)?.length * 15 / 100 <= uniqueStop.length) {
                                totalByTrip.statusJ = 'COMPLETE'
                            }
                            totalByTrip.busStops = uniqueStop.length
                        }
                        if (sameTripTrxs[0]?.apadPolygon?.length > 0) {
                            const decodedPolyline = PolylineUtils.decode(sameTripTrxs[0].apadPolygon)
                           const arrIb = []
                            const arrIbBetween = []
                            if (decodedPolyline.length > 0 && tripLog[sameTripTrxs[0].tripId]?.length > 0) {
                                decodedPolyline?.forEach((poly, index) => {
                                    if(index === 0 || index === 5){
                                    for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                        const isNear = geolib.isPointWithinRadius(
                                            { latitude: poly[0], longitude: poly[1] },
                                            { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                            200
                                        );
                                        // 
                                        if (isNear) {
                                            // check if inbound and scheduled?
                                            arrIb.push(poly)
                                            break
                                        }
    
                                    }
    
                                }
                                });
                                for (let index = 1; index < decodedPolyline.length -1; index++) {
                                    const element = decodedPolyline[index];
                                    for (let index = 0; index < tripLog[sameTripTrxs[0].tripId].length; index++) {
                                        const isNear = geolib.isPointWithinRadius(
                                            { latitude: element[0], longitude: element[1] },
                                            { latitude: tripLog[sameTripTrxs[0].tripId][index].latitude, longitude: tripLog[sameTripTrxs[0].tripId][index].longitude },
                                            200
                                        );
                                        // 
                                        if (isNear) {
                                            // check if inbound and scheduled?
                                            arrIbBetween.push(element)
                                            break
                                        }
    
                                    }
                                    
                                }
                            }
                           
                            if (arrIb?.length >= 2 && arrIbBetween?.length >=1) {
    
                                totalByTrip.status = 'Complete'
                            }
                        }
                    })

                    const numberArray = totalByTrip.trxsTime.map(Number);
                    grandTotalRote = `${sameTripTrxs[0].routeShortName} ${sameTripTrxs[0].routeName}`
                    totall = Number(totall) + Number(totalByTrip.noOfAdult)
                    totallC = Number(totallC) + Number(totalByTrip.noOfChild)
                    totallS = Number(totallS) + Number(totalByTrip.noOfSenior)
                    totallO = Number(totallO) + Number(totalByTrip.noOfOku)
                    totalByTrip.salesStart = isNaN(moment.unix(Math.min(...numberArray))) ? '-' : moment.unix(Math.min(...numberArray)).format('HH:mm');
                    totalByTrip.salesEnd = isNaN(moment.unix(Math.max(...numberArray))) ? '-' : moment.unix(Math.max(...numberArray)).format('HH:mm');
                    totalBusStop = Number(totalBusStop) + Number(totalByTrip.busStops)
                    totalTravelKm = Number(totalTravelKm) + Number(totalByTrip.kmApad)
                    totalClaim = Number(totalClaim) + Number(totalByTrip.totalClaim)
                    totalClaimGps = Number(totalClaimGps) + Number(totalByTrip.totalClaimG)
                    TravelGps = Number(TravelGps) + Number(totalByTrip.kmApadG)
                    routeName = totalByTrip.routeName
                    routeSName = totalByTrip.routeId
                    sDate = totalByTrip.serviceDate
                    totalByTrip.apadDate = tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.date ? tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.date : ''
                    totalByTrip.apadDeparture = tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.departure ? tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.departure : ''
                    totalByTrip.apadArrival = tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.arrival ? tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.arrival : ''
                    totalByTrip.apadDirection = tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.direction ? tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.direction : ''
                    totalByTrip.apadCheck = tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.vc_check ? tripIdWithApadDataPopulated[sameTripTrxs[0].tripId]?.vc_check : ''
                    data += `${sameTripTrxs[0].routeShortName},${sameTripTrxs[0].routeShortName} ${sameTripTrxs[0].routeName},${totalByTrip.direction},${tripNumber},${totalByTrip.apadDate},${totalByTrip.apadDeparture},${totalByTrip.apadArrival},${totalByTrip.apadDirection},${totalByTrip.apadCheck},${totalByTrip.serviceDate},${totalByTrip.startPoint},${sameTripTrxs[0].tripId},${totalByTrip.busPlate},${totalByTrip.busAge},${totalByTrip.kmRate},${totalByTrip.driverIdentification},${totalByTrip.busStops},${totalByTrip.kmApad},${totalByTrip.totalClaim},${totalByTrip.kmApadG},${totalByTrip.totalClaimG},${totalByTrip.status},${totalByTrip.statusDetail},${totalByTrip.kmApadB},${totalByTrip.kmRateB},,${totalByTrip.actualStartS},${totalByTrip.serviceStart},${totalByTrip.actualStart},${totalByTrip.salesStart},${totalByTrip.serviceEnd},${totalByTrip.actualEnd},${totalByTrip.salesEnd}, ${totalByTrip.punctuality}, ${totalByTrip.passenger},${totalByTrip.totalAmount},${totalByTrip.totalOn},${totalByTrip.transferCount},${totalByTrip.monthlyPass},${totalByTrip.noOfAdult},${totalByTrip.noOfChild},${totalByTrip.noOfSenior} ,${totalByTrip.noOfStudent},${totalByTrip.noOfOku},${totalByTrip.jkm}, ${totalByTrip.maim},,${totalByTrip.statusJ},\r\n`
                    sDateT = totalByTrip.serviceDate
                    sDateA = totalByTrip.serviceDateForApad
                    subTotal = subTotal + totalByTrip.totalAmount
                })
                data += `, ,,,TOTAL (${sDate} - ${routeSName} ${routeName}) ,,,,,,,,,,,,${totalBusStop},${totalTravelKm},${totalClaim},${TravelGps},${totalClaimGps},,,,,,,,,,,,,,0,${subTotal},${totall + totallC + totallS + totallO},0,0,${totall},${totallC},${totallS},0,${totallO},0,0\r\n`
                index = 0
                totallT = Number(totallT) + Number(totall)
                totallCT = Number(totallCT) + Number(totallC)
                totallST = Number(totallST) + Number(totallS)
                totallOT = Number(totallOT) + Number(totallO)
                totalBusStopT = Number(totalBusStopT) + Number(totalBusStop)
                totalTravelKmT = Number(totalTravelKmT) + Number(totalTravelKm)
                totalClaimT = Number(totalClaimT) + Number(totalClaim)
                totalClaimGpsT = Number(totalClaimGpsT) + Number(totalClaimGps)
                TravelGpsT = Number(TravelGpsT) + Number(TravelGps)
                tAmount = Number(tAmount) + Number(subTotal)
            })
            totallTG = totallTG + totallT
            totallCTG = totallCTG + totallCT
            totallSTG = totallSTG + totallST
            totallOTG = totallOTG + totallOT
            totalBusStopTG = totalBusStopTG + totalBusStopT
            totalTravelKmTG = totalTravelKmTG + totalTravelKmT
            totalClaimTG = totalClaimTG + totalClaimT
            totalClaimGpsTG = totalClaimGpsTG + totalClaimGpsT
            TravelGpsTG = TravelGpsTG + TravelGpsT
            tAmountG = tAmountG + tAmount
            totallTGR = totallTGR + totallT
            totallCTGR = totallCTGR + totallCT
            totallSTGR = totallSTGR + totallST
            totallOTGR = totallOTGR + totallOT
            totalBusStopTGR = totalBusStopTGR + totalBusStopT
            totalTravelKmTGR = totalTravelKmTGR + totalTravelKmT
            totalClaimTGR = totalClaimTGR + totalClaimT
            totalClaimGpsTGR = totalClaimGpsTGR + totalClaimGpsT
            TravelGpsTGR = TravelGpsTGR + TravelGpsT
            tAmountGR = tAmountGR + tAmount

            let isPrinted = true
            for (let index = 0; index < onlyInApadData.length; index++) {
                let dayOfTrip = moment(onlyInApadData[index].date, "DD-MM-YYYY").format("YYYY-MM-DD");
                if (moment(sDateA).isSame(
                    dayOfTrip,
                    "day"
                )) {
                    if(isPrinted){
                    data += `\rExtra trip,,,,${onlyInApadData[index]?.date},${onlyInApadData[index]?.departure},${onlyInApadData[index]?.arrival},${onlyInApadData[index]?.direction},${onlyInApadData[index]?.vc_check},\r\n`
                    isPrinted = false
                }
                    else{
                        data += `Extra trip,,,,${onlyInApadData[index]?.date},${onlyInApadData[index]?.departure},${onlyInApadData[index]?.arrival},${onlyInApadData[index]?.direction},${onlyInApadData[index]?.vc_check},\r\n`

                    }
                }

            }
            
            for (let index = 0; index < apadDataWithNotOkInvalidTrips.length; index++) {
                let dayOfTrip = moment(apadDataWithNotOkInvalidTrips[index].date, "DD-MM-YYYY").format("YYYY-MM-DD");
                if (moment(sDateA).isSame(
                    dayOfTrip,
                    "day"
                )) {
                    data += `Invalid trip,,,,${apadDataWithNotOkInvalidTrips[index]?.date},${apadDataWithNotOkInvalidTrips[index]?.departure},${apadDataWithNotOkInvalidTrips[index]?.arrival},${apadDataWithNotOkInvalidTrips[index]?.direction},${apadDataWithNotOkInvalidTrips[index]?.vc_check},\r\n`
                }

            }
            data += `, ,,,TOTAL for service date - ${sDateT} ,,,,,,,,,,,,${totalBusStopT},${totalTravelKmT},${totalClaimT},${TravelGpsT},${totalClaimGpsT},,,,,,,,,,,,,,0,${tAmount},${totallT + totallCT + totallST + totallOT},0,0,${totallT},${totallCT},${totallST},0,${totallOT},0,0\r`
            if (indexTop == tabulated.length - 1) {
                data += `, ,,,Total For Route ${grandTotalRote} : ,,,,,,,,,,,,${totalBusStopTGR},${totalTravelKmTGR},${totalClaimTGR},${TravelGpsTGR},${totalClaimGpsTGR},,,,,,,,,,,,,,0,${tAmountGR},${totallTGR + totallCTGR + totallSTGR + totallOTGR},0,0,${totallTGR},${totallCTGR},${totallSTGR},0,${totallOTGR},0,0\r\n`

                totallTGR = 0
                totallCTGR = 0
                totallSTGR = 0
                totallOTGR = 0
                totalBusStopTGR = 0
                totalTravelKmTGR = 0
                totalClaimTGR = 0
                totalClaimGpsTGR = 0
                TravelGpsTGR = 0
                tAmountGR = 0
            }
            currentRoute = trxs[0].routeId
        })

        // data += `, ,,,TOTAL for Route ${grandTotalRote} ,,,,,,,,,,,,${totalBusStopTG},${totalTravelKmTG},${totalClaimTG},${TravelGpsTG},${totalClaimGpsTG},,,,,,,,,,,,,0,${tAmountG},${totallTG + totallCTG + totallSTG + totallOTG},0,0,${totallTG},${totallCTG},${totallSTG},0,${totallOTG},0,0\r\n`
        data += `, ,,,Grand Total: ,,,,,,,,,,,,${totalBusStopTG},${totalTravelKmTG},${totalClaimTG},${TravelGpsTG},${totalClaimGpsTG},,,,,,,,,,,,,,0,${tAmountG},${totallTG + totallCTG + totallSTG + totallOTG},0,0,${totallTG},${totallCTG},${totallSTG},0,${totallOTG},0,0\r\n`
        var BOM = "\uFEFF";
        var csvContent = BOM + data;
        exportData(csvContent, `Apad Compare Report-${moment(fromDate,"DD-MM-YYYY").format('MMMYYYY')}-${user.agency.name}.csv`, 'text/csv;charset=utf-8;')

    }, [tabulated, apadDataWithNotOk,apadDataWithNotOkInvalidTrips, onlyInApadData, tripIdWithApadData, apadData, tripIdWithApadDataChanged, tripIdWithApadDataPopulated])

    const handleForce = (data, fileInfo) => {
        setOnlyInApadData([])
        setApadData([])
        setApadDataWithNotOk([])
        setApadDataWithNotOkInvalidTrips([])
        console.log("data",data);
        const apadDataWithNotOk = data.filter(({ vc_check }) => {
            return vc_check != 'OK'
        })
        const apadDataWithNotOkInvalid = apadDataWithNotOk.filter(({ departure, arrival }) => {
            let startTime = moment(`2022-01-01 ${departure}`).format("X")
            let endTime = moment(`2022-01-01 ${arrival}`).format("X")
            return Math.abs(startTime - endTime) <= 600

        })
        const apadDataWithNotOkFiltered = apadDataWithNotOk.filter(({ departure, arrival }) => {
            let startTime = moment(`2022-01-01 ${departure}`).format("X")
            let endTime = moment(`2022-01-01 ${arrival}`).format("X")
            return Math.abs(startTime - endTime) > 600


        })
        const apadDataWithOk = data.filter(({ departure, arrival }) => {
            let startTime = moment(`2022-01-01 ${departure}`).format("X")
            let endTime = moment(`2022-01-01 ${arrival}`).format("X")
            return Math.abs(startTime - endTime) > 600
        })
        setApadData(apadDataWithOk)
        setApadDataWithNotOk(apadDataWithNotOkFiltered)
        setApadDataWithNotOkInvalidTrips(apadDataWithNotOkInvalid)
    }



    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    return (
        <ContentWrapper >
            <h2 className='mb-3' >Apad Compare Report</h2>
            <Card className='mt-3' >
                <Card.Body>
                    {
                   
                            <div>
                                {
                                    routesArr  ?
                                        <div>
                                            <div style={{ "textAlign": "center" }}>
                                                <CloudUpload size={50} color={'blue'} />
                                                <CSVReader
                                                    cssClass="react-csv-input"
                                                    label="Upload APAD csv file:   "
                                                    onFileLoaded={handleForce}
                                                    parserOptions={papaparseOptions}
                                                />
                                                <a style={{ color: 'blue' }} href={process.env.PUBLIC_URL + "/apadSampleFormat.csv"} download>Download Sample File</a>


                                            </div>
                                            <TransactionQuery onClearQuery={handleClearQuery} onSubmitQuery={handleFilterTransactionData} routeOpt={routesArr.map(({ shortName }) => shortName)} driverOpt={driverList} vehicleOpt={vehicleList} agencyNameOpt={[user?.agency?.name]} />
                                        </div>
                                        :
                                        <div className='d-flex justify-content-center align-items-center' >
                                            <CircularProgress />
                                        </div>
                                }
                                <Alert className='mt-3' variant='info' >
                                <InfoCircle />The Trip ID is updated under the column RPH No. , so that you may find the GPS log of a trip by checking the Trip ID over the History Log. <br></br>
                                </Alert>
                                <div className='w-100 text-right' >
                                    <Button onClick={generateExportCsvData} >Export Data</Button>
                                </div>
                                <Table numbering columns={tableHeader} data={claimData} />
                            </div>
                    }
                </Card.Body>
            </Card>
            <Modal size='sm' centered show={loading} >
                <div className='text-center' >
                    <CircularProgress size={70} />
                </div>
            </Modal>
        </ContentWrapper>
    )
}

export default ClaimReportPageApad

