import moment from 'moment'
import { useMemo, useState } from 'react'
import _ from 'lodash'
import { Badge, Button } from 'react-bootstrap'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import T from 'react-timetable-events'
import './Timetable.css'

const Timetable = ({ data = [], onEventCardClick = () => { }, onCreateEvent = () => { } }) => {
    const [currWeek, setCurrWeek] = useState(moment())

    const week = useMemo(() => {
        const scheduledTrips = {
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
        }

        const start = moment(currWeek).startOf('week')
        const end = moment(currWeek).endOf('week')

        data.forEach(({ scheduledAt, routeShortName, vehicleRegistrationNumber, driverName, id, routeColor, driverId, routeId, vehicleId, endedAt, startedAt }) => {
            const scheduled = moment(scheduledAt)
            if (scheduled.isSameOrAfter(start) && scheduled.isSameOrBefore(end)) {
                const scheduledDay = moment(scheduledAt).format('dddd').toLowerCase()
                let status = null
                if (endedAt !== null) {
                    status = 'completed'
                } else if (startedAt === null) {
                    if (new Date(scheduledAt).getTime() >= Date.now()) {
                        status = 'active'
                    } else {
                        status = 'missed'
                    }
                }
                scheduledTrips[scheduledDay].push({
                    hour: moment(scheduledAt).format('YYMMDDHH'),
                    startTime: moment(scheduledAt),
                    endTime: moment(scheduledAt).add(1, 'hour'),
                    data: {
                        id,
                        vehicleRegistrationNumber,
                        vehicleId,
                        routeShortName,
                        routeId,
                        driverName,
                        driverId,
                        routeColor,
                        scheduledAt: moment(scheduledAt),
                        status
                    },
                })
            }

        })

        Object.entries(scheduledTrips).forEach(([day, events]) => {
            //put same hour in one event
            scheduledTrips[day] = _(events)
                .groupBy('hour')
                .map((v, id) => ({
                    id,
                    data: _.map(v, 'data'),
                    startTime: v[0].startTime,
                    endTime: v[0].endTime,
                }))
                .value()
        })

        return {
            scheduledTrips,
            start,
            end
        }
    }, [currWeek, data])

    const handlePrevWeek = () => {
        const p = moment(currWeek).subtract(1, 'week')
        setCurrWeek(p)
    }

    const handleNextWeek = () => {
        const p = moment(currWeek).add(1, 'week')
        setCurrWeek(p)
    }

    const handleRerenderDayLabel = (day) => {
        if (week.start) {
            const d = +moment(day, 'dddd').format('d')
            return moment(week.start).add(d, 'day').format('ddd (DD/MM)')
        }
        return day
    }

    return (
        <>
            <div className='d-flex justify-content-center align-items-center my-2' >
                <Button onClick={handlePrevWeek} className='bg-dark' >
                    <ChevronLeft size={20} />
                </Button>
                <span style={{ fontSize: 20 }} className='mx-5' >{week.start.format('DD/MM')} - {week.end.format('DD/MM')}</span>
                <Button onClick={handleNextWeek} className='bg-dark' >
                    <ChevronRight size={20} />
                </Button>
            </div>
            <div className='g-hover-pointer' title='Click to create new Schedule' onClick={onCreateEvent} >
                <T
                    events={week.scheduledTrips}
                    hoursInterval={{ from: 1, to: 24 }}
                    renderEvent={({ event, defaultAttributes: { className: cls, ...dA }, classNames }) => (
                        <div {...dA} className={`${cls} timetable-evt-container bg-light text-dark`} key={event.id}>
                            <span className={`${classNames.event_info} d-flex justify-content-between align-items-center p-2`}><b>{event.startTime.format("HH:00")} - {event.endTime.format("HH:00")}</b> <Badge variant='primary' className='align-middle' style={{ fontSize: 12 }}>{event.data.length}</Badge></span>
                            {
                                event.data.map((evt) =>
                                    <div onClick={() => onEventCardClick(evt)} key={evt.id} className={`${classNames.event_info} g-hover-pointer trip-card border border-primary rounded`} title='Click for more details' >
                                        <small>{evt.scheduledAt.format("HH:mm")} <Badge className='text-light align-middle' style={{ backgroundColor: evt.routeColor }} >{evt.routeShortName}</Badge></small>
                                        <small>{evt.vehicleRegistrationNumber}</small>
                                        <div title={evt.status} className={`status-circle bg-${evt.status === 'completed' ? 'success' : evt.status === 'active' ? 'warning' : 'danger'}`} />
                                    </div>
                                )
                            }
                        </div>
                    )}
                    getDayLabel={handleRerenderDayLabel}
                />
            </div>
        </>
    )
}

export default Timetable