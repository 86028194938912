import React, { useState, useEffect } from 'react'
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'

import ellipseBG from '../../assets/image/Ellipse-signup-bg.svg'
import logoPrimary from '../../assets/logo/logo-primary.png'
import products from '../../assets/image/justnaik-product.png'

import './SignUp.css'
import { Check } from 'react-bootstrap-icons'
import { Link, Redirect } from 'react-router-dom'
import { FailedAlert, SuccessAlert } from '../../components'
import { toast } from 'react-toastify'
import validator from 'validator'

const fleetSizeOptions = [
    '1 - 4',
    '5 - 9',
    '10 - 19',
    '20 - 99',
    '100 - 499',
    '500 - 999',
    '1,000 and above'
]

const SignUp = (props) => {
    const [companyName, setCompanyName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [isEmailSubscribed, setIsEmailSubscribed] = useState(true)
    const [fleetSize, setFleetSize] = useState(fleetSizeOptions[0])

    const [passwordConditions, setPasswordConditions] = useState(null)
    const [confirmPass, setConfirmPass] = useState(null)

    useEffect(() => {
        if (passwordConditions !== null) {
            if (passwordConditions[0].checked === true && passwordConditions[1].checked === true && passwordConditions[2].checked === true && confirmPass === true) {
                const password = document.querySelector('#password').value
                setPassword(password)
            } else {
                setPassword('')
            }
        }
    }, [confirmPass])

    const passwordChecker = (evt) => {
        const pass = evt.currentTarget.value
        const specialChar = new RegExp(/[!@#$%^&*(),.?":{}|<>]/)
        const number = new RegExp(/[0-9]/)

        const prev = [
            {
                text: 'Minimum 8 characters',
                checked: false
            },
            {
                text: 'One special character',
                checked: false
            },
            {
                text: 'One number',
                checked: false
            }
        ]

        if (pass.length < 8) {
            prev[0].checked = false
        } else {
            prev[0].checked = true
        }

        if (!specialChar.test(pass)) {
            prev[1].checked = false
        } else {
            prev[1].checked = true
        }

        if (!number.test(pass)) {
            prev[2].checked = false
        } else {
            prev[2].checked = true
        }

        setPasswordConditions([...prev])

        const cpass = document.querySelector('#confirm-password').value
        if (pass === cpass) {
            setConfirmPass(true)
        } else {
            setConfirmPass(false)
        }

    }

    const confirmPassword = (evt) => {
        const cpass = evt.currentTarget.value
        const password = document.querySelector('#password').value
        if (!cpass.length > 0) return setConfirmPass(null)
        if (password === cpass) {
            setConfirmPass(true)
        } else {
            setConfirmPass(false)
        }
    }

    if (props.user.jwtToken) {
        toast.success(`Welcome ${props.user.firstName}!`)
        // return window.location.pathname = "/"
        return <Redirect to='/' />
    }

    const handleSubmitSignUp = (e) => {
        e.preventDefault()

        if (!companyName || validator.isEmpty(companyName)) return toast.warn('CompanyName is required')
        if (!firstName || validator.isEmpty(firstName)) return toast.warn('firstName is required')
        if (!lastName || validator.isEmpty(lastName)) return toast.warn('lastName is required')
        if (!email || validator.isEmpty(email)) return toast.warn('email is required')
        if (!validator.isEmail(email)) return toast.warn('email is not valid')
        if (!password || validator.isEmpty(password)) return toast.warn('password not valid')

        if (!phone || validator.isEmpty(password)) return toast.warn('password not valid')
        const phone_number = phone.startsWith('0') ? `+6${phone}` : `+60${phone}`

        props.requestUserSignUp({
            companyName,
            firstName,
            lastName,
            email,
            password,
            phone_number,
            fleetSize,
            isEmailSubscribed
        })
    }

    return (
        <div className='signup-main' >
            <img className="signup-bg" src={ellipseBG} alt='bg' />
            <Container>
                <div className='logo-primary' >
                    <img width={200} src={logoPrimary} alt='logo-primary' />
                </div>
                <Row className='h-100' >
                    <Col lg={6} md={12} xs={12} className='h-100 pt-3 d-flex flex-column justify-content-center align-items-center' >
                        <h1 className='text-primary product-text-signup' >Your ultimate <br /> mobility partner </h1>
                        <img className='products-img-signup' src={products} alt='products' />
                    </Col>
                    <Col lg={{ span: 6, order: 'last' }} md={{ span: 12, order: 'first' }} xs={{ span: 12, order: 'first' }} className='pt-3 d-flex justify-content-center align-items-center' >
                        <Card className='w-100' >
                            <Card.Body>
                                <Card.Title>Welcome</Card.Title>
                                <Form onSubmit={handleSubmitSignUp} >
                                    <Form.Row>
                                        <Form.Group md={6} sm={12} as={Col} >
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required onChange={(e) => setFirstName(e.currentTarget.value)} type='text' placeholder='John' />
                                        </Form.Group>
                                        <Form.Group md={6} sm={12} as={Col}  >
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control required onChange={(e) => setLastName(e.currentTarget.value)} type='text' placeholder='Smith' />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group >
                                        <Form.Label>Company Name</Form.Label>
                                        <Form.Control required onChange={(e) => setCompanyName(e.currentTarget.value)} type='text' placeholder='Hexon Data Sdn Bhd' />
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group md={6} sm={12} as={Col} >
                                            <Form.Label>Company Email</Form.Label>
                                            <Form.Control required onChange={(e) => setEmail(e.currentTarget.value)} type='email' placeholder='info@hexondata.com' />
                                        </Form.Group>
                                        <Form.Group md={6} sm={12} as={Col} >
                                            <Form.Label>Company Phone No.</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Prepend><InputGroup.Text>(+60)</InputGroup.Text></InputGroup.Prepend>
                                                <Form.Control required onChange={(e) => setPhone(e.currentTarget.value)} type='tel' pattern='[0-9]{7,11}' placeholder='123456789' />
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Group >
                                        <Form.Label>Estimated Number of Vehicles</Form.Label>
                                        <Form.Control required onChange={(e) => setFleetSize(e.currentTarget.value)} as='select' custom >
                                            {
                                                fleetSizeOptions.map((opt, i) => (<option key={i} value={opt} >{opt}</option>))
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Password</Form.Label>
                                        {
                                            passwordConditions !== null &&
                                            passwordConditions.map(({ text, checked }, i) => (
                                                <div key={i} className='p-0 m-0'>
                                                    {
                                                        checked ?
                                                            <SuccessAlert text={text} /> :
                                                            <FailedAlert text={text} />
                                                    }
                                                </div>
                                            ))
                                        }
                                        <Form.Control required id='password' onChange={passwordChecker} type='password' />
                                    </Form.Group>
                                    <Form.Group >
                                        <Form.Label>Confirm Password</Form.Label>
                                        {
                                            confirmPass !== null &&
                                            <Alert className='py-0 my-1' variant={confirmPass ? 'success' : 'danger'} >
                                                <Check /> <small>{confirmPass ? 'Password matched!' : 'Password not match'}</small>
                                            </Alert>
                                        }
                                        <Form.Control id='confirm-password' required onChange={confirmPassword} type='password' />
                                    </Form.Group>
                                    <Form.Group className='d-flex' >
                                        <Form.Check checked={isEmailSubscribed} onChange={() => setIsEmailSubscribed(!isEmailSubscribed)} id='subscribe-newsletters-checkbox' type='checkbox' custom />
                                        <Form.Text >I agree to receive email communication from JustNaik</Form.Text>
                                    </Form.Group>
                                    <Alert className='mt-3' variant='info' >
                                        <small>
                                            By signing up, you confirm that you've read and accepted our <a target='_blank' href='https://www.justnaik.com/privacy-policy' >Terms of Service and Privacy Notice</a>
                                        </small>
                                    </Alert>
                                    <Button type='submit' className='w-100' >Sign Up</Button>
                                </Form>
                                <h6 className='mt-3' >Already has an account? <Link to='/signin' >Sign In</Link></h6>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SignUp