import React, { useCallback, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

const todayDay = moment()
const minDateDefault = moment().subtract(3, 'months').subtract(+todayDay.format('DD') - 1, 'days')
const fromDateDefault = moment().subtract(1, 'weeks').subtract(+todayDay.format('DD') - 1, 'days')

const DateRangePicker = ({ fromDate = fromDateDefault, toDate = todayDay, minDate = minDateDefault, onFromChanged = () => { }, onToChanged = () => { }, clearBtnRef = undefined }) => {
    const [selectedStartDate, setSelectedStartDate] = useState(fromDate);
    const [selectedEndDate, setSelectedEndDate] = useState(toDate);

    const inputPropsStyle = {
        style: {
            padding: 10
        }
    }

    const handleDateStartChange = (date) => {
        setSelectedStartDate(date)
        onFromChanged(date)
    }

    const handleDateEndChange = (date) => {
        setSelectedEndDate(date)
        onToChanged(date)
    }

    const handleClear = useCallback(() => {
        setSelectedStartDate(null)
        setSelectedEndDate(null)
    }, [])

    return (
        <div className='text-right' >
            <DatePicker
                label="From"
                value={selectedStartDate}
                onChange={handleDateStartChange}
                // disableFuture
                disableToolbar
                variant='inline'
                inputVariant='outlined'
                inputProps={inputPropsStyle}
                className='ml-3 mt-3'
                minDate={minDate}
                maxDate={moment(selectedEndDate).subtract(1, 'day')}
            />

            <DatePicker
                label="To"
                value={selectedEndDate}
                onChange={handleDateEndChange}
                disableFuture
                disableToolbar
                variant='inline'
                inputVariant='outlined'
                inputProps={inputPropsStyle}
                className='ml-3 mt-3'
                minDate={moment(selectedStartDate).add(1, 'day')}
            />
            <button onClick={handleClear} ref={clearBtnRef} hidden />
        </div>
    )
}

export default DateRangePicker