import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Card, Form, Row, Col, Button } from 'react-bootstrap'
import { PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PolylineUtils from '@mapbox/polyline'

import { ContentWrapper, Table, Modal, StaticMap, ConfirmModal } from '../../components'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'

const WaypointForm = ({ edit, onClickCancel, onSubmitForm, maxSequenceNumber, defaultValues = { sequence: '', directionId: '', isActive: '', isCanSkip: '', hasNearbyStop: '' } }) => {
    const [sequence, setSequence] = useState(null)
    const [directionId, setDirectionId] = useState(null)
    // const [isActive, setIsActive] = useState(null)
    const [isCanSkip, setIsCanSkip] = useState(null)
    const [hasNearbyStop, setHasNearbyStop] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()
        const data = {}

        if (sequence !== null) {
            data.sequence = sequence
        }

        if (directionId !== null) {
            data.directionId = directionId
        }

        // if (isActive !== null) {
        //     data.isActive = isActive
        // }

        if (isCanSkip !== null) {
            data.isCanSkip = isCanSkip
        }

        if (hasNearbyStop !== null) {
            data.hasNearbyStop = hasNearbyStop
        }

        onSubmitForm(defaultValues.waypointId, data)

        onClickCancel()
    }

    return (
        <Row>
            <Col className={` ${!edit ? 'pt-3' : ''} `} lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title>Create New Headway</Card.Title>
                            }
                            <Row>
                                <Col lg={2} md={12} >
                                    <Form.Group>
                                        <Form.Label >Sequence</Form.Label>
                                        <Form.Control type='number' min='1' max={maxSequenceNumber} step='1' onChange={(e) => setSequence(e.currentTarget.value)} value={sequence ? sequence : defaultValues.sequence} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col>
                                <Col lg={4} md={12} >
                                    <Form.Group>
                                        <Form.Label >Direction ID</Form.Label>
                                        <Form.Control custom as='select' value={directionId ? directionId : defaultValues.directionId} onChange={(e) => setDirectionId(e.currentTarget.value)} required={!edit ? true : false} >
                                            <option value='0' >0 - Travel in one loop (e.g. No out/inbound travel)</option>
                                            <option value='1' >1 - Travel in one direction (e.g. outbound travel)</option>
                                            <option value='2' >2 - Travel in the opposite direction (e.g. inbound travel)</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col lg={3}>
                                    <Form.Group>
                                        <Form.Label >Can Skip</Form.Label>
                                        <Form.Switch id='waypoint-selected-isCanSkip' defaultChecked={isCanSkip ? isCanSkip : defaultValues.isCanSkip} onChange={(e) => setIsCanSkip(e.target.checked)} />
                                    </Form.Group>
                                </Col>
                                <Col lg={3} >
                                    <Form.Group>
                                        <Form.Label >Has Nearby Stop</Form.Label>
                                        <Form.Switch id='waypoint-selected-hasNearbyStop' defaultChecked={hasNearbyStop ? hasNearbyStop : defaultValues.hasNearbyStop} onChange={(e) => setHasNearbyStop(e.target.checked)} />
                                    </Form.Group>
                                </Col>
                                {/* <Col lg={2} >
                                    <Form.Group>
                                        <Form.Label >Active</Form.Label>
                                        <Form.Switch id='waypoint-selected-active' defaultChecked={isActive ? isActive : defaultValues.isActive} onChange={(e) => setIsActive(e.target.checked)} required={!edit ? true : false} />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            {
                                edit &&
                                <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            }
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Create' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row >
    )
}

const EditorialWaypoint = () => {
    const [route, setRoute] = useState(null)
    const [allStops, setAllStops] = useState(null)// all agency stops
    const [routeStops, setRouteStops] = useState(null)// all agency stops
    const [refresh, setRefresh] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [selectedWaypointIndex, setSelectedWaypointIndex] = useState(null)
    // const [populateFromCSV, setPopulateFromCSV] = useState(false)
    // const [file, setFile] = useState(null)
    // const [populatedStopName, setPopulatedStopName] = useState(null)

    const { routeId } = useParams()

    // const handleClearFile = () => {
    //     setFile(null)
    //     setPopulatedStopName(null)
    // }

    // const handleSubmitFile = (e) => {
    //     const f = e.target.files[0]
    //     const name = e.currentTarget.name
    //     if (f) {

    //         const reader = new FileReader()
    //         reader.onload = ({ target: { result } }) => {

    //             try {
    //                 const resArr = result.split('\r\n')
    //                 const headerArr = resArr.splice(0, 1)[0].split(',')

    //                 const dataObjArr = []

    //                 resArr.forEach(row => {
    //                     const rowArr = row.split(',')
    //                     headerArr.forEach((key, i) => {
    //                         if (key === 'stop_name') {
    //                             dataObjArr.push(rowArr[i])
    //                         }
    //                     })
    //                 })

    //                 // 
    //                 setPopulatedStopName(dataObjArr)

    //             } catch (e) {
    //                 toast.error('Please Provide Proper CSV file. Make sure to have stop_name list in the file')
    //                 handleClearFile()
    //             }
    //         }
    //         reader.readAsText(f)

    //         setFile([name, f])
    //     } else {
    //         setFile(null)
    //     }
    // }

    const handleGetRoute = useCallback(() => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.ROUTE_GET}/${routeId}`
        }).then((data) => {
            
            setRoute(data)
            setRouteStops(data.stops)
        }).catch(e => { })
    }, [routeId])

    const handleGetAllStops = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.STOP_GET_ALL}`
        }).then((data) => {
            // 
            setAllStops(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetRoute()
        handleGetAllStops()
    }, [refresh, handleGetRoute])

    const handleWaypointUpdate = (waypointId, data) => {
        if (!Object.keys(data).length > 0) return toast.warn('Nothing was updated')
        // 
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.WAYPOINT_UPDATE}/${waypointId}`,
            data
        }).then(() => {
            toast.success('Waypoint Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleWaypointDelete = useCallback(async (waypointId) => {
        setConfirmDelete(null)
        const { data } = await ApiRequest.fetch({
            method: 'delete',
            url: `${API_END_POINTS.WAYPOINT_UPDATE}/${waypointId}`,
        })
        
        toast.success('Waypoint Updated!')
        setRefresh(r => !r)
    }, [])

    const handleWaypointCreate = (index) => {
        const stopId = allStops[index].id
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.WAYPOINT_CREATE}/${routeId}`,
            data: { stopId }
        }).then(() => {
            toast.success('Waypoint Added!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    const handleEdit = (index) => {
        setSelectedWaypointIndex(index)
        setShowEditModal(true)
    }

    const allStopsTableHeader = useMemo(() => [
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Add',
            accessor: 'onAdd',
            disableFilters: true
        }
    ], [])

    const routeStopsTableHeader = useMemo(() => [
        {
            Header: 'Sequence',
            accessor: 'sequence',
            // disableFilters: true
        },
        {
            Header: 'Name',
            accessor: 'name',
            // disableFilters: true
        },
        {
            Header: 'Address',
            accessor: 'address',
            // disableFilters: true
        },
        {
            Header: 'Can Skip',
            accessor: 'isCanSkip_',
            // disableFilters: true
        },
        {
            Header: 'Has Nearby Stop',
            accessor: 'hasNearbyStop_',
            // disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'Delete',
            accessor: 'onDelete',
            disableFilters: true
        }
    ], [])


    const appendAllStopsAction = (data) => {
        data.forEach((row, i) => {
            row['onAdd'] = <PlusCircle className='g-hover-pointer text-primary' onClick={() => handleWaypointCreate(i)} />
        })
        return data
    }

    const appendRouteStopsAction = (data) => {
        data.forEach((row, i) => {
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['onDelete'] = <Trash className='g-hover-pointer text-danger' onClick={() => setConfirmDelete(row?.waypointId)} />
            row['isCanSkip_'] = String(row.isCanSkip).toUpperCase()
            row['hasNearbyStop_'] = String(row.hasNearbyStop).toUpperCase()
        })
        return data
    }

    const handleClickCancel = () => {
        setShowEditModal(false)
        setSelectedWaypointIndex(null)
    }

    const goTo = useHistory()

    const handleFilterForMarker = (stops) => {
        const markers = []
        stops.forEach(({ latitude, longitude, name, sequence }) => {
            markers.push([latitude, longitude, `${sequence} - ${name}`])
        })
        return markers
    }

    // const filterAllStops = useMemo(() => {
    //     if (populatedStopName) {
    //         const filtered = allStops.filter(({ name }) => {
    //             for (let i = 0; i < populatedStopName.length; i++) {
    //                 if (name === populatedStopName[i]) return true
    //             }
    //             return false
    //         })

    //         return filtered.reverse()
    //     }

    //     if (allStops) return allStops

    //     return []

    // }, [allStops, populatedStopName])

    return (
        <ContentWrapper>
            <Button className='mb-3' onClick={() => goTo.goBack()} >Back</Button>
            <Card>
                <Card.Body>
                    {
                        route &&
                        <h4>{route.shortName} - {route.name}</h4>
                    }
                </Card.Body>
            </Card>
            <Row >
                <Col className='mt-3' lg={4} md={12} >
                    <Card  >
                        {/* {
                            populateFromCSV ?
                                <Card.Body>
                                    <Card.Title >Populate from CSV file</Card.Title>
                                    <InputGroup className='overflow-hidden' >
                                        <InputGroup.Prepend ><InputGroup.Text>Stops for {route?.shortName} - {route?.name}</InputGroup.Text></InputGroup.Prepend>
                                        <FormFile className='overflow-hidden' id='populate-stop' name='populate-stop' custom accept='.csv' onChange={handleSubmitFile} label={file ? file[1].name : 'Select a csv file'} />
                                        {
                                            file &&
                                            <InputGroup.Append>
                                                <InputGroup.Text as='button' onClick={handleClearFile} title='Clear' ><X color='red' /></InputGroup.Text>
                                            </InputGroup.Append>
                                        }
                                    </InputGroup>
                                    {
                                        populatedStopName && 
                                        <Table columns={allStopsTableHeader} data={appendAllStopsAction(filterAllStops)} />
                                    }
                                    <Button className='mt-3 mr-3' onClick={() => setPopulateFromCSV(false)} variant='info' >Insert from All Stops</Button>
                                </Card.Body>
                                : */}
                        <Card.Body>
                            <Card.Title >All Stops</Card.Title>
                            {
                                allStops && allStops.length > 0 &&
                                <Table columns={allStopsTableHeader} data={appendAllStopsAction(allStops)} />
                            }
                            {/* <Button className='mt-3 mr-3' onClick={() => setPopulateFromCSV(true)} variant='info' >Populate from CSV</Button> */}
                            <Link to='/editorial/stop' >
                                <Button className='mt-3' >Create new Stop</Button>
                            </Link>
                        </Card.Body>
                        {/* } */}
                    </Card>
                </Col >
                <Col className='mt-3' lg={8} md={12} >
                    <Card >
                        <Card.Body>
                            <Card.Title>Preview</Card.Title>
                            <StaticMap markers={routeStops ? handleFilterForMarker(routeStops) : []} polylines={route && route.polygon ? [PolylineUtils.decode(route.polygon)] : []} polylinesPathOpt={[{ color: route && route.colorCode ? route.colorCode : 'red' }]} />
                        </Card.Body>
                    </Card>
                    {
                        routeStops && routeStops.length > 0 &&
                        <Card className='mt-3' >
                            <Card.Body>
                                <Card.Title >Waypoints - Route Stops</Card.Title>
                                <Table columns={routeStopsTableHeader} data={appendRouteStopsAction(routeStops)} />
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row >
            {
                confirmDelete && routeStops && <ConfirmModal description={`Are you sure to delete sequence ${routeStops.find(({ waypointId }) => +waypointId === +confirmDelete).sequence}? Action cant be undo.`} title="Confirm delete sequence?" open={!!confirmDelete} onCancel={() => setConfirmDelete(null)} onConfirm={() => handleWaypointDelete(confirmDelete)} />
            }
            {
                routeStops && routeStops.length > 0 &&
                <Modal title='Edit Waypoint Instance' show={showEditModal} onHide={handleClickCancel} >
                    <WaypointForm edit maxSequenceNumber={routeStops.length} defaultValues={routeStops[selectedWaypointIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleWaypointUpdate} />
                </Modal>
            }
        </ContentWrapper >
    )
}

export default EditorialWaypoint