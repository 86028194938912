import { connect } from "react-redux"
import { requestUserExp, requestUserExpUpdate, requestPJCityBusUserExp } from "../../redux/actions"
import TripExperiencePage from "./TripExperiencePage"

const mapStateToProps = ({ userExp = [], user, pjCityBusUserExp = []}) => {
    const feedback = userExp.filter(({ feedback: f }) =>
        Boolean(f !== null && f !== "-")
    )
    const tripExperience = userExp.length > 0 ? userExp : null
    const pjCityBusExperience = pjCityBusUserExp.length > 0 ? pjCityBusUserExp : null

    return {
        tripExperience,
        feedback,
        agencyId: user?.agencyId || null,
        pjCityBusExperience
    }
}

export default connect(mapStateToProps, {
    requestUserExp, requestPJCityBusUserExp, requestUserExpUpdate
})(TripExperiencePage)