import { all } from 'redux-saga/effects';
import { watchUserRequests } from './user.saga';
import { watchUserExpRequests } from './userExp.saga';
import { watchTripSchedulerRequests } from './tripScheduler.saga';

export default function* rootSaga() {
  yield all([
    watchUserRequests(),
    watchUserExpRequests(),
    watchTripSchedulerRequests()
  ]);
}