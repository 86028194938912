import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Button, Card, Col, Collapse, Form, Row,Alert } from 'react-bootstrap'
import { Globe, PencilSquare, PlusCircle, Trash,InfoCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment';

import { ContentWrapper, EditorMap, Modal, StaticMap, Table, StatCard, ConfirmModal } from '../../components'
import { NoDataInfo } from '../../components/Alert'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import "./ScheduleV2TripPage.css";
import EventForm from '../Schedule/EventForm'
import Timetable from '../Schedule/Timetable'
import { ToggleButton } from './toggleButton'
// import { requestTripSchedulerCreate, requestTripSchedulerUpdate } from '../../redux/actions'

// stops
// - name
// - address
// -latitude: 3.10406750238446
// -longitude: 101.645327729448
// - agency_id
// - is_active

const TripForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { vehicleId: '', driverId: '', isActive: '' } }) => {
    // const [name, setname] = useState(null)
    // const [address, setAddress] = useState(null)
    const [isActive, setIsActive] = useState(null)
    // const [newMarker, setNewMarker] = useState(null)
    // const [lat, setLat] = useState(defaultValues.latitude || null)
    // const [long, setLong] = useState(defaultValues.longitude || null)
    const [driverId, setDriverId] = useState(null)
    const [vehicleId, setVehicleId] = useState(null)

    const [vehicles, setVehicles] = useState([])
    const [drivers, setDrivers] = useState([])

    const handleGetVehicles = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.VEHICLE_GET_ALL}`
        }).then((data) => {
            setVehicles(data.map(({ registrationNumber, id }) => ({ name: registrationNumber, id })))
        }).catch(e => { })
    }


    const handleGetDrivers = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DRIVER_GET_ALL}`
        }).then((data) => {
            setDrivers(data.map(({ firstName, lastName, id }) => ({ name: `${firstName} ${lastName}`, id })))
        }).catch(e => { })
    }


    useEffect(() => {
        handleGetDrivers()
        handleGetVehicles()
    }, [])

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (driverId !== null) {
            data.driverId = driverId
        }
        if (vehicleId !== null) {
            data.vehicleId = vehicleId
        }
        if (isActive !== null) {
            data.isActive = isActive
        }
        // if (lat !== null && long !== null) {

        //     data.latitude = lat
        //     data.longitude = long
        // }
        // if (newMarker !== null) {
        //     data.latitude = newMarker[0]
        //     data.longitude = newMarker[1]
        // }

        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    // const handleNewMarkerAdded = (pos) => {
    //     setNewMarker(pos)
    // }

    // const preloadMarkerPos = useMemo(()=>{
    //     const latitude = defaultValues.latitude
    //     const longitude = defaultValues.longitude
    //     const name = defaultValues.name
    //     if(latitude !== '' && longitude !== ''){
    //         return [latitude, longitude, name]
    //     }else{
    //         return []
    //     }
    // },[defaultValues])

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit}  >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {
                                !edit &&
                                <Card.Title></Card.Title>
                            }
                            {/* <EditorMap title='Select New Stop Location' editMarker onMarkerAdded={handleNewMarkerAdded} preloadMarker={preloadMarkerPos} /> */}
                            <Form.Row className='mt-0' >
                                <Col className='m-0' md={6} >
                                    {/* <Form.Group>
                                        <Form.Label >Stop Name</Form.Label>
                                        <Form.Control onChange={(e) => setname(e.currentTarget.value)} required={!edit ? true : false} placeholder='Jaya One' defaultValue={defaultValues.name} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label >Description</Form.Label>
                                        <Form.Control as='textarea' required={!edit ? true : false} onChange={(e) => setAddress(e.currentTarget.value)} placeholder='Description' defaultValue={defaultValues.address} />
                                    </Form.Group> */}

                                    <Form.Group className="mt-2">
                                        <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Vehicle:</Form.Label>
                                        {<Form.Control className="mt-2" required as='select' value={vehicleId ? vehicleId : defaultValues.vehicleId ? defaultValues.vehicleId : null} onChange={(e) => setVehicleId(e.target.value)}>

                                            {/* <option disabled value={-1} key={-1} >{defaultValues.vehicleId} </option> */}
                                            <option disabled value={-1} key={-1} ></option>


                                            {vehicles.length > 0 && vehicles.map((vehicle) =>

                                                <option key={vehicle.id} value={vehicle.id} >{vehicle.name} </option>

                                            )}

                                        </Form.Control>}

                                    </Form.Group>
                                </Col>
                                <Col className='m-0' md={6} >

                                    <Form.Group className="mt-3">
                                        <Form.Label style={{ fontWeight: 'bold', fontSize: '15px' }}>Driver:</Form.Label>
                                        <Form.Control required as='select' value={driverId ? driverId : defaultValues.driverId ? defaultValues.driverId : null} onChange={(e) => setDriverId(e.target.value)}>

                                            <option disabled value={-1} key={-1}> </option>

                                            {drivers.length > 0 && drivers.map((driver) =>

                                                <option key={driver.id} value={driver.id} >{driver.name} </option>

                                            )}

                                        </Form.Control>

                                    </Form.Group>
                                </Col>
                                {
                                    edit &&
                                    <Form.Group style={{ marginLeft: 15 }}>
                                        <Form.Label >Active</Form.Label>
                                        <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active-stop' />
                                    </Form.Group>
                                }
                                {/* <Col className='m-0' md={4} >
                                    <Form.Group>
                                        <Form.Label >Latitude</Form.Label>
                                        <Form.Control required={!edit ? true : false} placeholder='Latitude' onChange={e => setLat(e.target.value)} value={newMarker !== null ? newMarker[0] : lat !== null ? lat : defaultValues.latitude} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label >Longitude</Form.Label>
                                        <Form.Control required={!edit ? true : false} placeholder='Longitude' onChange={e => setLong(e.target.value)} value={newMarker !== null ? newMarker[1] : long !== null ? long : defaultValues.longitude} />
                                    </Form.Group>
                                </Col> */}
                            </Form.Row>
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Add Stop' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}

const ScheduleV2TripPage = ({ tripScheduler, requestTripScheduler, requestTripSchedulerCreate, requestTripSchedulerUpdate }) => {
    const [add, setAdd] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [addCollapse, setAddCollapse] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [selectedStopIndex, setSelectedStopIndex] = useState(null)
    const [stops, setStops] = useState(null)
    const [trips, setTrips] = useState(null)
    const [isTableView, setIsTableView] = useState(true)

    const [todayStats, setTodayStats] = useState(null)

    const [openForm, setOpenForm] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const handleWaypointDelete = useCallback(async (Id) => {
        setConfirmDelete(null)
        const { data } = await ApiRequest.fetch({
            method: 'delete',
            url: `${API_END_POINTS.TRIP_UPDATE_BY_ID}/${Id}`,
        })
        
        toast.success('Trips Updated!')
        setRefresh(r => !r)
    }, [])

    const handleGetTodayStat = () => {
        ApiRequest.fetch({
            method: 'get',
            url: API_END_POINTS.TODAY_SCHEDULER_STAT
        }).then((data) => {
            
            const active = data.filter(({ scheduledAt, startedAt }) => (new Date(scheduledAt).getTime() >= Date.now()) && startedAt === null)
            const completed = data.filter(({ endedAt }) => (endedAt !== null))
            const missed = data.filter(({ scheduledAt, startedAt }) => (new Date(scheduledAt).getTime() < Date.now() && startedAt === null))
            setTodayStats({ active, completed, missed })
        }).catch(e => { })
    }

    const createTripScheduler = () => {
        ApiRequest.fetch({
            method: 'get',
            url: API_END_POINTS.TRIP_SCHEDULER_PAST_WEEK,

        }).then((data) => {
            
            setTrips(data)
        }).catch(e => { });

    }

    useEffect(() => {
        handleGetTodayStat()
        // createTripScheduler()
    }, [])


    const tableHeader = useMemo(() => [
        {
            Header: 'Trip Id',
            accessor: 'id'
            // disableFilters: true,
        },

        {
            Header: 'Route Name',
            accessor: 'routeName',
            // disableFilters: true
        },
        {
            Header: 'Direction',
            accessor: 'direction_',
            // disableFilters: true
        },
        {
            Header: 'Driver Name',
            accessor: 'driverName',
            // disableFilters: true
        },
        {
            Header: 'Vehicle No.',
            accessor: 'vehicleRegistrationNumber',
            // disableFilters: true
        },
        {
            Header: 'Assigned Driver Name',
            accessor: 'driverNameAssigned',
            // disableFilters: true
        },
        {
            Header: 'Assigned Vehicle No.',
            accessor: 'vehicleRegistrationNumberAssigned',
            // disableFilters: true
        },
       
        {
            Header: 'Scheduled At',
            accessor: 'scheduledAt_',
            disableFilters: true
        },
        {
            Header: 'Started At',
            accessor: 'startedAt_',
            disableFilters: true
        },
        {
            Header: 'Ended At',
            accessor: 'endedAt_',
            disableFilters: true
        },
        {
            Header: 'Status',
            accessor: 'status',
            disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            disableFilters: true
        },
        {
            Header: 'Last Modified',
            accessor: 'modifiedAt_',
            disableFilters: true
        },
        {
            Header: 'Is Active',
            accessor: 'isActive_',
            disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'Delete',
            accessor: 'onDelete',
            disableFilters: true
        }

    ], [])

    const handleGetStops = () => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.STOP_GET_ALL}`
        }).then((data) => {
            // 
            setStops(data)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetStops()
        createTripScheduler()
        requestTripScheduler()

    }, [refresh])

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleClickCancel = () => {
        setSelectedStopIndex(null)
        setAdd(false)
        setShowEditModal(false)
    }

    const handleTripUpdate = (id, data) => {
        
        
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.TRIP_SCHEDULER}/${id}`,
            data
        }).then(() => {
            toast.success('Trip Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }



    const handleEdit = (index) => {
        setShowEditModal(true)
        setSelectedStopIndex(index)
    }

    const handlePreviewRoute = (index) => {
        setShowPreviewModal(true)
        setSelectedStopIndex(index)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['onEdit'] = <PencilSquare style={{ opacity: moment().isBefore(row?.scheduledAt)  ? 1 : 0.4 }} className='g-hover-pointer text-primary' onClick={() => {
                if (moment().isBefore(row?.scheduledAt)) handleEdit(i)
            }} />
            row['onDelete'] = <Trash style={{ opacity: moment().isBefore(row?.scheduledAt) ? 1 : 0.4 }} className='g-hover-pointer text-danger' onClick={() => {
                if (moment().isBefore(row?.scheduledAt)) {
                    setConfirmDelete(row?.id)
                }
            }
            } />

            // row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            // row['createdAt_'] = <p>{`${moment(row.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}`}</p>

            row['createdAt_'] = <p>{`${moment(row.createdAt).format('YYYY-MM-DD hh:mm A (dddd)')}`}</p>

            if (row.startedAt) {

                row['startedAt_'] = <p>{`${moment(row.startedAt).format('YYYY-MM-DD hh:mm A (dddd)')}`}</p>
            }
            if (row.endedAt) {

                row['endedAt_'] = <p>{`${moment(row.endedAt).format('YYYY-MM-DD hh:mm A (dddd)')}`}</p>
            }
            row['scheduledAt_'] = <p>{`${moment(row.scheduledAt).format('YYYY-MM-DD hh:mm A (dddd)')}`}</p>
            row['isActive_'] = <p>{String(row.isActive)}</p>

            row['modifiedAt_'] = <p>{row.updatedAt ? `${moment(row.updatedAt).format('YYYY-MM-DD hh:mm A (dddd)')}` : ''}</p>
            // row['modifiedAt_'] = <p>{row.updatedAt ? `${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}` : ''}</p>

            const active = new Date(row.scheduledAt).getTime() >= Date.now() && row.startedAt === null
            const completed = row.endedAt !== null
            const missed = (new Date(row.scheduledAt).getTime() < Date.now() && row.startedAt === null)
            row['status'] = <p>{completed ? 'completed' : active ? 'active' : 'missed'}</p>
            row['direction_'] = <p>{row.obIb == 0 ? 'Loop' : row.obIb == 1 ? 'Outbound' : 'Inbound'}</p>

            // 1-outbound

        })
        return data
    }
    const handleOpenForm = () => {
        setOpenForm(true)
    }
    const handleCloseForm = () => {
        setOpenForm(false)
        setSelectedEvent(null)
    }
    const handleEventCardClick = (eData) => {
        setOpenForm(true)
        setSelectedEvent(eData)
    }
    const handleSubmitForm = (data) => {
        
        // submit form
        // use redux
        // setRefresh(r => !r)
        ApiRequest.fetch({
            method: 'post',
            url: API_END_POINTS.TRIP_SCHEDULER,
            data
        }).then(() => {
            toast.success('Trip Added!!')
            setRefresh(!refresh)
        }).catch(e => { });
        // requestTripSchedulerCreate(form)

        // 
    }

    const handleUpdateForm = (form) => {
        // update form
        // use redux
        requestTripSchedulerUpdate(form)
        // setRefresh(r => !r)

        // 
    }

    return (
        <>
            <ContentWrapper style={{ marginTop: 10, paddingTop: 10 }}>
                {/* <h2 className='mb-3' >Scheduled Trips</h2>
                <div className='divider' /> */}
                <Alert className='mt-0' variant='info' > <InfoCircle /> To avoid conflicts, use "Schedule Ad-hoc Trip" to schedule trip other than the timetable, only when required.<br></br>
                  </Alert>
                <Button onClick={handleOpenForm} className='col-sm-12 mb-5 py-2 mt-0' >Schedule Ad-hoc Trip</Button>
                <Row>
                    <Col md={10}>   <h4>Today's Stats </h4></Col>
                    <Col md={2}><h4> <ToggleButton
                    selected={isTableView}
                    toggleSelected={() => {
                        setIsTableView(!isTableView);
                    }}
                    
                /></h4></Col>
                </Row>
              
                <Row>
                    <StatCard lg={4} md={4} sm={12} data={todayStats?.active.length || 0} text='Active' />
                    <StatCard lg={4} md={4} sm={12} data={todayStats?.completed.length || 0} text='Completed' />
                    <StatCard lg={4} md={4} sm={12} data={todayStats?.missed.length || 0} text='Missed' />
                </Row>
               
                    <EventForm data={selectedEvent} onUpdate={handleUpdateForm} onSubmit={handleSubmitForm} open={openForm} onClose={handleCloseForm} />
                {
                 isTableView &&   trips && trips.length > 0 ?
                        <Table numbering title='List of Scheduled Trips' titleLeft='NOTE: Tabular trip log is available for next 7 days' columns={tableHeader} data={appendAction(trips)} />
                        :
                        isTableView ?  <NoDataInfo>
                            No Trips added yet.<br />
                            {/* To add a stop simply click the <PlusCircle /> icon above.<br /><br />
                            Or if you have GTFS stops.txt file, we suggest using our <Link to='/quick-start-gtfs' >Quick Start GTFS</Link> */}
                        </NoDataInfo> :<></>
                }

                {!isTableView && <Card className='mt-3' >
                    <Card.Body >
                        <Card.Title >Schedule Overview</Card.Title>
                        <Timetable data={tripScheduler} onEventCardClick={handleEventCardClick} />
                    </Card.Body>
                </Card>}
            </ContentWrapper>
            {
                confirmDelete && trips && <ConfirmModal description={`Are you sure, you want to delete the trip? Action can't be undone.`} title="Confirm delete?" open={!!confirmDelete} onCancel={() => setConfirmDelete(null)} onConfirm={() => handleWaypointDelete(confirmDelete)} />
            }

            {
                trips && selectedStopIndex !== null &&
                <Modal size='xl' title={`Edit TripId ${trips[selectedStopIndex].id}`} show={showEditModal} onHide={() => setShowEditModal(false)} >
                    <TripForm edit defaultValues={trips[selectedStopIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleTripUpdate} />
                </Modal>
            }
        </>
    )
}

export default ScheduleV2TripPage