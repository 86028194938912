import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, Button, Card, Col, Form, InputGroup, Row, Spinner } from 'react-bootstrap'
import { ArrowRepeat, PlusCircle, Clipboard, ExclamationCircle, PencilSquare  } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import { ConfirmModal, ContentWrapper, Modal, Table } from '../../components'
import { API_END_POINTS } from '../../config'
import { ApiRequest } from '../../helpers/api-request'
import { NoDataInfo } from '../../components/Alert'
import { IconButton } from '@material-ui/core'

const DeviceForm = ({ edit, onClickCancel, onSubmitForm, defaultValues = { FPUserId: '', } }) => {
    const [userId, setUserId] = useState(null)

    const handleBeforeSubmit = (e) => {
        e.preventDefault()

        const data = {}

        if (userId !== null) {
            data.userId = userId
        }

        const dataId = defaultValues.id
        onSubmitForm(dataId, data)
        onClickCancel()
    }

    return (
        <Row>
            <Col className='pt-3' lg={12} >
                <Form onSubmit={handleBeforeSubmit} >
                    <Card className={` ${!edit ? 'bg-primary text-light' : ''} `} >
                        <Card.Body>
                            {/* {
                                !edit &&
                                <Card.Title>Edit Device</Card.Title>
                            } */}
                            <Row>
                                <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >Fasspay User ID</Form.Label>
                                        <Form.Control onChange={(e) => setUserId(e.currentTarget.value)} required={!edit ? true : false} placeholder='Fasspay User ID' defaultValue={defaultValues.FPUserId} />
                                    </Form.Group>
                                </Col>
                                {/* <Col md={6} sm={12} >
                                    <Form.Group>
                                        <Form.Label >Last Name</Form.Label>
                                        <Form.Control onChange={(e) => setLastName(e.currentTarget.value)} required={!edit ? true : false} placeholder='Smith' defaultValue={defaultValues.lastName} />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            {/* <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label >Identification Number</Form.Label>
                                        <Form.Control onChange={(e) => setIdentificationNumber(e.currentTarget.value)} required={!edit ? true : false} placeholder='3283892-33-7328' defaultValue={defaultValues.identificationNumber} />
                                    </Form.Group>
                                </Col>
                                {
                                    edit &&
                                    <Col>
                                        <Form.Group>
                                            <Form.Label >Active</Form.Label>
                                            <Form.Check type='switch' onChange={(e) => setIsActive(e.target.checked)} defaultChecked={defaultValues.isActive} id='edit-changes-active-stop' />
                                        </Form.Group>
                                    </Col>
                                }
                            </Row> */}
                            {/* <h5>Fill the below details if applicable:</h5> */}

                            {/* <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label >STAFF ID:</Form.Label>
                                        <Form.Control  onChange={(e) => setStaffId(e.currentTarget.value)} defaultValue={defaultValues.staffId} />
                                    </Form.Group>
                                </Col>
                            </Row> */}
                        </Card.Body>
                        <Card.Footer className='d-flex justify-content-end' >
                            <Button className='mx-1' variant='warning' onClick={onClickCancel} >Cancel</Button>
                            <Button className='mx-1' variant={!edit ? 'light' : 'primary'} type='submit' >{!edit ? 'Add Driver' : 'Save Changes'}</Button>
                        </Card.Footer>
                    </Card>
                </Form>
            </Col>
        </Row>
    )
}


const DeviceResetModal = ({ onClickCancel, isReset, id }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(false)
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)

    const handleBeforeSubmit = useCallback((e) => {
        e.preventDefault()
        setLoading(true)
        if (isReset) {
            setIsOpenConfirm(true)
        } else {
            handleCreateDevice()
        }
    }, [])

    const handleResetToken = useCallback((id) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.DEVICE_RESET_CODE}/${id}`,
        }).then((data) => {
            toast.success('Device Token has been reset')
            // 
            setData(data)
        }).catch()
            .finally(() => setLoading(false))
    }, [toast])

    const handleCreateDevice = useCallback(() => {
        ApiRequest.fetch({
            method: 'post',
            url: `${API_END_POINTS.DEVICE_CREATE}`,
        }).then((data) => {
            toast.success('New Device added')
            // 
            setData(data)
        }).catch()
            .finally(() => setLoading(false))
    }, [toast])

    const handleCopy = () => {
        const idText = document.getElementById('accessid')
        const codeText = document.getElementById('accesscode')

        navigator.clipboard.writeText(JSON.stringify({
            accessCode: codeText.value,
            accessId: idText.value
        }))
            .then(() => {
                toast.success('Copied!')
            })
    }

    const handleOnConfirm = useCallback(() => {
        handleResetToken(id)
        setIsOpenConfirm(false)
    }, [id])

    const handleOnConfirmCancel = useCallback(() => {
        setLoading(false)
        setIsOpenConfirm(false)
        toast.info('Reset device Token cancelled')
    }, [toast])

    return (
        <>
            <ConfirmModal
                title='Reset Device Token?'
                description={
                    <span>
                        Are you sure to reset this device token?<br />
                        Doing so will remove all previous paired data
                    </span>
                }
                onCancel={handleOnConfirmCancel}
                onConfirm={handleOnConfirm}
                open={isOpenConfirm} />
            <Row>
                <Col className='pt-3' lg={12} >
                    <Form onSubmit={handleBeforeSubmit} >
                        <Card >
                            <Card.Body >
                                {
                                    data ?
                                        <div>
                                            <Alert variant='danger' >
                                                Please <b>SAVE</b> these Access Tokens somewhere you can easily refer back.<br />
                                                This tokens will <b>Not be showing again</b> once this window is closed.
                                            </Alert>
                                            <Alert className='text-right' variant='success' value='hello' >
                                                <IconButton onClick={handleCopy} color='primary' title='Copy' >
                                                    <Clipboard size={20} />
                                                </IconButton>
                                                <InputGroup size='lg' className='my-2' >
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>Access ID</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control id='accessid' readOnly value={data.accessId} />
                                                </InputGroup>
                                                <InputGroup size='lg' className='my-2' >
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text>Access Code</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <Form.Control id='accesscode' readOnly value={data.accessCode} />
                                                </InputGroup>
                                            </Alert>
                                        </div>
                                        :
                                        <div>
                                            {isReset &&
                                                <Alert variant='danger' className='text-center' >
                                                    <ExclamationCircle size={30} className='w-100' /><br />
                                                    This process will <b>remove</b> the previous paired vehicle data<br />
                                                    Action is not <b>reversible</b>
                                                </Alert>
                                            }
                                            <h5>Click the {isReset ? 'Reset' : 'Add'} button to proceed</h5>
                                        </div>
                                }
                                {
                                    loading &&
                                    <div className='d-flex justify-content-center align-items-center' >
                                        <Spinner className='text-primary' animation="border" />
                                    </div>
                                }
                            </Card.Body>
                            <Card.Footer className='d-flex justify-content-end' >
                                <Button className='mx-1' variant='warning' onClick={onClickCancel} >Close</Button>
                                {
                                    !data && <Button className='mx-1' variant='primary' type='submit' >{isReset ? 'Reset' : 'Add'}</Button>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

const UnusedTokensTable = ({ unusedTokens = null }) => {
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null)
    const [showResetTokenModal, setShowResetTokenModal] = useState(false)

    const handleEdit = (index) => {
        setSelectedDeviceIndex(index)
        setShowResetTokenModal(true)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['resetAccessTokens'] = <ArrowRepeat className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['updatedAt_'] = row.updatedAt ? <p>{`${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}`}</p> : <p></p>
            // row['updatedAt_'] = <p>{`${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}`}</p>
        })
        return data
    }

    const handleClickCancel = () => {
        setSelectedDeviceIndex(null)
        setShowResetTokenModal(false)
    }

    const tableHeader = useMemo(() => [
        {
            Header: 'Access ID',
            accessor: 'accessId',
            // disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            // disableFilters: true
        },
        {
            Header: 'Updated At',
            accessor: 'updatedAt_',
            // disableFilters: true
        },
        {
            Header: 'Reset Device',
            accessor: 'resetAccessTokens',
            disableFilters: true
        }
    ], [])

    return (
        <>
            {
                unusedTokens && unusedTokens.length > 0 &&
                <Table numbering title='List of Unused Tokens' columns={tableHeader} data={appendAction(unusedTokens)} />
            }
            {
                unusedTokens && selectedDeviceIndex !== null &&
                <Modal closeButton={false} title={`Reset Device for Access ID ${unusedTokens[selectedDeviceIndex].accessId}`} show={showResetTokenModal} >
                    <DeviceResetModal id={unusedTokens[selectedDeviceIndex].id} isReset onClickCancel={handleClickCancel} />
                </Modal>
            }
        </>
    )
}

const EditorialDevice = () => {
    const [add, setAdd] = useState(false)
    const [refresh, setRefresh] = useState(false)
    // const [addCollapse, setAddCollapse] = useState(false)
    const [devices, setDevices] = useState(null)
    const [unusedTokens, setUnusedTokens] = useState(null)
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showResetTokenModal, setShowResetTokenModal] = useState(false)

    const handleToggleAddForm = () => {
        setAdd(!add)
    }

    const handleEdit = (index) => {
        setSelectedDeviceIndex(index)
        setShowEditModal(true)
    }

    const handleReset = (index) => {
        setSelectedDeviceIndex(index)
        setShowResetTokenModal(true)
    }

    const appendAction = (data) => {
        data.map((row, i) => {
            row['resetAccessTokens'] = <ArrowRepeat className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['onEdit'] = <PencilSquare className='g-hover-pointer text-primary' onClick={() => handleEdit(i)} />
            row['resetAccessTokens'] = <ArrowRepeat className='g-hover-pointer text-primary' onClick={() => handleReset(i)} />
            row['createdAt_'] = <p>{`${new Date(row.createdAt).toLocaleDateString()} ${new Date(row.createdAt).toLocaleTimeString()}`}</p>
            row['updatedAt_'] = <p>{`${new Date(row.updatedAt).toLocaleDateString()} ${new Date(row.updatedAt).toLocaleTimeString()}`}</p>
        })
        return data
    }

    const handleGetDevices = useCallback(() => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DEVICE_FIND_ALL}`
        }).then((data) => {
            // 
            setDevices(data)
        }).catch()

        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.AGENCY_ALL}`
        }).then((data) => {
            console.log("AGENCIES", data);
        }).catch()
    })

    const handleGetUnusedTokens = useCallback(() => {
        ApiRequest.fetch({
            method: 'get',
            url: `${API_END_POINTS.DEVICE_FIND_UNUSED_TOKEN}`
        }).then((data) => {
            setUnusedTokens(data)
        }).catch()
    }, [])

    const handleClickCancel = () => {
        setSelectedDeviceIndex(null)
        setAdd(false)
        setShowResetTokenModal(false)
    }
    const handleDeviceUpdate = (id, data) => {
        ApiRequest.fetch({
            method: 'put',
            url: `${API_END_POINTS.DEVICE_UPDATE_USER_ID}/${id}`,
            data
        }).then(() => {
            toast.success('Device Updated!')
            setRefresh(!refresh)
        }).catch(e => { })
    }

    useEffect(() => {
        handleGetDevices()
        handleGetUnusedTokens()
    }, [refresh])

    const tableHeader = useMemo(() => [
        {
            Header: 'Access ID',
            accessor: 'accessId',
            // disableFilters: true
        },
        {
            Header: 'Model Name',
            accessor: 'modelName',
            // disableFilters: true
        },
        {
            Header: 'OS Version',
            accessor: 'osVersion',
            // disableFilters: true

        },
        {
            Header: 'Serial No.',
            accessor: 'serialNumber',
            // disableFilters: true

        },
        {
            Header: 'Paired Vehicle',
            accessor: 'registrationNumber',
            // disableFilters: true
        },
        {
            Header: 'IMEI No.',
            accessor: 'imei',
            // disableFilters: true
        },
        {
            Header: 'App Version',
            accessor: 'appVersion',
            // disableFilters: true
        },
        {
            Header: 'Fasspay User ID',
            accessor: 'FPUserId',
            // disableFilters: true
        },
        {
            Header: 'Created At',
            accessor: 'createdAt_',
            // disableFilters: true
        },
        {
            Header: 'Updated At',
            accessor: 'updatedAt_',
            // disableFilters: true
        },
        {
            Header: 'Edit',
            accessor: 'onEdit',
            disableFilters: true
        },
        {
            Header: 'Reset Device',
            accessor: 'resetAccessTokens',
            disableFilters: true
        }
    ], [])

    return (
        <>
            <ContentWrapper>
                <h2 className='mb-3' >Device Editorial</h2>
                <div className='divider' />
                <Row >
                    <Col className='pt-3 text-dark' md={10} sm={12} >
                        <Card className='h-100' >
                            <Card.Body>
                                <Card.Title >Active Device Count</Card.Title>
                                {
                                    devices &&
                                    <h1>{devices.length}</h1>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className='pt-3' md={2} sm={12} >
                        <Card onClick={handleToggleAddForm} className='bg-primary btn btn-outline-light h-100' >
                            <Card.Body className='d-flex justify-content-center align-items-center' >
                                <PlusCircle size={50} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    devices && devices.length > 0 ?
                        <Table numbering title='List of Added Device' columns={tableHeader} data={appendAction(devices)} />
                        :
                        <NoDataInfo>
                            No device added yet.<br />
                            To add a device simply click the <PlusCircle /> icon above.
                        </NoDataInfo>
                }
                {
                    unusedTokens &&
                    <UnusedTokensTable unusedTokens={unusedTokens} />
                }
            </ContentWrapper>
            {
                devices && selectedDeviceIndex !== null &&
                <Modal title={"Edit Fasspay User ID"} show={showEditModal} onHide={handleClickCancel} >
                    <DeviceForm edit defaultValues={devices[selectedDeviceIndex]} onClickCancel={handleClickCancel} onSubmitForm={handleDeviceUpdate} />
                </Modal>
            }            {
                devices && selectedDeviceIndex !== null &&
                <Modal closeButton={false} title={`Reset Device for Device ${devices[selectedDeviceIndex].modelName}`} show={showResetTokenModal} >
                    <DeviceResetModal id={devices[selectedDeviceIndex].id} isReset onClickCancel={handleClickCancel} />
                </Modal>
            }
            <Modal closeButton={false} title='Add New Device' show={add} >
                <DeviceResetModal onClickCancel={handleClickCancel} />
            </Modal>
        </>
    )
}

export default EditorialDevice