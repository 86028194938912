
import { connect } from "react-redux"
import { requestTripScheduler, requestTripSchedulerCreate, requestTripSchedulerUpdate } from "../../redux/actions"
import ScheduleV2TripPage from "./ScheduleV2TripPage";

const mapStateToProps = ({ tripScheduler = [] }) => {
    return {
        tripScheduler
    }
}

export default connect(mapStateToProps, {
    requestTripScheduler, requestTripSchedulerCreate, requestTripSchedulerUpdate
})(ScheduleV2TripPage)