import { connect } from "react-redux";
import ApadPmhsScoreboardPage from "./ApadPmhsScoreboardPage";

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(
    mapStateToProps,
    {}
) (ApadPmhsScoreboardPage)